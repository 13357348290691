import Typography from 'widgets/Typography/Typography';
import React from 'react';

function NoDataFoundForSelectedFilters() {
  return (
    <Typography
      sx={{
        color: 'var(--Body-Text-Light, #605E5E)',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '-0.132px',
        mt: 1,
        ml: 2,
      }}
      label='No data found for selected filters'
    />
  );
}

export default NoDataFoundForSelectedFilters;
