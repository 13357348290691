/** @format */

import {
  Modal,
  Box,
  Card,
  CardActionArea,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import env from 'envConfig';
import React from 'react';
import { CoachingMoment } from 'types/VideoPreview-types';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Loader from 'widgets/Loader/Loader';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import CloseIcon from '@mui/icons-material/Close';
import { formatTimeInMinSecs } from 'utils/formatTimeInMinSecs';
import Typography from 'widgets/Typography/Typography';

function TipCascadeModal({
  tipCascadeModalOpen,
  handleTipCascadeModalClose,
  cascadeRef,
  videoData,
  setCurrentIndex,
  handleTileClick,
  highlightedIndex,
  highlightedCardRef,
  videoPlaying,
  getEmotionName,
  vidRef,
  handlePlay,
  currentIndex,
  filteredSegments,
}: any) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    minHeight: isMobile || isTablet ? '30%' : '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };
  return (
    <Modal
      open={tipCascadeModalOpen}
      onClose={handleTipCascadeModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <IconButton
          aria-label='close'
          onClick={handleTipCascadeModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div ref={cascadeRef}>
          {filteredSegments.map((item: any, index: number) => {
            const coachingMoment = videoData.coaching_moments.find(
              (moment: CoachingMoment) =>
                moment.delivery_time_stamp === item.start_time_stamp
            );

            const handleBackward = () => {
              if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
              }
            };

            const handleForward = () => {
              const nextIndex = currentIndex + 1;
              if (nextIndex < knowledgeTipsWithMedia.length) {
                setCurrentIndex(nextIndex);
              }
            };

            const knowledgeTipsWithMedia =
              coachingMoment?.knowledge_tips.filter(
                (tip: any) => tip.media_link !== null
              );

            const isSingleTipWithMedia = knowledgeTipsWithMedia.length === 1;
            const disableForward =
              knowledgeTipsWithMedia.length <= 1 ||
              currentIndex >= knowledgeTipsWithMedia.length - 1;
            const disableBackward = currentIndex === 0;

            return (
              highlightedIndex === index && (
                <Box
                  key={index}
                  sx={{
                    // position: 'absolute',
                    // border: '1px solid red',
                    p: 5,
                    mt: 1,
                    overflowY: 'scroll',
                    margin: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    height: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    <Typography
                      sx={{
                        color: '#0E4EEC',
                        backgroundColor: '#E2FCFF',
                        width: 'fit-content',
                        height: 'fit-content',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        borderRadius: '35px',
                      }}
                      label=''
                    >
                      {formatTimeInMinSecs(Math.floor(item.start_time_stamp))}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>
                      {item.flag_id === 1 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'red' }}
                          className='rotate-icon'
                        />
                      )}
                      {item.flag_id === 2 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'yellow' }}
                          className='rotate-icon'
                        />
                      )}
                      {item.flag_id === 3 && (
                        <BookmarkIcon
                          sx={{ marginRight: '5px', color: 'green' }}
                          className='rotate-icon'
                        />
                      )}
                      {getEmotionName(item.emotion_id).toUpperCase()}
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography variant='h6' label='Suggestions' />
                    <Typography label=''>
                      {isSingleTipWithMedia
                        ? '1/1'
                        : `${currentIndex + 1}/${
                            knowledgeTipsWithMedia.length
                          }`}
                    </Typography>{' '}
                  </Box>
                  <Typography label={item.time_segment_label} />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from propagating
                        handleBackward();
                      }}
                      className={`arrow-icon ${
                        disableBackward ? 'disabled' : ''
                      }`}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <video
                      ref={vidRef}
                      controls
                      style={{ width: '90%', height: '35vh' }}
                      onPlay={handlePlay}
                      // onEnded={handleEnd}
                      // onPause={handleEnd}
                      src={
                        knowledgeTipsWithMedia[currentIndex]
                          ? `${env.REACT_APP_BASE_URL}/${knowledgeTipsWithMedia[currentIndex].media_link}`
                          : ''
                      }
                    ></video>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from propagating
                        handleForward();
                      }}
                      className={`arrow-icon ${
                        disableForward ? 'disabled' : ''
                      }`}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )
            );
          })}
        </div>
      </Box>
    </Modal>
  );
}

export default TipCascadeModal;
