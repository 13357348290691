import { Box, Button, Divider } from '@mui/material';
import axios from 'axios';
import DragDropIcon from 'icons/DragDropIcon';
import DragDropIconDemoSandoBoxPreview from 'icons/DragDropIconDemoSandoBoxPreview';
import React, { useEffect, useState } from 'react';
import { ProtectedUrls } from 'routes/urls';
import sandboxPreviewAPI from 'shared/Api/sandboxPreviewAPI';
import SandboxPreviewWrapper from 'shared/SandboxPreviewWrapper/SandboxPreviewWrapper';
import Typography from 'widgets/Typography/Typography';
import VideoDisplayCard from 'widgets/VideoDisplayCard/VideoDisplayCard';
import SessionCards from './SessionCards';
import SingleSessionUpload from './SingleSessionUpload';
import SessionUploadStatus from './SessionUploadStatus';
import UploadWithDragDrop from '../SandboxUpload/UploadWithDragDrop';
import { useLocation } from 'react-router-dom';
import Loader from 'widgets/Loader/Loader';
import PreviewFeedback from '../PreviewFeedback';

interface Params {
  [key: string]: string;
}

function SandboxGallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [apiKey, setApiKey] = useState('f7833e18-3c34-5f2a-b6fa-73b3129a5e03');
  const [env, setEnv] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const {
    apikey: newApiKey,

    env: envName,
  } = params;

  async function getDemoGalleryData() {
    setLoading(true);
    await sandboxPreviewAPI
      .get(ProtectedUrls.ProcessedDataListDemoGallery.url())
      .then((response) => {
        setGalleryData(response.data);
        setLoading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (envName) {
      setEnv(envName);
    }

    return () => {};
  }, [params]);

  useEffect(() => {
    getDemoGalleryData();
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <SandboxPreviewWrapper>
      {!isUploading ? (
        <>
          <Box>
            {!isUploading && (
              <Typography
                sx={{
                  color: '#2C2C2C',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '-0.198px',
                }}
                label='gallery.Gallery'
              />
            )}
          </Box>

          <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
        </>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pl: 3,
          pr: 3,
        }}
      >
        {!isUploading ? <SessionCards galleryData={galleryData} /> : null}
        <UploadWithDragDrop
          apiKey={apiKey}
          env={env}
          setIsUploading={setIsUploading}
        />
        {!isUploading ? <PreviewFeedback /> : null}
      </Box>
    </SandboxPreviewWrapper>
  );
}

export default SandboxGallery;
