import { Box } from '@mui/material';
import React from 'react';
import Typography from 'widgets/Typography/Typography';

function AssessmentLinkValidation({
  heading,
  description,
}: {
  heading: string;
  description: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',

        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant='h3' sx={{ color: 'red' }} label={heading} />
      <Typography sx={{ fontSize: '14px', mt: 2 }} label={description} />
    </Box>
  );
}

export default AssessmentLinkValidation;
