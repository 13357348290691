/** @format */

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import useProcessDataListTable from './hooks/useProcessDataListTable';
import Loader from 'widgets/Loader/Loader';
import {
  ProcessDataListTableProps,
  Data,
  FilteredProcessDataList,
} from 'types/Dashboard-types';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import ColumnsList from './ColumnsList';
import { headCells } from './HeaderCells';
import ProcessDataTableBody from './ProcessDataTableBody';
import DashboardFilter from './DashboardFilter';
import { useEffect, useState } from 'react';
import { widgets } from 'constants/Widgets';
import { WidgetData } from 'types/Dashboard-types';
import EngagementWidget from './DashboardWidgets/EngagementWidget';
import { Grid, useMediaQuery } from '@mui/material';
import PositivityWidget from './DashboardWidgets/PositivityWidget';
import VariationWidget from './DashboardWidgets/VariationWidget';
import EnergyWidget from './DashboardWidgets/EnergyWidget';
import FlagsWidget from './DashboardWidgets/FlagsWidget';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUserWidgetPreferenceService } from 'services/UserPrefrence-service';
import { UserWidgetPreferenceSelectore } from 'redux/selectors/selectors';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import { ProtectedUrls } from 'routes/urls';

interface Params {
  [key: string]: string;
}
interface IFrameProcessDataListTableProps {
  processDataListWithSession: Array<ProcessDataListVersion2>;
  setProcessDataListWithSession: any;
  onFilterDataChange?: (filteredData: FilteredProcessDataList[]) => void;
  processDataListVersion2: Array<ProcessDataListVersion2>;
}

export default function ProcessDataListTable({
  processDataListWithSession,
  setProcessDataListWithSession,
  onFilterDataChange,
  processDataListVersion2,
}: IFrameProcessDataListTableProps) {
  const {
    handleRequestSort,
    handleChangeRowsPerPage,
    handleChangePage,
    formatDate,
    page,
    emptyRows,
    rowsPerPage,
    visibleRows,
    order,
    orderBy,
  } = useProcessDataListTable(processDataListWithSession);
  const dispatch = useAppDispatch();
  const [apiKey, setApiKey] = useState('');
  const [env, setEnv] = useState('');
  const [userWidgetPreference, setUserWidgetPreference] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    // Extract sessionId and apiKey from the params object
    const {
      apikey: newApiKey,

      env: envName,
    } = params;

    // Check if the values exist before updating the state

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (envName) {
      setEnv(envName);
    }

    return () => {};
  }, [params]);

  useEffect(() => {
    if (apiKey) {
      setLoading(true);
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response: any) => {
          setUserWidgetPreference(response.data);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [apiKey, env]);

  const initialColumnVisibility: Record<string, boolean> = {};
  const [columnVisibility, setColumnVisibility] = React.useState(
    initialColumnVisibility
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  headCells.forEach((cell) => {
    initialColumnVisibility[cell.id] = cell.showColumn !== false;
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColumnToggle = (columnId: string) => {
    setColumnVisibility((prevVisibility: Record<string, boolean>) => ({
      ...prevVisibility,
      [columnId]: !prevVisibility[columnId],
    }));
  };
  const [filteredDataWithInputs, setFilteredDataWithInputs] = useState<
    FilteredProcessDataList[]
  >([]);
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetData[]>([]);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const [selectedRows, setSelectedRows] = useState<Number[]>([]);
  const [rows, setRows] = useState<Array<Data>>([]);
  function handleOnChange(session_id: number) {
    setSelectedRows((selectedRows) => {
      const selectedIndex = selectedRows.indexOf(session_id);
      if (selectedIndex === -1) {
        return [...selectedRows, session_id];
      } else {
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows.splice(selectedIndex, 1);
        return updatedSelectedRows;
      }
    });
  }

  function handleSelectAll() {
    if (selectedRows.length === rows.length) {
      setSelectedRows([]);
    } else {
      const allIds = rows.map((row) => row.coaching_session_reports_id);
      setSelectedRows(allIds);
    }
  }

  const handleFilter = (payload: FilteredProcessDataList) => {
    setFilteredDataWithInputs([payload]);

    if (onFilterDataChange) {
      onFilterDataChange([payload]);
    }
  };

  useEffect(() => {
    const userPreferences = userWidgetPreference[0];
    if (userPreferences) {
      const preferencedWidgets = widgets
        .filter((widget) => userPreferences[widget.name.trim()])
        .map((widget) => widget);

      setSelectedWidgets(preferencedWidgets);
    }
  }, [userWidgetPreference]);

  const isEngagementSelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 1
  );
  const isPositivitySelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 4
  );
  const isVariationSelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 3
  );
  const isEnergySelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 2
  );
  const isFlagsSelected = selectedWidgets.some((widgetId) => widgetId.id === 5);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ maxWidth: '100%', width: '100%' }}>
      {processDataListWithSession.length < 0 ? (
        <Loader />
      ) : (
        <>
          {isMobile || isTablet ? (
            <Box sx={{ width: '100%', height: 'auto' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <DashboardFilter
                    onFilter={handleFilter}
                    processDataListWithSession={processDataListWithSession}
                    setProcessDataListWithSession={
                      setProcessDataListWithSession
                    }
                    selectedWidgets={selectedWidgets}
                    setSelectedWidgets={setSelectedWidgets}
                    processDataListVersion2={processDataListVersion2}
                  />
                </Grid>

                {/* Flags Widget */}
                {isFlagsSelected && (
                  <Grid item xs={10} sm={6}>
                    <FlagsWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {/* Engagement Widget */}
                {isEngagementSelected && (
                  <Grid item xs={10} sm={6}>
                    <EngagementWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {/* Energy Widget */}
                {isEnergySelected && (
                  <Grid item xs={10} sm={6}>
                    <EnergyWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {/* Variation Widget */}
                {isVariationSelected && (
                  <Grid item xs={10} sm={6}>
                    <VariationWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {/* Positivity Widget */}
                {isPositivitySelected && (
                  <Grid item xs={10} sm={6}>
                    <PositivityWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Paper sx={{ width: '100%', mt: 2 }} elevation={1}>
                      <EnhancedTableToolbar handleClick={handleClick} />
                      <TableContainer sx={{ maxWidth: 440 }}>
                        <Table stickyHeader aria-label='sticky table'>
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            columnVisibility={columnVisibility}
                            headCells={headCells}
                            handleSelectAll={handleSelectAll}
                            selectedRows={selectedRows}
                          />
                          <ProcessDataTableBody
                            visibleRows={visibleRows}
                            columnVisibility={columnVisibility}
                            formatDate={formatDate}
                            emptyRows={emptyRows}
                            handleOnChange={handleOnChange}
                            selectedRows={selectedRows}
                          />
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />{' '}
                    </Paper>
                    <ColumnsList
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                      headCells={headCells}
                      columnVisibility={columnVisibility}
                      handleColumnToggle={handleColumnToggle}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                minHeight: '100vh',
                height: '100%',

                mt: 4,
              }}
            >
              <Box
                sx={{
                  Width: '100%',
                  height: 'auto',

                  backgroundColor: '#f9f9f9',
                }}
              >
                <DashboardFilter
                  onFilter={handleFilter}
                  processDataListWithSession={processDataListWithSession}
                  setProcessDataListWithSession={setProcessDataListWithSession}
                  selectedWidgets={selectedWidgets}
                  setSelectedWidgets={setSelectedWidgets}
                  processDataListVersion2={processDataListVersion2}
                />
              </Box>

              <Box
                sx={{
                  Width: '100%',
                  height: 'auto',
                  display: 'flex',
                  mb: 5,
                }}
              >
                <Grid container spacing={1}>
                  {isEngagementSelected && (
                    <Grid item md={3}>
                      <EngagementWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isEnergySelected && (
                    <Grid item md={3}>
                      <EnergyWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isVariationSelected && (
                    <Grid item md={3}>
                      <VariationWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isPositivitySelected && (
                    <Grid item md={3}>
                      <PositivityWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isFlagsSelected && (
                    <Grid item md={3}>
                      <FlagsWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Paper
                sx={{
                  overflow: 'hidden',
                  minHeight: '100vh',
                  height: '100%',
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <EnhancedTableToolbar handleClick={handleClick} />

                <Box>
                  <TableContainer
                    sx={{
                      minHeight: '100vh',
                      height: '100%',
                      maxWidth: 1370,
                      width: '100%',
                      overflow: 'auto',
                    }}
                  >
                    <Table
                      sx={{ minWidth: 750, maxWidth: '100%' }}
                      aria-labelledby='tableTitle'
                      size='small'
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columnVisibility={columnVisibility}
                        headCells={headCells}
                        handleSelectAll={handleSelectAll}
                        selectedRows={selectedRows}
                      />
                      <ProcessDataTableBody
                        visibleRows={visibleRows}
                        columnVisibility={columnVisibility}
                        formatDate={formatDate}
                        emptyRows={emptyRows}
                        handleOnChange={handleOnChange}
                        selectedRows={selectedRows}
                      />
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />{' '}
                </Box>
              </Paper>
              <ColumnsList
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                headCells={headCells}
                columnVisibility={columnVisibility}
                handleColumnToggle={handleColumnToggle}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
