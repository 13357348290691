import { Box, Grid } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import Typography from 'widgets/Typography/Typography';
import VideoDisplayCard from 'widgets/VideoDisplayCard/VideoDisplayCard';

interface SessionInfo {
  coaching_session_reports_id: number;
  processing_event_name: string;
  format: string;
  session_title: string;
  session_type_id: number;
  total_attendees: number;
  version_number: string;
  process_status: number;
  overall_emotion: number;
  processing_start_date_time: string;
  processing_end_date_time: string;
  overall_energy: number;
  engagement_score: number;
  positivity_score: number;
  variation_score: number;
  thumbnail_image_bytes: string;
  session_name: string;
  error_flag: boolean;
  overall_summary: string;
  overall_emotion_name: string;
}

function SessionCards({ galleryData }: { galleryData: SessionInfo[] }) {
  const history = useHistory();

  function redirectToPreview(coaching_session_reports_id: number) {
    let urlPath: string;
    if (coaching_session_reports_id !== -1) {
      const dataToEncode = {
        apiKey: 'f7833e18-3c34-5f2a-b6fa-73b3129a5e03',
        coaching_session_reports_id: coaching_session_reports_id,
      };

      const encodedData = btoa(JSON.stringify(dataToEncode));

      localStorage.setItem(
        'coaching_session_reports_id',
        JSON.stringify(coaching_session_reports_id)
      );

      const urlPath = `${
        ProtectedRoutes.SandboxVideoPreview
      }?id=${encodeURIComponent(encodedData)}`;

      history.push(urlPath);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {galleryData.length > 0 ? (
          galleryData.map((videoData: SessionInfo, index: number) => (
            <Grid item sm={3} md={3}>
              <VideoDisplayCard
                key={index}
                title={
                  videoData.session_title ?? videoData.processing_event_name
                }
                engagementScore={videoData.engagement_score}
                endTime={videoData.processing_end_date_time}
                startTime={videoData.processing_start_date_time}
                overallEmotion={videoData.overall_emotion_name}
                thumbnailImageBytes={videoData.thumbnail_image_bytes}
                sessionName={videoData.session_name}
                metrics_processing_event_history_id={
                  videoData.coaching_session_reports_id
                }
                handleOpenDeleteModal={() => {}}
                set_metrics_processing_event_history_id={() => {}}
                handleOpenReprocessVideoModal={() => {}}
                setReprocessVideoData={() => {}}
                sessionDetails={{
                  id: videoData.session_type_id,
                  name: videoData.session_name,
                }}
                onClickVideoCard={() => {
                  redirectToPreview(videoData.coaching_session_reports_id);
                }}
                totalAttendees={videoData.total_attendees}
                error_flag={videoData.error_flag}
                overall_summary={videoData.overall_summary}
                process_status={videoData.process_status}
                variation_score={videoData.variation_score}
                engagement_score={videoData.engagement_score}
                positivity_score={videoData.positivity_score}
                overall_energy={videoData.overall_energy}
                isPreview={true}
              />
            </Grid>
          ))
        ) : (
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography
              sx={{
                color: '#C1C1C3',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: '24px',
                letterSpacing: '-0.198px',
              }}
              label='No Data Available'
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default SessionCards;
