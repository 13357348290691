/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { BillingDataInitialStateType } from 'types/Billing-types';

const billingDataInitialState: BillingDataInitialStateType = {
  isLoadingBillingData: false,
  billingData: [],
  errorBillingData: false,
};

const getBillingDataSlice = createSlice({
  name: 'getBillingDataSlice',
  initialState: billingDataInitialState,
  reducers: {
    billingDataLoad(state) {
      state.isLoadingBillingData = true;
    },
    billingDataSuccess(state, action) {
      state.isLoadingBillingData = false;
      state.billingData = action.payload;
    },
    billingDataFailure(state, action) {
      state.isLoadingBillingData = false;
      state.errorBillingData = action.payload;
    },
  },
});

export const { billingDataLoad, billingDataSuccess, billingDataFailure } =
  getBillingDataSlice.actions;
export { getBillingDataSlice };
