import React from 'react'

function DeleteBulkInactiveIcon () {
    return (
        <svg 
            width="14" 
            height="15" 
            viewBox="0 0 14 15" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M3.25991 14.7788C2.81039 14.7788 2.42572 14.6189 2.10588 14.299C1.78604 13.9792 1.62584 13.5942 1.6253 13.1442V2.51918H0.807991V0.884568H4.89453V0.0672607H9.79838V0.884568H13.8849V2.51918H13.0676V13.1442C13.0676 13.5937 12.9077 13.9787 12.5878 14.299C12.268 14.6194 11.8831 14.7793 11.433 14.7788H3.25991ZM11.433 2.51918H3.25991V13.1442H11.433V2.51918ZM4.89453 11.5096H6.52914V4.1538H4.89453V11.5096ZM8.16376 11.5096H9.79838V4.1538H8.16376V11.5096Z" 
                fill="#605E5E"
            />
        </svg>
    )
}

export default DeleteBulkInactiveIcon;