import React from 'react';
import {
  Typography as TextView,
  TypographyProps as TextViewProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TypographyProps extends TextViewProps {
  component?: string;
  label: string;
}

const Typography: React.FC<TypographyProps> = ({
  label,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <TextView {...props}>
      {t(label)}
      {children}
    </TextView>
  );
};

export default Typography;
