import { createSlice } from '@reduxjs/toolkit';

const FacebookSSOSignInInitialState = {
  isLoadingFacebookSSOSignIn: false,
  errorFacebookSSOSignIn: '',
  FacebookSSOSignInData: {},
};

const FacebookSSOSignInSlice = createSlice({
  name: 'FacebookSSOSignInSlice',
  initialState: FacebookSSOSignInInitialState,
  reducers: {
    facebookSSOSignInLoad(state) {
      state.isLoadingFacebookSSOSignIn = true;
    },
    facebookSSOSignInSuccess(state, action) {
      state.isLoadingFacebookSSOSignIn = false;
      state.FacebookSSOSignInData = action.payload;
    },
    facebookSSOSignInFailure(state, action) {
      state.isLoadingFacebookSSOSignIn = false;
      state.errorFacebookSSOSignIn = action.payload;
    },
  },
});

export const {
  facebookSSOSignInLoad,
  facebookSSOSignInSuccess,
  facebookSSOSignInFailure,
} = FacebookSSOSignInSlice.actions;

export { FacebookSSOSignInSlice };

const FacebookSSOSignOutInitialState = {
  isLoadingFacebookSSOSignOut: false,
  errorFacebookSSOSignOut: '',
  FacebookSSOSignOutData: {},
};

const FacebookSSOSignOutSlice = createSlice({
  name: 'FacebookSSOSignOutSlice',
  initialState: FacebookSSOSignOutInitialState,
  reducers: {
    facebookSSOSignOutLoad(state) {
      state.isLoadingFacebookSSOSignOut = true;
    },
    facebookSSOSignOutSuccess(state, action) {
      state.isLoadingFacebookSSOSignOut = false;
      state.FacebookSSOSignOutData = action.payload;
    },
    facebookSSOSignOutFailure(state, action) {
      state.isLoadingFacebookSSOSignOut = false;
      state.errorFacebookSSOSignOut = action.payload;
    },
  },
});

export const {
  facebookSSOSignOutLoad,
  facebookSSOSignOutSuccess,
  facebookSSOSignOutFailure,
} = FacebookSSOSignOutSlice.actions;

export { FacebookSSOSignOutSlice };
