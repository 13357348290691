/** @format */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface DeleteUnsavedRecordingModalProps {
  openDeleteUnsavedRecordingModal: boolean;
  handleCloseDeleteUnsavedRecordingModal: () => void;
  videoDesc: string;
  handleDeleteRecordedVideo: () => void;
}

function DeleteUnsavedRecordingModal({
  openDeleteUnsavedRecordingModal,
  handleCloseDeleteUnsavedRecordingModal,
  videoDesc,
  handleDeleteRecordedVideo,
}: DeleteUnsavedRecordingModalProps) {
  return (
    <Dialog
      open={openDeleteUnsavedRecordingModal}
      onClose={handleCloseDeleteUnsavedRecordingModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div style={{ padding: '30px' }}>
        <DialogTitle>
          <Typography
            variant='h5'
            sx={{
              color: '#33344B',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '23px',
              marginBottom: 4,
            }}
            label='record.DeleteVideo'
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                color: '#33344B',
              }}
              label={videoDesc}
            />

            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.154px',
                color: '#33344B',
              }}
              label='record.IfYouHaveNotSavedThisRecording,YouWillNotBeAbleToRestoreIt'
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: 5,
          }}
        >
          <Button
            onClick={handleCloseDeleteUnsavedRecordingModal}
            label='record.Cancel'
          />

          <Button
            onClick={handleDeleteRecordedVideo}
            autoFocus
            variant='contained'
            sx={{
              borderRadius: 80,
              backgroundColor: '#1206F9',
              width: '20%',
              padding: 1,
            }}
            label='record.Delete'
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default DeleteUnsavedRecordingModal;
