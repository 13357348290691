import { AxiosResponse } from 'axios';
import { i18n } from 'i18n/i18n';
import {
  facebookSSOSignInFailure,
  facebookSSOSignInLoad,
  facebookSSOSignInSuccess,
  facebookSSOSignOutFailure,
  facebookSSOSignOutLoad,
  facebookSSOSignOutSuccess,
} from 'redux/slices/FacebookSSO-slice';
import { AppThunk } from 'redux/store';
import {
  OpenRoutes,
  ProtectedRoutes,
  deepCandidateOpenRoutes,
} from 'routes/routes';
import { DeepCandidateUrls, ProtectedUrls, PublicUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  getUserAndCheckAuthProviderOfUser,
  handleSuccessfulLogin,
} from 'utils/AuthenticationHelpers';
import { Toast } from 'widgets/Toast/Toast';
import { History } from 'history';
import { FacebookSsoLogout } from 'components/SSO/Facebook/facebookAuthConfig';
import { getLoginDataServiceApi } from './Auth-service';
import { t } from 'i18next';

const clearSessionStorage = () => {
  return new Promise<void>((resolve, reject) => {
    try {
      sessionStorage.clear();
      localStorage.clear();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const handleFbSsoLogoutServiceApi =
  (history: History): AppThunk =>
  async (dispatch) => {
    dispatch(facebookSSOSignOutLoad());

    await FacebookSsoLogout().then((res) => {
      clearSessionStorage()
        .then(() => {
          history.push(ProtectedRoutes.dashboardRoute);
          Toast(t('toastMessageNotifications.LogoutSuccessful'), 'success');
          dispatch(facebookSSOSignOutSuccess(res));
        })
        .catch((error) => {
          console.error('handleLogoutFBSSOerror:', error);
          dispatch(facebookSSOSignOutFailure(error));
        });
    });
  };

export const handleSignInFacebookSuccessServiceApi =
  (history: History): AppThunk =>
  async (dispatch) => {
    dispatch(facebookSSOSignInLoad());

    await window.FB.login(
      function (response) {
        if (response) {
          try {
            if (!response.authResponse) {
              Toast(
                'Facebook sign in Authentication error. Failed to get access token.',
                'error'
              );
              dispatch(
                facebookSSOSignInFailure(
                  'Facebook sign in Authentication error. Failed to get access token.'
                )
              );
              return;
            }

            dispatch(getUserDataSignInFacebookServiceApi(response, history));
          } catch (error) {
            dispatch(facebookSSOSignInFailure(error));

            console.error('error facebook signin sso', error);
          }
        }
      },
      { scope: 'email' }
    );
  };
export const getUserDataSignInFacebookServiceApi =
  (
    response: { authResponse: { accessToken: string } },
    history: History
  ): AppThunk =>
  async (dispatch) => {
    const userInfoResponse = await commonAPI.get(
      `https://graph.facebook.com/v8.0/me?access_token=${response.authResponse.accessToken}&fields=id,last_name,first_name,email`
    );
    if (!userInfoResponse) {
      dispatch(
        facebookSSOSignInFailure(
          'failed to obtain user information from facebook'
        )
      );
      return;
    }

    const responseAuth = await commonAPI.get(
      ProtectedUrls.getAuthProviderDetails.url()
    );
    if (!responseAuth) {
      dispatch(facebookSSOSignInFailure('failed to obtain auth providers'));
      return;
    }

    const { email, id, first_name, last_name } = userInfoResponse.data;

    const signInPayloadFacebook = {
      username: email,
      password: id,
    };
    const payloadSignUp = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      password: id,
      authProvider: responseAuth.data['Facebook'],
    };
    if (
      history.location.pathname.toLowerCase() ===
        OpenRoutes.signInRoute.toLowerCase() ||
      history.location.pathname.toLowerCase() ===
        deepCandidateOpenRoutes.DCSignInRoute.toLowerCase()
    ) {
      dispatch(
        getLoginDataServiceApi(
          signInPayloadFacebook,
          history,
          true,
          payloadSignUp
        )
      );
    } else {
      const signUpResponse = await commonAPI.post(
        history.location.pathname.toLowerCase() ===
          deepCandidateOpenRoutes.DCSignUPRoute.toLowerCase()
          ? DeepCandidateUrls.deepCandidateSignUpApiRoute.url(payloadSignUp)
          : PublicUrls.signUpApiRoute.url(payloadSignUp)
      );

      if (signUpResponse.data[0]?.apikey) {
        Toast(
          i18n.t('toastMessageNotifications.AccountCreatedSuccessfully'),
          'success'
        );
        history.push(OpenRoutes.signUpSuccessPage);
      } else {
        switch (signUpResponse.data.message) {
          case 'Email-id Already exists':
            const userDetails = await commonAPI.get(
              ProtectedUrls.getUserDetailsAsUserUrl.url({
                email_address: payloadSignUp.email,
              })
            );
            await getUserAndCheckAuthProviderOfUser(
              userDetails.data[0]?.auth_provider,
              signUpResponse.data.message
            );

            break;
          default:
            Toast(signUpResponse.data.message, 'error');
        }
      }
    }
    dispatch(facebookSSOSignInSuccess(response));
  };
