import React from 'react';

function SendAssessmentIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7506 12.4497C15.307 12.4477 14.8738 12.3146 14.5056 12.0672L10.8381 9.62217C10.6926 9.50494 10.5961 9.33761 10.5674 9.15294C10.5388 8.96828 10.5802 8.77958 10.6834 8.6238C10.7866 8.46803 10.9443 8.3564 11.1255 8.31078C11.3067 8.26516 11.4984 8.28883 11.6631 8.37717L15.3381 10.8222C15.4606 10.9028 15.604 10.9458 15.7506 10.9458C15.8972 10.9458 16.0407 10.9028 16.1631 10.8222L19.8381 8.37717C20.0028 8.28883 20.1945 8.26516 20.3757 8.31078C20.5569 8.3564 20.7146 8.46803 20.8178 8.6238C20.9211 8.77958 20.9624 8.96828 20.9338 9.15294C20.9052 9.33761 20.8086 9.50494 20.6631 9.62217L16.9956 12.0672C16.6274 12.3146 16.1943 12.4477 15.7506 12.4497Z'
        fill='url(#paint0_linear_1513_2453)'
      />
      <path
        d='M21.75 18.75H9.75C9.15326 18.75 8.58097 18.5129 8.15901 18.091C7.73705 17.669 7.5 17.0967 7.5 16.5V7.5C7.5 6.90326 7.73705 6.33097 8.15901 5.90901C8.58097 5.48705 9.15326 5.25 9.75 5.25H21.75C22.3467 5.25 22.919 5.48705 23.341 5.90901C23.7629 6.33097 24 6.90326 24 7.5V16.5C24 17.0967 23.7629 17.669 23.341 18.091C22.919 18.5129 22.3467 18.75 21.75 18.75ZM9.75 6.75C9.55109 6.75 9.36032 6.82902 9.21967 6.96967C9.07902 7.11032 9 7.30109 9 7.5V16.5C9 16.6989 9.07902 16.8897 9.21967 17.0303C9.36032 17.171 9.55109 17.25 9.75 17.25H21.75C21.9489 17.25 22.1397 17.171 22.2803 17.0303C22.421 16.8897 22.5 16.6989 22.5 16.5V7.5C22.5 7.30109 22.421 7.11032 22.2803 6.96967C22.1397 6.82902 21.9489 6.75 21.75 6.75H9.75Z'
        fill='url(#paint1_linear_1513_2453)'
      />
      <path
        d='M5.25 14.25H3.75C3.55109 14.25 3.36032 14.171 3.21967 14.0303C3.07902 13.8897 3 13.6989 3 13.5C3 13.3011 3.07902 13.1103 3.21967 12.9697C3.36032 12.829 3.55109 12.75 3.75 12.75H5.25C5.44891 12.75 5.63968 12.829 5.78033 12.9697C5.92098 13.1103 6 13.3011 6 13.5C6 13.6989 5.92098 13.8897 5.78033 14.0303C5.63968 14.171 5.44891 14.25 5.25 14.25Z'
        fill='url(#paint2_linear_1513_2453)'
      />
      <path
        d='M5.25 11.25H2.25C2.05109 11.25 1.86032 11.171 1.71967 11.0303C1.57902 10.8897 1.5 10.6989 1.5 10.5C1.5 10.3011 1.57902 10.1103 1.71967 9.96967C1.86032 9.82902 2.05109 9.75 2.25 9.75H5.25C5.44891 9.75 5.63968 9.82902 5.78033 9.96967C5.92098 10.1103 6 10.3011 6 10.5C6 10.6989 5.92098 10.8897 5.78033 11.0303C5.63968 11.171 5.44891 11.25 5.25 11.25Z'
        fill='url(#paint3_linear_1513_2453)'
      />
      <path
        d='M5.25 8.25H0.75C0.551088 8.25 0.360322 8.17098 0.21967 8.03033C0.0790176 7.88968 0 7.69891 0 7.5C0 7.30109 0.0790176 7.11032 0.21967 6.96967C0.360322 6.82902 0.551088 6.75 0.75 6.75H5.25C5.44891 6.75 5.63968 6.82902 5.78033 6.96967C5.92098 7.11032 6 7.30109 6 7.5C6 7.69891 5.92098 7.88968 5.78033 8.03033C5.63968 8.17098 5.44891 8.25 5.25 8.25Z'
        fill='#1906F9'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1513_2453'
          x1='15.7506'
          y1='8.28809'
          x2='15.7506'
          y2='12.4497'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#1906F9' />
          <stop offset='1' stop-color='#07DCAA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1513_2453'
          x1='15.75'
          y1='5.25'
          x2='15.75'
          y2='18.75'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#1906F9' />
          <stop offset='1' stop-color='#07DCAA' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1513_2453'
          x1='4.5'
          y1='12.75'
          x2='4.5'
          y2='14.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#1906F9' />
          <stop offset='0.0001' stop-color='#07DCAA' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1513_2453'
          x1='3.75'
          y1='9.75'
          x2='3.75'
          y2='11.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#1906F9' />
          <stop offset='1' stop-color='#07DCAA' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SendAssessmentIcon;
