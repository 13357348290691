import { UploadFiletypes, SessionTypes } from "types/Upload-types";

export const UploadFiletypeList: UploadFiletypes[] = [
  { label: 'upload.VideoAudio', id: 1 },
  { label: 'upload.LinkUrl', id: 2 },
];

export const SessionTypeList: SessionTypes[] = [
  {
    id: 1,
    name: "Date",
  },
  {
    id: 2,
    name: "Work Session",
  },
  {
    id: 3,
    name: "Casual Session",
  },
  {
    id: 4,
    name: "Sales Call",
  },
  {
    id: 5,
    name: "Support Call",
  },
  {
    id: 6,
    name: "Classroom",
  },
  {
    id: 7,
    name: "Interview",
  },
  {
    id: 8,
    name: "Pitch",
  },
  {
    id: 9,
    name: "Pitch Practice",
  },
  {
    id: 10,
    name: "Interview Practice",
  },
];
