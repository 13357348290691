/** @format */

import { Box } from '@mui/material'; // Added Button
import React from 'react';
import { getBillingDataSelector } from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getBillingDataServiceApi } from 'services/Billings-service';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import { BillingDataPayload } from 'types/Billing-types';
import BillingDataTable from './BillingDataTable';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { useCallback } from 'react';

function BillingComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { billingData } = useAppSelector(getBillingDataSelector);

  const initialValues: BillingDataPayload = {
    userName: '',
    fromDate: `${new Date().getFullYear()}-01-01`,
    toDate: `${new Date().getFullYear() + 1}-01-01`,
  };

  const GetBillingDataSchema = useCallback(
    (t: TFunction<'translation', undefined>) =>
      Yup.object().shape({
        userName: Yup.string().required(t('billings.UserEmailIsRequired')),
        fromDate: Yup.date().required(t('billings.FromDateIsRequired')),
        toDate: Yup.date().required(t('billings.ToDateIsRequired')),
      }),
    []
  );

  const handleSubmitGetBillingData = useCallback(
    (values: BillingDataPayload) => {
      dispatch(getBillingDataServiceApi(values));
    },
    [dispatch]
  );

  return (
    <AuthWrapper>
      <Box>
        <Typography
          sx={{
            color: '#2C2C2C',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '-0.198px',
            marginTop: 2,
            marginLeft: 2,
          }}
          variant='h5'
          label='billings.BillingDataList'
        />

        <Box
          sx={{
            marginBottom: 3,
            marginTop: 2,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={GetBillingDataSchema(t)}
            onSubmit={handleSubmitGetBillingData}
          >
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  height: 70,
                }}
              >
                <Field
                  sx={{ width: '20%' }}
                  as={TextField}
                  variant='outlined'
                  name='userName'
                  helperText={
                    <ErrorMessage
                      name='userName'
                      component='div'
                      className='error-message'
                    />
                  }
                  label={'billings.Useremail'}
                  InputProps={{
                    style: {
                      borderRadius: '35px',
                    },
                  }}
                  id='txtuserName'
                  size='small'
                  InputLabelProps={{
                    shrink: true, // This prevents the label from moving
                  }}
                />

                <Field
                  sx={{ width: '20%' }}
                  format={'DD/MM/YYYY'}
                  as={TextField}
                  variant='outlined'
                  name='fromDate'
                  helperText={
                    <ErrorMessage
                      name='fromDate'
                      component='div'
                      className='error-message'
                    />
                  }
                  label={'billings.FromDate'}
                  type='date'
                  InputProps={{
                    style: {
                      borderRadius: '35px',
                    },
                  }}
                  id='fromDate'
                  size='small'
                  InputLabelProps={{
                    shrink: true, // This prevents the label from moving
                  }}
                  defaultValue='Hello World'
                />

                <Field
                  sx={{ width: '20%' }}
                  as={TextField}
                  variant='outlined'
                  name='toDate'
                  helperText={
                    <ErrorMessage
                      name='toDate'
                      component='div'
                      className='error-message'
                    />
                  }
                  label={'billings.ToDate'}
                  type='date'
                  InputProps={{
                    style: {
                      borderRadius: '35px',
                    },
                  }}
                  id='toDate'
                  size='small'
                  InputLabelProps={{
                    shrink: true, // This prevents the label from moving
                  }}
                />

                <Button
                  type='submit'
                  variant='contained'
                  sx={{
                    borderRadius: '5rem',
                    background: '#1206F9',
                    color: '#fff',
                    width: 'fit-content',
                    height: 'fit-content',

                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.375rem',
                  }}
                  label='billings.Submit'
                >
                  <Typography
                    sx={{ color: 'white', fontFamily: 'Poppins' }}
                    label='billings.Submit'
                  />
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
        <BillingDataTable rows={billingData} />
      </Box>
    </AuthWrapper>
  );
}

export default BillingComponent;
