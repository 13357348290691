import React, { useState, useEffect } from 'react';
import TextField from 'widgets/TextField/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  getProcessDataListForParticipantsVersion2Selector,
  getSessionTypesSelector,
} from 'redux/selectors/selectors';
import { useAppSelector } from 'redux/store';
import { UploadMediaData } from 'types/Upload-types';
import { Box, Divider, ListItemText, useMediaQuery } from '@mui/material';
import UserRoleIcon from 'icons/UserRoleIcon';
import { useTranslation } from 'react-i18next';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface UserRoleFilterProps {
  selectedUserRole: { id: number; name: string }[];
  setSelectedUserRole: (selectedRoles: { id: number; name: string }[]) => void;
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
}

const UserRoleFilter: React.FC<UserRoleFilterProps> = ({
  selectedUserRole,
  setSelectedUserRole,
  ProcessDataListForParticipantsVersion2,
}) => {
  const [open, setOpen] = useState(false);
  const { SessionTypes } = useAppSelector(getSessionTypesSelector);

  const [userRolesOptions, setUserRolesOptions] = useState<
    { id: number; name: string; index: number }[]
  >([]);

  const currentSessionType: UploadMediaData[] = SessionTypes.filter(
    (sessionType: UploadMediaData) =>
      sessionType.id ===
      ProcessDataListForParticipantsVersion2[0]?.session_type_id
  );
  const selectedSessionType = currentSessionType[0];

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const attendeesRecord =
    ProcessDataListForParticipantsVersion2[0]?.attendees_record || [];

  let mappedOptions: { id: number; name: string; index: number }[] = [];

  useEffect(() => {
    const countMap: { [key: string]: number } = {};

    mappedOptions = attendeesRecord.map((participant: any, index: number) => {
      let roleName = '';

      switch (participant.session_role_id) {
        case 5:
          roleName = 'Interviewer';
          break;
        case 6:
          roleName = 'Interviewee';
          break;
        case 3:
          roleName = 'Participant';
          break;
        case 9:
          roleName = 'Provider';
          break;
        case 10:
          roleName = 'Patient';
          break;
        default:
          roleName = `Unknown Role ${participant.session_role_id}`;
          break;
      }

      countMap[roleName] = (countMap[roleName] || 0) + 1;

      const finalRoleName = `${roleName} ${countMap[roleName]}`;

      return {
        id: parseInt(`${participant.session_role_id}${countMap[roleName]}`, 10),
        name: finalRoleName,
        index: index,
      };
    });

    mappedOptions.unshift({
      id: -1,
      name: 'Overall Session',
      index: -1,
    });

    setUserRolesOptions(mappedOptions);
  }, [attendeesRecord, selectedSessionType]);

  const handleSelectAll = () => {
    const allOptionIds: { id: number; name: string; index: number }[] =
      userRolesOptions
        .filter((option) => option.id !== -1)
        .map((option) => ({
          id: option.id,
          name: option.name,
          index: option.index,
        }));
    setSelectedUserRole(allOptionIds);
  };

  const handleClearAll = () => {
    setSelectedUserRole([]);
  };
  const handleToggleRole = (role: any) => {
    // Check if the role is "Overall Session"
    if (role.id === -1) {
      // "Overall Session" is selected, clear the array
      setSelectedUserRole([]);
    } else {
      // Check if the role is already selected
      const isSelected = selectedUserRole.some((opt) => opt.id === role.id);

      if (isSelected) {
        // Role is selected, remove it
        const updatedRoles = selectedUserRole.filter(
          (opt) => opt.id !== role.id
        );
        setSelectedUserRole(updatedRoles);
      } else {
        // Role is not selected, add it to the existing roles
        const updatedRoles = [...selectedUserRole, role];
        setSelectedUserRole(updatedRoles);
      }
    }
  };

  const overallSessionOption = userRolesOptions.find(
    (option) => option.id === -1
  );

  // ...
  const FilterOptionsStyle = {
    display: 'flex',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <TextField
      select
      label='User Role'
      variant='outlined'
      size='small'
      sx={{
        width: isMobile || isTablet ? '100%' : '90%',
        mt: 1,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1206f9', // Set the border color for focused state
        },
      }}
      SelectProps={{
        open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        multiple: true,
        renderValue: (selected: number[] | string | unknown) => {
          const selectedValues = Array.isArray(selected)
            ? selected.map((id) => {
                const selectedRole = userRolesOptions.find(
                  (role) => role.id === id
                );
                return selectedRole ? selectedRole.name : '';
              })
            : [];

          return selectedValues.length > 0
            ? selectedValues.join(', ')
            : 'Overall Session';
        },
        IconComponent: () => (
          <ExpandMoreIcon
            style={{
              color: 'blue',
              backgroundColor: '#1206F912',
              borderRadius: '50%',
              marginRight: isMobile || isTablet ? '2%' : '5%',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(true)}
          />
        ),
      }}
      value={
        selectedUserRole.length > 0
          ? selectedUserRole.map((role) => role.id)
          : [-1] // Use an empty array as the value for 'Overall Session'
      }
    >
      {/* User role options */}
      {!userRolesOptions || userRolesOptions.length === 1 ? (
        <MenuItem>
          <Typography
            sx={{ color: 'red', fontSize: '14px' }}
            label='videoPreviewAdvance.NoSpeakersDetected'
          />
        </MenuItem>
      ) : null}

      {userRolesOptions.map((option: any) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {option.id === -1 ? null : (
            <Box sx={FilterOptionsStyle}>
              <Checkbox
                checked={selectedUserRole.some((opt) => opt.id === option.id)}
                onChange={() => handleToggleRole(option)}
              />
              <ListItemText primary={option.name} />
            </Box>
          )}
        </MenuItem>
      ))}
      <Divider />

      {selectedUserRole.length >= 2 ? (
        <MenuItem>
          <Typography
            sx={{ color: ' #EA3323', fontSize: '10px' }}
            label='videoPreviewAdvance.NoteThePerformanceScoreShownIsOverall'
          />
        </MenuItem>
      ) : null}

      <MenuItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant='outlined'
          onClick={handleSelectAll}
          sx={{ marginTop: '10px' }}
          label='videoPreviewAdvance.SelectAll'
        />

        <Button
          variant='outlined'
          onClick={handleClearAll}
          sx={{ marginTop: '10px', marginLeft: '10px' }}
          label='videoPreviewAdvance.ClearAll'
        />
      </MenuItem>
    </TextField>
  );
};

export default UserRoleFilter;
