import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

interface AddEmailComponentProps {
  handleAddCandidate: (email: string) => void;
  email: string;
  setEmail: Function;
}

const AddEmailComponent: React.FC<AddEmailComponentProps> = ({
  handleAddCandidate,
  email,
  setEmail,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleAddButtonClick();
    }
  };

  const handleAddButtonClick = () => {
    if (!validateEmail(email)) {
      setError(t('SendAssessments.InvalidEmailAddress'));
      return;
    }
    handleAddCandidate(email);
  };

  const validateEmail = (input: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(input);
  };

  return (
    <TextField
      id='outlined-basic'
      label={t('SendAssessments.AddCandidateEmail')}
      variant='outlined'
      size='small'
      sx={{ width: '85%' }}
      value={email}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <IconButton
            type='submit'
            aria-label='search'
            onClick={handleAddButtonClick}
          >
            <AddIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default AddEmailComponent;
