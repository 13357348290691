/** @format */

import { subscriptionsArrayStatus } from 'constants/Subscriptions';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSubscriptionSelector } from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ProtectedRoutes } from 'routes/routes';
import { getSubscriptionServiceApi } from 'services/Subscription-service';

function useSubscription() {
  const [userSubscription, setUserSubscription] = React.useState<string>('');
  const { getSubscriptionData } = useAppSelector(getSubscriptionSelector);
  const history = useHistory();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSubscriptionServiceApi());
  }, [dispatch, history]);

  React.useEffect(() => {
    if (
      getSubscriptionData &&
      getSubscriptionData[0] &&
      getSubscriptionData[0].planid === null &&
      (history.location.pathname === ProtectedRoutes.recordRoute ||
        history.location.pathname === ProtectedRoutes.uploadRoute)
    ) {
      setUserSubscription(subscriptionsArrayStatus[0].status); // set status to no subscription found
    } else if (
      getSubscriptionData &&
      getSubscriptionData[0] &&
      getSubscriptionData[0].expirydate &&
      new Date(getSubscriptionData[0].expirydate) < new Date() &&
      (history.location.pathname === ProtectedRoutes.recordRoute ||
        history.location.pathname === ProtectedRoutes.uploadRoute)
    ) {
      setUserSubscription(subscriptionsArrayStatus[1].status); // set status to expired
    }
  }, [history.location.pathname, getSubscriptionData]);

  return { userSubscription };
}

export default useSubscription;
