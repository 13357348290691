import i18n from 'i18next';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  UserRolesLoad,
  UserRolesFailure,
  UserRolesSuccess,
} from 'redux/slices/userRoles-slice';

import { Toast } from 'widgets/Toast/Toast';

export const GetUserRolesService = (): AppThunk => async (dispatch) => {
  dispatch(UserRolesLoad());

  await commonAPI
    .get(ProtectedUrls.getUserRoles.url())
    .then((response) => {
      const filteredUserRoles = response.data.filter(
        (UserRoles: { id: number; name: string }) => {
          return UserRoles.id === 3 || UserRoles.id === 5 || UserRoles.id === 6;
        }
      );

      dispatch(UserRolesSuccess(filteredUserRoles));

      if (response.data) {
      } else {
        Toast(response.data, 'error');
      }
    })
    .catch((error) => {
      dispatch(UserRolesFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')));
    });
};
