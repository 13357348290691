import { createSlice } from '@reduxjs/toolkit';
import {
  JobTitleType,
  KeySkillsSetTypes,
} from 'types/DeepRecruiterTypes/JobRequirementForm-types';
import { getTagData } from 'types/DeepRecruiterTypes/SelectAssessment-types';

const getPositionIdentifierInitialState: {
  getPositionIdentifierData: JobTitleType[];
  isLoadingGetPositionIdentifier: boolean;
  errorGetPositionIdentifier: boolean;
} = {
  isLoadingGetPositionIdentifier: false,
  getPositionIdentifierData: [],
  errorGetPositionIdentifier: false,
};
const addTagsInitialState: {
  isLoadingAddTags: boolean;
  addTagsData: KeySkillsSetTypes;
  errorAddTags: boolean;
} = {
  isLoadingAddTags: false,
  addTagsData: { id: -1, skill_name: '', icon: '' },
  errorAddTags: false,
};
const getTagsInitialState: {
  getTagsData: KeySkillsSetTypes[];
  isLoadingGetTags: boolean;
  errorGetTags: boolean;
} = {
  isLoadingGetTags: false,
  getTagsData: [] as getTagData[],
  errorGetTags: false,
};
const addPositionIdentifierInitialState = {
  isLoadingAddPositionIdentifier: false,
  addPositionIdentifierData: { id: -1, title: '' },
  errorAddPositionIdentifier: false,
};

const getPositionIdentifierSlice = createSlice({
  name: ' getPositionIdentifierSlice',
  initialState: getPositionIdentifierInitialState,
  reducers: {
    getPositionIdentifierLoad(state) {
      state.isLoadingGetPositionIdentifier = true;
    },
    getPositionIdentifierSuccess(state, action) {
      state.isLoadingGetPositionIdentifier = false;
      state.getPositionIdentifierData = action.payload;
    },
    getPositionIdentifierFailure(state, action) {
      state.isLoadingGetPositionIdentifier = false;
      state.errorGetPositionIdentifier = action.payload;
    },
  },
});

const addPositionIdentifierSlice = createSlice({
  name: ' addPositionIdentifierSlice',
  initialState: addPositionIdentifierInitialState,
  reducers: {
    addPositionIdentifierLoad(state) {
      state.isLoadingAddPositionIdentifier = true;
    },
    addPositionIdentifierSuccess(state, action) {
      state.isLoadingAddPositionIdentifier = false;
      state.addPositionIdentifierData = action.payload;
    },
    addPositionIdentifierFailure(state, action) {
      state.isLoadingAddPositionIdentifier = false;
      state.errorAddPositionIdentifier = action.payload;
    },
  },
});

const getTagsSlice = createSlice({
  name: 'getTagsSlice',
  initialState: getTagsInitialState,
  reducers: {
    getTagsLoad(state) {
      state.isLoadingGetTags = true;
    },
    getTagsSuccess(state, action) {
      state.isLoadingGetTags = false;
      state.getTagsData = action.payload;
    },
    getTagsFailure(state, action) {
      state.isLoadingGetTags = false;
      state.errorGetTags = action.payload;
    },
  },
});

const addTagsSlice = createSlice({
  name: 'addTagsSlice',
  initialState: addTagsInitialState,
  reducers: {
    addTagsLoad(state) {
      state.isLoadingAddTags = true;
    },
    addTagsSuccess(state, action) {
      state.isLoadingAddTags = false;
      state.addTagsData = action.payload;
    },
    addTagsFailure(state, action) {
      state.isLoadingAddTags = false;
      state.errorAddTags = action.payload;
    },
  },
});

const handleSubmitJobCreationFormInitialState = {
  isLoadingJobCreationFormData: false,
  jobCreationFormData: {},
  jobCreationFormDataError: null,
};

const handleSubmitJobCreationFormSlice = createSlice({
  name: 'handleSubmitJobCreationFormSlice',
  initialState: handleSubmitJobCreationFormInitialState,
  reducers: {
    handleSubmitJobCreationFormLoad(state) {
      state.isLoadingJobCreationFormData = true;
    },
    handleSubmitJobCreationFormSuccess(state, action) {
      state.isLoadingJobCreationFormData = false;
      state.jobCreationFormData = action.payload;
    },
    handleSubmitJobCreationFormFailure(state, action) {
      state.isLoadingJobCreationFormData = false;
      state.jobCreationFormDataError = action.payload;
    },
  },
});

export const {
  handleSubmitJobCreationFormLoad,
  handleSubmitJobCreationFormSuccess,
  handleSubmitJobCreationFormFailure,
} = handleSubmitJobCreationFormSlice.actions;

export { handleSubmitJobCreationFormSlice };

export const { addTagsLoad, addTagsSuccess, addTagsFailure } =
  addTagsSlice.actions;
export const { getTagsLoad, getTagsSuccess, getTagsFailure } =
  getTagsSlice.actions;
export const {
  addPositionIdentifierLoad,
  addPositionIdentifierSuccess,
  addPositionIdentifierFailure,
} = addPositionIdentifierSlice.actions;
export const {
  getPositionIdentifierLoad,
  getPositionIdentifierSuccess,
  getPositionIdentifierFailure,
} = getPositionIdentifierSlice.actions;

export { getPositionIdentifierSlice };
export { addPositionIdentifierSlice };
export { getTagsSlice };
export { addTagsSlice };

const DRJobCreationVariablesInitialState: {
  isAfterAddPositionIdentifier: boolean;
} = {
  isAfterAddPositionIdentifier: false,
};

const drJobCreationVariablesSlice = createSlice({
  name: 'drJobCreationVariablesSlice',
  initialState: DRJobCreationVariablesInitialState,
  reducers: {
    setIsAfterAddPositionIdentifier(state, action) {
      state.isAfterAddPositionIdentifier = action.payload;
    },
  },
});
export const { setIsAfterAddPositionIdentifier } =
  drJobCreationVariablesSlice.actions;
export { drJobCreationVariablesSlice };
