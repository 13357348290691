import { Language } from "types/common/Common-types";

const languages : Language[]= 
[ { id: 1, language: 'Abkhaz', code: 'ab' },
  { id: 2, language: 'Afar', code: 'aa' },
  { id: 3, language: 'Afrikaans', code: 'af' },
  { id: 4, language: 'Akan', code: 'ak' },
  { id: 5, language: 'Albanian', code: 'sq' },
  { id: 6, language: 'Amharic', code: 'am' },
  { id: 7, language: 'Arabic', code: 'ar' },
  { id: 8, language: 'Aragonese', code: 'an' },
  { id: 9, language: 'Armenian', code: 'hy' },
  { id: 10, language: 'Assamese', code: 'as' },
  { id: 11, language: 'Avaric', code: 'av' },
  { id: 12, language: 'Avestan', code: 'ae' },
  { id: 13, language: 'Aymara', code: 'ay' },
  { id: 14, language: 'Azerbaijani', code: 'az' },
  { id: 15, language: 'Bambara', code: 'bm' },
  { id: 16, language: 'Bashkir', code: 'ba' },
  { id: 17, language: 'Basque', code: 'eu' },
  { id: 18, language: 'Belarusian', code: 'be' },
  { id: 19, language: 'Bengali', code: 'bn' },
  { id: 20, language: 'Bihari', code: 'bh' },
  { id: 21, language: 'Bislama', code: 'bi' },
  { id: 22, language: 'Bosnian', code: 'bs' },
  { id: 23, language: 'Breton', code: 'br' },
  { id: 24, language: 'Bulgarian', code: 'bg' },
  { id: 25, language: 'Burmese', code: 'my' },
  { id: 26, language: 'Catalan; Valencian', code: 'ca' },
  { id: 27, language: 'Chamorro', code: 'ch' },
  { id: 28, language: 'Chechen', code: 'ce' },
  { id: 29, language: 'Chichewa; Chewa; Nyanja', code: 'ny' },
  { id: 30, language: 'Chinese', code: 'zh' },
  { id: 31, language: 'Chuvash', code: 'cv' },
  { id: 32, language: 'Cornish', code: 'kw' },
  { id: 33, language: 'Corsican', code: 'co' },
  { id: 34, language: 'Cree', code: 'cr' },
  { id: 35, language: 'Croatian', code: 'hr' },
  { id: 36, language: 'Czech', code: 'cs' },
  { id: 37, language: 'Danish', code: 'da' },
  { id: 38, language: 'Divehi; Dhivehi; Maldivian;', code: 'dv' },
  { id: 39, language: 'Dutch', code: 'nl' },
  { id: 40, language: 'English', code: 'en' },
  { id: 41, language: 'Esperanto', code: 'eo' },
  { id: 42, language: 'Estonian', code: 'et' },
  { id: 43, language: 'Ewe', code: 'ee' },
  { id: 44, language: 'Faroese', code: 'fo' },
  { id: 45, language: 'Fijian', code: 'fj' },
  { id: 46, language: 'Finnish', code: 'fi' },
  { id: 47, language: 'French', code: 'fr' },
  { id: 48, language: 'Fula; Fulah; Pulaar; Pular', code: 'ff' },
  { id: 49, language: 'Galician', code: 'gl' },
  { id: 50, language: 'Georgian', code: 'ka' },
  { id: 51, language: 'German', code: 'de' },
  { id: 52, language: 'Greek, Modern', code: 'el' },
  { id: 53, language: 'Guaraní', code: 'gn' },
  { id: 54, language: 'Gujarati', code: 'gu' },
  { id: 55, language: 'Haitian; Haitian Creole', code: 'ht' },
  { id: 56, language: 'Hausa', code: 'ha' },
  { id: 57, language: 'Hebrew (modern)', code: 'he' },
  { id: 58, language: 'Herero', code: 'hz' },
  { id: 59, language: 'Hindi', code: 'hi' },
  { id: 60, language: 'Hiri Motu', code: 'ho' },
  { id: 61, language: 'Hungarian', code: 'hu' },
  { id: 62, language: 'Interlingua', code: 'ia' },
  { id: 63, language: 'Indonesian', code: 'id' },
  { id: 64, language: 'Interlingue', code: 'ie' },
  { id: 65, language: 'Irish', code: 'ga' },
  { id: 66, language: 'Igbo', code: 'ig' },
  { id: 67, language: 'Inupiaq', code: 'ik' },
  { id: 68, language: 'Ido', code: 'io' },
  { id: 69, language: 'Icelandic', code: 'is' },
  { id: 70, language: 'Italian', code: 'it' },
  { id: 71, language: 'Inuktitut', code: 'iu' },
  { id: 72, language: 'Japanese', code: 'ja' },
  { id: 73, language: 'Javanese', code: 'jv' },
  { id: 74, language: 'Kalaallisut, Greenlandic', code: 'kl' },
  { id: 75, language: 'Kannada', code: 'kn' },
  { id: 76, language: 'Kanuri', code: 'kr' },
  { id: 77, language: 'Kashmiri', code: 'ks' },
  { id: 78, language: 'Kazakh', code: 'kk' },
  { id: 79, language: 'Khmer', code: 'km' },
  { id: 80, language: 'Kikuyu, Gikuyu', code: 'ki' },
  { id: 81, language: 'Kinyarwanda', code: 'rw' },
  { id: 82, language: 'Kirghiz, Kyrgyz', code: 'ky' },
  { id: 83, language: 'Komi', code: 'kv' },
  { id: 84, language: 'Kongo', code: 'kg' },
  { id: 85, language: 'Korean', code: 'ko' },
  { id: 86, language: 'Kurdish', code: 'ku' },
  { id: 87, language: 'Kwanyama, Kuanyama', code: 'kj' },
  { id: 88, language: 'Latin', code: 'la' },
  { id: 89, language: 'Luxembourgish, Letzeburgesch', code: 'lb' },
  { id: 90, language: 'Luganda', code: 'lg' },
  { id: 91,
    language: 'Limburgish, Limburgan, Limburger',
    code: 'li' },
  { id: 92, language: 'Lingala', code: 'ln' },
  { id: 93, language: 'Lao', code: 'lo' },
  { id: 94, language: 'Lithuanian', code: 'lt' },
  { id: 95, language: 'Luba-Katanga', code: 'lu' },
  { id: 96, language: 'Latvian', code: 'lv' },
  { id: 97, language: 'Manx', code: 'gv' },
  { id: 98, language: 'Macedonian', code: 'mk' },
  { id: 99, language: 'Malagasy', code: 'mg' },
  { id: 100, language: 'Malay', code: 'ms' },
  { id: 101, language: 'Malayalam', code: 'ml' },
  { id: 102, language: 'Maltese', code: 'mt' },
  { id: 103, language: 'Māori', code: 'mi' },
  { id: 104, language: 'Marathi (Marāṭhī)', code: 'mr' },
  { id: 105, language: 'Marshallese', code: 'mh' },
  { id: 106, language: 'Mongolian', code: 'mn' },
  { id: 107, language: 'Nauru', code: 'na' },
  { id: 108, language: 'Navajo, Navaho', code: 'nv' },
  { id: 109, language: 'Norwegian Bokmål', code: 'nb' },
  { id: 110, language: 'North Ndebele', code: 'nd' },
  { id: 111, language: 'Nepali', code: 'ne' },
  { id: 112, language: 'Ndonga', code: 'ng' },
  { id: 113, language: 'Norwegian Nynorsk', code: 'nn' },
  { id: 114, language: 'Norwegian', code: 'no' },
  { id: 115, language: 'Nuosu', code: 'ii' },
  { id: 116, language: 'South Ndebele', code: 'nr' },
  { id: 117, language: 'Occitan', code: 'oc' },
  { id: 118, language: 'Ojibwe, Ojibwa', code: 'oj' },
  { id: 119,
    language: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    code: 'cu' },
  { id: 120, language: 'Oromo', code: 'om' },
  { id: 121, language: 'Oriya', code: 'or' },
  { id: 122, language: 'Ossetian, Ossetic', code: 'os' },
  { id: 123, language: 'Panjabi, Punjabi', code: 'pa' },
  { id: 124, language: 'Pāli', code: 'pi' },
  { id: 125, language: 'Persian', code: 'fa' },
  { id: 126, language: 'Polish', code: 'pl' },
  { id: 127, language: 'Pashto, Pushto', code: 'ps' },
  { id: 128, language: 'Portuguese', code: 'pt' },
  { id: 129, language: 'Quechua', code: 'qu' },
  { id: 130, language: 'Romansh', code: 'rm' },
  { id: 131, language: 'Kirundi', code: 'rn' },
  { id: 132,
    language: 'Romanian, Moldavian, Moldovan',
    code: 'ro' },
  { id: 133, language: 'Russian', code: 'ru' },
  { id: 134, language: 'Sanskrit (Saṁskṛta)', code: 'sa' },
  { id: 135, language: 'Sardinian', code: 'sc' },
  { id: 136, language: 'Sindhi', code: 'sd' },
  { id: 137, language: 'Northern Sami', code: 'se' },
  { id: 138, language: 'Samoan', code: 'sm' },
  { id: 139, language: 'Sango', code: 'sg' },
  { id: 140, language: 'Serbian', code: 'sr' },
  { id: 141, language: 'Scottish Gaelic; Gaelic', code: 'gd' },
  { id: 142, language: 'Shona', code: 'sn' },
  { id: 143, language: 'Sinhala, Sinhalese', code: 'si' },
  { id: 144, language: 'Slovak', code: 'sk' },
  { id: 145, language: 'Slovene', code: 'sl' },
  { id: 146, language: 'Somali', code: 'so' },
  { id: 147, language: 'Southern Sotho', code: 'st' },
  { id: 148, language: 'Spanish; Castilian', code: 'es' },
  { id: 149, language: 'Sundanese', code: 'su' },
  { id: 150, language: 'Swahili', code: 'sw' },
  { id: 151, language: 'Swati', code: 'ss' },
  { id: 152, language: 'Swedish', code: 'sv' },
  { id: 153, language: 'Tamil', code: 'ta' },
  { id: 154, language: 'Telugu', code: 'te' },
  { id: 155, language: 'Tajik', code: 'tg' },
  { id: 156, language: 'Thai', code: 'th' },
  { id: 157, language: 'Tigrinya', code: 'ti' },
  { id: 158, language: 'Tibetan Standard, Tibetan, Central', code: 'bo' },
  { id: 159, language: 'Turkmen', code: 'tk' },
  { id: 160, language: 'Tagalog', code: 'tl' },
  { id: 161, language: 'Tswana', code: 'tn' },
  { id: 162, language: 'Tonga (Tonga Islands)', code: 'to' },
  { id: 163, language: 'Turkish', code: 'tr' },
  { id: 164, language: 'Tsonga', code: 'ts' },
  { id: 165, language: 'Tatar', code: 'tt' },
  { id: 166, language: 'Twi', code: 'tw' },
  { id: 167, language: 'Tahitian', code: 'ty' },
  { id: 168, language: 'Uighur, Uyghur', code: 'ug' },
  { id: 169, language: 'Ukrainian', code: 'uk' },
  { id: 170, language: 'Urdu', code: 'ur' },
  { id: 171, language: 'Uzbek', code: 'uz' },
  { id: 172, language: 'Venda', code: 've' },
  { id: 173, language: 'Vietnamese', code: 'vi' },
  { id: 174, language: 'Volapük', code: 'vo' },
  { id: 175, language: 'Walloon', code: 'wa' },
  { id: 176, language: 'Welsh', code: 'cy' },
  { id: 177, language: 'Wolof', code: 'wo' },
  { id: 178, language: 'Western Frisian', code: 'fy' },
  { id: 179, language: 'Xhosa', code: 'xh' },
  { id: 180, language: 'Yiddish', code: 'yi' },
  { id: 181, language: 'Yoruba', code: 'yo' },
  { id: 182, language: 'Zhuang, Chuang', code: 'za' } ]
  
  export default languages;
  