import { WidgetData } from 'types/Dashboard-types';

export const widgets: WidgetData[] = [
  {
    id: 1,
    name: 'Engagement ',
  },
  {
    id: 2,
    name: 'Energy ',
  },
  {
    id: 3,
    name: 'Variation ',
  },
  {
    id: 4,
    name: 'Positivity ',
  },

  {
    id: 5,
    name: 'Flags ',
  },
];
