/** @format */

import React from 'react';

function StopRecordingIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='11' cy='11' r='10.5' fill='white' stroke='#D81722' />
      <rect x='6' y='6' width='10' height='10' rx='2' fill='#D81722' />
    </svg>
  );
}

export default StopRecordingIcon;
