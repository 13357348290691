import { Box, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import { FilteredProcessDataList } from 'types/Dashboard-types';
import NoDataFoundForSelectedFilters from 'widgets/NoDataFoundForSelectedFilters';
import Typography from 'widgets/Typography/Typography';

function EngagementWidget({
  processDataListWithSession,
  filteredDataWithInputs,
}: {
  processDataListWithSession: Array<ProcessDataListVersion2>;
  filteredDataWithInputs: FilteredProcessDataList[];
}) {
  const [filteredAverageEngagementScore, setFilteredAverageEngagementScore] =
    useState(0);
  const [earliestDate, setEarliestDate] = useState(new Date());
  const [difference, setDifference] = useState(0);
  const [chartData, setChartData] = useState<Array<number>>([]);

  useEffect(() => {
    const filteredData =
      filteredDataWithInputs.length > 0
        ? filteredDataWithInputs[0]?.FilteredData
        : processDataListWithSession;

    if (filteredData && filteredData.length > 0) {
      const filteredTotalEngagementScore = filteredData.reduce(
        (accumulator: number, data: ProcessDataListVersion2) =>
          accumulator + data.engagement_score,
        0
      );
      const filteredAvg = filteredTotalEngagementScore / filteredData.length;

      setFilteredAverageEngagementScore(Math.floor(filteredAvg));

      const mappedData = filteredData.map((item: ProcessDataListVersion2) => {
        return parseFloat(item.engagement_score.toString());
      });

      setChartData(mappedData);
    }
  }, [filteredDataWithInputs, processDataListWithSession]);

  useEffect(() => {
    const engagementData =
      filteredDataWithInputs.length > 0
        ? filteredDataWithInputs[0]?.FilteredData
        : processDataListWithSession;

    if (engagementData && engagementData.length > 0) {
      engagementData.sort(
        (a: ProcessDataListVersion2, b: ProcessDataListVersion2) => {
          return (
            new Date(a.processing_start_date_time).getTime() -
            new Date(b.processing_start_date_time).getTime()
          );
        }
      );

      const earliest = engagementData[0].engagement_score;
      const latest = engagementData[engagementData.length - 1].engagement_score;

      const result = (earliest - latest).toFixed(2); // Round to 2 decimal places
      setDifference(parseFloat(result));

      setEarliestDate(new Date(engagementData[0].processing_start_date_time));
    }
  }, [filteredDataWithInputs, processDataListWithSession]);

  return (
    <Paper elevation={1} sx={{ width: '100%', height: '100%' }}>
      <Grid container sx={{ mt: 2, ml: 1 }}>
        <Grid item md={6}>
          <Grid item sm={12}>
            <Typography
              sx={{
                color: 'var(--Body-Text-Light, #605E5E)',
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.132px',
                mt: 1,
                ml: 2,
              }}
              label='dashboard.OverallScore'
            />

            <Typography
              sx={{
                color: 'var(--Body-Text, #33344B)',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.198px',

                ml: 2,
              }}
              label='dashboard.Engagement'
            />
          </Grid>
          {processDataListWithSession.length > 0 && (
            <Grid item sm={12}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  ml: 2,
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Body-Text, #33344B)',
                    fontFamily: 'Poppins',
                    fontSize: '36px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '42px',
                  }}
                  label=''
                >
                  {Math.floor(filteredAverageEngagementScore)}
                </Typography>

                <Typography
                  sx={{
                    color: true
                      ? difference > 0
                        ? 'green'
                        : difference < 0
                        ? 'red'
                        : 'black'
                      : 'var(--Body-Text, #33344B)',
                    fontFamily: 'Poppins',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '12px',
                    mt: 3,
                    borderRadius: '30px',
                    background: '#F4E8EA',

                    width: 'fit-content',
                    height: 'fit-content',
                  }}
                  label=''
                >
                  {difference}%
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: 'var(--Black, #000)',
                  fontFamily: 'Poppins',
                  fontSize: '8px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: '22px',

                  ml: 2,
                  mt: 1,
                }}
                label='dashboard.Since'
              >
                {earliestDate.toLocaleString()}
              </Typography>
            </Grid>
          )}
        </Grid>
        {processDataListWithSession.length > 0 && (
          <Grid item md={6}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                mt: 5,
                ml: 2,
              }}
            >
              {chartData.length > 0 && (
                <SparkLineChart
                  data={chartData}
                  height={75}
                  width={130}
                  curve='natural'
                  area
                  colors={['#06AA8D', '#07DCAA']}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          mt: 2,
          ml: 1,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {processDataListWithSession.length <= 0 && (
          <NoDataFoundForSelectedFilters />
        )}
      </Grid>
    </Paper>
  );
}

export default EngagementWidget;
