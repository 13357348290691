/** @format */

import React, { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './subscriptions.css';
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import SubscriptionDisplayCard from 'widgets/SubscriptionCard/SubscriptionDisplayCard';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getAvailableSubscriptionPlansSelector,
  getStripeSessionIdSelector,
  getSubscriptionSelector,
  validateCouponCodeSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getAvailableSubscriptionPlansServiceApi,
  getStripeSessionIdServiceApi,
  getSubscriptionServiceApi,
  validateCouponCodeServiceApi,
} from 'services/Subscription-service';
import { GetStripeSessionIdPayloadType } from 'types/Subscription-types';
import { ProtectedRoutes } from 'routes/routes';
import Loader from 'widgets/Loader/Loader';
import { uuidGenerator } from 'utils/UuidGenerator';
import { Toast } from 'widgets/Toast/Toast';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function SubscriptionsComponent() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { getStripeSessionIdData, isLoadingGetStripeSessionId } =
    useAppSelector(getStripeSessionIdSelector);

  const { isLoadingValidateCouponCode } = useAppSelector(
    validateCouponCodeSelector
  );

  const [couponCode, setCouponCode] = useState<string>('');
  const [showDiscount, setShowDiscount] = useState(-1);

  const {
    getAvailableSubscriptionPlansData,
    isLoadingGetAvailableSubscriptionPlans,
  } = useAppSelector(getAvailableSubscriptionPlansSelector);
  const { getSubscriptionData, isLoadingGetSubscription } = useAppSelector(
    getSubscriptionSelector
  );

  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({
    userPlanCreatedDate: '',
    userPlanExpiryDate: '',
    currentSubscriptionPlan: {},
  });

  useEffect(() => {
    if (getStripeSessionIdData.url.length > 5)
      window.location.href = getStripeSessionIdData.url;
  }, [getStripeSessionIdData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get('success');
    const cancelParam = searchParams.get('canceled');

    if (successParam === 'true') {
      history.push({
        pathname: ProtectedRoutes.StripeSubscriptionPaymentSuccess,
        state: { status: 'success' },
      });
    }

    if (cancelParam === 'true') {
      history.push({
        pathname: ProtectedRoutes.StripeSubscriptionPaymentFail,
        state: { status: 'fail' },
      });
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getAvailableSubscriptionPlansServiceApi());
    dispatch(getSubscriptionServiceApi());
  }, [dispatch]);

  useEffect(() => {
    if (
      getSubscriptionData.length > 0 &&
      getAvailableSubscriptionPlansData.length > 0
    ) {
      const currentSubscriptionPlan = getAvailableSubscriptionPlansData.find(
        (availableSubscriptionPlan: any) => {
          return availableSubscriptionPlan.id === getSubscriptionData[0].planid;
        }
      );

      setSubscriptionDetails({
        ...subscriptionDetails,

        userName:
          sessionStorage.getItem('first_name') +
          ' ' +
          sessionStorage.getItem('last_name'),
        userEmail: sessionStorage.getItem('userName'),

        currentSubscriptionPlan: currentSubscriptionPlan,

        userPlanCreatedDate: new Date(
          getSubscriptionData[0]?.createddate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
        userPlanExpiryDate: new Date(
          getSubscriptionData[0]?.expirydate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      });
    }
  }, [getSubscriptionData, getAvailableSubscriptionPlansData]);

  function onChangeCouponText(e: any) {
    setCouponCode(e.target.value);
  }

  function handleSubscriptionPayment(props: any) {
    if (props.gatewayplanid.toLowerCase() === 'coupon') {
      if (couponCode === '') {
        Toast(t('toastMessageNotifications.PleaseEnterCouponCode'), 'error');
      } else {
        dispatch(validateCouponCodeServiceApi(couponCode, history));
      }
    } else {
      const payloadGetStripeSessionIdServiceApi: GetStripeSessionIdPayloadType =
        {
          price_id: props.gatewayplanid,
          response_domain: `${window.location.origin}/#/subscriptions`,
          plan_id: props.id,
          payment_mode: props.stripepaymentmode,
          session_id: uuidGenerator(),
          coupon_code: couponCode,
        };

      dispatch(
        getStripeSessionIdServiceApi(payloadGetStripeSessionIdServiceApi)
      );
    }
  }

  const handleViewSubscriptionDetails = () => {
    history.push({
      pathname: ProtectedRoutes.profileRoute,
      state: {
        newValue: 2,
      },
    });
  };

  return (
    <AuthWrapper>
      {isLoadingGetStripeSessionId ||
      isLoadingGetAvailableSubscriptionPlans ||
      isLoadingValidateCouponCode ? (
        <Loader />
      ) : (
        <Box className='subscription-layout' sx={{ p: 1, gap: 2 }}>
          <Typography
            variant='h4'
            sx={{ fontWeight: 600, textAlign: 'center' }}
            label='mysubcriptions.UpgradeYourPlan'
          />

          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Poppins',
              maxWidth: '600px',
            }}
            label='mysubcriptions.GoBeyondBasicChooseaPlanThatIncludesAllTheCoreFeaturesToHelpYouPolishYourCommunicationSkills'
          />

          {getSubscriptionData &&
            getSubscriptionData[0] &&
            getSubscriptionData[0].planid !== null && (
              <Button
                onClick={handleViewSubscriptionDetails}
                label={'mysubcriptions.ViewCurrentPlanDetails'}
                sx={{
                  color: '#1206f9',

                  backgroundColor: 'transparent',
                  '&:hover': {
                    color: '#1206f9',

                    backgroundColor: 'transparent',
                  },
                }}
              />
            )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '13px',
              width: '98%',
              mb: 2,

              flexWrap: 'wrap',
            }}
          >
            {getAvailableSubscriptionPlansData.length > 0 &&
              getSubscriptionData.length > 0 &&
              getAvailableSubscriptionPlansData.map(
                (subscriptionPlan, index) => {
                  return (
                    <SubscriptionDisplayCard
                      subscriptionPlan={subscriptionPlan}
                      index={index}
                      handleSubscriptionPayment={handleSubscriptionPayment}
                      currentUserSubscription={getSubscriptionData}
                      showDiscount={showDiscount}
                      setShowDiscount={setShowDiscount}
                      onChangeCouponText={onChangeCouponText}
                      couponCode={couponCode}
                      setCouponCode={setCouponCode}
                    />
                  );
                }
              )}
          </Box>
          <Typography
            sx={{ textAlign: 'center' }}
            label='mysubcriptions.QuestionsEmailUsAt'
          >
            <Link href='mailto:hello@mirro.ai' variant='body2'>
              {' '}
              <Typography label='mysubcriptions.convocoachmirroai' />
            </Link>
          </Typography>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default SubscriptionsComponent;
