/** @format */

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  useMediaQuery,
  Box,
  InputAdornment,
  IconButton,
  Paper,
} from '@mui/material';
import FacebookButton from 'components/SSO/Facebook/FacebookButton';
import FacebookSigninButton from 'components/SSO/Facebook/FacebookSigninButton';
import MicrosoftSignInButton from 'components/MicrosoftLoginSSO/MicrosoftSignInButton';
import GoogleSignInButton from 'components/SSO-Google/GoogleSigninButton';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OpenRoutes } from 'routes/routes';
import { SignInSchema } from 'schema/Auth/Auth';
import Footer from 'shared/Footer/Footer';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';

function LoginForm({
  handleSignInFormSubmit,
  loginStatus,
  showPassword,
  handleClickShowPassword,
}: any) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const initialValuesSignIn = {
    username: '',
    password: '',
  };

  const history = useHistory();

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : isTablet ? '70%' : '50%',
        minHeight: '65vh',
        height: '100%',
        zIndex: 1,
        ml: -1,
        mt: -2,
        mb: 1,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          height: 'fit-content',
          borderRadius: '6px',
          padding: 5,
          border: '2px solid #D4D4D4',
          pb: 8,
        }}
      >
        <Formik
          initialValues={initialValuesSignIn}
          validationSchema={SignInSchema(t)}
          onSubmit={handleSignInFormSubmit}
        >
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: '10%',
                minHeight: '60vh',
                height: '100%',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  color: '#33344B',
                  fontFamily: 'Poppins',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '23px',
                }}
                label='loginpage.Login'
              />

              <Field
                name='username'
                as={TextField}
                variant='outlined'
                fullWidth
                helperText={
                  <ErrorMessage
                    name='username'
                    component='Box'
                    className='error-message'
                  />
                }
                id='txtuseremailid'
                InputProps={{
                  style: {
                    borderRadius: '35px',
                  },
                }}
                label='User Email'
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                sx={{
                  mt: '5%',
                  minHeight: '80px',
                }}
              />
              <Field
                sx={{
                  mt: '5%',

                  minHeight: '80px',
                }}
                name='password'
                as={TextField}
                variant='outlined'
                fullWidth
                helperText={
                  <ErrorMessage
                    name='password'
                    component='Box'
                    className='error-message'
                  />
                }
                type={showPassword ? 'text' : 'password'}
                label='loginpage.Password'
                id='txtuserpassword'
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  style: {
                    borderRadius: '35px',
                  },

                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                        id='togglePasswordVisibility'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                id='forgotPassword'
                aria-label='forgotPassword'
                sx={{ alignSelf: 'end', mt: '0%' }}
              >
                <Typography
                  onClick={() => history.push(OpenRoutes.forgotPasswordRoute)}
                  sx={{
                    color: '#1206F9',
                    textAlign: 'right',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                  }}
                  label='loginpage.ForgotPassword'
                />
              </IconButton>
              <Button
                sx={{
                  backgroundColor: '#1206f9',
                  mt: '4%',
                  color: '#fff',
                  padding: 2,
                  borderRadius: '25px',
                  '&:hover': {
                    backgroundColor: '#1206f9',
                  },
                }}
                id='btnSignin'
                variant='contained'
                type='submit'
                disabled={loginStatus.isLoadingLoginData}
                label='loginpage.Login'
              />

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography label='loginpage.OrLoginWith' />
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: isMobile || isTablet ? 'column' : 'row',
                    gap: 1,
                  }}
                >
                  <MicrosoftSignInButton isCandidateScreen={false} />
                  <GoogleSignInButton />
                  <FacebookSigninButton isCandidateScreen={false} />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography label='loginpage.NewToMirroai' />
                  <Typography
                    onClick={() => history.push(OpenRoutes.signUpRoute)}
                    id='signupRedirect'
                    sx={{
                      color: '#1206F9',
                      fontWeight: ' 600',
                      lineHeight: '22px',
                    }}
                    label='loginpage.Signup'
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Paper>
      <Footer />
    </Box>
  );
}

export default LoginForm;
