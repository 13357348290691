import React from 'react';
import { Box } from '@mui/material';
import './Spinner.css';

const YoutubeSpinner = () => {
  return (
    <Box
      className='youtube-spinner'
      sx={{
        display: 'inline-block',
        width: '50px',
        height: '50px',
      }}
    >
      <svg className='youtube-spinner' viewBox='25 25 50 50'>
        <circle
          className='path'
          cx='50'
          cy='50'
          r='20'
          fill='none'
          strokeWidth='4'
          strokeMiterlimit='10'
        />
      </svg>
    </Box>
  );
};

export default YoutubeSpinner;
