/** @format */

import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, Paper } from '@mui/material';
import { ColumnsListProps, HeadCell } from 'types/Dashboard-types';
import { useTranslation } from 'react-i18next';

export default function ColumnsList({
  anchorEl,
  open,
  handleClose,
  headCells,
  columnVisibility,
  handleColumnToggle,
}: ColumnsListProps) { 
  const {t} = useTranslation();
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{ mt: 1 }}
    >
      {headCells.map((cell: HeadCell) => (
        <>
          {cell.label.length > 0 && (
            <MenuItem>
              <Checkbox
                checked={columnVisibility[cell.id]}
                onChange={() => handleColumnToggle(cell.id)}
                role='checkbox'
                aria-checked='false'
              />

              {t(cell.label)}
            </MenuItem>
          )}
        </>
      ))}
    </Menu>
  );
}
