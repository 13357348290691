/** @format */

import { Box, Modal } from '@mui/material';
import React from 'react';
import Typography from 'widgets/Typography/Typography';

interface CountDownModalProps {
  openCountDownModal: boolean;
  countdown: number;
}

function CountDownModal({
  openCountDownModal,
  countdown,
}: CountDownModalProps) {
  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={openCountDownModal}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: 20, color: 'white' }}>
        <Typography variant='h1' label=''>
          {countdown}
        </Typography>
      </Box>
    </Modal>
  );
}

export default CountDownModal;
