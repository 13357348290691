/** @format */

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import './footerStyles.css';
import { Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import env from 'envConfig';
import { RedirectUrls } from 'routes/urls';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import { Toast } from 'widgets/Toast/Toast';

export default function Footer() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const mobileMenuId = 'primary-search-account-menu-mobile-footer';
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{ fontFamily: 'poppins' }}
    >
      <MenuItem
        sx={{ fontFamily: 'poppins' }}
        onClick={() => window.open(RedirectUrls.privacyPolicy, '_blank')}
        id='privacy-policy-Redirect-mobile'
      >
        <p>{t('footer.PrivacyPolicy')}</p>
      </MenuItem>
      <MenuItem
        sx={{ fontFamily: 'poppins' }}
        onClick={() => window.open(RedirectUrls.termsOfService, '_blank')}
        id='tos-Redirect-mobile'
      >
        <p>{t('footer.TermsOfService')}</p>
      </MenuItem>
      <MenuItem
        sx={{ fontFamily: 'poppins' }}
        onClick={() => window.open(RedirectUrls.faqs, '_blank')}
        id='faq-Redirect-mobile'
      >
        <p>{t('footer.Faq')}</p>
      </MenuItem>
      <MenuItem
        sx={{ fontFamily: 'poppins' }}
        onClick={() => window.open(RedirectUrls.contactUs, '_blank')}
        id='contact-us-Redirect-mobile'
      >
        <p>{t('footer.ContactUs')}</p>
      </MenuItem>
      <MenuItem
        sx={{ fontFamily: 'poppins' }}
        onClick={handleFeedbackLinkClick}
        id='feedbackLinkRedirect'
      >
        <p>{t('footer.Feedback')}</p>
      </MenuItem>
    </Menu>
  );
  const drawerWidth = 240;

  function handleFeedbackLinkClick() {
    const isUserLoggedIn = sessionStorage.getItem('apiKey');

    if (isUserLoggedIn) history.push(ProtectedRoutes.feedback);
    else Toast('Please Login to submit feedback', 'error');
  }

  return (
    <AppBar
      className='footer-layout-wrapper'
      position='fixed'
      color='primary'
      sx={{
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        height: '50px',
        border: '1px solid #d4dfff',
        background: '#fafafa',

        // width: open ? `calc(100% - ${drawerWidth}px)` : '100%',

        marginLeft: `${drawerWidth}px`,

        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <div
          className={`footer-toolbar${isMobile || isTablet ? '-mobile' : ''}`}
        >
          <div className='footer-toolbar-menu'>
            &copy; {`2021 - ${new Date().getFullYear()}`}{' '}
            {t('footer.2023Mirroai')} | {t('footer.AllRightsReserved')} |{' '}
            {env.REACT_APP_VERSION}
          </div>

          {isMobile || isTablet ? (
            <div>
              <IconButton
                size='small'
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                id='footerOptionOpenButtonMobileView'
              >
                <MoreIcon />
              </IconButton>
            </div>
          ) : (
            <div
              className={`footer-toolbar-right-panel${
                isMobile || isTablet ? '-mobile' : ''
              }`}
            >
              <div
                onClick={() =>
                  window.open(RedirectUrls.privacyPolicy, '_blank')
                }
                id='privacy-policy-Redirect'
                role='button'
                className='footer-toolbar-menu'
              >
                {t('footer.PrivacyPolicy')}
              </div>

              <div
                onClick={() =>
                  window.open(RedirectUrls.termsOfService, '_blank')
                }
                id='tos-Redirect'
                role='button'
                className='footer-toolbar-menu'
              >
                {t('footer.TermsOfService')}
              </div>

              <div
                onClick={() => window.open(RedirectUrls.faqs, '_blank')}
                className='footer-toolbar-menu'
                role='button'
                id='faq-Redirect'
              >
                {t('footer.Faq')}
              </div>

              <div
                onClick={() => window.open(RedirectUrls.contactUs, '_blank')}
                className='footer-toolbar-menu'
                role='button'
                id='contact-us-Redirect'
              >
                {t('footer.ContactUs')}
              </div>

              <div
                onClick={handleFeedbackLinkClick}
                role='button'
                className='footer-toolbar-menu'
                id='feedbackLink'
              >
                {t('footer.Feedback')}
              </div>
            </div>
          )}
        </div>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
}
