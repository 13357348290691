import Typography from 'widgets/Typography/Typography';
import React from 'react';
import { VideoData } from 'types/VideoPreview-types';
import Loader from 'widgets/Loader/Loader';

function OverallSummaryVersion2({
  ProcessDataListForParticipants,
  selectedSummaryTab,
}: {
  ProcessDataListForParticipants: any;
  selectedSummaryTab: number;
}) {
  if (ProcessDataListForParticipants.length === 0) {
    return <Loader />;
  }

  const renderArray = (array: string[]) => {
    return (array || []).map((item, index) => (
      <Typography key={index} label='' sx={{ fontSize: '14px' }}>
        {item}
      </Typography>
    ));
  };

  return (
    <div style={{ marginTop: 3, gap: 2 }}>
      {ProcessDataListForParticipants?.[0]?.session_type_id === 58 ||
      ProcessDataListForParticipants?.[0]?.session_type_id === 59 ? (
        <div>
          {ProcessDataListForParticipants?.[0]?.detailed_summary ? (
            <div>
              <section style={{ marginTop: 1, marginBottom: 1 }}>
                <Typography
                  label='Patient Overview'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <Typography label='' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.patient_overview?.relevant_info
                  }
                </Typography>
                <Typography label='' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.patient_overview?.medical_history
                  }
                </Typography>
                <Typography label='' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.patient_overview?.current_health_status
                  }
                </Typography>
              </section>

              <section style={{ marginTop: 2, marginBottom: 2 }}>
                <Typography
                  label='Energy Levels'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <Typography label='Dominant Emotion:' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.dominant_emotion
                  }
                </Typography>
                <Typography label='Energy Levels:' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.energy_levels
                  }
                </Typography>
                <Typography label='Engagement Score:' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.engagement_score
                  }
                </Typography>
                <Typography label='confident tone :' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.confidence_fatigue_stress
                      ?.confident_tone
                  }
                </Typography>
                <Typography label=' fatigued tone :' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.confidence_fatigue_stress
                      ?.fatigued_tone
                  }
                </Typography>
                <Typography label='stressed tone:' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.overall_sentiment?.confidence_fatigue_stress
                      ?.stressed_tone
                  }
                </Typography>
              </section>

              <section style={{ marginTop: 2, marginBottom: 1 }}>
                <Typography
                  label='Emotional Discrepancies'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <Typography label='' sx={{ fontSize: '14px' }}>
                  {
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.detailed_tone_analysis?.emotional_discrepancies
                  }
                </Typography>
              </section>

              <section style={{ marginTop: 1, marginBottom: 1 }}>
                <Typography
                  label='Stress Indicators'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                {renderArray(
                  ProcessDataListForParticipants?.[0]?.detailed_summary
                    ?.detailed_tone_analysis?.stress_indicators
                )}
              </section>

              <section style={{ marginTop: 1, marginBottom: 1 }}>
                <Typography
                  label='Recovery Plan'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>

                <Typography
                  label='Immediate Actions'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.recovery_plan?.immediate_actions || []
                  ).map((action: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {action}
                    </li>
                  ))}
                </ul>

                <Typography
                  label='Short-term Goals'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.recovery_plan?.short_term_goals || []
                  ).map((goal: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {goal}
                    </li>
                  ))}
                </ul>

                <Typography
                  label='Long-term Strategies'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.recovery_plan?.long_term_strategies || []
                  ).map((strategy: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {strategy}
                    </li>
                  ))}
                </ul>

                <Typography
                  label='Emotional Support'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.recovery_plan?.emotional_support || []
                  ).map((support: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {support}
                    </li>
                  ))}
                </ul>

                <Typography
                  label='Progress Monitoring'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.recovery_plan?.progress_monitoring || []
                  ).map((monitoring: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {monitoring}
                    </li>
                  ))}
                </ul>
              </section>

              <section style={{ marginTop: 1, marginBottom: 1 }}>
                <Typography
                  label='Identified Services'
                  sx={{ fontSize: '15px', fontWeight: 600 }}
                ></Typography>
                <ul>
                  {(
                    ProcessDataListForParticipants?.[0]?.detailed_summary
                      ?.medicaid_billable_events?.identified_services || []
                  ).map((service: any, index: number) => (
                    <li key={index} style={{ fontSize: '14px' }}>
                      {service}
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          ) : (
            <Typography label=''>
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {ProcessDataListForParticipants[0].overall_summary}
              </pre>
            </Typography>
          )}
        </div>
      ) : (
        <Typography label=''>
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {ProcessDataListForParticipants[0].overall_summary}
          </pre>
        </Typography>
      )}
    </div>
  );
}

export default OverallSummaryVersion2;
