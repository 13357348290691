/** @format */

import { AppThunk } from 'redux/store';
import { TokenResponse } from '@react-oauth/google';
import { i18n } from 'i18n/i18n';
import { signUpDataSuccess, signUpDataFailure } from 'redux/slices/Auth-slice';
import { OpenRoutes, ProtectedRoutes } from 'routes/routes';
import { ProtectedUrls, PublicUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';
import { History } from 'history';
import {
  googleSSOFailure,
  googleSSOSignInFailure,
  googleSSOSignInSuccess,
  googleSSOSuccess,
} from 'redux/slices/GoogleSSO-slice';
import { AxiosResponse } from 'axios';
import {
  getUserAndCheckAuthProviderOfUser,
  handleSuccessfulLogin,
} from 'utils/AuthenticationHelpers';

export const handleSignUpGoogleSuccessServiceApi =
  (
    response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!response.access_token) {
        Toast(
          i18n.t('toastMessageNotifications.GoogleAuthenticationErrorFailedToGetAccessToken'),
          'error'
        );
        return;
      }

      const userInfoResponse = await commonAPI.get(
        PublicUrls.getGoogleUserInfoAfterLoginSuccess.url(
          response.access_token
        ),
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: 'application/json',
          },
        }
      );

      const responseAuth = await commonAPI.get(
        ProtectedUrls.getAuthProviderDetails.url()
      );

      const { email, family_name, given_name, id } = userInfoResponse.data;
      const signUpPayloadGoogle = {
        firstName: given_name,
        lastName: family_name,
        email: email,
        password: id,
        authProvider: responseAuth.data['Google'],
      };

      const signUpResponse = await commonAPI.post(
        PublicUrls.signUpApiRoute.url(signUpPayloadGoogle)
      );

      dispatch(googleSSOSuccess(signUpResponse.data));

      if (signUpResponse.data[0]?.apikey) {
        Toast(i18n.t('toastMessageNotifications.AccountCreatedSuccessfully'), 'success');
        history.push(OpenRoutes.signUpSuccessPage);
      } else {
        switch (signUpResponse.data.message) {
          case 'Email-id Already exists':
            const userDetails: AxiosResponse<any, any> = await commonAPI.get(
              ProtectedUrls.getUserDetailsAsUserUrl.url({
                email_address: email,
              })
            );
            await getUserAndCheckAuthProviderOfUser(
              userDetails.data[0]?.auth_provider,
              signUpResponse.data.message
            );

            break;
          default:
            Toast(signUpResponse.data.message, 'error');
        }
      }
    } catch (error) {
      dispatch(googleSSOFailure(error));
      dispatch(signUpDataFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')));
      console.error(error);
    }
  };

export const handleSignInGoogleSuccessServiceApi =
  (
    response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!response.access_token) {
        Toast(
          i18n.t('toastMessageNotifications.GoogleAuthenticationErrorFailedToGetAccessToken'),
          'error'
        );
        return;
      }

      const userInfoResponse = await commonAPI.get(
        PublicUrls.getGoogleUserInfoAfterLoginSuccess.url(
          response.access_token
        ),
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: 'application/json',
          },
        }
      );

      const { email, id } = userInfoResponse.data;
      const signInPayloadGoogle = {
        username: email,
        password: id,
      };

      const signInResponse = await commonAPI.get(
        PublicUrls.loginApiRoute.url(signInPayloadGoogle)
      );

      dispatch(googleSSOSignInSuccess(signInResponse.data));

      if (signInResponse.data[0]?.apikey) {
        await handleSuccessfulLogin(signInResponse.data[0]);

        Toast(i18n.t('toastMessageNotifications.LoginSuccessful'), 'success');
        history.push(ProtectedRoutes.galleryRoute);
      } else {
        switch (signInResponse.data.message) {
          case 'Password did not Match':
            const userDetails: AxiosResponse<any, any> = await commonAPI.get(
              ProtectedUrls.getUserDetailsAsUserUrl.url({
                email_address: email,
              })
            );
            await getUserAndCheckAuthProviderOfUser(
              userDetails.data[0]?.auth_provider,
              signInResponse.data.message
            );

            break;
          default:
            Toast(signInResponse.data.message, 'error');
        }
      }
    } catch (error) {
      dispatch(googleSSOSignInFailure(error));
      console.error(error);
    }
  };
