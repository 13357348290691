/** @format */

import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import { globalSearchSelector } from 'redux/selectors/selectors';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ProtectedUrls } from 'routes/urls';

interface Params {
  [key: string]: string;
}

function useDashboard(filteredDataWithInputs: Array<ProcessDataListVersion2>) {
  const dispatch = useAppDispatch();

  const [processDataListWithSession, setProcessDataListWithSession] = useState<
    ProcessDataListVersion2[]
  >([]);
  const [processDataListVersion2, setProcessDataListVersion2] = useState<
    ProcessDataListVersion2[]
  >([]);
  const [apiKey, setApiKey] = useState('');
  const [env, setEnv] = useState('');

  const { globalSearchValue } = useAppSelector(globalSearchSelector);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    const {
      apikey: newApiKey,

      env: envName,
    } = params;

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (envName) {
      setEnv(envName);
    }

    return () => {};
  }, [params]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListVersion2Service.url()
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          setProcessDataListVersion2(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [apiKey, env]);

  function searchVideosByTitle(searchQuery: string) {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const FilteredData = filteredDataWithInputs[0];

    let searchedData: ProcessDataListVersion2[] = [];

    if (
      filteredDataWithInputs.length === 0 &&
      processDataListVersion2.length > 0
    ) {
      // If filteredDataWithInputs is empty, filter processDataListVersion2
      searchedData = processDataListVersion2.filter(
        ({ session_title, processing_event_name }) => {
          if (session_title)
            return (
              session_title.toLowerCase().includes(lowerCaseSearchQuery) ||
              processing_event_name.toLowerCase().includes(lowerCaseSearchQuery)
            );

          return false; // No need to return an empty object
        }
      );
    } else {
      if (Array.isArray(FilteredData)) {
        searchedData = FilteredData.filter(
          ({ session_title, processing_event_name }: any) => {
            if (session_title)
              return (
                session_title.toLowerCase().includes(lowerCaseSearchQuery) ||
                processing_event_name
                  .toLowerCase()
                  .includes(lowerCaseSearchQuery)
              );

            return false; // No need to return an empty object
          }
        );
      }
    }

    setProcessDataListWithSession(searchedData);
  }

  useEffect(() => {
    searchVideosByTitle(globalSearchValue);
    // eslint-disable-next-line
  }, [globalSearchValue, processDataListVersion2]);
  return {
    processDataListWithSession,
    setProcessDataListWithSession,
  };
}

export default useDashboard;
