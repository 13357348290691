import { i18n } from 'i18n/i18n';
import {
  ReprocessVersion2VideoLoad,
  ReprocessVersion2VideoSuccess,
  ReprocessVersion2VideoFailure,
  deleteProcessVideoV2Failure,
  deleteProcessVideoV2Load,
  deleteProcessVideoV2Success,
} from 'redux/slices/GalleryVersion2-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { GetReprocessVideoServiceProps } from 'types/Gallery.types';
import { getProcessDataListVersion2Service } from './Gallery-service';

export const reprocessMultipleService =
  (
    reprocessVideoData: GetReprocessVideoServiceProps,
    handleOpenSuccessReprocessVideoModal: Function,
    handleReprocess: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(ReprocessVersion2VideoLoad());
    const params = handleReprocess(reprocessVideoData.sessionDetails.id);

    await commonAPI
      .get(ProtectedUrls.reprocessBulkRecords.url() + params)
      .then((response) => {
        dispatch(ReprocessVersion2VideoSuccess(response.data));
        handleOpenSuccessReprocessVideoModal();
        dispatch(getProcessDataListVersion2Service());
      })
      .catch((error) => {
        dispatch(
          ReprocessVersion2VideoFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };

export const deleteMultipleService =
  (
    handleOpenOnDeleteSuccessModal: Function,
    handleDelete: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(deleteProcessVideoV2Load());
    const params = handleDelete();
    await commonAPI
      .get(ProtectedUrls.deleteBulkRecords.url() + params)
      .then((response) => {
        dispatch(deleteProcessVideoV2Success(response.data));
        dispatch(getProcessDataListVersion2Service());
        handleOpenOnDeleteSuccessModal();
      })
      .catch((error) => {
        dispatch(
          deleteProcessVideoV2Failure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };
