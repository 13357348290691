import React from 'react';
import {
  Autocomplete as DefaultAutocomplete,
  TextFieldProps,
} from '@mui/material';
import TextField from 'widgets/TextField/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AutocompleteRenderInputParams,
  AutocompleteProps as DefaultAutocompleteProps,
} from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

type DefaultAutocompletePropsWithDefaults<T> = DefaultAutocompleteProps<
  T,
  false,
  false,
  false
>;

interface AutocompleteProps<T> extends DefaultAutocompletePropsWithDefaults<T> {
  options: T[];
  optionLabelKey: keyof T;
  label: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: T | null
  ) => void;
  renderInput: (params: TextFieldProps) => React.ReactNode;
  value: any;
  id?: string;
  sx?: Record<string, any>;
  clearIcon?: boolean;
}

const Autocomplete = <T extends Record<string, any>>({
  options,
  label = '',
  onChange,
  value,
  optionLabelKey,
  renderInput,
  id = '',
  sx = {},
  clearIcon = true,
  ...props
}: AutocompleteProps<T>) => {
  const { t } = useTranslation();
  return (
    <DefaultAutocomplete
      options={options}
      id={id}
      getOptionLabel={(option: T) => option[optionLabelKey]}
      clearIcon={clearIcon}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={t(label)} />
      )}
      {...props}
      popupIcon={
        <ExpandMoreIcon
          style={{
            color: 'blue',
            backgroundColor: 'rgba(18, 6, 249, 0.07)',
            borderRadius: '50%',
          }}
        />
      }
      onChange={onChange}
      value={value}
      sx={sx}
    />
  );
};

export default Autocomplete;
