/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';

import { loadTranslations } from './i18n/i18n';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'components/MicrosoftLoginSSO/authConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import env from 'envConfig';
import { googleSsoConfig } from 'components/SSO-Google/config';
import { initializeFacebookSsoConfig } from 'components/SSO/Facebook/facebookAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

loadTranslations(i18n.language).then(() => {
  initializeFacebookSsoConfig().then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <GoogleOAuthProvider clientId={googleSsoConfig.web.client_id}>
          <MsalProvider instance={msalInstance}>
            <Provider store={store}>
              <App />
            </Provider>
          </MsalProvider>
        </GoogleOAuthProvider>
      </React.StrictMode>,
      document.getElementById('root') as HTMLElement
    );
  });
});

reportWebVitals(console.log);
