/** @format */

import React, { useEffect, useState } from 'react';
import VideoPreviewAdvanceVersion2 from 'components/VideoPreviewAdvance/VideoPreviewAdvanceVersion2/VideoPreviewAdvanceVersion2';
import { Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import Loader from 'widgets/Loader/Loader';
import SandboxPreviewWrapper from 'shared/SandboxPreviewWrapper/SandboxPreviewWrapper';

export default function SandboxVideoPreview() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get('id');

    if (successParam) {
      const decryptedData = JSON.parse(atob(successParam));
      if (decryptedData) {
        localStorage.setItem(
          'coaching_session_reports_id',
          decryptedData.coaching_session_reports_id
        );
        sessionStorage.setItem('apiKey', decryptedData.apiKey);
        sessionStorage.setItem('route', '/preview/mediaPreview');
        setLoading(false);
      }
    }
  }, [location.search]);
  return (
    <SandboxPreviewWrapper>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ paddingLeft: 1 }}>
          <VideoPreviewAdvanceVersion2 isDisable={true} />
        </Box>
      )}
    </SandboxPreviewWrapper>
  );
}
