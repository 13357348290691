export function getDefaultVideoDescription() {
  const now = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  const formattedTime = now
    .toLocaleString('en-US', options)
    .replace(',', '')
    .replace(/:/g, '-')
    .replace(/ /g, '-')
    .replace(/\//g, '-');

  return 'Video-' + formattedTime;
}
