import { createSlice } from '@reduxjs/toolkit';
type AssessmentStatus = 'Success' | 'Failure'; // Define possible status values

type AssessmentEmailData = {
  [email: string]: AssessmentStatus;
};

const sendAssessmentEmailToCandidatesSliceInitialState: {
  sendAssessmentEmailToCandidatesSuccessData: AssessmentEmailData;
  isLoadingsendAssessmentEmailToCandidates: boolean;
  sendAssessmentEmailToCandidatesError: boolean;
} = {
  sendAssessmentEmailToCandidatesSuccessData: {},
  isLoadingsendAssessmentEmailToCandidates: false,
  sendAssessmentEmailToCandidatesError: false,
};
const sendAssessmentEmailToCandidatesSlice = createSlice({
  name: 'sendAssessmentEmailToCandidatesSlice',
  initialState: sendAssessmentEmailToCandidatesSliceInitialState,
  reducers: {
    sendAssessmentEmailToCandidatesLoad(state) {
      state.isLoadingsendAssessmentEmailToCandidates = true;
    },
    sendAssessmentEmailToCandidatesSuccess(state, action) {
      state.sendAssessmentEmailToCandidatesSuccessData = action.payload;
      state.isLoadingsendAssessmentEmailToCandidates = false;
    },
    sendAssessmentEmailToCandidatesError(state, action) {
      state.sendAssessmentEmailToCandidatesError = action.payload;
      state.isLoadingsendAssessmentEmailToCandidates = false;
    },
  },
});
export const {
  sendAssessmentEmailToCandidatesLoad,
  sendAssessmentEmailToCandidatesSuccess,
  sendAssessmentEmailToCandidatesError,
} = sendAssessmentEmailToCandidatesSlice.actions;

export { sendAssessmentEmailToCandidatesSlice };

const candidateAssignmentSubmissionCheckInitialState: {
  candidateAssignmentSubmissionCheckData: boolean;
  isLoadingcandidateAssignmentSubmissionCheck: boolean;
  candidateAssignmentSubmissionCheckInitialStateError: boolean;
} = {
  candidateAssignmentSubmissionCheckData: false,
  isLoadingcandidateAssignmentSubmissionCheck: false,
  candidateAssignmentSubmissionCheckInitialStateError: false,
};

const candidateAssignmentSubmissionCheckSlice = createSlice({
  name: 'candidateAssignmentSubmissionCheckSlice',
  initialState: candidateAssignmentSubmissionCheckInitialState,
  reducers: {
    candidateAssignmentSubmissionCheckLoad(state) {
      state.isLoadingcandidateAssignmentSubmissionCheck = true;
    },
    candidateAssignmentSubmissionCheckSuccess(state, action) {
      state.candidateAssignmentSubmissionCheckData = action.payload;
      state.isLoadingcandidateAssignmentSubmissionCheck = false;
    },
    candidateAssignmentSubmissionCheckError(state, action) {
      state.candidateAssignmentSubmissionCheckInitialStateError =
        action.payload;
      state.isLoadingcandidateAssignmentSubmissionCheck = false;
    },
  },
});
export const {
  candidateAssignmentSubmissionCheckLoad,
  candidateAssignmentSubmissionCheckSuccess,
  candidateAssignmentSubmissionCheckError,
} = candidateAssignmentSubmissionCheckSlice.actions;

export { candidateAssignmentSubmissionCheckSlice };
