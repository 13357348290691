/** @format */

import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AdminDrawerItemsArray, DrawerItemsArray } from './DrawerItemsArray';
import { useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  setOpen: Function;
  handleOpenMobileDrawer: any;
  handleCloseMobileDrawer: any;
  openMobileDrawer: boolean;
}

export default function MiniDrawer({
  open,
  setOpen,
  handleOpenMobileDrawer,
  handleCloseMobileDrawer,
  openMobileDrawer,
}: AppBarProps) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  return isMobile || isTablet ? (
    <MobileDrawer
      handleOpenMobileDrawer={handleOpenMobileDrawer}
      handleCloseMobileDrawer={handleCloseMobileDrawer}
      openMobileDrawer={openMobileDrawer}
    />
  ) : (
    <DesktopDrawer open={open} setOpen={setOpen} />
  );
}
