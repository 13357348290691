import React, { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import RecorderSettingsForAssessment from './RecorderSettingsForAssessment/RecorderSettingsForAssessment';
import PromptsComponent from './PromptsComponent/PromptsComponent';
import CheckCameraAndMicPermissionDialog from 'widgets/CheckCameraAndMicPermissionDialog/CheckCameraAndMicPermissionDialog';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import { getPractiseSectionsServiceApi } from 'services/MyAssessment-service';
import { uploadSectionVideoSelector } from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import { setVideoTitle } from 'redux/slices/MyAssessment-slice';

function MyAssessmentsComponent() {
  const dispatch = useAppDispatch();
  const [togglePromptSection, setTogglePromptSection] =
    useState<Boolean>(false);

  const [mainRecordingSettings, setMainRecordingSettings] = useState({
    videoDescription: '',
    asessmentType: {
      id: -1,
      name: '',
      type: '',
      description: '',
      timerdirection: '',
    },
    recordingType: { id: -1, name: '' },
    cameraDetails: { deviceId: '', kind: '', label: '', groupId: '' },
    microphoneDetails: { deviceId: '', kind: '', label: '', groupId: '' },
  });

  function handleTogglePromptSection() {
    setTogglePromptSection(!togglePromptSection);
    dispatch(
      getPractiseSectionsServiceApi(mainRecordingSettings.asessmentType.id)
    );
  }

  useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1, true));
  }, []);

  useEffect(() => {
    dispatch(setVideoTitle(mainRecordingSettings.videoDescription));
  }, [mainRecordingSettings.videoDescription]);
  return (
    <AuthWrapper>
      {togglePromptSection ? (
        <PromptsComponent
          mainRecordingSettings={mainRecordingSettings}
          setMainRecordingSettings={setMainRecordingSettings}
          handleTogglePromptSection={handleTogglePromptSection}
          isFromCandidateAssessment={false}
          candidateDetails={{
            firstName: '',
            lastName: '',
            email: '',
            assessmentId: -1,
          }}
        />
      ) : (
        <RecorderSettingsForAssessment
          handleTogglePromptSection={handleTogglePromptSection}
          setMainRecordingSettings={setMainRecordingSettings}
          isFromCandidateAssessment={false}
          mandatoryDataForCandidateAssessmentSubmission={{
            senderApiKey: '',
            selectedAssessmentType: {
              id: -1,
              name: '',
              type: '',
              description: '',
              timerdirection: '',
            },
            expirationDateTimeStampInUtc: '',
            user_mail_id: '',
          }}
          candidateDetails={{
            firstName: '',
            lastName: '',
            email: '',
            assessmentId: -1,
          }}
        />
      )}
    </AuthWrapper>
  );
}

export default MyAssessmentsComponent;
