import React from 'react';

function TextGreenFlagAnalysisIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#62C960'
        d='M15.81 10.548l-4.83 4.83a2.2 2.2 0 00-.578 1.02l-.375 1.498a.89.89 0 001.079 1.078l1.498-.374a2.2 2.2 0 001.02-.578l4.83-4.83a1.87 1.87 0 00-2.645-2.644M7 2.5a.5.5 0 10-1 0V3h-.5A1.5 1.5 0 004 4.5v12A1.5 1.5 0 005.5 18h3.503c.007-.114.025-.23.054-.347l.375-1.498a3.2 3.2 0 01.84-1.485l4.83-4.83A2.9 2.9 0 0116 9.232V4.5A1.5 1.5 0 0014.5 3H14v-.5a.5.5 0 00-1 0V3h-2.5v-.5a.5.5 0 00-1 0V3H7v-.5zM7.5 7h5a.5.5 0 010 1h-5a.5.5 0 010-1zm5.5 3.5a.5.5 0 01-.5.5h-5a.5.5 0 010-1h5a.5.5 0 01.5.5zM7.5 13h2a.5.5 0 010 1h-2a.5.5 0 010-1z'
      ></path>
    </svg>
  );
}

export default TextGreenFlagAnalysisIcon;
