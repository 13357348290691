/** @format */

import { createSlice } from '@reduxjs/toolkit';

interface globalSearchInitialStateTypes {
  globalSearchValue: string;
}
const globalSearchInitialState: globalSearchInitialStateTypes = {
  globalSearchValue: '',
};
const globalSearchSlice = createSlice({
  name: 'GlobalSearchSlice',
  initialState: globalSearchInitialState,
  reducers: {
    onChangeGlobalSearch(state, action) {
      state.globalSearchValue = action.payload;
    },
  },
});

export const { onChangeGlobalSearch } = globalSearchSlice.actions;

export { globalSearchSlice };
