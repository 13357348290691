import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { CameraDetails } from "types/MyAssessments-types";

interface DeviceInfo {
  deviceId: string;
  label: string;
}

function PatientWorkflowIosRecord({
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  setVideoDevices,
  setAudioDevices,
}: {
  goToPatientWorkflowStep: Function;
  videoDevices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  selectedVideoDevice: CameraDetails;
  selectedAudioDevice: DeviceInfo | null;
  setSelectedVideoDevice: Function;
  setSelectedAudioDevice: Function;
  setVideoDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  setAudioDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
}) {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleStart = () => {
    if (!selectedAudioDevice) {
      setError("Audio device is not found. Please select an audio device.");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => {
        setPermissionGranted(true);
        return navigator.mediaDevices.enumerateDevices();
      })
      .then((devices) => {
        const videoOptions = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const audioOptions = devices.filter(
          (device) => device.kind === "audioinput"
        );

        setVideoDevices(
          videoOptions.map((device) => ({
            deviceId: device.deviceId,
            label: device.label || `Video Device ${videoOptions.length}`,
          }))
        );

        setAudioDevices(
          audioOptions.map((device) => ({
            deviceId: device.deviceId,
            label: device.label || `Audio Device ${audioOptions.length}`,
          }))
        );

        // If audio device exists, start the stream and redirect to the next component
        if (audioOptions.length > 0) {
          setSelectedAudioDevice({
            deviceId: audioOptions[0].deviceId,
            label:
              audioOptions[0].label || `Audio Device ${audioOptions.length}`,
          });

          startStream(selectedVideoDevice, audioOptions[0]);

          // Redirect to the next component after starting the stream
        } else {
          setError("Audio device is mandatory. Please select an audio device.");
        }
      })
      .catch(() => {
        setError("Unable to access devices. Please check permissions.");
      });
  };

  const startStream = (
    videoDevice: DeviceInfo | null,
    audioDevice: DeviceInfo | null
  ) => {
    setError(null);
    if (!audioDevice) {
      setError(
        "No audio device found. Please ensure the audio device is connected."
      );
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: videoDevice
          ? { deviceId: { exact: videoDevice.deviceId } }
          : false,
        audio: false,
      })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch(() => {
        setError("Error starting media stream. Check devices and permissions.");
      });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ maxHeight: "50vh" }}
    >
      {/* Video Stream Section */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            style={{ width: "100%", height: "auto", backgroundColor: "black" }}
          />
        </Paper>
      </Grid>

      {/* Autocomplete Dropdowns Section */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
        >
          <Autocomplete
            options={videoDevices}
            getOptionLabel={(option) => option.label}
            value={selectedVideoDevice}
            onChange={(event, newValue) => setSelectedVideoDevice(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Video Devices" variant="outlined" />
            )}
            sx={{ width: "100%", marginBottom: 2 }}
          />

          <Autocomplete
            options={audioDevices}
            getOptionLabel={(option) => option.label}
            value={selectedAudioDevice}
            onChange={(event, newValue) => setSelectedAudioDevice(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Audio Devices" variant="outlined" />
            )}
            sx={{ width: "100%", marginBottom: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            sx={{ width: "100%", mt: 1 }}
          >
            Start
          </Button>

          {error && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PatientWorkflowIosRecord;
