/** @format */

import { createSlice } from '@reduxjs/toolkit';
import {
  ProcessDataListInitialStateTypes,
  ReprocessVideoInitialStateTypes,
} from 'types/Gallery.types';

const ProcessDataListInitialState: ProcessDataListInitialStateTypes = {
  isLoadingProcessDataList: false,
  errorProcessDataList: '',
  processDataList: [],
};
const getProcessDataListSlice = createSlice({
  name: 'getProcessDataListSlice',
  initialState: ProcessDataListInitialState,
  reducers: {
    ProcessDataListLoad(state) {
      state.isLoadingProcessDataList = true;
    },
    ProcessDataListSuccess(state, action) {
      state.isLoadingProcessDataList = false;
      state.processDataList = action.payload;
    },
    ProcessDataListFailure(state, action) {
      state.isLoadingProcessDataList = false;
      state.errorProcessDataList = action.payload;
    },
  },
});

export const {
  ProcessDataListLoad,
  ProcessDataListSuccess,
  ProcessDataListFailure,
} = getProcessDataListSlice.actions;
export { getProcessDataListSlice };

const ReprocessVideoInitialState: ReprocessVideoInitialStateTypes = {
  isLoadingReprocessVideo: false,
  errorReprocessVideo: '',
  reprocessVideoList: [],
};
const getReprocessVideoSlice = createSlice({
  name: 'getReprocessVideoSlice',
  initialState: ReprocessVideoInitialState,
  reducers: {
    ReprocessVideoLoad(state) {
      state.isLoadingReprocessVideo = true;
    },
    ReprocessVideoSuccess(state, action) {
      state.isLoadingReprocessVideo = false;
      state.reprocessVideoList = action.payload;
    },
    ReprocessVideoFailure(state, action) {
      state.isLoadingReprocessVideo = false;
      state.errorReprocessVideo = action.payload;
    },
  },
});

export const {
  ReprocessVideoLoad,
  ReprocessVideoSuccess,
  ReprocessVideoFailure,
} = getReprocessVideoSlice.actions;
export { getReprocessVideoSlice };

const DeleteProcessVideoInitialState = {
  isDeletingProcessVideo: false,
  deleteProcessVideoError: '',
};

const deleteProcessVideoSlice = createSlice({
  name: 'deleteProcessVideoSlice',
  initialState: DeleteProcessVideoInitialState,
  reducers: {
    deleteProcessVideoLoad(state) {
      state.isDeletingProcessVideo = true;
      state.deleteProcessVideoError = '';
    },
    deleteProcessVideoSuccess(state) {
      state.isDeletingProcessVideo = false;
    },
    deleteProcessVideoFailure(state, action) {
      state.isDeletingProcessVideo = false;
      state.deleteProcessVideoError = action.payload;
    },
  },
});

export const {
  deleteProcessVideoLoad,
  deleteProcessVideoSuccess,
  deleteProcessVideoFailure,
} = deleteProcessVideoSlice.actions;

export { deleteProcessVideoSlice };
