/** @format */
import { createSlice } from '@reduxjs/toolkit';
const loadTranslationInitialState: { isLoadingTranslation: boolean } = {
  isLoadingTranslation: false,
};
const loadTranslationSlice = createSlice({
  name: 'loadTranslationSlice',
  initialState: loadTranslationInitialState,
  reducers: {
    setIsLoadingTranslation(state, action) {
      state.isLoadingTranslation = action.payload;
    },
  },
});
export const { setIsLoadingTranslation } = loadTranslationSlice.actions;
export { loadTranslationSlice };
const globalTranslationsForTestCasesInitialState: any = {
  globalTranslationsForTestCases: {},
};
const globalTranslationsForTestCasesSlice = createSlice({
  name: 'globalTranslationsForTestCasesSlice',
  initialState: globalTranslationsForTestCasesInitialState,
  reducers: {
    setGlobalTranslationsForTestCases(state, action) {
      state.globalTranslationsForTestCases = action.payload;
    },
  },
});

export const { setGlobalTranslationsForTestCases } =
  globalTranslationsForTestCasesSlice.actions;

export { globalTranslationsForTestCasesSlice };
