/** @format */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './sign-up-success.css';

import { useHistory } from 'react-router-dom';
import { OpenRoutes } from 'routes/routes';
import { Box, Link } from '@mui/material';
import SuccessCheckMarkIcon from 'icons/SuccesscheckmarkIcon';
import Typography from 'widgets/Typography/Typography';

type GenericSuccessProps = {
  setComponentToShow: (component: string) => void;
};
function GenericSuccess({ setComponentToShow }: GenericSuccessProps) {
  const history = useHistory();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
      } else {
        history.push(OpenRoutes.signInRoute);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);
  return (
    <div className='div'>
      <div className='rectangle' />
      <div className='group-wrapper'>
        <Typography
          variant='h4'
          className='Welcome-success'
          label='signuppage.WelcomeToMirro'
        />
        <br />
        <span style={{ marginTop: '10px' }}>
          <span className='span-WelcomeText'>
            <Typography label='signuppage.YourAICommunicationCoach' />
          </span>
        </span>
        <br />
        <span>
          <span className='span-genericMessage'>
            <Typography label='signuppage.KnowHowOthersPerceiveYou' />
          </span>
        </span>
        <br />
        <span>
          <span className='span-communicationSkills'>
            <Typography label='signuppage.LearnCommunicationSkillsAndCommunicateConfidently' />
          </span>
        </span>
        <br />
        <div className='success-imgSpan'>
          <SuccessCheckMarkIcon />
        </div>
        <span>
          <Typography
            variant='h4'
            className='span-thanksMessage'
            label='signuppage.Thankyou'
          />
        </span>

        <span>
          <h5 className='span-genericSuccessMessage'>
            <Typography label='signuppage.YourSubmissionHasBeenSent' />
          </h5>
        </span>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <span className='span-redirectMessage'>
              <Typography
                sx={{ fontSize: '14px' }}
                label='signuppage.YouWillAutomaticallyBeRedirectedToLoginin'
              >
                {''} {countdown}
              </Typography>
            </span>
          </Box>
          {''}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Typography sx={{ fontSize: '14px' }} label='signuppage.Seconds' />
          </Box>
          {''}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => history.push(OpenRoutes.signInRoute)}
            >
              <Typography
                sx={{ color: '#1206f9', fontSize: '14px' }}
                label='signuppage.RedirectNow'
              />
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default GenericSuccess;
