/** @format */

import useFeatureFlag from 'hooks/useFeatureFlag';
import React, { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import GalleryVersion2 from './GalleryVersion2/GalleryVersion2';
import './gallery.css';
import { Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { RedirectUrls } from 'routes/urls';
import { useAppDispatch } from 'redux/store';
import { acceptCookiesService } from 'services/Cookies-service';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

export default function GalleryComponent() {
  const dispatch = useAppDispatch();

  const [cookieBannerOpen, setCookieBannerOpen] = useState(true);

  function checkCookieAcceptedStatus() {
    let tempString: string | null = sessionStorage.getItem(
      'loggedInUserDetails'
    );

    if (tempString) {
      const temp = JSON.parse(tempString);

      setCookieBannerOpen(temp.cookie_accepted);
    }
  }
  useEffect(() => {
    checkCookieAcceptedStatus();
  }, []);
  function handleCookieAccept() {
    dispatch(acceptCookiesService());
    let tempString: string | null = sessionStorage.getItem(
      'loggedInUserDetails'
    );
    if (tempString) {
      const temp = JSON.parse(tempString);
      temp.cookie_accepted = true;
      sessionStorage.setItem('loggedInUserDetails', JSON.stringify(temp));
      checkCookieAcceptedStatus();
    }
  }

  return (
    <AuthWrapper>
      <GalleryVersion2 />

      {!cookieBannerOpen && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 10,
            left: 0,
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            zIndex: (theme) => {
              return theme.zIndex.drawer + 2;
            },
          }}
        >
          <Paper id='cookiesBanner' elevation={25} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography label='gallery.ThisWebsiteUsesCookiesForOptimalUserExperienceView' />

              <Link to={RedirectUrls.privacyPolicy}>
                <Typography label='gallery.PrivacyPolicy' />
              </Link>
              <Button
                id='acceptCookiesButton'
                variant='contained'
                sx={{ borderRadius: 25, ml: 4, backgroundColor: '#1206f9' }}
                onClick={handleCookieAccept}
                label='gallery.Accept'
              />
            </Box>
          </Paper>
        </Box>
      )}
    </AuthWrapper>
  );
}
