/** @format */

import React, { useCallback, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import {
  Paper,
  Radio,
  FormControlLabel,
  Box,
  useMediaQuery,
} from '@mui/material';

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import { FeedBackSchema } from 'schema/feedback';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getLoginDataSelector,
  handleFeedbackSubmitSelector,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import { FeedbackDataPayload } from 'types/common/Feedback-types';

import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from 'widgets/CustomButton/Button';
import { handleFeedbackSubmitServiceApi } from 'services/Feedback-service';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { Toast } from 'widgets/Toast/Toast';

function PreviewFeedback() {
  const [showResponse, setShowResponse] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [quillImage, setQuillImage] = useState('');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  const username = sessionStorage.getItem('Email');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isLoadingHandleFeedbackSubmit } = useAppSelector(
    handleFeedbackSubmitSelector
  );

  const handleSubmitFeedback = useCallback(
    async (
      values: FeedbackDataPayload,
      { resetForm }: { resetForm: () => void }
    ) => {
      if (values.Subject && values.DetailOfResponse) {
        dispatch(
          handleFeedbackSubmitServiceApi(
            values,
            quillImage,
            setShowResponse,
            resetForm,
            setUserInteracted
          )
        );
        Toast('Feedback Submitted successfully', 'success');
      }
    },
    [dispatch, quillImage, setShowResponse, setUserInteracted]
  );

  const findImageUrl = useCallback((htmlContent: string) => {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    const imgElement = doc.querySelector('img');
    return imgElement ? imgElement.src : '';
  }, []);

  const handleUserInteraction = useCallback(() => {
    setShowResponse(false);
    setUserInteracted(true);
  }, [setShowResponse, setUserInteracted]);

  const initialValues: FeedbackDataPayload = {
    Name: '',
    NiceToHave: t('feedback.Feedback'),
    Email: username,
    Subject: '',
    DetailOfResponse: '',
    Image: quillImage,
  };

  return (
    <Box>
      {isLoadingHandleFeedbackSubmit ? (
        <Loader />
      ) : (
        <Box
          sx={{
            p: isMobile || isTablet ? 0 : 3,
            display: 'flex',
            justifyContent: 'center',

            height: '100%',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: isMobile || isTablet ? 4 : 8,
              borderRadius: 3,
              width: isMobile || isTablet ? '80%' : '80%',
              height: '100%',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={FeedBackSchema(t)}
              onSubmit={handleSubmitFeedback}
            >
              <Form className='form-container'>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box className='Feedback-Title'>
                    <Typography
                      sx={{
                        color: '#2c2c2c',
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        fontWeight: 600,
                        letterSpacing: '-0.2px',
                        textAlign: isMobile || isTablet ? 'center' : 'left',
                        whiteSpace: 'wrap',
                      }}
                      label='feedback.HowWasYourExperienceSubmitYourFeedback'
                    />
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <FormControlLabel
                      id='optionFeedback'
                      value='Feedback'
                      control={
                        <Field
                          type='radio'
                          name='NiceToHave'
                          value='Feedback'
                          as={Radio}
                        />
                      }
                      label={t('feedback.Feedback')}
                    />
                    <FormControlLabel
                      id='optionNiceToHave'
                      value='Nice to have'
                      control={
                        <Field
                          type='radio'
                          name='NiceToHave'
                          value='Nice to have'
                          as={Radio}
                        />
                      }
                      label={t('feedback.NiceToHave')}
                    />
                    <FormControlLabel
                      id='optionIssue'
                      value='Issue'
                      control={
                        <Field
                          type='radio'
                          name='NiceToHave'
                          value='Issue'
                          as={Radio}
                        />
                      }
                      label={t('feedback.Issue')}
                    />
                    <FormControlLabel
                      id='optionOther'
                      value='Other'
                      control={
                        <Field
                          type='radio'
                          name='NiceToHave'
                          value='Other'
                          as={Radio}
                        />
                      }
                      label={t('feedback.Other')}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 3,
                      mt: 3,
                      mb: 3,
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: isMobile || isTablet ? 'column' : 'row',
                        gap: isMobile || isTablet ? 3 : 5,
                        width: '100%',
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Field
                          label={t('feedback.Name')}
                          id='feedbackNameInput'
                          placeholder='Enter your name'
                          as={TextField}
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          variant='outlined'
                          name='Name'
                          fullWidth
                          helperText={
                            <ErrorMessage
                              name='Name'
                              component='div'
                              className='error-message'
                            />
                          }
                        />
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Field
                          sx={{ width: '100%' }}
                          label={t('feedback.Email')}
                          id='feedbackEmailInput'
                          as={TextField}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          variant='outlined'
                          name='Email'
                          fullWidth
                          helperText={
                            <ErrorMessage
                              name='Email'
                              component='div'
                              className='error-message'
                            />
                          }
                        />
                      </Box>
                    </Box>
                    <Field
                      label={t('feedback.SubJect')}
                      id='feedbackSubjectInput'
                      as={TextField}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      variant='outlined'
                      name='Subject'
                      fullWidth
                      sx={{
                        '& ::placeholder': {
                          fontSize: '0.9rem',
                        },
                      }}
                      placeholder={t('feedback.AddSubject')}
                      helperText={
                        <ErrorMessage
                          name='Subject'
                          component='div'
                          className='error-message'
                        />
                      }
                    />

                    <Field
                      label={t('feedback.DetailsOfResponse')}
                      id='feedbackDetailsInput'
                      as={TextField}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      multiline
                      rows={6}
                      variant='outlined'
                      name='DetailOfResponse'
                      fullWidth
                      sx={{
                        '& ::placeholder': {
                          fontSize: '0.9rem',
                        },
                      }}
                      placeholder={t('feedback.Details')}
                      helperText={
                        <ErrorMessage
                          name='DetailOfResponse'
                          component='div'
                          className='error-message'
                        />
                      }
                    >
                      {({ field, form }: FieldProps<string>) => (
                        <ReactQuill
                          value={field.value}
                          placeholder='Details of response'
                          onChange={(value) => {
                            form.setFieldValue('DetailOfResponse', value);
                            const imageUrl: string = findImageUrl(value);
                            setQuillImage(imageUrl);
                          }}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, false] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ color: [] }, { background: [] }], // Font color and highlight
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              ['link', 'image', 'emoji'], // 'video' is not supported directly
                              [{ indent: '-1' }, { indent: '+1' }], // Decrease and increase indent
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'color',
                            'background',
                            'list',
                            'bullet',
                            'link',
                            'image',
                            'emoji', // Assuming you have a custom emoji format
                            'indent',
                            'clean',
                          ]}
                        />
                      )}
                    </Field>
                  </Box>

                  <Button
                    sx={{ backgroundColor: '#1206f9', color: '#fff' }}
                    variant='contained'
                    type='submit'
                    id='feedbackSubmit'
                    disabled={isLoadingHandleFeedbackSubmit}
                    label={
                      isLoadingHandleFeedbackSubmit
                        ? 'feedback.Submitting'
                        : 'feedback.Submit'
                    }
                  />
                </Box>
              </Form>
            </Formik>
          </Paper>
        </Box>
      )}
    </Box>
  );
}

export default PreviewFeedback;
