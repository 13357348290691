/** @format */

import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { SubscriptionDisplayCardProps } from 'types/Subscription-types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';

function SubscriptionDisplayCard({
  subscriptionPlan,
  index,
  handleSubscriptionPayment,
  currentUserSubscription,
  showDiscount,
  setShowDiscount,
  couponCode,
  setCouponCode,
  onChangeCouponText,
}: SubscriptionDisplayCardProps) {
  const [showAppliedCoupon, setShowAppliedCoupon] = useState(false);
  const { t } = useTranslation();
  const { expirydate, planid } = currentUserSubscription[0];
  const isExpired = new Date(expirydate) < new Date();
  const isCurrentPlan = planid === subscriptionPlan.id;
  const color = isExpired
    ? '#1206f9'
    : isCurrentPlan
    ? '#F1EFFF !important'
    : '#1206f9';
  const ButtonTitleStatus = isCurrentPlan
    ? isExpired
      ? t('mysubcriptions.Renew')
      : t('mysubcriptions.CurrentPlan')
    : planid === null
    ? t('mysubcriptions.GetStarted')
    : t('mysubcriptions.Upgrade');

  const disabled = isExpired ? false : isCurrentPlan;
  return (
    <Card
      id={index.toString()}
      sx={{
        width: 255,
        height: '300px',
        padding: 2,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt:2,
      }}
    >
      <Typography
        variant='h5'
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
        }}
        label={subscriptionPlan.planname}
      />

      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '22px',

          minHeight: '50px',
        }}
        label=''
      >
        {`$${subscriptionPlan.planamount}`}
        <Typography
          sx={{ mt: 1 }}
          label={
            subscriptionPlan.planname === 'Half-Yearly'
              ? 'mysubcriptions.Every6Months'
              : subscriptionPlan.planname === 'Monthly'
              ? 'mysubcriptions.EveryMonth'
              : subscriptionPlan.planname === 'Lifetime'
              ? 'mysubcriptions.Lifetime'
              : subscriptionPlan.planname === 'mirro ai Early Bird'
              ? 'mysubcriptions.OneTime3Months'
              : ''
          }
        />
      </Typography>
      <CardContent>
        {(isExpired || !isCurrentPlan) &&
          (showAppliedCoupon && showDiscount === index ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // gap: 2,

                minHeight: '50px',
              }}
            >
              <Typography
                sx={{ fontSize: '12px' }}
                label='mysubcriptions.AppliedCoupon'
              >
                : {couponCode}{' '}
              </Typography>
              <IconButton
                aria-label='edit'
                size='small'
                onClick={() => setShowAppliedCoupon(false)}
              >
                <ModeEditOutlineOutlinedIcon fontSize='inherit' />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ minHeight: '50px' }}>
              {showDiscount === index ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    error={couponCode === ''}
                    fullWidth
                    placeholder={` ${
                      subscriptionPlan.id === 1138
                        ? t('mysubcriptions.Entercouponcode')
                        : t('mysubcriptions.EnterDiscountCode')
                    } `}
                    InputProps={{
                      style: {
                        fontSize: 10,
                        height: '42px',
                      },
                    }}
                    onChange={onChangeCouponText}
                    value={couponCode}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{ fontSize: '12px' }}
                    label={`${
                      subscriptionPlan.id === 1138
                        ? t('mysubcriptions.HaveACouponCode')
                        : t('mysubcriptions.HaveADiscountCode')
                    } `}
                  />

                  <Button
                    sx={{
                      color: '#1206f9',

                      backgroundColor: 'transparent',
                      '&:hover': {
                        color: '#1206f9',

                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => {
                      setShowDiscount(index);
                      setCouponCode('');
                    }}
                    label='mysubcriptions.Apply'
                  />
                </Box>
              )}
            </Box>
          ))}
      </CardContent>
      <CardActions>
        <Button
          sx={{
            backgroundColor: color,
            borderRadius: 35,
            width: '100%',
            padding: 1,
          }}
          className='overlap-group-2'
          id={`btn-${index.toString()}`}
          variant='contained'
          disabled={disabled}
          type='submit'
          onClick={() => handleSubscriptionPayment(subscriptionPlan)}
          label={ButtonTitleStatus}
        />
      </CardActions>
    </Card>
  );
}

export default SubscriptionDisplayCard;
