import { PatientWorkFlowStepComponentList } from 'constants/PatientWorkflow';
import React from 'react';
import { patientWorkFlowStepComponentProps } from 'types/TeleHealth/PatientWorkflow-types';
import ConfirmationComponent from './ConfirmationComponent/ConfirmationComponent';
import QuestionnaireComponent from './QuestionnaireComponent/QuestionnaireComponent';
import RecorderSettingComponent from './RecorderSettingComponent/RecorderSetting';
const stepComponents: {
  [key in PatientWorkFlowStepComponentList]?: React.FC<patientWorkFlowStepComponentProps>;
} = {
  [PatientWorkFlowStepComponentList.RecorderSetting]: RecorderSettingComponent,
  [PatientWorkFlowStepComponentList.Quesionnaire]: QuestionnaireComponent,
  [PatientWorkFlowStepComponentList.Confirmation]: ConfirmationComponent,
};
const StepComponent: React.FC<patientWorkFlowStepComponentProps> = ({
  currentPatientWorkflowStep,
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  setVideoDevices,
  setAudioDevices,
  apiKey,
  Questionnaire,
  patientID,
  providerID,
  customfilename,
  sessionId,
}) => {
  const Step = stepComponents[currentPatientWorkflowStep];
  return Step ? (
    <Step
      currentPatientWorkflowStep={currentPatientWorkflowStep}
      goToPatientWorkflowStep={goToPatientWorkflowStep}
      videoDevices={videoDevices}
      audioDevices={audioDevices}
      selectedVideoDevice={selectedVideoDevice}
      selectedAudioDevice={selectedAudioDevice}
      setSelectedVideoDevice={setSelectedVideoDevice}
      setSelectedAudioDevice={setSelectedAudioDevice}
      setVideoDevices={setVideoDevices}
      setAudioDevices={setAudioDevices}
      apiKey={apiKey}
      Questionnaire={Questionnaire}
      patientID={patientID}
      providerID={providerID}
      sessionId={sessionId}
      customfilename={customfilename}
    />
  ) : null;
};

export default StepComponent;
