/** @format */
import { RootState } from '../store';

export const getLoginDataSelector = (state: RootState) =>
  state.api.getLoginDataReducer;
export const getSignUpDataSelector = (state: RootState) =>
  state.api.getSignUpDataReducer;
export const getFeatureFlagsSelector = (state: RootState) =>
  state.api.getFeatureFlagsReducer;
export const updateFeatureFlagsSelector = (state: RootState) =>
  state.api.updateFeatureFlagsReducer;
export const getUploadMediaSelector = (state: RootState) =>
  state.api.getUploadMediaReducer;

export const getMicrosoftLoginDataSelector = (state: RootState) =>
  state.api.getMicrosoftLoginDataReducer;

export const getMicrosoftSignUpDataSelector = (state: RootState) =>
  state.api.getMicrosoftSignUpDataReducer;

export const getBillingDataSelector = (state: RootState) =>
  state.api.getBillingReducer;
export const getProcessDataListSelector = (state: RootState) =>
  state.api.getProcessDataListReducer;
export const getSessionTypesSelector = (state: RootState) =>
  state.api.getSessionTypesSReducer;
export const getProcessDataListForParticipantSelector = (state: RootState) =>
  state.api.getProcessDataListForParticipantReducer;

export const getReprocessVideoSelector = (state: RootState) =>
  state.api.getReprocessVideoReducer;

export const deleteProcessVideoSelector = (state: RootState) =>
  state.api.deleteProcessVideoReducer;
export const getAuthProviderDetailsSelector = (state: RootState) =>
  state.api.getAuthProviderDetailsReducer;

export const uploadSingleFileSelector = (state: RootState) =>
  state.api.uploadSingleFileReducer;

export const uploadMultipleFilesSelector = (state: RootState) =>
  state.api.uploadMultipleFilesReducer;

export const globalSearchSelector = (state: RootState) =>
  state.api.globalSearchReducer;

export const getUserDetailsAsUserSelector = (state: RootState) =>
  state.api.getUserDetailsAsUserReducer;

export const updateUserDetailsSelector = (state: RootState) =>
  state.api.updateUserDetailsReducer;

export const getSubscriptionSelector = (state: RootState) =>
  state.api.getSubscriptionReducer;

export const getStripeSessionIdSelector = (state: RootState) =>
  state.api.getStripeSessionIdReducer;

export const getAvailableSubscriptionPlansSelector = (state: RootState) =>
  state.api.getAvailableSubscriptionPlansReducer;
export const getProcessDataListVersion2Selector = (state: RootState) =>
  state.api.getProcessDataListVersion2Reduce;

export const getReprocessVersion2VideoSelector = (state: RootState) =>
  state.api.getReprocessVersion2VideoReduce;

export const getEmotionTypeSelector = (state: RootState) =>
  state.api.getEmotionTypeReduce;

export const getProcessDataListForParticipantsVersion2Selector = (
  state: RootState
) => state.api.getProcessDataListForParticipantsVersion2Reduce;

export const KnowledgeTipsSelector = (state: RootState) =>
  state.api.KnowledgeTipsReduce;

export const uploadVideoByLinkSelector = (state: RootState) =>
  state.api.uploadVideoByLinkReducer;

export const acceptCookiesSelector = (state: RootState) =>
  state.api.acceptCookiesReducer;

export const deleteProcessVideoV2Selector = (state: RootState) =>
  state.api.deleteProcessVideoV2reducer;

export const ForgetPasswordSelector = (state: RootState) =>
  state.api.setForgetPasswordReducer;
export const updatePasswordSelectore = (state: RootState) =>
  state.api.updatePasswordReducer;

export const sendForgetPasswordMailSelectore = (state: RootState) =>
  state.api.sendForgetPasswordMailReducer;

export const validateCouponCodeSelector = (state: RootState) =>
  state.api.validateCouponCodeReducer;

export const googleSSOSelector = (state: RootState) =>
  state.api.googleSSOReducer;

export const googleSSOSignInSelector = (state: RootState) =>
  state.api.googleSSOSignInReducer;
export const loadTranslationSelector = (state: RootState) =>
  state.api.loadTranslationReducer;
export const UserRolesSelectore = (state: RootState) =>
  state.api.UserRolesReducer;

export const UserWidgetPreferenceSelectore = (state: RootState) =>
  state.api.UserWidgetPreferenceReducer;

export const getWidgetListSelectore = (state: RootState) =>
  state.api.getWidgetListReducer;

export const updateUserWidgetPreferenceSelectore = (state: RootState) =>
  state.api.updateUserWidgetPreferenceReducer;

export const updateVideoTitleSelectore = (state: RootState) =>
  state.api.updateVideoTitleReducer;

export const getProcessStatusSelector = (state: RootState) =>
  state.api.getProcessStatusReducer;

export const handleFeedbackSubmitSelector = (state: RootState) =>
  state.api.handleFeedbackSubmitReducer;

export const handleUpdateVideoTitleForIframeSelector = (state: RootState) =>
  state.api.handleUpdateVideoTitleForIframeReducer;

export const hrequestProfileDataSelector = (state: RootState) =>
  state.api.requestProfileDataReducer;

export const getPractiseSectionsSelector = (state: RootState) =>
  state.api.getPractiseSectionsReducer;

export const uploadSectionVideoSelector = (state: RootState) =>
  state.api.uploadSectionVideoReducer;

export const allPromptsSectionsStatusSelector = (state: RootState) =>
  state.api.allPromptsSectionsStatusReducer;

export const sectionUploadStatusSelector = (state: RootState) =>
  state.api.sectionUploadStatusReducer;

export const globalTranslationsForTestCasesSelector = (state: RootState) =>
  state.api.globalTranslationsForTestCasesReducer;

export const sendAssessmentEmailToCandidatesSelector = (state: RootState) =>
  state.api.sendAssessmentEmailToCandidatesReducer;

export const candidateAssignmentSubmissionCheckSelector = (state: RootState) =>
  state.api.candidateAssignmentSubmissionCheckReducer;

export const getPositionIdentifierSelector = (state: RootState) =>
  state.api.getPositionIdentifierReducer;

export const addPositionIdentifierSelector = (state: RootState) =>
  state.api.addPositionIdentifierReducer;

export const getTagsSelector = (state: RootState) => state.api.getTagsReducer;
export const addTagsSelector = (state: RootState) => state.api.addTagsReducer;

export const handleSubmitJobCreationFormSelector = (state: RootState) =>
  state.api.handleSubmitJobCreationFormReducer;

export const getAssessmentListSelector = (state: RootState) =>
  state.api.getAssessmentListSliceReducer;

export const createAssignmentSelector = (state: RootState) =>
  state.api.createAssignmentReducer;

export const addUserToAssignmentListSelector = (state: RootState) =>
  state.api.addUserToAssignmentListReducer;

export const getRecruiterRecordSelector = (state: RootState) =>
  state.api.getRecruiterRecordReducer;

export const UpdateJobStatusSelector = (state: RootState) =>
  state.api.UpdateJobStatusReducer;

export const deleteRecordSelector = (state: RootState) =>
  state.api.deleteRecordReducer;

export const SendMailToUserSelector = (state: RootState) =>
  state.api.SendMailToUserReducer;
export const deepCandidateSignUpDataSelector = (state: RootState) =>
  state.api.deepCandidateSignUpDataReducer;

export const FacebookSSOSignInSelector = (state: RootState) =>
  state.api.facebookSSOSignInReducer;

export const FacebookSSOSigOutnSelector = (state: RootState) =>
  state.api.facebookSSOSignOutReducer;

export const getRecruiterWidgetDataSelector = (state: RootState) =>
  state.api.getRecruiterWidgetDataReducer;

export const sendOtpToUserSelector = (state: RootState) =>
  state.api.sendOtpToUserReducer;

export const VerifyOTPSelector = (state: RootState) =>
  state.api.VerifyOTPReducer;

export const VisitorFeedbackSelector = (state: RootState) =>
  state.api.VisitorFeedbackReducer;

export const getTranscriptDataSelector = (state: RootState) =>
  state.api.getTranscriptDataReducer;

export const getAIPermissionsSelector = (state: RootState) =>
  state.api.getAIPermissionsReducer;
