/** @format */

import * as React from 'react';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { uuidGenerator } from 'utils/UuidGenerator';
import { useAppSelector } from 'redux/store';
import { getBillingDataSelector } from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
const ColumnWidth = 250;

interface BillingTableRow {
  events: number;
  first_name: string;
  last_name: string;
  minutes: number;
  used_in_what_month: string;
  username: string;
}

interface DataTableProps {
  rows: Array<BillingTableRow>;
}

export default function DataTable({ rows }: DataTableProps) {
  const { t } = useTranslation();
  const { isLoadingBillingData } = useAppSelector(getBillingDataSelector);
  const columns: GridColDef[] = [
    {
      field: 'events',
      headerName: t('billings.Events'),
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'first_name',
      headerName: t('billings.FirstName'),
      width: ColumnWidth,
    },
    {
      field: 'last_name',
      headerName: t('billings.LastName'),
      width: ColumnWidth,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'username',
      headerName: t('billings.Email'),
      width: ColumnWidth,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'minutes',
      headerName: t('billings.Minutes'),
      type: 'number',
      width: ColumnWidth,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'used_in_what_month',
      headerName: t('billings.MonthUsed'),
      width: ColumnWidth,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const CustomNoRowsOverlay = () => {
    return (
      <GridOverlay>
        {t('billings.EnterTheFieldValuesAboveToGetTheData')}
      </GridOverlay>
    );
  };

  return (
    <div style={{ height: 550, width: '100%' }}>
      {isLoadingBillingData ? (
        <Loader />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={uuidGenerator}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{ fontFamily: 'Poppins' }}
        />
      )}
    </div>
  );
}
