/** @format */

import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function StripeSubscriptionPaymentFailComponent() {
  const history = useHistory();

  return (
    <AuthWrapper>
      <Box sx={{ minHeight: '80vh' }}>
        <Typography
          sx={{
            color: '#2C2C2C',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '-0.198px',
            marginTop: 2,
            marginLeft: 2,
          }}
          label='mysubcriptions.SUBSCRIPTIONPAYMENTSTATUS'
        />

        <Box
          sx={{
            marginTop: 2,
            marginLeft: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <Typography
            sx={{ color: '#FF2600' }}
            label='mysubcriptions.OopsThereIsSomeProblemWithYourPaymentPleaseTryAgain'
          />

          <Button
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 20,
              color: '#1206f9',
              border: '1px solid #1206f9',
              width: 20,
            }}
            onClick={() => history.push(ProtectedRoutes.subscriptionsRoute)}
            label='mysubcriptions.Back'
          />
        </Box>
      </Box>
    </AuthWrapper>
  );
}

export default StripeSubscriptionPaymentFailComponent;
