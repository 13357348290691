/** @format */

import Button from 'widgets/CustomButton/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { callMsGraph } from './graph';
import Loader from 'widgets/Loader/Loader';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import MicrosoftIcon from 'icons/MicrosoftIcon';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { useAppDispatch } from 'redux/store';
import { RequestProfileDataServiceApi } from 'services/Auth-service';
import SsoIconButton from 'components/SSO/SsoIconButton';

function MicrosoftSignInButton({
  isCandidateScreen,
}: {
  isCandidateScreen: boolean;
}) {
  const history = useHistory();

  const isAuthenticated = useIsAuthenticated();
  const { handleMicrosoftLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [isAuthtenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthtenticated) {
      dispatch(
        RequestProfileDataServiceApi(
          callMsGraph,
          setLoading,
          instance,
          loginRequest,
          accounts,
          history
        )
      );
    }
    // eslint-disable-next-line
  }, [isAuthtenticated]);

  return loading ? (
    <Loader />
  ) : isCandidateScreen ? (
    <SsoIconButton
      onCLickSsoButton={handleMicrosoftLogin}
      SsoIcon={MicrosoftIcon}
    />
  ) : (
    <Button
      onClick={handleMicrosoftLogin}
      startIcon={<MicrosoftIcon />}
      id='btnSigninMicrosoft'
      variant='outlined'
      sx={{
        textTransform: 'capitalize',
        width: '100%',
        height: '100%',
        padding: 2,
        borderRadius: '25px',
      }}
      label='loginpage.Microsoft'
    />
  );
}

export default MicrosoftSignInButton;
