import { i18n } from 'i18n/i18n';
import { AppThunk } from 'redux/store';
import { ProtectedUrls, PublicUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';
import {
  UserWidgetPreferenceLoad,
  UserWidgetPreferenceFailure,
  UserWidgetPreferenceSuccess,
  getWidgetListLoad,
  getWidgetListFailure,
  getWidgetListSuccess,
  UpdateUserWidgetPreferenceLoad,
  UpdateUserWidgetPreferenceFailure,
  UpdateUserWidgetPreferenceSuccess,
} from 'redux/slices/UserPreference-slice';
import { UserWidgetPreference } from 'types/Dashboard-types';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getUserWidgetPreferenceService =
  (): AppThunk => async (dispatch) => {
    dispatch(UserWidgetPreferenceLoad());

    try {
      const response = await commonAPI.get(
        ProtectedUrls.getWidgetPreferences.url()
      );
      const data = response.data;

      const filteredData = data.map((item: UserWidgetPreference) => {
        const trueValues = Object.entries(item)
          .filter(([key, value]) => value === true)
          .reduce((acc, [key]) => ({ ...acc, [key]: true }), {});
        return trueValues;
      });

      dispatch(UserWidgetPreferenceSuccess(filteredData));
    } catch (error) {
      dispatch(
        UserWidgetPreferenceFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };

export const getWidgetListService = (): AppThunk => async (dispatch) => {
  dispatch(getWidgetListLoad());

  try {
    const response = await commonAPI.get(ProtectedUrls.getWidgetList.url());

    dispatch(getWidgetListSuccess(response.data));
  } catch (error) {
    dispatch(
      getWidgetListFailure(
        error ||
          i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
      )
    );
  }
};

export const updateUserWidgetPreferenceService =
  (payload: UserWidgetPreference): AppThunk =>
  async (dispatch) => {
    dispatch(UpdateUserWidgetPreferenceLoad());
    try {
      const response = await commonAPI.post(
        ProtectedUrls.updateUserWidgetPreference.url(payload)
      );

      dispatch(UpdateUserWidgetPreferenceSuccess(response.data));
      Toast(
        i18n.t('toastMessageNotifications.YourWidgetPreferenceHaveBeenUpdated'),
        'success'
      );
    } catch (error) {
      dispatch(
        UpdateUserWidgetPreferenceFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };
