import React from 'react';

function SkippedAssessmentIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='39'
      height='24'
      viewBox='0 0 39 24'
      fill='none'
    >
      <g clip-path='url(#clip0_592_1109)'>
        <path
          d='M9.71952 21.74C10.2608 21.8756 10.5886 22.4104 10.4513 22.9453C10.3141 23.4801 9.76526 23.7965 9.23164 23.6684C5.96131 22.8549 3.33895 20.821 1.73047 18.1769C0.129606 15.5404 -0.449753 12.3012 0.373547 9.08462C1.19685 5.86805 3.26272 3.28424 5.94606 1.70232C8.62941 0.127933 11.9226 -0.444571 15.1929 0.368988C16.3364 0.65524 17.396 1.08462 18.3642 1.64206C19.3247 2.19196 20.2013 2.86993 20.9789 3.65336V3.02059C20.9941 2.47068 21.4515 2.03377 22.008 2.0413C22.5645 2.0413 23.0067 2.50081 22.999 3.04319L22.9609 6.20703C22.9609 6.30496 22.9457 6.40289 22.9152 6.50081C22.8237 6.99799 22.3587 7.35204 21.8403 7.30684L18.6386 7.05072C18.0821 7.00552 17.6705 6.52341 17.7238 5.98104C17.7696 5.43867 18.2574 5.03189 18.8063 5.07709L19.6525 5.14488C19.6525 5.14488 19.6144 5.11475 19.5991 5.09215C18.9435 4.41419 18.1965 3.82661 17.3655 3.35204C16.5575 2.88499 15.6656 2.52341 14.7127 2.28989C11.9836 1.61193 9.23164 2.0865 6.99043 3.40477C4.74923 4.72304 3.0264 6.87746 2.33269 9.56673C1.64661 12.256 2.12687 14.9603 3.46854 17.1675C4.80259 19.3671 6.99806 21.0696 9.72714 21.7475L9.71952 21.74ZM13.188 22.0112C12.1513 22.094 11.8997 23.563 12.967 23.9396C13.1118 23.9848 13.2262 23.9999 13.3863 23.9848C14.1028 23.9245 14.8575 23.7889 15.5512 23.5931C16.6794 23.2692 16.4431 21.5969 15.2006 21.6496C15.132 21.6496 15.0633 21.6647 15.0024 21.6873C14.5221 21.853 13.714 21.966 13.2033 22.0112H13.188ZM18.2651 20.1053C17.8915 20.3765 17.7619 20.8511 17.9296 21.2729C18.1812 21.8756 18.9359 22.0865 19.4695 21.6948C20.0565 21.2729 20.6054 20.7758 21.1085 20.2485C21.2686 20.0827 21.3677 19.8492 21.3753 19.6232C21.4134 18.6967 20.2776 18.2221 19.6296 18.9001C19.2256 19.3294 18.7453 19.7588 18.2651 20.1053Z'
          fill='black'
        />
        <path
          d='M7.21912 13.4089L7.88995 13.3486C7.92045 13.6122 7.99668 13.8307 8.11102 14.004C8.22537 14.1772 8.40833 14.3128 8.65227 14.4183C8.89621 14.5237 9.17064 14.5765 9.47557 14.5765C9.75 14.5765 9.98632 14.5388 10.1921 14.4559C10.398 14.3731 10.5581 14.2676 10.6572 14.1245C10.7563 13.9889 10.8096 13.8382 10.8096 13.6725C10.8096 13.5068 10.7639 13.3637 10.6648 13.2356C10.5657 13.1075 10.4056 13.0096 10.1845 12.9192C10.0397 12.8665 9.71951 12.7761 9.23163 12.6631C8.73612 12.5426 8.39308 12.4371 8.19488 12.3316C7.93569 12.196 7.74511 12.0303 7.62314 11.8345C7.50117 11.6386 7.43256 11.4202 7.43256 11.1716C7.43256 10.9004 7.5088 10.6518 7.66126 10.4183C7.81372 10.1847 8.04242 10.0115 8.3321 9.88343C8.6294 9.7629 8.95719 9.70264 9.31548 9.70264C9.71188 9.70264 10.0625 9.7629 10.3675 9.89096C10.6724 10.019 10.9087 10.2073 11.0688 10.4484C11.2289 10.697 11.3204 10.9757 11.3356 11.2846L10.6572 11.3373C10.619 10.9983 10.4971 10.7497 10.2836 10.5765C10.0702 10.4032 9.75762 10.3203 9.34597 10.3203C8.93432 10.3203 8.60653 10.3957 8.40833 10.5539C8.21012 10.7121 8.11102 10.9004 8.11102 11.1188C8.11102 11.3072 8.17963 11.4653 8.31685 11.5859C8.45407 11.7064 8.81235 11.8345 9.39171 11.9625C9.97107 12.0906 10.3675 12.2036 10.5809 12.3015C10.8935 12.4446 11.1222 12.6254 11.2746 12.8439C11.4271 13.0623 11.4957 13.3109 11.4957 13.5972C11.4957 13.8834 11.4118 14.1471 11.2518 14.3957C11.0917 14.6443 10.8554 14.8401 10.5504 14.9757C10.2455 15.1113 9.90246 15.1866 9.51368 15.1866C9.0258 15.1866 8.62177 15.1188 8.29398 14.9757C7.96618 14.8326 7.707 14.6217 7.52404 14.3429C7.34109 14.0642 7.24199 13.7478 7.22674 13.3863L7.21912 13.4089Z'
          fill='black'
        />
        <path
          d='M12.4333 15.1113V9.80811H13.1423V12.4371L15.8104 9.80811H16.7709L14.5221 11.955L16.87 15.1113H15.9324L14.019 12.4296L13.1423 13.2733V15.1113H12.4333Z'
          fill='black'
        />
        <path
          d='M17.5866 15.1113V9.80811H18.2956V15.1113H17.5866Z'
          fill='black'
        />
        <path
          d='M19.5457 15.1113V9.80811H21.5735C21.9318 9.80811 22.1986 9.82317 22.3891 9.86084C22.656 9.90603 22.877 9.9889 23.0524 10.1094C23.2277 10.23 23.3725 10.4032 23.4793 10.6217C23.586 10.8401 23.6393 11.0812 23.6393 11.3448C23.6393 11.7968 23.4945 12.181 23.2048 12.4898C22.9151 12.7987 22.3891 12.9569 21.6268 12.9569H20.247V15.1113H19.5381H19.5457ZM20.2547 12.3316H21.6421C22.0995 12.3316 22.4273 12.2488 22.6178 12.0755C22.8084 11.9098 22.9075 11.6687 22.9075 11.3599C22.9075 11.1414 22.8542 10.9456 22.7398 10.7874C22.6255 10.6292 22.473 10.5237 22.29 10.471C22.1681 10.4409 21.947 10.4258 21.6268 10.4258H20.2547V12.3241V12.3316Z'
          fill='black'
        />
        <path
          d='M24.5541 15.1113V9.80811H26.5819C26.9402 9.80811 27.2146 9.82317 27.3976 9.86084C27.6644 9.90603 27.8854 9.9889 28.0608 10.1094C28.2361 10.23 28.3809 10.4032 28.4877 10.6217C28.5944 10.8401 28.6478 11.0812 28.6478 11.3448C28.6478 11.7968 28.5029 12.181 28.2132 12.4898C27.9236 12.7987 27.3976 12.9569 26.6352 12.9569H25.2555V15.1113H24.5465H24.5541ZM25.2631 12.3316H26.6505C27.1079 12.3316 27.4357 12.2488 27.6263 12.0755C27.8168 11.9098 27.9159 11.6687 27.9159 11.3599C27.9159 11.1414 27.855 10.9456 27.7482 10.7874C27.6339 10.6292 27.4814 10.5237 27.2985 10.471C27.1765 10.4409 26.9554 10.4258 26.6352 10.4258H25.2631V12.3241V12.3316Z'
          fill='black'
        />
        <path
          d='M29.5702 15.1113V9.80811H33.4504V10.4333H30.2791V12.0605H33.2522V12.6857H30.2791V14.4936H33.5723V15.1188H29.5626L29.5702 15.1113Z'
          fill='black'
        />
        <path
          d='M34.5557 15.1113V9.80811H36.4081C36.8274 9.80811 37.1476 9.8307 37.361 9.88343C37.666 9.95123 37.9328 10.0793 38.1462 10.2601C38.4283 10.4936 38.6417 10.7949 38.7789 11.164C38.9162 11.5332 38.9924 11.955 38.9924 12.422C38.9924 12.8213 38.9467 13.1829 38.8476 13.4917C38.7485 13.8006 38.6265 14.0567 38.4816 14.2676C38.3368 14.4785 38.1691 14.6292 37.9938 14.7497C37.8184 14.8703 37.605 14.9531 37.3534 15.0134C37.1019 15.0736 36.8122 15.1038 36.492 15.1038H34.5557V15.1113ZM35.2647 14.4861H36.4081C36.7588 14.4861 37.0409 14.4559 37.2391 14.3881C37.4373 14.3203 37.5974 14.23 37.7193 14.1094C37.887 13.9437 38.0166 13.7177 38.1157 13.439C38.2072 13.1603 38.2529 12.8138 38.2529 12.4145C38.2529 11.8571 38.1615 11.4352 37.9785 11.1339C37.7956 10.8326 37.5669 10.6367 37.3077 10.5388C37.1171 10.4635 36.8122 10.4333 36.3853 10.4333H35.2571V14.4861H35.2647Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_592_1109'>
          <rect width='39' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SkippedAssessmentIcon;
