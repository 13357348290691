/** @format */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  Avatar,
  Box,
  Container,
  CardMedia,
  Paper,
  useMediaQuery,
  Divider,
} from '@mui/material';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import { updateUserDetailsServiceApi } from 'services/UserDetails-service';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';

// Transform file to base64
const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

function ProfileComponent() {
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState(
    sessionStorage.getItem('first_name')
  );
  const [lastName, setLastName] = useState(sessionStorage.getItem('last_name'));
  const [userName, setUserName] = useState(sessionStorage.getItem('username'));
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [profileImage, setImage] = useState(
    sessionStorage.getItem('profile_image_bytes') as string
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      if (file) {
        setSelectedImage(file);
        file2Base64(file).then((base64) => {
          setImage(base64.split(';base64,')[1]);
        });
      }
    },
    [setSelectedImage, setImage]
  );

  const handleSubmit = useCallback(() => {
    const detailsPayload = {
      first_name: firstName as string,
      last_name: lastName as string,
      email_address: userName as string,
    };
    dispatch(
      updateUserDetailsServiceApi(profileImage, selectedImage, detailsPayload)
    );
  }, [dispatch, firstName, lastName, userName, profileImage, selectedImage]);

  const handleImageUpload = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  return (
    <Container
      id='profile'
      component='main'
      maxWidth='sm'
      sx={{
        mt: isMobile || isTablet ? 0 : 3,
        ml: isMobile || isTablet ? 0 : 36.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
      }}
    >
      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '1.5rem',
          letterSpacing: '-0.012rem',
          marginTop: 2,
          marginBottom: isMobile || isTablet ? 3 : 6,
        }}
        label='profile.Profile'
        id='profilePageTitle'
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left',
          marginBottom: 5,
        }}
      >
        <Avatar
          sx={{ width: '5.1875rem', height: '5.1875rem', flexShrink: 0 }}
          alt={`${firstName?.charAt(0)}`}
          src={`data:image/png;base64,${profileImage}`}
        />
        <Box sx={{ marginLeft: 2 }}>
          <Typography
            sx={{
              color: '#2C2C2C',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.5rem',
              letterSpacing: '-0.00963rem',
            }}
            label='profile.ProfilePicture'
          />

          <Typography
            sx={{
              color: '#676767',
              fontSize: '0.75rem',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.5rem',
              letterSpacing: '-0.03rem',
            }}
            label='profile.600pxBy600pxJpgGifPngImagesWillWorkBest'
          />

          <Button
            id='saveChangesButton'
            variant='contained'
            sx={{
              borderRadius: '5rem',
              background: '#1206F9',
              float: 'right',
              width: 'fit-content',
              height: 'fit-content',
              mt: 1,
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '1.375rem',
            }}
            label='profile.UploadNewImage'
            onClick={handleImageUpload}
          />

          <input
            ref={fileInputRef}
            type='file'
            accept='image/png,image/jpeg,image/gif'
            onChange={handleImageChange}
            hidden
          />
        </Box>
      </Box>
      <Box>
        <TextField
          sx={{ width: '100%' }}
          className='TxtStyle'
          id='firstname'
          value={firstName}
          label={'profile.FirstName'}
          variant='outlined'
          onChange={(e) => setFirstName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          sx={{ marginTop: 5, width: '100%' }}
          className='TxtStyle'
          id='lastname'
          value={lastName}
          label={'profile.LastName'}
          variant='outlined'
          onChange={(e) => setLastName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Divider sx={{ marginTop: 7.5 }} />
        <Box>
          <Button
            id='saveChangesButton'
            variant='contained'
            onClick={handleSubmit}
            sx={{
              borderRadius: '5rem',
              background: '#1206F9',
              float: 'right',
              width: 'fit-content',
              height: 'fit-content',
              mt: 2,
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '1.375rem',
              color: '#fff',
            }}
            label='profile.SaveChanges'
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ProfileComponent;
