/** @format */

import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useScrollTrigger } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface FeedbackResponseProps {
  onUserInteraction: () => void; // Callback for user interaction
}

export default function FeedbackResponse({
  onUserInteraction,
}: FeedbackResponseProps) {
  const [open, setOpen] = React.useState(true);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    onUserInteraction();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <DialogContentText>
            <Typography
              label={'feedback.YourFeedbackIsSuccessfullySubmitted'}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: '#1206f9', backgroundColor: 'white' }}
            onClick={handleClose}
            autoFocus
            label='feedback.Yes'
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
