/** @format */

import React from 'react';

function GalleryIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
    >
      <path
        d='M7.59985 9.50024H0.949951C0.69803 9.50024 0.456288 9.40021 0.278162 9.22192C0.100036 9.0438 0 8.8022 0 8.5503V0.950187C0 0.698268 0.100036 0.456528 0.278162 0.278403C0.456288 0.100279 0.69801 0.000244141 0.949951 0.000244141H7.59985C7.85193 0.000244141 8.09352 0.100279 8.27164 0.278403C8.44977 0.456528 8.54996 0.698248 8.54996 0.950187V8.5503C8.54996 8.80222 8.44977 9.0438 8.27164 9.22192C8.09352 9.40021 7.85192 9.50024 7.59985 9.50024ZM1.89982 7.60019H6.64998V1.90021H1.89982V7.60019ZM18.0501 19.0002H11.4001C11.1481 19.0002 10.9065 18.9001 10.7284 18.722C10.5502 18.5439 10.45 18.3022 10.45 18.0502V10.4501C10.45 10.1982 10.5502 9.95661 10.7284 9.77848C10.9065 9.6002 11.1481 9.50017 11.4001 9.50017H18.0501C18.302 9.50017 18.5437 9.6002 18.7218 9.77848C18.9 9.95661 19 10.1982 19 10.4501V18.0502C19 18.3021 18.9 18.5439 18.7218 18.722C18.5437 18.9001 18.302 19.0002 18.0501 19.0002ZM12.35 17.1001H17.1002V11.4001H12.35V17.1001Z'
        fill='#1206F9'
      />
      <path
        d='M18.0501 7.60012H11.4001C11.1481 7.60012 10.9065 7.50008 10.7284 7.32196C10.5502 7.14383 10.45 6.90211 10.45 6.65017V0.950187C10.45 0.698268 10.5502 0.456528 10.7284 0.278403C10.9065 0.100279 11.1481 0.000244141 11.4001 0.000244141H18.0501C18.302 0.000244141 18.5437 0.100279 18.7218 0.278403C18.9 0.456528 19 0.698248 19 0.950187V6.65017C19 6.90209 18.9 7.14383 18.7218 7.32196C18.5437 7.50008 18.302 7.60012 18.0501 7.60012ZM12.35 5.70023H17.1002V1.90013H12.35V5.70023ZM7.59985 18.9999H0.949951C0.69803 18.9999 0.456288 18.8999 0.278162 18.7218C0.100036 18.5436 0 18.3019 0 18.05V12.35C0 12.0981 0.100036 11.8563 0.278162 11.6782C0.456288 11.5001 0.69801 11.4001 0.949951 11.4001H7.59985C7.85193 11.4001 8.09352 11.5001 8.27164 11.6782C8.44977 11.8563 8.54996 12.0981 8.54996 12.35V18.05C8.54996 18.3019 8.44977 18.5436 8.27164 18.7218C8.09352 18.8999 7.85192 18.9999 7.59985 18.9999ZM1.89982 17.1H6.64998V13.2999H1.89982V17.1Z'
        fill='#07DCAA'
      />
    </svg>
  );
}

export default GalleryIcon;
