/** @format */

import SignUpComponent from 'components/SignUp/SignUp';
import React from 'react';

function SignUp() {
  return <SignUpComponent />;
}

export default SignUp;
