/** @format */

import React, { useEffect } from 'react';
import WebRecord from './WebRecord';
import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isSafari,
} from 'react-device-detect';
import IosRecord from './IosRecord';
import { useAppDispatch } from 'redux/store';
import { getAiPermissionServiceApi } from 'services/AiPermission-service';

function Record() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAiPermissionServiceApi());
  }, [dispatch]);

  return isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13 ? (
    <IosRecord />
  ) : (
    <WebRecord />
  );
}

export default Record;
