/** @format */

import DashboardTablePlayVideoIcon from 'icons/DashboardTablePlayVideoIcon';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import { Data, ProcessDataTableBodyProps } from 'types/Dashboard-types';
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Tooltip,
  Collapse,
  Checkbox,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DetailsTable from './DetailsTable';
import { Toast } from 'widgets/Toast/Toast';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

function ProcessDataTableBody({
  visibleRows,
  columnVisibility,
  formatDate,
  emptyRows,
  handleOnChange,
  selectedRows,
}: ProcessDataTableBodyProps) {
  const history = useHistory();
  const [expandedRows, setExpandedRows] = React.useState<
    Record<number, boolean>
  >({}); // Use an object to track expanded rows

  const toggleRowExpansion = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const { t } = useTranslation();
  return (
    <TableBody>
      {visibleRows?.map((row: Data, index) => {
        const labelId = `videoId${row.coaching_session_reports_id}`;
        const isExpanded = expandedRows[index] || false;

        return (
          <React.Fragment key={row.coaching_session_reports_id}>
            <TableRow hover tabIndex={-1} key={row.coaching_session_reports_id}>
              <TableCell padding='checkbox'>
                <IconButton
                  onClick={() => toggleRowExpansion(index)}
                  aria-label={
                    isExpanded ? 'Collapse  Details' : 'Expand for Details'
                  }
                >
                  {isExpanded ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={selectedRows.includes(
                    row.coaching_session_reports_id
                  )}
                  onChange={() =>
                    handleOnChange(row.coaching_session_reports_id)
                  }
                  role='checkbox'
                  aria-checked='false'
                />
              </TableCell>
              {columnVisibility.recordings && (
                <TableCell
                  component='th'
                  id={labelId}
                  scope='row'
                  padding='none'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 1,
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                      color: '#5349FF',
                      maxWidth: '340px', // changing from 360 to 340px ti fit the dropdown as per figma
                      minWidth: '340px', // changing from 360 to 340px ti fit the dropdown as per figma
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                    }}
                    onClick={() => {
                      if (row.error_flag) {
                        Toast('there was error in processing', 'error');
                        history.push({
                          pathname: ProtectedRoutes.videoPreviewAdvance,
                          state: {
                            coaching_session_reports_id:
                              row.coaching_session_reports_id,
                          },
                        });
                      } else if (!row.error_flag && row.process_status < 9) {
                        Toast(
                          'Please wait until the processing of this media is complete and then try again in some time',
                          'error'
                        );
                      } else {
                        history.push({
                          pathname: ProtectedRoutes.videoPreviewAdvance,
                          state: {
                            coaching_session_reports_id:
                              row.coaching_session_reports_id,
                          },
                        });
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                        color: '#5349FF',
                        fontWeight: 500,
                      }}
                      label=''
                    >
                      {row.recordings.length > 0 ? (
                        <Box sx={{ cursor: 'pointer' }}>
                          {row.recordings.replace(/^\d{14}[_-]?/, '')}
                          <IconButton
                            onClick={() => {
                              if (row.error_flag) {
                                Toast('there was error in processing', 'error');
                                history.push({
                                  pathname: ProtectedRoutes.videoPreviewAdvance,
                                  state: {
                                    coaching_session_reports_id:
                                      row.coaching_session_reports_id,
                                  },
                                });
                              } else if (
                                !row.error_flag &&
                                row.process_status < 9
                              ) {
                                Toast('Session is in processing', 'error');
                              } else {
                                history.push({
                                  pathname: ProtectedRoutes.videoPreviewAdvance,
                                  state: {
                                    coaching_session_reports_id:
                                      row.coaching_session_reports_id,
                                  },
                                });
                              }
                            }}
                            aria-label='redirect-to-videoPreview'
                          >
                            <DashboardTablePlayVideoIcon />
                          </IconButton>
                        </Box>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </Box>
                  <Box>{formatDate(row.processingStartDateTime)}</Box>
                </TableCell>
              )}
              {columnVisibility.eventType && (
                <TableCell sx={{ minWidth: 150 }} align='left'>
                  {row.eventType}
                </TableCell>
              )}
              {columnVisibility.attendees && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',
                    padding: 2,
                  }}
                  align='center'
                >
                  {row.attendees}
                </TableCell>
              )}
              {columnVisibility.emotion && (
                <TableCell
                  sx={{
                    minWidth: 120,
                    padding: 2,
                    color:
                      row.flag_type_name === 'green'
                        ? 'green'
                        : row.flag_type_name === 'yellow'
                        ? '#8c7131'
                        : row.flag_type_name === 'red'
                        ? '#d33022'
                        : 'black',

                    fontWeight: 600,
                  }}
                  align='left'
                >
                  {row.emotion}
                </TableCell>
              )}
              {columnVisibility.engagement && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.engagement}
                </TableCell>
              )}

              {columnVisibility.positivity && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.positivity}
                </TableCell>
              )}
              {columnVisibility.green && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.green}
                </TableCell>
              )}
              {columnVisibility.yellow && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.yellow}
                </TableCell>
              )}
              {columnVisibility.red && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.red}
                </TableCell>
              )}
              {columnVisibility.confidentTone && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.confidentTone}
                </TableCell>
              )}
              {columnVisibility.stressedTone && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.stressedTone}
                </TableCell>
              )}
              {columnVisibility.fatiguedTone && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.fatiguedTone}
                </TableCell>
              )}
              {columnVisibility.intervieweeTalkTimePercentage && (
                <TableCell
                  sx={{
                    wordWrap: 'break-word',

                    padding: 2,
                  }}
                  align='center'
                >
                  {row.intervieweeTalkTimePercentage}
                </TableCell>
              )}
            </TableRow>
            {isExpanded && (
              <TableRow>
                <TableCell colSpan={15}>
                  <Collapse in={isExpanded}>
                    <Box sx={{ width: '100%' }}>
                      <DetailsTable processDataList={row} />
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

export default ProcessDataTableBody;
