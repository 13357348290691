import React, { useState } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import OutlinedBoxWithFloatingLabel from 'widgets/OutlinedBoxWithFloatingLabel/OutlinedBoxWithFloatingLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const IncrementDecrementBy64 = ({
  count,
  setCount,
}: {
  count: number;
  setCount: Function;
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  const handleIncrement = () => {
    setCount((prevCount: number) => prevCount + 64);
  };

  const handleDecrement = () => {
    setCount((prevCount: number) => prevCount - 64);
  };

  return (
    <Box
      sx={{ textAlign: 'left', width: isMobile || isTablet ? '70%' : '25%' }}
    >
      <OutlinedBoxWithFloatingLabel
        label={
          isMobile
            ? 'Output_Per...'
            : isTablet
            ? 'Output_Period_Msec...'
            : 'Output_Period_Msec (ci only)'
        }
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <IconButton
            aria-label='decrement button'
            size='small'
            onClick={handleDecrement}
          >
            <RemoveIcon />
          </IconButton>
          <Typography>{count}</Typography>

          <IconButton
            aria-label='increment button'
            size='small'
            onClick={handleIncrement}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </OutlinedBoxWithFloatingLabel>
    </Box>
  );
};

export default IncrementDecrementBy64;
