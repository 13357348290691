import { Box, Tabs, Tab, styled, Tooltip, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import OverallSummaryVersion2 from './VideoPreviewAdvanceVersion2/VideoPreviewAdvanceComponents/OverallSummaryVersion2';
import TranscriptIcon from 'icons/TranscriptIcon';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import { PdfGenrator } from 'widgets/PdfRenderer';
import env from 'envConfig';
import Loader from 'widgets/Loader/Loader';

function TabsForPromptSection({
  selectedSummaryTab,
  setSummaryTab,
  ProcessDataListForParticipantsVersion2,
  selectedPrompt,
}: {
  selectedSummaryTab: number;
  setSummaryTab: Function;
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  selectedPrompt: number;
}) {
  const [showPdfGenerator, setShowPdfGenerator] = useState(false);

  const CustomTabs = styled(Tabs)({
    '& MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root': {
      backgroundColor: '#8F8AFC',
    },

    '& .Mui-selected': {
      color: '#1206F9 !important',
      fontWeight: 'bold',
    },
  });

  const Overlay = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1); /* Solid white background */
    z-index: 9999; /* Ensure it's above other elements */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* Allow overflow with scrollbar */
  `;

  const ContentContainer = styled('div')`
    max-width: 80%; /* Adjust as needed */
    max-height: 80%; /* Adjust as needed */
    overflow: auto; /* Enable scrolling if content exceeds container */
    padding: 20px; /* Add padding as needed */
  `;

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSummaryTab(newValue);
  };
  const exportOptions = [
    // {
    //   id: 1,
    //   name: 'mp4',
    //   Icon: BlankIcon,
    //   isPremiumFeature: false,
    // },
    // {
    //   id: 4,
    //   name: 'mp3',
    //   Icon: BlankIcon,
    //   isPremiumFeature: false,
    // },
    {
      id: 2,
      name: 'Pdf',
      Icon: TranscriptIcon,
      isPremiumFeature: true,
    },
    // {
    //   id: 3,
    //   name: 'mp4 and score',
    //   Icon: TranscriptIcon,
    //   isPremiumFeature: true,
    // },
  ];

  const CIexportOptions = [];

  function handleOnChangeExportOption(
    _event: React.SyntheticEvent<Element, Event>,
    newValue: {
      id: number;
      name: string;
      isPremiumFeature: boolean;
      Icon: () => React.JSX.Element;
    } | null
  ) {
    if (newValue) setSelectedExportOption(newValue);
  }

  function BlankIcon() {
    return <></>;
  }
  const [selectedExportOption, setSelectedExportOption] = useState({
    id: -1,
    name: '',
    Icon: BlankIcon,
    isPremiumFeature: true,
  });

  const isUserPremium = true;

  useEffect(() => {
    if (selectedExportOption.id === 2) {
      setShowPdfGenerator(true);
    } else {
      setShowPdfGenerator(false);
    }
  }, [selectedExportOption]);

  if (
    ProcessDataListForParticipantsVersion2[0]?.is_prompt_section ||
    ProcessDataListForParticipantsVersion2[0]?.session_type_id === 59
  ) {
    CIexportOptions.push({
      id: 2,
      name: 'Pdf',
      Icon: TranscriptIcon,
      isPremiumFeature: true,
    });
  }
  if (!ProcessDataListForParticipantsVersion2) {
    return <Loader />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <CustomTabs
          value={selectedSummaryTab}
          onChange={handleChange}
          aria-label='custom tabs example'
        >
          <Tab value={0} label=' Overall Summary' />
          {ProcessDataListForParticipantsVersion2[0]?.is_prompt_section && (
            <Tab value={1} label='Prompt Summary' />
          )}
        </CustomTabs>
        <Tooltip title='Premium Feature' placement='top'>
          <Box
            sx={{
              width: '25%',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              opacity:
                ProcessDataListForParticipantsVersion2[0]?.is_prompt_section ||
                ProcessDataListForParticipantsVersion2[0]?.session_type_id ===
                  59
                  ? 1
                  : 0.4,
            }}
          >
            <TranscriptIcon />
            <Autocomplete
              id='combo-box-Export'
              options={CIexportOptions}
              optionLabelKey='name'
              label='View Report'
              value={selectedExportOption}
              onChange={handleOnChangeExportOption}
              size={'small'}
              sx={{
                borderColor: '#1206f9',
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1206f9',
                },
              }}
              getOptionDisabled={(option) => {
                return !isUserPremium && option.isPremiumFeature;
              }}
              renderInput={(params) => <TextField {...params} label='' />}
              renderOption={(props, option) => {
                return (
                  <Box
                    component='li'
                    sx={{ display: 'flex', gap: 2 }}
                    {...props}
                  >
                    <option.Icon />
                    <Typography label={option.name} />
                  </Box>
                );
              }}
              disabled={
                !(
                  ProcessDataListForParticipantsVersion2[0]
                    ?.is_prompt_section ||
                  ProcessDataListForParticipantsVersion2[0]?.session_type_id ===
                    59
                )
              }
            />
          </Box>
        </Tooltip>
      </Box>
      {selectedSummaryTab === 0 && (
        <OverallSummaryVersion2
          ProcessDataListForParticipants={
            ProcessDataListForParticipantsVersion2
          }
          selectedSummaryTab={selectedSummaryTab}
        />
      )}
      {selectedSummaryTab === 1 && (
        <OverallSummaryVersion2
          ProcessDataListForParticipants={
            ProcessDataListForParticipantsVersion2[0]?.prompt_sections.length >
              0 && ProcessDataListForParticipantsVersion2[0]?.prompt_sections
          }
          selectedSummaryTab={selectedSummaryTab}
        />
      )}
      {showPdfGenerator && (
        <Overlay>
          <ContentContainer>
            <PdfGenrator
              ProcessDataListForParticipantsVersion2={
                ProcessDataListForParticipantsVersion2
              }
              setShowPdfGenerator={setShowPdfGenerator}
            />
          </ContentContainer>
        </Overlay>
      )}{' '}
    </Box>
  );
}

export default TabsForPromptSection;
