/** @format */

import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './shared/PrivateRoutes/PrivateRoute';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getFeatureFlagsServiceApi } from 'services/FeatureFlags-services';
import AdminRoute from 'shared/AdminRoutes/AdminRoutes';
import { PageNotFound } from 'pages/PageNotFound';
import { arrayRoutes } from 'routes/AppRouteArray';
import TagManager from 'react-gtm-module';
import env from 'envConfig';
import Loader from 'widgets/Loader/Loader';
import {
  FacebookSSOSigOutnSelector,
  loadTranslationSelector,
} from 'redux/selectors/selectors';
import PreviewRoute from 'shared/PreviewRoutes/PreviewRoute';

function App() {
  const dispatch = useAppDispatch();
  const { isLoadingTranslation } = useAppSelector(loadTranslationSelector);
  const gtm_id = env.REACT_APP_GTM_ID as string;
  const tagManagerArgs = {
    gtmId: gtm_id,
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    dispatch(getFeatureFlagsServiceApi());
  }, [dispatch]);
  const { isLoadingFacebookSSOSignOut } = useAppSelector(
    FacebookSSOSigOutnSelector
  );

  return (
    <Router>
      <Switch>
        {arrayRoutes.map((route, index) => {
          const RouteComponent =
            route.type === 'private'
              ? PrivateRoute
              : route.type === 'admin'
              ? AdminRoute
              : route.type === 'preview'
              ? PreviewRoute
              : route.path === 'preview/mediaPreview'
              ? PreviewRoute
              : Route;
          return (
            <RouteComponent
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        })}
        <Route path='*' component={PageNotFound} />
      </Switch>
      <Toaster />{' '}
      {(isLoadingTranslation || isLoadingFacebookSSOSignOut) && <Loader />}
    </Router>
  );
}

export default App;
