import { Dispatch, SetStateAction } from 'react';
import {
  candidateAssignmentSubmissionCheckLoad,
  candidateAssignmentSubmissionCheckSuccess,
  candidateAssignmentSubmissionCheckError,
} from 'redux/slices/SendAssessment-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { CandidateDetailsType } from 'types/SendAssessments-types';
import { Toast } from 'widgets/Toast/Toast';

export const candidateAssignmentSubmissionCheckServiceApi =
  (
    candidate_details: CandidateDetailsType,
    setCandidateDetails: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(candidateAssignmentSubmissionCheckLoad());
    try {
      const response = await commonAPI.get(
        ProtectedUrls.candidateAssignmentSubmissionCheck.url(candidate_details)
      );

      dispatch(candidateAssignmentSubmissionCheckSuccess(response.data));
      if (response.data) {
        Toast('Assessment already submitted', 'error');
      } else {
        setCandidateDetails(candidate_details);
      }
    } catch (error) {
      console.error(
        'error - candidateAssignmentSubmissionCheckServiceApi',
        error
      );
      dispatch(candidateAssignmentSubmissionCheckError(error));
    }
  };
