import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import './forgotpassword.css';

import MirroBranding from 'components/SignIn/MirroBranding';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useAppSelector } from 'redux/store';
import {
  getUserDetailsAsUserSelector,
  sendForgetPasswordMailSelectore,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';

function ForgotPasswordComponent() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const { isLoadingForgetPasswordMail } = useAppSelector(
    sendForgetPasswordMailSelectore
  );

  const { isLoadingUserDetails } = useAppSelector(getUserDetailsAsUserSelector);

  return (
    <Box
      sx={{
        width: '99vw',
        height: '98vh',
        background: '#FBFBFF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: isMobile || isTablet ? 2 : 15,
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          height: '30%',
          gap: isMobile || isTablet ? 4 : 0,
          alignItems: isMobile || isTablet ? 'center' : 'left',
        }}
      >
        <MirroBranding />
        <ForgotPasswordForm />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: isMobile || isTablet ? 80 : 0,
        }}
      >
        <Box sx={{ width: '80%' }}>
          <img
            src='./LoginImg/mirro-ribbon-1.png'
            alt='mirro-ribbon'
            width={'100%'}
            style={{
              maxWidth: '100%',
              height: '100%',
            }}
          />
        </Box>

        <Box>
          <img
            src='./LoginImg/mirro-ribbon-2.png'
            alt=''
            width={'100%'}
            style={{
              maxWidth: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
      {isLoadingForgetPasswordMail && <Loader />}
      {isLoadingUserDetails && <Loader />}
    </Box>
  );
}

export default ForgotPasswordComponent;
