/** @format */

import React from 'react';

function DragDropIconDemoSandoBoxPreview() {
  return (
    <svg
      width='37'
      height='37'
      viewBox='0 0 37 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_3355_3299)'>
        <path
          d='M29.525 15.56C28.505 10.385 23.96 6.5 18.5 6.5C14.165 6.5 10.4 8.96 8.525 12.56C4.01 13.04 0.5 16.865 0.5 21.5C0.5 26.465 4.535 30.5 9.5 30.5H29C33.14 30.5 36.5 27.14 36.5 23C36.5 19.04 33.425 15.83 29.525 15.56ZM29 27.5H9.5C6.185 27.5 3.5 24.815 3.5 21.5C3.5 18.425 5.795 15.86 8.84 15.545L10.445 15.38L11.195 13.955C12.62 11.21 15.41 9.5 18.5 9.5C22.43 9.5 25.82 12.29 26.585 16.145L27.035 18.395L29.33 18.56C31.67 18.71 33.5 20.675 33.5 23C33.5 25.475 31.475 27.5 29 27.5ZM12.5 20H16.325V24.5H20.675V20H24.5L18.5 14L12.5 20Z'
          fill='#1906F9'
        />
      </g>
      <defs>
        <clipPath id='clip0_3355_3299'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DragDropIconDemoSandoBoxPreview;
