/** @format */

import { Box, Paper } from '@mui/material';
import React from 'react';
import FeatureTableList from './FeatureTableList';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

function FeaturesComponent() {
  return (
    <AuthWrapper>
      {' '}
      <Typography
        sx={{
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
          marginTop: 2,
          marginLeft: 2,
        }}
        label='featureFlags.FeatureFlags'
      />
      <Box
        sx={{
          marginTop: 5,
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          fontFamily: 'Poppins',
          flexDirection: 'column',
          alignItems: 'center',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            padding: 1,
            width: '100%',
            height: 'fit-content',
            borderRadius: '20px',
            fontFamily: 'Poppins',
          }}
          elevation={8}
        >
          <FeatureTableList />
        </Paper>
      </Box>
    </AuthWrapper>
  );
}

export default FeaturesComponent;
