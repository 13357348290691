/** @format */

import React from 'react';

function FeedbackIcon() {
  return (
    <svg
      width='21'
      height='17'
      viewBox='0 0 21 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.54941 11.8889V5.66667V2.55556C3.54941 1.69645 4.24585 1 5.10496 1H18.2626C19.1217 1 19.8182 1.69644 19.8182 2.55555V11.8889C19.8182 12.748 19.1217 13.4444 18.2626 13.4444H7.42292L2 15L3.54941 11.8889Z'
        stroke='#4027FE'
        stroke-width='1.27273'
      />
      <path
        d='M7.42303 4.88885H16.7195M7.42303 7.99997H16.7195'
        stroke='#23C290'
        stroke-width='1.27273'
      />
    </svg>
  );
}

export default FeedbackIcon;
