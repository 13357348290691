/** @format */

import React from 'react';

function FeatureFlagIcon() {
  return (
    /** @format */

    <svg
      width='24'
      height='20'
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2.15455V18.865C0 19.2157 0.284299 19.5 0.635 19.5C0.985701 19.5 1.27 19.2157 1.27 18.865V10.3947V1.11545C1.27 1.05169 1.21831 1 1.15455 1C0.516908 1 0 1.51691 0 2.15455Z'
        fill='#23C290'
      />
      <path
        d='M12.644 1.55395L0.862821 1.59809C0.741061 1.59854 0.642597 1.69738 0.642597 1.81914L0.642612 9.84209H12.6229C12.7917 9.84209 12.8983 9.66036 12.8157 9.51301L10.89 6.07484C10.8545 6.01148 10.8524 5.93474 10.8844 5.86954L12.8434 1.87228C12.9155 1.72508 12.808 1.55333 12.644 1.55395Z'
        stroke='#4027FE'
        stroke-width='1.27'
      />
    </svg>
  );
}

export default FeatureFlagIcon;
