import { createSlice } from '@reduxjs/toolkit';
import {
  UserWidgetPreferenceInitialSateTypes,
  getWidgetListInitialStateTypes,
  updateUserPrefrenceInitialStateTypes,
} from 'types/Dashboard-types';

const UserWidgetPrefrenceInitialState: UserWidgetPreferenceInitialSateTypes = {
  isLoadingUserWidgetPreference: false,
  errorUserWidgetPreference: '',
  userWidgetPreference: [],
};

const getWidgetListInitialState: getWidgetListInitialStateTypes = {
  isLoadingWidgetList: false,
  errorWidgetList: '',
  WidgetList: [],
};

const updateWidgetPreferenceInitialState: updateUserPrefrenceInitialStateTypes =
  {
    isLoadingUpdateWidgetPreference: false,
    errorUpdateWidgetPreference: '',
    preferences: {
      engagement: false,
      energy: false,
      variation: false,
      positivity: false,
      flags: false,
    },
  };

const UserWidgetPreferenceSlice = createSlice({
  name: 'api',
  initialState: UserWidgetPrefrenceInitialState,
  reducers: {
    UserWidgetPreferenceLoad(state) {
      state.isLoadingUserWidgetPreference = true;
    },
    UserWidgetPreferenceSuccess(state, action) {
      state.isLoadingUserWidgetPreference = false;
      state.userWidgetPreference = action.payload;
    },
    UserWidgetPreferenceFailure(state, action) {
      state.isLoadingUserWidgetPreference = false;
      state.errorUserWidgetPreference = action.payload;
    },
  },
});

export const {
  UserWidgetPreferenceLoad,
  UserWidgetPreferenceFailure,
  UserWidgetPreferenceSuccess,
} = UserWidgetPreferenceSlice.actions;
export { UserWidgetPreferenceSlice };

const getWidgetListSlice = createSlice({
  name: 'api',
  initialState: getWidgetListInitialState,
  reducers: {
    getWidgetListLoad(state) {
      state.isLoadingWidgetList = true;
    },
    getWidgetListSuccess(state, action) {
      state.isLoadingWidgetList = false;
      state.WidgetList = action.payload;
    },
    getWidgetListFailure(state, action) {
      state.isLoadingWidgetList = false;
      state.errorWidgetList = action.payload;
    },
  },
});

export const { getWidgetListLoad, getWidgetListFailure, getWidgetListSuccess } =
  getWidgetListSlice.actions;
export { getWidgetListSlice };

const updateUserWidgetPreferenceSlice = createSlice({
  name: 'api',
  initialState: updateWidgetPreferenceInitialState,
  reducers: {
    UpdateUserWidgetPreferenceLoad(state) {
      state.isLoadingUpdateWidgetPreference = true;
    },
    UpdateUserWidgetPreferenceSuccess(state, action) {
      state.isLoadingUpdateWidgetPreference = false;
      state.preferences = action.payload;
    },
    UpdateUserWidgetPreferenceFailure(state, action) {
      state.isLoadingUpdateWidgetPreference = false;
      state.errorUpdateWidgetPreference = action.payload;
    },
  },
});

export const {
  UpdateUserWidgetPreferenceLoad,
  UpdateUserWidgetPreferenceSuccess,
  UpdateUserWidgetPreferenceFailure,
} = updateUserWidgetPreferenceSlice.actions;
export { updateUserWidgetPreferenceSlice };
