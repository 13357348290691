import { i18n } from 'i18n/i18n';

import {
  signUpDataLoad,
  signUpDataSuccess,
  signUpDataFailure,
} from 'redux/slices/Auth-slice';
import { AppThunk } from 'redux/store';
import { OpenRoutes, deepCandidateOpenRoutes } from 'routes/routes';
import { DeepCandidateUrls } from 'routes/urls';
import { getLoginDataServiceApi } from 'services/Auth-service';
import commonAPI from 'shared/Api/commonAPI';
import { SignupFormModel } from 'types/Auth/Auth-types';
import { Toast } from 'widgets/Toast/Toast';
import { History } from 'history';

export const getDeepCandidateSignUpDataServiceApi =
  (
    values: SignupFormModel,

    isMicrosoftSignUP: Boolean,
    payloadSignUp: SignupFormModel,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    dispatch(signUpDataLoad());

    await commonAPI
      .post(DeepCandidateUrls.deepCandidateSignUpApiRoute.url(values))
      .then((response) => {
        dispatch(signUpDataSuccess(response.data));
        if (response.data[0]?.apikey) {
          Toast(
            i18n.t('toastMessageNotifications.AccountCreatedSuccessfully'),
            'success'
          );

          const payloadSignIn = {
            username: payloadSignUp.email,
            password: payloadSignUp.password,
          };

          isMicrosoftSignUP
            ? dispatch(
                getLoginDataServiceApi(
                  payloadSignIn,
                  history,
                  false,
                  payloadSignUp
                )
              )
            : history.push(deepCandidateOpenRoutes.DCSignInRoute);
        } else {
          Toast(response.data.message, 'error');
        }
      })
      .catch((error) =>
        dispatch(
          signUpDataFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };
