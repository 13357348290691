/** @format */

import React from 'react';
import './SubscriptionStatusMessageModal.css';
import { Box, Dialog, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

const SubscriptionStatusMessageModal = ({ message }: { message: string }) => {
  return (
    <Dialog
      open
      sx={{
        zIndex: (theme) => {
          return theme.zIndex.drawer - 1;
        },
      }}
    >
      <Box>
        <Typography
          variant='h4'
          sx={{ textAlign: 'center', padding: 5 }}
          label={message}
        />
      </Box>
      <DialogActions sx={{ justifyContent: ' center' }}>
        <Typography label='mysubcriptions.GoTo' />
        <Link to={ProtectedRoutes.subscriptionsRoute}>
          <Typography label='mysubcriptions.MySubscriptions' />
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionStatusMessageModal;
