/** @format */
export const AdminRoutes = {
  featureRoute: '/feature',
  billingRoute: '/billing',
};

export const ProtectedRoutes = {
  dashboardRoute: '/',
  galleryRoute: '/gallery',
  learningsRoute: '/learnings',
  subscriptionsRoute: '/subscriptions',
  helpRoute: '/help',
  recordRoute: '/record',
  feedback: '/feedback',
  uploadRoute: '/upload',
  videoPreviewAdvance: '/videopreviewadvance',
  profileRoute: '/profile',
  StripeSubscriptionPaymentFail: '/StripeSubscriptionPaymentFail',
  StripeSubscriptionPaymentSuccess: '/StripeSubscriptionPaymentSuccess',
  myAssessmentsRoute: '/myAssessments',
  SendAssessmentsRoute: '/sendAssessment',
  SandboxVideoPreview: '/preview/mediapreview',
  SandboxGallery: '/preview/galleryPreview',
};

export const OpenRoutes = {
  signInRoute: '/login',
  signUpRoute: '/signup',
  forgotPasswordRoute: '/forgotPassword',
  UpdateForgottenPasswordRoute: '/ResetPassword',

  signUpSuccessPage: '/Confirmation',
  IframeVideoControlRoute: '/IFrameVideoPreviewAdvance',
  IframeDashboardRoute: '/IframeDashboard',
  candidateAssessmentRoute: '/candidateAssessment',
  MirroSandboxRoute: '/preview',
  QuestionnaireRoute: '/Questionnaire',
};

export const deepCandidateOpenRoutes = {
  DCSignUPRoute: '/DCSignup',
  DCSignInRoute: '/DCSignin',
};
