/** @format */

import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAuth from 'hooks/useAuth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { History } from 'history';
import {
  Container,
  Tab,
  Tabs,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
} from '@mui/material';

import ProfileComponent from 'components/AccountSettings/Profile';
import ResetPasswordComponent from './ResetPasswordComponent';
import LogOutModal from 'widgets/LogOutModal';
import CurrentPlan from './CurrentPlan';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getAvailableSubscriptionPlansServiceApi,
  getSubscriptionServiceApi,
} from 'services/Subscription-service';
import {
  getAvailableSubscriptionPlansSelector,
  getSubscriptionSelector,
} from 'redux/selectors/selectors';
import { currentSubscriptionType } from 'types/Account-types';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import { handleFbSsoLogoutServiceApi } from 'services/Facebook-sso-service';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface LocationState {
  newValue?: number;
  // Define other properties here as needed
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: 440 }}>
          <Typography label=''>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function SidebarComponent() {
  const { t } = useTranslation();
  const history: History = useHistory();
  const location = useLocation<LocationState>();

  const [firstName, setFirstName] = React.useState(
    sessionStorage.getItem('first_name')
  );
  const name =
    sessionStorage.getItem('first_name') +
    ' ' +
    sessionStorage.getItem('last_name');
  const username = sessionStorage.getItem('username');
  const profileImage = sessionStorage.getItem('profile_image_bytes');
  const dispatch = useAppDispatch();
  const { getSubscriptionData } = useAppSelector(getSubscriptionSelector);
  const {
    isLoadingGetAvailableSubscriptionPlans,
    getAvailableSubscriptionPlansData,
  } = useAppSelector(getAvailableSubscriptionPlansSelector);
  useEffect(() => {
    dispatch(getSubscriptionServiceApi());
    dispatch(getAvailableSubscriptionPlansServiceApi());
  }, []);

  const [currentPlan, setCurrentPlan] =
    useState<currentSubscriptionType | null>(null);
  useEffect(() => {
    if (getSubscriptionData.length > 0) {
      const currentPlanDetails: currentSubscriptionType[] =
        getAvailableSubscriptionPlansData.filter((element: { id: number }) => {
          return element.id === getSubscriptionData[0].planid;
        });
      setCurrentPlan(
        currentPlanDetails.length > 0 ? currentPlanDetails[0] : null
      );
    }
  }, [getSubscriptionData, getAvailableSubscriptionPlansData]);

  const tabTheme = {
    color: '#605E5E',
    position: 'relative',
    top: 40,
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    textTransform: 'none',
    alignSelf: 'start',
    left: 10,
  };

  const [value, setValue] = React.useState(0);

  const tabLabels = [
    t('profile.Profile'),
    t('profile.Password'),
    t('profile.CurrentPlan').replace(/p/g, 'P'),
  ];

  const [selectedTabLabel, setSelectedTabLabel] = useState(tabLabels[0]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    setSelectedTabLabel(tabLabels[newValue]);
    handleAccordionChange(newValue);
  };

  useEffect(() => {
    if (location.state?.newValue) {
      setValue(location.state.newValue);
      handleAccordionChange(location.state.newValue);
    }
  }, [location.state]);

  const [logoutOpen, setLogoutOpen] = React.useState(false);

  const handleLogoutClose = () => {
    setLogoutOpen(false);
    handleChange(0, 0);
  };

  const { handleLogout, handleMicrosoftLogout } = useAuth();

  const checkLog = () => {
    handleLogoutClose();
    const authProvider = sessionStorage.getItem('authProviderName');

    switch (authProvider) {
      case 'Mirro':
        handleLogout();
        break;
      case 'Microsoft':
        handleMicrosoftLogout();
        break;
      case 'Google':
        handleLogout();
        break;
      case 'Facebook':
        dispatch(handleFbSsoLogoutServiceApi(history));
        break;
      default: {
        handleLogout();
        console.log('No logout case found for this SSO');
      }
    }
  };
  const tabComponents = [ProfileComponent, ResetPasswordComponent, CurrentPlan];
  const [accordionExpanded, setAccordionExpanded] = useState(
    Array(tabLabels.length).fill(false)
  );
  const handleAccordionChange = (index: number) => {
    const newAccordionState = [...accordionExpanded];
    newAccordionState[index] = !newAccordionState[index];
    setAccordionExpanded(newAccordionState);
    const allExpanded = newAccordionState.every((expanded) => expanded);
    setAllAccordionsExpanded(allExpanded);
  };
  const [allAccordionsExpanded, setAllAccordionsExpanded] = useState(false);
  const handleExpandAll = () => {
    const newAccordionState = Array(tabLabels.length).fill(
      !allAccordionsExpanded
    );
    setAccordionExpanded(newAccordionState);
    setAllAccordionsExpanded(!allAccordionsExpanded);
  };

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const renderAccordion = () => {
    return (
      <div>
        {tabLabels.map((label, index) => (
          <Accordion
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              textAlign: 'left',
            }}
            key={label}
            expanded={accordionExpanded[index]}
            onChange={(event) => handleChange(event, index)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography label={label} />
            </AccordionSummary>
            <AccordionDetails>
              {React.createElement(tabComponents[index])}
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          onClick={handleExpandAll}
          sx={{
            borderRadius: '5rem',
            background: '#1206F9',
            float: 'right',
            width: 'fit-content',
            height: 'fit-content',
            mt: 1,
          }}
          label={
            allAccordionsExpanded
              ? 'profile.ExpandAll'.replace(/Expand/g, 'Collapse')
              : 'profile.ExpandAll'
          }
        />
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div>
        <Box
          sx={{
            position: 'absolute',
            top: 200,
            width: 207,
            height: 442,
            flexShrink: 0,
            borderRadius: 3,
            border: '1px solid #D2DEFF',
            bgcolor: '#F6F5FF',
          }}
        >
          <Typography
            sx={{
              position: 'relative',
              top: 22,
              left: 19,
              color: '#2C2C2C',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              letterSpacing: '-0.22px',
            }}
            label='profile.AccountSettings'
          />

          <Tabs
            orientation='vertical'
            value={value}
            onChange={handleChange}
            aria-label='Vertical tabs example'
            centered={false}
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{ height: 400, borderRight: 1, borderColor: '#F6F5FF' }}
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                disableRipple={true}
                sx={{ ...tabTheme, left: index === 0 ? 10 : 15 }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {tabComponents.map((Component, index) => (
          <TabPanel key={index} value={value} index={index}>
            <Component />
          </TabPanel>
        ))}
      </div>
    );
  };

  return (
    <AuthWrapper>
      <Container>
        <div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: isMobile || isTablet ? 'relative' : 'absolute',
              textAlign: 'center',
              mt: isMobile || isTablet ? 2 : 7,
              mb: 2,
            }}
          >
            <Avatar
              src={`data:image/png;base64,${profileImage}`}
              alt={`${firstName?.charAt(0)}`}
              style={{
                width: '3rem',
                height: '3rem',
                flexShrink: 0,
                borderRadius: '3rem',
                marginRight: '0.625rem',
              }}
            />
            <Box sx={{ textAlign: 'left' }}>
              <Typography
                sx={{
                  color: '#2C2C2C',
                  fontFamily: 'Poppins',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  letterSpacing: '-0.04rem',
                }}
                label={name}
              />

              <Typography
                sx={{
                  color: '#605E5E',
                  fontFamily: 'Poppins',
                  fontSize: '0.75rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  letterSpacing: '-0.03rem',
                }}
                label=''
              >
                {username}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: '#676767',
                    fontFamily: 'Poppins',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    letterSpacing: '-0.03rem',
                  }}
                  label='profile.Subscription'
                />

                <Typography
                  sx={{
                    color: '#33344B',
                    fontFamily: 'Poppins',
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    letterSpacing: '-0.03rem',
                    ml: '0.25rem',
                  }}
                  label=''
                >
                  {currentPlan ? currentPlan.planname : t('profile.NA')}
                </Typography>
              </Box>
            </Box>
          </Box>

          {isMobile || isTablet ? renderAccordion() : renderTabs()}
        </div>

        <LogOutModal
          logoutOpen={logoutOpen}
          handleLogout={checkLog}
          handleLogoutClose={handleLogoutClose}
        />
      </Container>
    </AuthWrapper>
  );
}

export default SidebarComponent;
