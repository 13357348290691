import { createSlice } from '@reduxjs/toolkit';
import { HandleFeedbackSubmitsInitialStateTypes } from 'types/Feedback-types';

const handleFeedbackSubmitInitialState: HandleFeedbackSubmitsInitialStateTypes =
  {
    isLoadingHandleFeedbackSubmit: false,
    errorHandleFeedbackSubmit: '',
    handleFeedbackSubmit: [],
  };
const handleFeedbackSubmitSlice = createSlice({
  name: ' handleFeedbackSubmitSlice',
  initialState: handleFeedbackSubmitInitialState,
  reducers: {
    handleFeedbackSubmitLoad(state) {
      state.isLoadingHandleFeedbackSubmit = true;
    },
    handleFeedbackSubmitSuccess(state, action) {
      state.isLoadingHandleFeedbackSubmit = false;
      state.handleFeedbackSubmit = action.payload;
    },
    handleFeedbackSubmitFailure(state, action) {
      state.isLoadingHandleFeedbackSubmit = false;
      state.errorHandleFeedbackSubmit = action.payload;
    },
  },
});

export const {
  handleFeedbackSubmitLoad,
  handleFeedbackSubmitSuccess,
  handleFeedbackSubmitFailure,
} = handleFeedbackSubmitSlice.actions;
export { handleFeedbackSubmitSlice };
