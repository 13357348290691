/** @format */

import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '16f42ea3-52d4-44f2-98b7-1d190bb9985c',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error('msalConfig Error', message);
            return;
          case LogLevel.Info:
            console.info('msalConfig Info', message);
            return;
          case LogLevel.Verbose:
            console.debug('msalConfig Verbose', message);
            return;
          case LogLevel.Warning:
            console.warn('msalConfig Warning', message);
            return;
          default:
            console.info('msalConfig', message); // Default case
            break;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
