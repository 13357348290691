/** @format */

import DashboardComponent from 'components/Dashboard/Dashboard';
import React from 'react';

function Dashboard() {
  return <DashboardComponent />;
}

export default Dashboard;
