/** @format */

import { useState } from 'react';

function usePagination(data: Array<any>, itemsPerPage: number) {
  const count = Math.ceil(data.length / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem('currentPageGalleryPagination'))
      ? Number(localStorage.getItem('currentPageGalleryPagination'))
      : 1
  );
  let [page, setPage] = useState(
    Number(localStorage.getItem('currentPageGalleryPagination'))
      ? Number(localStorage.getItem('currentPageGalleryPagination'))
      : 1
  );
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;

    return data.slice(begin, end);
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  const handleChange = (e: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
    localStorage.setItem('currentPageGalleryPagination', p.toString());
    jump(p);
  };

  return {
    currentData,
    handleChange,
    page,
    count,
    setPage,
    setCurrentPage,
  };
}

export default usePagination;
