/** @format */

import { SignInFormModel, SignupFormModel } from 'types/Auth/Auth-types';
import { BillingDataPayload } from 'types/Billing-types';
import { UpdateFeatureFlagPayload } from 'types/Features-flags-types';
import { GetReprocessVideoServiceProps } from 'types/Gallery.types';
import {
  ForgotPasswordMailPayload,
  GetUserDetailsAsUserPayload,
  UpdateUserDetailsPayload,
} from 'types/UserDetails-types';
import { UploadImagePayload } from 'types/ProfileImage-types';
import { GetStripeSessionIdPayloadType } from 'types/Subscription-types';
import { ForgetPasswordDataPayload } from 'types/common/forgetpassword-types';
import { UserWidgetPreference } from 'types/Dashboard-types';
import { updateVideoTitlePayload } from 'types/VideoPreview-types';
import {
  THSType,
  TempPromptQuestionsArrayType,
} from 'types/MyAssessments-types';
import store from 'redux/store';
import { urls } from '../constants/ConstantsForTestCases';
import { Payload } from '../types/DeepRecruiterTypes/SelectAssessment-types';
import {
  CandidateDetailsType,
  PayloadForsendAssessmentEmailToCandidates,
} from 'types/SendAssessments-types';
import { FormValues } from 'types/PreviewTypes/UserValidation-types';
import { UploadQuestionnairePayload } from 'types/TeleHealth/PatientWorkflow-types';

export const PublicUrls = {
  loginApiRoute: {
    url: (params: SignInFormModel) =>
      `/Login?username=${encodeURIComponent(
        params.username
      )}&password=${encodeURIComponent(params.password)}`,
  },

  signUpApiRoute: {
    url: (params: SignupFormModel) => `/SignUp?first_name=${encodeURIComponent(
      params.firstName
    )}&last_name=${encodeURIComponent(
      params.lastName
    )}&user_name=${encodeURIComponent(
      params.email
    )}&password=${encodeURIComponent(
      params.password
    )}&is_company=false&cookie_accepted=false&auth_provider=${
      params.authProvider
    }
    `,
  },
  getSessionRoute: {
    url: (typeId: number) => `/GetSessionTypes?session_type_id=${typeId}`,
  },
  postUpdatePasswordMail: {
    url: (payload: ForgotPasswordMailPayload) =>
      `/forgot_password_Mail?email_id=${
        payload.email
      }&resetlink=${encodeURIComponent(payload.resetLink)} &logofootpath=${
        payload.logofootpath
      }&homepagelink=${payload.homepagelink}&logopath=${payload.logopath}`,
  },

  getGoogleUserInfoAfterLoginSuccess: {
    url: (access_token: string) =>
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
  },
  sendOTPToUser: {
    url: (Email: string) => `/SendOTP_to_user?user_mail_id=${Email}`,
  },
  verifyOtp: {
    url: (Payload: FormValues) =>
      `/ValidateOTP_forPreview?user_mail_id=${Payload.Email}&otp=${Payload.otp}`,
  },
  VisitorFeedbackurl: {
    url: (visitorID: number) => `/Visitor_Feedback?visitor_id=${visitorID}`,
  },
};

export const AdminUrls = {
  getFeatureFlagApiUrl: {
    url: () => '/GetFeatures',
  },
  updateFeatureFlagApiUrl: {
    url: (payload: UpdateFeatureFlagPayload) =>
      `/Admin-SetFeature?feature_name=${payload.feature_name}&state=${payload.state}`,
  },
  getBillingApiUrl: {
    url: (values: BillingDataPayload) =>
      `/GetBilling?user_name=${values.userName}&date_from=${values.fromDate}&date_to=${values.toDate}`,
  },
  GetLocalizedStrings: {
    url: (payload: { language: string; application_type_id: number }) =>
      `/GetLocalizedStrings?language=${payload.language}&application_type_id=${payload.application_type_id}`,
  },

  deleteUserFromMirro: {
    url: (userName: string) => `/Admin-DeleteUser?username=${userName}`,
  },
};

export const ProtectedUrls = {
  getProcessedDataListService: {
    url: () => `/GetProcessedDataList`,
  },
  getProcessedDataListForParticipantService: {
    URL: (metrics_processing_events_history_id: number) =>
      `/GetProcessedDataListForParticipants?metrics_processing_events_history_id=${metrics_processing_events_history_id}`,
  },
  getReprocessVideoService: {
    url: (reprocessVideoData: GetReprocessVideoServiceProps) =>
      `/ReProcess?video_title=${reprocessVideoData.video_title}&metrics_processing_events_history_id_list=${reprocessVideoData.metrics_processing_events_history_id_list}&session_type_id=${reprocessVideoData.sessionDetails.id}`,
  },
  deleteProcessVideo: {
    url: (id: number) =>
      `/DeleteFile?metrics_processing_events_history_ids=${id}`,
  },
  getAuthProviderDetails: {
    url: () => `/GetAuthProviderNames`,
  },
  uploadSingleFile: {
    url: (
      session_type_id: number,
      dynamic_prediction: boolean,
      video_analysis: boolean,
      text_analysis: boolean,
      outputPeriodMsec?: number
    ) => {
      return outputPeriodMsec
        ? `UploadMultipleFiles?session_type_id=${session_type_id}&output_period_msec=${outputPeriodMsec}&dynamic_prediction=${dynamic_prediction}&video_analysis=${video_analysis}&text_analysis=${text_analysis}`
        : `UploadMultipleFiles?session_type_id=${session_type_id}&dynamic_prediction=${dynamic_prediction}&video_analysis=${video_analysis}&text_analysis=${text_analysis}`;
    },
  },
  getUserDetailsUrl: {
    url: () => `/Admin-GetUserDetails`,
  },

  getUserDetailsAsUserUrl: {
    url: (payload: GetUserDetailsAsUserPayload) =>
      `/Get-User-Details?email_id=${payload.email_address}`,
  },

  updateUserDetailsUrl: {
    url: (payload: UpdateUserDetailsPayload) =>
      `/UpdateUserDetails?first_name=${payload.first_name}&last_name=${payload.last_name}`,
  },

  updateProfileImageUrl: {
    url: (payload: UploadImagePayload) =>
      `/UpdateProfileImage?profile_image_bytes=${payload.profile_image_bytes}`,
  },

  getSubscriptionUrl: {
    url: () => '/GetUserSubscriptionDetails',
  },

  getAvailableSubscriptionPlansUrl: {
    url: () => `/GetAvailableSubscriptionPlans`,
  },

  getStripeSessionIdUrl: {
    url: (payload: GetStripeSessionIdPayloadType) =>
      `/create-checkout-session?price_id=${payload.price_id}&plan_id=${
        payload.plan_id
      }&payment_mode=${payload.payment_mode}&session_id=${
        payload.session_id
      }&response_domain=${encodeURIComponent(payload.response_domain)}`,
  },
  getProcessedDataListVersion2Service: {
    url: () => `/v2.0/GetProcessedDataList`,
  },
  getReprocessVideoVersion2VideoService: {
    url: (reprocessVideoData: GetReprocessVideoServiceProps) =>
      `/ReProcess?video_title=${reprocessVideoData.video_title}&coaching_session_reports_id_list=${reprocessVideoData.metrics_processing_events_history_id_list}&session_type_id=${reprocessVideoData.sessionDetails.id}`,
  },
  reprocessBulkRecords: {
    url: () => `/ReProcess?`,
  },
  getEmotionTypes: {
    url: () => `/GetEmotionTypes`,
  },
  getProcessedDataListForParticipantVersion2Service: {
    URL: (coaching_session_reports_id: number) =>
      `/v2.0/GetProcessedDataListForParticipants?coaching_session_reports_id=${coaching_session_reports_id}`,
  },
  getKnowledgeTips: {
    url: (tip_no: number) => `/GetKnowledgeTips?tip_id=${tip_no}`,
  },

  uploadVideoByLink: {
    url: (payload: {
      videoLink: string;
      videoDesc: string;
      sessionId: number;
    }) =>
      `/UploadVideoByLink?link=${payload.videoLink}&session_type_id=${payload.sessionId}&video_description=${payload.videoDesc}`,
  },

  acceptCookies: {
    url: () => `/Accept_Cookies`,
  },
  deleteProcessVideoV2: {
    url: (id: number) => `/DeleteFile?coaching_session_reports_ids=${id}`,
  },
  deleteBulkRecords: {
    url: () => `/DeleteFile?`,
  },
  forgetPasswordUrl: {
    url: (userHash: string, newPassword: string) =>
      `/Forget_Password?hashcode=${userHash}&new_password=${newPassword}`,
  },
  updatePassword: {
    url: (oldPassword: String, newPassword: String) =>
      `/UpdatePassword?old_password=${oldPassword}&new_password=${newPassword}`,
  },

  validateCouponCode: {
    url: (couponCode: string) => `/ValidteCouponCode?coupon_name=${couponCode}`,
  },
  getUserRoles: {
    url: () => `/GetRoles`,
  },
  getWidgetPreferences: {
    url: () => `/Get-user-preferences?preferences_for=widgets`,
  },
  getWidgetList: {
    url: () => `/GetWidgetList`,
  },
  updateUserWidgetPreference: {
    url: (preferences: UserWidgetPreference) =>
      `/Update-widget-preferences?engagement=${preferences.engagement}&energy=${preferences.energy}&variation=${preferences.variation}&positivity=${preferences.positivity}&flags=${preferences.flags}`,
  },
  updateVideoTitle: {
    url: (payload: updateVideoTitlePayload) =>
      `/v2.0/VideoTitleUpdate?coaching_session_reports_id=${payload.coaching_session_reports_id}&video_title=${payload.video_title}&language=English`,
  },
  getProcessStatus: {
    URL: (coaching_session_reports_id: number) =>
      `/GetProcessStatus?coaching_session_reports_id=${coaching_session_reports_id}`,
  },
  getPractiseSections: {
    url: (sessionId: number) =>
      `/GetPromptsPracticeSections?session_id=${sessionId}`,
  },

  uploadSectionVideo: {
    url: (
      payload: {
        asessmentType: {
          id: number;
        };
        id: number;
        videoDescription: string;
      },
      isFirstUpload: boolean,
      relatedToJobId: number,
      isSkipped: boolean,
      candidateDetails: {
        firstName: string;
        lastName: string;
        email: string;
        assessmentId: number;
      },
      dynamic_prediction: boolean
    ) => {
      const firstNameNotEmpty =
        candidateDetails.firstName !== '' &&
        candidateDetails.lastName !== '' &&
        candidateDetails.email !== '';

      const candidate_details_payload = {
        first_name: candidateDetails.firstName,
        last_name: candidateDetails.lastName,
        email: candidateDetails.email,
        assignment_id: candidateDetails.assessmentId,
      };

      const candidateDetailsString = firstNameNotEmpty
        ? `&candidate_details=${JSON.stringify(candidate_details_payload)}`
        : '';

      const url =
        (isFirstUpload &&
          store.getState().api.sectionUploadStatusReducer.isFirstReupload) ||
        store.getState().api.sectionUploadStatusReducer.isFirstUnskiped
          ? `/UploadSectionVideo?session_type_id=${
              payload.asessmentType.id
            }&section_id=${payload.id}&title=${
              store.getState().api.sectionUploadStatusReducer.videoTitle
            }&related_to=${relatedToJobId}&is_skipped=${isSkipped}${candidateDetailsString}&dynamic_prediction=${dynamic_prediction}
`
          : isFirstUpload
          ? `/UploadSectionVideo?session_type_id=${
              payload.asessmentType.id
            }&section_id=${payload.id}&title=${
              store.getState().api.sectionUploadStatusReducer.videoTitle
            }&is_skipped=${isSkipped}${candidateDetailsString}&dynamic_prediction=${dynamic_prediction}
    `
          : `/UploadSectionVideo?session_type_id=${
              payload.asessmentType.id
            }&section_id=${payload.id}&title=${
              store.getState().api.sectionUploadStatusReducer.videoTitle
            }&related_to=${relatedToJobId}&is_skipped=${isSkipped}${candidateDetailsString}&dynamic_prediction=${dynamic_prediction}
    `;

      return url;
    },
  },
  allPromptsSectionsStatus: {
    url: (coachingSessionReportsId: number) =>
      `/PracticeSections?coaching_session_reports_id=${coachingSessionReportsId}`,
  },

  sendAssessmentEmailToCandidates: {
    url: () => {
      return `/assessment_Mail`;
    },
  },
  candidateAssignmentSubmissionCheck: {
    url: (candidate_details: CandidateDetailsType) => {
      return `/CandidateAssignmentSubmissionCheck?first_name=${candidate_details.firstName}&last_name=${candidate_details.lastName}&email=${candidate_details.email}&assignment_id=${candidate_details.assessmentId}`;
    },
  },

  getProcessedDataListForCandidates: {
    url: () => `/GetProcessedDataListForCandidates`,
  },
  ProcessedDataListDemoGallery: {
    url: () => `/GetProcessedDataListDemoGallery`,
  },

  getTranscriptData: {
    url: (coaching_session_reports_id: number) =>
      `/GetTranscription?coaching_session_reports_id=${coaching_session_reports_id}`,
  },

  getAIPermissions: {
    url: () => `/Get-AI-Permissions-for-User`,
  },
};
export const RedirectUrls = {
  privacyPolicy: 'https://mirro.ai/Privacy-policy',
  termsOfService: 'https://mirro.ai/Terms-of-Service',
  faqs: 'https://mirro.ai/FAQs',
  contactUs: 'https://mirro.ai/Contact-us',
  mirroAi: 'https://mirro.ai/',
};

export const DeepRecruiterUrls = {
  getTags: {
    url: (positionidentifierId: number) =>
      `/getTags_by_PositionIdentifier?positionidentifier_id=${positionidentifierId}`,
  },
  getPositionIdentifier: {
    url: () => `/getPositionIdentifier`,
  },
  addTags: {
    url: (title: string, positionidentifierId: number) =>
      `/addTags?title=${title}&positionidentifier_id=${positionidentifierId} `,
  },
  addPositionIdentifier: {
    url: (title: string) => `/addPositionIdentifier?title=${title}`,
  },
  handleSubmitJobCreationForm: {
    url: () => ``,
  },
  getAssessmentList: {
    url: () => `/GetListOFQuestions`,
  },
  createAssignment: {
    url: (job_id: string, yrsofExp: string, job_title: number) =>
      `/createAssignmentRequest?job_id=${job_id}&yrsofExp=${yrsofExp}&job_title=${job_title}`,
  },
  addUserToAssignmentList: {
    url: (job_id: string) => `/addUserToAssignmentList?job_id=${job_id}`,
  },
  getRecruiterRecords: {
    url: () => `/GetRecruiterRecord`,
  },
  updateJobStatus: {
    url: (job_id: string, status: string) =>
      `/UpdateJob_Status?job_id=${job_id}&status=${status}`,
  },
  DeleteRecordS: {
    url: () => `/DeleteAssignments`,
  },
  SendAssignmentMail: {
    url: () => `/SendAssignment`,
  },
  getRecruiterWidgetData: {
    url: () => `/GetRecruiterDashboardWidgetData`,
  },
};

export const DeepCandidateUrls = {
  deepCandidateSignUpApiRoute: {
    url: (params: SignupFormModel) => `/SignUp?first_name=${encodeURIComponent(
      params.firstName
    )}&last_name=${encodeURIComponent(
      params.lastName
    )}&user_name=${encodeURIComponent(
      params.email
    )}&password=${encodeURIComponent(
      params.password
    )}&is_company=false&cookie_accepted=false&auth_provider=${
      params.authProvider
    }&product_id=2
    `,
  },
};
