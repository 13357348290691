/** @format */

import React from 'react';

export default function GlobalSearchIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M11.7321 10.3182H10.9907L10.7279 10.065C11.8541 8.75184 12.436 6.96032 12.1169 5.05624C11.6758 2.44869 9.4984 0.366395 6.87055 0.0474853C2.90061 -0.440259 -0.440517 2.89891 0.0475131 6.86652C0.36661 9.49284 2.45012 11.6689 5.05921 12.1098C6.9644 12.4287 8.75697 11.8471 10.0709 10.7216L10.3243 10.9842V11.7252L14.313 15.7116C14.6978 16.0961 15.3266 16.0961 15.7114 15.7116C16.0962 15.327 16.0962 14.6986 15.7114 14.314L11.7321 10.3182ZM6.10096 10.3182C3.76405 10.3182 1.87763 8.43293 1.87763 6.09739C1.87763 3.76184 3.76405 1.87653 6.10096 1.87653C8.43788 1.87653 10.3243 3.76184 10.3243 6.09739C10.3243 8.43293 8.43788 10.3182 6.10096 10.3182Z'
        fill='#8D8D8D'
      />
    </svg>
  );
}
