import axios from 'axios';
import env from 'envConfig';
import { i18n } from 'i18n/i18n';
import {
  handleFeedbackSubmitFailure,
  handleFeedbackSubmitLoad,
  handleFeedbackSubmitSuccess,
} from 'redux/slices/Feedback-slice';
import { AppThunk } from 'redux/store';
import commonAPI from 'shared/Api/commonAPI';
import { FeedbackDataPayload } from 'types/common/Feedback-types';

export const handleFeedbackSubmitServiceApi =
  (
    values: FeedbackDataPayload,
    quillImage: String,
    setShowResponse: Function,
    resetForm: Function,
    setUserInteracted: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(handleFeedbackSubmitLoad());

    const freshdeskApiUrl =
      env.REACT_APP_FRESHDESKDOMAIN + env.REACT_APP_FRESHDESK_APIPATH;

    const apiKey = env.REACT_APP_FRESHDESK_APIKEY;
    const authInfo = `${apiKey}:X`;
    const base64Auth = btoa(authInfo);

    const formData = new FormData();
    formData.append('type', values.NiceToHave);
    formData.append('priority', '1');
    formData.append('status', '2');
    formData.append('email', values.Email || ''); // Ensure email is not null
    formData.append('subject', values.Subject);
    formData.append('description', values.DetailOfResponse);

    if (quillImage) {
      const base64String: String = quillImage;
      const arrayBuffer = Uint8Array.from(
        atob(base64String.split(',')[1]),
        (c) => c.charCodeAt(0)
      ).buffer;

      // Create a Blob from ArrayBuffer
      const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });

      // Create a File from Blob
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

      formData.append('attachments[]', file);
    }
    await axios
      .post(freshdeskApiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Basic ${base64Auth}`,
        },
      })
      .then((response) => {
        dispatch(handleFeedbackSubmitSuccess(response.data));
        setShowResponse(true);
        resetForm();
        setUserInteracted(false);
      })
      .catch((error) =>
        dispatch(
          handleFeedbackSubmitFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };
