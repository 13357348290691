import { Box } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import Typography from 'widgets/Typography/Typography';

function isActive(stream: MediaProvider | null): boolean {
  return !!stream && ('active' in stream) ? stream.active === true : false;
}



const RecordedVideoPreview = React.memo(
  ({ stream }: { stream: MediaProvider | null }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const livePreviewRef = useRef<HTMLVideoElement | null>(null);
    const location = useLocation();
    const [livestream, setLiveStream] = useState<MediaStream | null>(null); 
    
    useEffect(() => {
     
      if (!stream || !isActive(stream)) {
       
        const startWebcam = async () => {
          try {
            const livestream = await navigator.mediaDevices.getUserMedia({ video: true });
            setLiveStream(livestream);
            if (livePreviewRef.current) {
                        livePreviewRef.current.srcObject = livestream;
            }
          } catch (error) {
           
            
          }
        };
  
        if (!livestream) {
          startWebcam();
        }
  
       
        return () => {
          if ( livestream) { 
            livestream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
            setLiveStream(null);
          }
        };
      } else {
        
       
        if (videoRef.current && stream) {
          videoRef.current.srcObject = stream;
        }
      }
    }, [livestream, stream]);     

    if (!stream || !isActive(stream)) {
      return (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            top: 0,
          }}
        >
          <video
            id='livePreview'
            ref={livePreviewRef}
            autoPlay
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              zIndex: 10,
            }}
          >
            <Typography
              variant='h5'
              gutterBottom
              label={
                location.pathname === '/record'
                  ? 'record.ClickRecordButtonToRecordVideo'
                  : 'record.RecordAnswer'
              }
            />
          </Box>
        </div>
      );
    }

    return (
      <video
        id='livePreview'
        ref={videoRef}
        autoPlay
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    );
  }
);

export default RecordedVideoPreview;
