export const uploadStatusList = {
  queued: 'queued',
  clearing: 'clearing',
  uploading: 'uploading',
  uploadedSkippedAPI: 'uploadedSkippedAPI',
  failedSkippedAPI: 'failedSkippedAPI',
  failed: 'failed',
  uploaded: 'uploaded',
  skipped: 'skipped',
};
