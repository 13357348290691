/** @format */

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Toast } from 'widgets/Toast/Toast';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'components/MicrosoftLoginSSO/authConfig';
import {
  deepCandidateSignUpDataSelector,
  getAuthProviderDetailsSelector,
  getLoginDataSelector,
  getSignUpDataSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getSignUpDataServiceApi,
  getLoginDataServiceApi,
  getAuthProviderDetailsServiceApi,
} from 'services/Auth-service';
import { SignupFormModel, SignInFormModel } from 'types/Auth/Auth-types';
import { useEffect } from 'react';
import { OpenRoutes, ProtectedRoutes } from 'routes/routes';
import { getDeepCandidateSignUpDataServiceApi } from 'services/DeepRecruiter/deepCandidateAuth-service';

function useAuth() {
  const history = useHistory();
  const { t } = useTranslation();
  const { instance } = useMsal();

  const clearSessionStorage = () => {
    return new Promise<void>((resolve, reject) => {
      try {
        sessionStorage.clear();
        localStorage.clear();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleLogout = async () => {
    clearSessionStorage()
      .then(() => {
        history.push(ProtectedRoutes.dashboardRoute);
        Toast(t('toastMessageNotifications.LogoutSuccessful'), 'success');
      })
      .catch((error) => {
        console.error('handleLogout:', error);
      });
  };

  const handleMicrosoftLogin = async () => {
    localStorage.setItem('isSSO', 'true');
    await instance.loginRedirect(loginRequest).catch((error) => {
      console.error('handleMicrosoftLogin', error);
    });
  };

  const handleMicrosoftLogout = async () => {
    await instance
      .logoutRedirect({
        postLogoutRedirectUri: `/#${OpenRoutes.signInRoute}`,
      })
      .then(async () => {
        await clearSessionStorage().catch((error) =>
          console.error('handleMicrosoftLogout', error)
        );
      });

    // sessionStorage.clear();
  };

  const dispatch = useAppDispatch();

  const { authProviderDetailsList } = useAppSelector(
    getAuthProviderDetailsSelector
  );

  const loginStatus = useAppSelector(getLoginDataSelector);
  const signUpStatus = useAppSelector(getSignUpDataSelector);
  const payloadSignUp: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    authProvider: number | undefined;
  } = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    authProvider: authProviderDetailsList['Personal'],
  };

  const deepCandidateSignupStatus = useAppSelector(
    deepCandidateSignUpDataSelector
  );
  const handleSignUpFormSubmit = (values: SignupFormModel) => {
    dispatch(getSignUpDataServiceApi(values, false, payloadSignUp, history));
  };

  const handleDeepCandidateSignUpFormSubmit = (values: SignupFormModel) => {
    dispatch(
      getDeepCandidateSignUpDataServiceApi(
        values,
        false,
        payloadSignUp,
        history
      )
    );
  };

  const handleSignInFormSubmit = async (values: SignInFormModel) => {
    localStorage.setItem('isSSO', 'false');
    dispatch(getLoginDataServiceApi(values, history, false, payloadSignUp));
  };

  return {
    handleLogout,
    handleMicrosoftLogin,
    handleMicrosoftLogout,
    // RequestProfileData,
    handleSignInFormSubmit,
    handleSignUpFormSubmit,
    loginStatus,
    signUpStatus,
    handleDeepCandidateSignUpFormSubmit,
    deepCandidateSignupStatus,
  };
}

export default useAuth;
