import { createSlice } from '@reduxjs/toolkit';
import { knowledgeTipsInitialStateTypes } from 'types/common/knowledgeTips-types';

const knowledgeTipsInitialState: knowledgeTipsInitialStateTypes = {
  isloadingknowledgeTips: false,
  errorknowledgeTips: '',
  TipDetails: [],
};

const knowledgeTipsSlice = createSlice({
  name: 'getknowledgeTipsInitialState',
  initialState: knowledgeTipsInitialState,
  reducers: {
    KnowledgeTipsLoad(state) {
      state.isloadingknowledgeTips = true;
    },
    KnowledgeTipsSuccess(state, action) {
      state.isloadingknowledgeTips = false;
      state.TipDetails = action.payload;
    },
    KnowledgeTipsFailure(state, action) {
      state.isloadingknowledgeTips = false;
      state.errorknowledgeTips = action.payload;
    },
  },
});

export const { KnowledgeTipsLoad, KnowledgeTipsSuccess, KnowledgeTipsFailure } =
  knowledgeTipsSlice.actions;
export { knowledgeTipsSlice };
