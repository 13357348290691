import React from 'react';

function SuccessCheckMarkIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width='100'
      height='100'
    >
      <circle cx='12' cy='12' r='11' fill='#4CAF50' />
      <path
        fill='#FFFFFF'
        d='M9.3 16.28a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42l3.29 3.3 7.29-7.3a1 1 0 1 1 1.42 1.42l-8 8a1 1 0 0 1-.71.29z'
      />
    </svg>
  );
}
export default SuccessCheckMarkIcon;
