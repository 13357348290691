/** @format */

import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import React from 'react';
import Loader from 'widgets/Loader/Loader';
import { OpenRoutes } from 'routes/routes';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const location = useLocation();

  const isAuthenticated = sessionStorage.getItem('apiKey');

  const prevLocation = sessionStorage.getItem('route');

  if (
    isAuthenticated === 'f7833e18-3c34-5f2a-b6fa-73b3129a5e03' &&
    prevLocation
  ) {
    sessionStorage.clear();
  }

  if (!Component) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={OpenRoutes.signInRoute} />
        )
      }
    />
  );
};

export default PrivateRoute;
