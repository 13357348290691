/** @format */

import { createSlice } from '@reduxjs/toolkit';
import {
  GetAIPermissionsInitialStateTypes,
  SessionTypesInitialStateTypes,
  UploadMultipleFilesInitialStateTypes,
  UploadSingleFileInitialStateTypes,
  UploadVideoByLinkInitialStateTypes,
} from 'types/Upload-types';

const sessionTypesInitialState: SessionTypesInitialStateTypes = {
  isLoadingSessionTypes: false,
  errorSessionTypes: '',
  SessionTypes: [],
};

const getSessionTypesSlice = createSlice({
  name: 'api',
  initialState: sessionTypesInitialState,
  reducers: {
    sessionTypesLoad(state) {
      state.isLoadingSessionTypes = true;
    },
    sessionTypesSuccess(state, action) {
      state.isLoadingSessionTypes = false;
      state.SessionTypes = action.payload;
    },
    sessionTypesFailure(state, action) {
      state.isLoadingSessionTypes = false;
      state.errorSessionTypes = action.payload;
    },
  },
});

export const { sessionTypesLoad, sessionTypesSuccess, sessionTypesFailure } =
  getSessionTypesSlice.actions;
export { getSessionTypesSlice };

const uploadSingleFileInitialState: UploadSingleFileInitialStateTypes = {
  isLoadingUploadSingleFile: false,
  uploadSingleFile: {
    Job_Id: [],
  },
  uploadSingleFileError: '',
};

const uploadSingleFileSlice = createSlice({
  name: 'uploadSingleFileSlice',
  initialState: uploadSingleFileInitialState,
  reducers: {
    uploadSingleFileLoad(state) {
      state.isLoadingUploadSingleFile = true;
      state.uploadSingleFileError = '';
    },
    uploadSingleFileSuccess(state, action) {
      state.isLoadingUploadSingleFile = false;
      state.uploadSingleFile = action.payload;
    },
    uploadSingleFileFailure(state, action) {
      state.isLoadingUploadSingleFile = false;
      state.uploadSingleFileError = action.payload;
    },
  },
});

export const {
  uploadSingleFileLoad,
  uploadSingleFileSuccess,
  uploadSingleFileFailure,
} = uploadSingleFileSlice.actions;

export { uploadSingleFileSlice };

const uploadMultipleFilesInitialState: UploadMultipleFilesInitialStateTypes = {
  isLoadingUploadMultipleFiles: false,
  uploadMultipleFiles: [
    {
      Job_Id: [],
    },
  ],
  uploadMultipleFilesError: { message: '' },
};

const uploadMultipleFilesSlice = createSlice({
  name: 'uploadMultipleFilesSlice',
  initialState: uploadMultipleFilesInitialState,
  reducers: {
    uploadMultipleFilesLoad(state) {
      state.isLoadingUploadMultipleFiles = true;
    },
    uploadMultipleFilesSuccess(state, action) {
      state.isLoadingUploadMultipleFiles = false;
      state.uploadMultipleFiles = action.payload;
    },
    uploadMultipleFilesFailure(state, action) {
      state.isLoadingUploadMultipleFiles = false;
      state.uploadMultipleFilesError = action.payload;
    },
  },
});

export const {
  uploadMultipleFilesLoad,
  uploadMultipleFilesSuccess,
  uploadMultipleFilesFailure,
} = uploadMultipleFilesSlice.actions;

export { uploadMultipleFilesSlice };

const uploadVideoByLinkInitialState: UploadVideoByLinkInitialStateTypes = {
  isLoadingUploadVideoByLink: false,
  uploadVideoByLink: [],
  uploadVideoByLinkError: '',
};

const uploadVideoByLinkSlice = createSlice({
  name: 'uploadVideoByLinkSlice',
  initialState: uploadVideoByLinkInitialState,
  reducers: {
    uploadVideoByLinkLoad(state) {
      state.isLoadingUploadVideoByLink = true;
      state.uploadVideoByLinkError = '';
    },
    uploadVideoByLinkSuccess(state, action) {
      state.isLoadingUploadVideoByLink = false;
      state.uploadVideoByLink = action.payload;
    },
    uploadVideoByLinkFailure(state, action) {
      state.isLoadingUploadVideoByLink = false;
      state.uploadVideoByLinkError = action.payload;
    },
  },
});

export const {
  uploadVideoByLinkLoad,
  uploadVideoByLinkSuccess,
  uploadVideoByLinkFailure,
} = uploadVideoByLinkSlice.actions;

export { uploadVideoByLinkSlice };

const getAIPermissionsInitialState: GetAIPermissionsInitialStateTypes = {
  isLoadingGetAIPermissions: false,
  getAIPermissions: { video_analysis: false, text_analysis: false },
  getAIPermissionsError: '',
};

const getAIPermissionsSlice = createSlice({
  name: 'getAIPermissions',
  initialState: getAIPermissionsInitialState,
  reducers: {
    getAIPermissionsLoad(state) {
      state.isLoadingGetAIPermissions = true;
      state.getAIPermissionsError = '';
    },
    getAIPermissionsSuccess(state, action) {
      state.isLoadingGetAIPermissions = false;
      state.getAIPermissions = action.payload;
    },
    getAIPermissionsFailure(state, action) {
      state.isLoadingGetAIPermissions = false;
      state.getAIPermissionsError = action.payload;
    },
  },
});

export const {
  getAIPermissionsLoad,
  getAIPermissionsSuccess,
  getAIPermissionsFailure,
} = getAIPermissionsSlice.actions;

export { getAIPermissionsSlice };
