/** @format */

import HelpComponent from 'components/Help/Help';
import React from 'react';

function Help() {
  return <HelpComponent />;
}

export default Help;
