/** @format */

import useFeatureFlag from 'hooks/useFeatureFlag';
import React from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './VideoPreviewAdvance.css';
import VideoPreviewAdvanceVersion2 from './VideoPreviewAdvanceVersion2/VideoPreviewAdvanceVersion2';

export default function VideoPreviewAdvanceComponent() {
  return (
    <AuthWrapper>
      <VideoPreviewAdvanceVersion2 />
    </AuthWrapper>
  );
}
