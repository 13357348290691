/** @format */

import React from 'react';

function DragDropIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='105'
      height='83'
      viewBox='0 0 105 83'
      fill='none'
    >
      <path
        d='M65.9622 57.7327L55.1926 46.8649V83H49.8083V46.8673L39.0388 57.735L35.2698 53.9317L50.7503 38.3101C51.8275 37.223 53.4429 37.223 54.5192 38.3101L69.9997 53.9317L65.9622 57.7327ZM79.0205 21.8701C76.4622 9.23671 65.424 0 52.5005 0C39.5769 0 28.5397 9.23671 25.9805 21.8701C25.5766 23.6358 25.4423 25.4024 25.4423 27.3035C25.4423 30.4282 25.9805 33.552 27.0577 36.5412L32.1734 34.6391C31.3657 32.1939 30.8275 29.7487 30.8275 27.168C30.8275 26.3529 30.8275 25.5379 30.9618 24.8584C32.1734 13.7195 41.3275 5.43248 52.5 5.43248C62.5962 5.43248 71.2112 12.496 73.5 22.1412C70.5388 22.6842 67.7119 23.6358 65.1535 24.9939L67.7109 29.7487C70.8074 27.9831 74.442 27.168 78.0766 27.168C90.0577 27.168 99.75 36.9487 99.75 49.0391C99.75 61.1294 90.0577 70.9101 78.0766 70.9101H60.5769V76.3435H77.9423C92.8846 76.3435 105 64.1176 105 49.0391C105 34.368 93.4228 22.2772 79.02 21.8697L79.0205 21.8701ZM5.38431 49.0395C5.38431 39.3944 11.5763 31.2438 20.1923 28.3911V27.304C20.1923 25.8094 20.3266 24.1793 20.5962 22.6856C8.75031 25.5384 0 36.2706 0 49.04C0 64.1186 12.1154 76.3445 27.0577 76.3445H44.4231V70.911H27.0577C15.0766 70.911 5.38431 61.1303 5.38431 49.04V49.0395Z'
        fill='#605E5E'
      />
    </svg>
  );
}

export default DragDropIcon;
