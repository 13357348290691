/** @format */

import React from 'react';

function LogoutIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.43323 7.95176L10.8434 5.84289C11.034 5.67623 11.0533 5.38664 10.8866 5.1962C10.8732 5.18085 10.8588 5.16643 10.8434 5.153L8.4185 3.03136C8.23641 2.87207 7.96179 2.88174 7.79137 3.05346L7.78899 3.05584C7.62862 3.21743 7.62954 3.47856 7.79122 3.639C7.79613 3.64391 7.80119 3.64867 7.80633 3.65327L9.35185 5.03964H3.20945C2.95631 5.03964 2.75105 5.24488 2.75105 5.49802C2.75105 5.75116 2.95631 5.95633 3.20945 5.95633H9.35185L7.82706 7.27733C7.64167 7.43792 7.62157 7.71837 7.78217 7.90376C7.78439 7.90637 7.7867 7.90898 7.789 7.91151L7.79207 7.91489C7.96003 8.09988 8.24515 8.11623 8.43324 7.95172L8.43323 7.95176ZM6.87773 2.29163C6.62451 2.29163 6.41926 2.0863 6.41926 1.83308V0.916621H1.37529C1.12215 0.916621 0.916902 1.12179 0.916902 1.37493V9.62499C0.916902 9.87813 1.12215 10.0833 1.37529 10.0833H6.41926V9.16684C6.41926 8.91362 6.62451 8.70829 6.87773 8.70829C7.13103 8.70829 7.33628 8.91362 7.33628 9.16684V10.0833C7.33336 10.5417 6.87506 11 6.41926 11H0.91702C0.458549 11 0 10.5417 0 10.0833V0.916699C0 0.45831 0.458549 0 0.91702 0H6.4167C6.87502 0 7.33333 0.467818 7.33333 0.916699L7.33533 1.83316C7.33579 2.08583 7.13137 2.29116 6.8787 2.29171H6.8777L6.87773 2.29163Z'
        fill='#33344B'
      />
    </svg>
  );
}

export default LogoutIcon;
