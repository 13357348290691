/** @format */

import React from 'react';

function SubscriptionsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M16.2793 24.0002H1.89295C0.849641 24.0002 0 22.9654 0 21.6925V9.69246C0 8.42056 0.849641 7.38477 1.89295 7.38477H16.2793C17.3235 7.38477 18.1723 8.42056 18.1723 9.69246V21.6925C18.1723 22.9654 17.3235 24.0002 16.2793 24.0002ZM1.89295 9.23092C1.68472 9.23092 1.51436 9.43861 1.51436 9.69246V21.6925C1.51436 21.9463 1.68472 22.154 1.89295 22.154H16.2793C16.4876 22.154 16.6579 21.9463 16.6579 21.6925V9.69246C16.6579 9.43779 16.4876 9.23092 16.2793 9.23092H1.89295Z'
        fill='#1206F9'
      />
      <path
        d='M20.4438 18.3078C20.0251 18.3078 19.6866 17.8951 19.6866 17.3847V6.00008C19.6866 5.74541 19.6866 5.49629 19.308 5.53854H5.02866C4.61069 5.53854 4.27148 5.12501 4.27148 4.61546C4.27148 4.10591 4.61069 3.69238 5.02866 3.69238H19.308C20.3522 3.69238 21.201 4.72818 21.201 6.00008V17.3847C21.201 17.8951 20.8624 18.3078 20.4438 18.3078Z'
        fill='#07DCAA'
      />
      <path
        d='M23.4725 12.6156C23.0538 12.6156 22.7153 12.2029 22.7153 11.6926V2.30794C22.7153 2.05409 22.5449 1.8464 22.3367 1.8464H10.0573C9.63938 1.8464 9.30017 1.43287 9.30017 0.923322C9.30017 0.413772 9.63938 0.000244141 10.0573 0.000244141H22.3367C23.3809 0.000244141 24.2296 1.03604 24.2296 2.30794V11.6926C24.2296 12.2029 23.8911 12.6156 23.4725 12.6156Z'
        fill='#07DCAA'
      />
    </svg>
  );
}

export default SubscriptionsIcon;
