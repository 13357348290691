/** @format */
import { createSlice } from '@reduxjs/toolkit';
import { UserDetailsInitialStateType } from 'types/UserDetails-types';
import {
  UpdatePasswordInitialStateType,
  ForgotPasswordInitialStateType,
} from 'types/UserDetails-types';

const getUserDetailsInitialState: UserDetailsInitialStateType = {
  isLoadingUserDetails: false,
  errorUserDetailsData: false,
  UserDetailsData: [],
};
const getUserDetailsAsUserSlice = createSlice({
  name: 'api',
  initialState: getUserDetailsInitialState,
  reducers: {
    getUserDetailsAsUserLoad(state) {
      state.isLoadingUserDetails = true;
    },
    getUserDetailsAsUserSuccess(state, action) {
      state.isLoadingUserDetails = false;
      state.UserDetailsData = action.payload;
    },
    getUserDetailsAsUserFailure(state, action) {
      state.isLoadingUserDetails = false;
      state.errorUserDetailsData = action.payload;
    },
  },
});

export const {
  getUserDetailsAsUserLoad,
  getUserDetailsAsUserSuccess,
  getUserDetailsAsUserFailure,
} = getUserDetailsAsUserSlice.actions;
export { getUserDetailsAsUserSlice };

const updateUserDetailsInitialState: UserDetailsInitialStateType = {
  isLoadingUserDetails: false,
  errorUserDetailsData: false,
  UserDetailsData: [],
};

const updateUserDetailsSlice = createSlice({
  name: 'api',
  initialState: updateUserDetailsInitialState,
  reducers: {
    updateUserDetailsLoad(state) {
      state.isLoadingUserDetails = true;
    },
    updateUserDetailsSuccess(state, action) {
      state.isLoadingUserDetails = false;
      state.UserDetailsData = action.payload;
    },
    updateUserDetailsFailure(state, action) {
      state.isLoadingUserDetails = false;
      state.errorUserDetailsData = action.payload;
    },
  },
});

export const {
  updateUserDetailsLoad,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
} = updateUserDetailsSlice.actions;
export { updateUserDetailsSlice };

const UpdatePasswordIntialState: UpdatePasswordInitialStateType = {
  isLoadingUpdatePassword: false,
  errorUpdatePassword: '',
  UpdatePasswordDataSet: {
    oldPassword: '',
    newPassword: '',
  },
};

const updatePasswordSlice = createSlice({
  name: 'api',
  initialState: UpdatePasswordIntialState,
  reducers: {
    updatePasswordLoad(state) {
      state.isLoadingUpdatePassword = true;
    },
    updatePasswordSuccess(state, action) {
      state.isLoadingUpdatePassword = false;
      state.UpdatePasswordDataSet = action.payload;
    },
    updatePasswordFailure(state, action) {
      state.isLoadingUpdatePassword = false;
      state.errorUpdatePassword = action.payload;
    },
  },
});

export const {
  updatePasswordLoad,
  updatePasswordSuccess,
  updatePasswordFailure,
} = updatePasswordSlice.actions;
export { updatePasswordSlice };

const sendForgetPasswordMailInitialState: ForgotPasswordInitialStateType = {
  isLoadingForgetPasswordMail: false,
  errorForgetPasswordMain: '',
  ForgetPasswordDataSet: [],
};

const sendForgetPasswordMailSlice = createSlice({
  name: 'api',
  initialState: sendForgetPasswordMailInitialState,
  reducers: {
    postForgetPasswordMailLoad(state) {
      state.isLoadingForgetPasswordMail = true;
    },
    postForgetPasswordMailSuccess(state, action) {
      state.isLoadingForgetPasswordMail = false;
      state.ForgetPasswordDataSet = action.payload;
    },
    postForgetPasswordMailFailure(state, action) {
      state.isLoadingForgetPasswordMail = false;
      state.errorForgetPasswordMain = action.payload;
    },
  },
});
export const {
  postForgetPasswordMailFailure,
  postForgetPasswordMailLoad,
  postForgetPasswordMailSuccess,
} = sendForgetPasswordMailSlice.actions;
export { sendForgetPasswordMailSlice };
