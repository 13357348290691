import { createSlice } from '@reduxjs/toolkit';
import {
  VerifyOTPInitialStateTypes,
  VisitorFeedbackInitialStateTypes,
  sendValidationOTPToUserInitialStateTypes,
} from 'types/PreviewTypes/UserValidation-types';

const sendOtpInitialState: sendValidationOTPToUserInitialStateTypes = {
  isLoadingSendOtp: false,
  sendOtp: [],
  errorSendOtp: false,
};

const sendOtpToUserSlice = createSlice({
  name: 'Send Otp Api',
  initialState: sendOtpInitialState,
  reducers: {
    sendOtpLoad(state) {
      state.isLoadingSendOtp = true;
    },
    sendOtpSuccess(state, action) {
      state.isLoadingSendOtp = false;
      state.sendOtp = action.payload;
    },
    sendOtpFailure(state, action) {
      state.isLoadingSendOtp = false;
      state.errorSendOtp = action.payload;
    },
  },
});

export const { sendOtpLoad, sendOtpSuccess, sendOtpFailure } =
  sendOtpToUserSlice.actions;

export { sendOtpToUserSlice };

const VerifyOtpInitialState: VerifyOTPInitialStateTypes = {
  isVerifyingOtp: false,
  VerifyOtp: false,
  errorVerifyingOTP: false,
};

const VerifyOTPSlice = createSlice({
  name: 'Verify OTP API',
  initialState: VerifyOtpInitialState,
  reducers: {
    VerifyOTPLoad(state) {
      state.isVerifyingOtp = true;
    },
    VerifyOTPSuccess(state, action) {
      state.isVerifyingOtp = false;
      state.VerifyOtp = action.payload;
    },
    VerifyOTPFailure(state, action) {
      state.isVerifyingOtp = false;
      state.errorVerifyingOTP = action.payload;
    },
  },
});

export const { VerifyOTPLoad, VerifyOTPSuccess, VerifyOTPFailure } =
  VerifyOTPSlice.actions;

export { VerifyOTPSlice };

const VisitorFeedbackInitialState: VisitorFeedbackInitialStateTypes = {
  isLoadingSubmitVisitorFeedback: false,
  VisitorFeedback: [],
  errorSubmitingFeedback: false,
};

const VisitorFeedbackSlice = createSlice({
  name: 'Visitor feedback API',
  initialState: VisitorFeedbackInitialState,
  reducers: {
    VisitorFeedbackLoad(state) {
      state.isLoadingSubmitVisitorFeedback = true;
    },
    VisitorFeedbackSuccess(state, action) {
      state.isLoadingSubmitVisitorFeedback = false;
      state.VisitorFeedback = action.payload;
    },
    VisitorFeedbackFailure(state, action) {
      state.isLoadingSubmitVisitorFeedback = false;
      state.errorSubmitingFeedback = action.payload;
    },
  },
});

export const {
  VisitorFeedbackLoad,
  VisitorFeedbackSuccess,
  VisitorFeedbackFailure,
} = VisitorFeedbackSlice.actions;

export { VisitorFeedbackSlice };
