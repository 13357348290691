/** @format */

import SignInComponent from 'components/SignIn/SignIn';
import React from 'react';

function SignIn() {
  return <SignInComponent />;
}

export default SignIn;
