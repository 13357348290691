/** @format */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Tooltip, Avatar, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useTranslation } from 'react-i18next';
import VideoIcon from 'icons/VideoIcon.png';
import MicrophoneIcon from 'icons/MicrophoneIcon.png';
import UploadIcon from 'icons/UploadIcon.png';
import ChatIcon from 'icons/ChatIcon.png';
import CloseDashboardIcon from 'icons/CloseDashboardIcon';
import OpenDashboardIcon from 'icons/OpenDashboardIcon';
import useAuth from 'hooks/useAuth';
import { ProtectedRoutes } from 'routes/routes';
import { useHistory } from 'react-router';

import { useIsAuthenticated } from '@azure/msal-react';
import { recordingTypeList } from 'constants/Record';
import { useAppDispatch } from 'redux/store';
import { globalSearchService } from 'services/GlobalSearch-service';
import GlobalSearchIcon from 'icons/GlobalSearchIcon';
import LogOutModal from 'widgets/LogOutModal';
import LogoutIcon from 'icons/LogoutIcon';
import Typography from 'widgets/Typography/Typography';
import { handleFbSsoLogoutServiceApi } from 'services/Facebook-sso-service';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
interface HeaderProps {
  setOpen: Function;
  open: boolean;
  handleOpenMobileDrawer: any;
  handleCloseMobileDrawer: any;
  openMobileDrawer: boolean;
  ShowMenuIcons?: boolean;
}

export default function Header({
  setOpen,
  open,
  handleOpenMobileDrawer,
  handleCloseMobileDrawer,
  openMobileDrawer,
  ShowMenuIcons = true,
}: HeaderProps) {
  const [profileImage, setImage] = React.useState(
    sessionStorage.getItem('profile_image_bytes') as string
  );
  const [firstName, setFirstName] = React.useState(
    sessionStorage.getItem('first_name')
  );
  const { handleLogout, handleMicrosoftLogout } = useAuth();
  const history = useHistory();
  const menuId = 'primary-search-account-menu1';
  const mobileMenuId = 'mobile-header-primary-search-account-menu';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const checkLog = () => {
    handleCLoseLogoutModal();
    handleMenuClose();
    const authProvider = sessionStorage.getItem('authProviderName');

    switch (authProvider) {
      case 'Mirro':
        handleLogout();
        break;
      case 'Microsoft':
        handleMicrosoftLogout();
        break;
      case 'Google':
        handleLogout();
        break;
      case 'Facebook':
        dispatch(handleFbSsoLogoutServiceApi(history));
        break;
      default: {
        handleLogout();
        console.log('No logout case found for this SSO');
      }
    }
  };

  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);

  function handleOpenLogoutModal() {
    setOpenLogoutModal(true);
  }

  function handleCLoseLogoutModal() {
    setOpenLogoutModal(false);
  }

  const renderMenu = (
    <>
      {ShowMenuIcons && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            sx={{ fontFamily: 'poppins' }}
            id='profilesettingsMenu'
            onClick={() => history.push(ProtectedRoutes.profileRoute)}
            //onClick={handleMenuClose}
          >
            <Typography label='header.Profile' />
          </MenuItem>

          <MenuItem
            sx={{ fontFamily: 'poppins' }}
            id='signOutImg1'
            onClick={handleOpenLogoutModal}
          >
            <Typography label='header.Logout' />
          </MenuItem>
        </Menu>
      )}
    </>
  );

  const renderMobileMenu = (
    <>
      {ShowMenuIcons && (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <MenuItem
            onClick={() =>
              history.push({
                pathname: ProtectedRoutes.recordRoute,
                state: {
                  recordingType: recordingTypeList[0],
                },
              })
            }
          >
            <IconButton size='large' id='MobileVideoRecordButton'>
              <img
                src={VideoIcon}
                width='30px'
                height='25px'
                style={{ padding: '2px' }}
                alt='VideoIcon'
              />
            </IconButton>

            <p>
              <Typography label='header.RecordVideo' />
            </p>
          </MenuItem>

          <MenuItem
            onClick={() =>
              history.push({
                pathname: ProtectedRoutes.uploadRoute,
              })
            }
          >
            <IconButton size='large' id='uploadButton'>
              <img
                src={UploadIcon}
                width='25px'
                height='28px'
                style={{ padding: '2px' }}
                alt='UploadIcon'
              />
            </IconButton>
            <p>
              <Typography label='header.Upload' />
            </p>
          </MenuItem>
          <MenuItem
            onClick={() =>
              history.push({
                pathname: ProtectedRoutes.profileRoute,
              })
            }
          >
            <IconButton size='large' id='uploadButton'>
              <Avatar
                alt={`${firstName?.charAt(0)}`}
                src={`data:image/png;base64,${profileImage}`}
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
            <p>
              <Typography label='header.Profile' />{' '}
            </p>
          </MenuItem>

          <MenuItem id='signOutImg' onClick={handleOpenLogoutModal}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='primary-search-account-menu1'
              aria-haspopup='true'
            >
              <LogoutIcon />
            </IconButton>
            <p>
              {' '}
              <Typography label='header.Logout' />
            </p>
          </MenuItem>
        </Menu>
      )}
    </>
  );

  const isTablet = useMediaQuery('(max-width: 960px)');
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const dispatch = useAppDispatch();
  const [globalSearchValue, setGlobalSearchValue] = React.useState('');

  const [lastName, setLastName] = React.useState(
    sessionStorage.getItem('last_name')
  );

  React.useEffect(() => {
    dispatch(globalSearchService(globalSearchValue));
  }, [dispatch, globalSearchValue]);

  const [isSearchBarVisible, setSearchBarVisible] = React.useState(false);

  React.useEffect(() => {
    if (
      history.location.pathname.toLowerCase() ===
        ProtectedRoutes.dashboardRoute.toLowerCase() ||
      history.location.pathname.toLowerCase() ===
        ProtectedRoutes.galleryRoute.toLowerCase()
    ) {
      setSearchBarVisible(true);
    }
  }, [history.location.pathname]);

  return (
    <AppBar
      position='fixed'
      open={open}
      sx={{
        backgroundColor: '#FFFFFF',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        {ShowMenuIcons && (
          <>
            {open || openMobileDrawer ? (
              <IconButton
                size='large'
                edge='start'
                aria-label='open drawer-toolbar'
                sx={{ mr: 2 }}
                onClick={
                  isMobile || isTablet
                    ? handleCloseMobileDrawer
                    : handleDrawerClose
                }
                id='icon-options-vertical'
              >
                <CloseDashboardIcon />
              </IconButton>
            ) : (
              <IconButton
                size='large'
                edge='start'
                aria-label='open drawer'
                sx={{ mr: 2 }}
                onClick={
                  isMobile || isTablet
                    ? handleOpenMobileDrawer
                    : handleDrawerOpen
                }
                id='icon-menu1'
              >
                <OpenDashboardIcon />
              </IconButton>
            )}
          </>
        )}

        <img
          src='./comonImg/mirro-logo-with-namee.png'
          width='100px'
          alt='mirro-logo-with-namee'
          id='mirro-logo-with-name'
        />

        {isSearchBarVisible && (
          <Box
            sx={{
              border: '1px solid #DFDFDF',
              color: '#BDBDBD',
              width: '40%',
              marginLeft: isMobile ? '10%' : isTablet ? '20%' : '10%',
              height: '40px',
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              borderRadius: '35px',
              padding: 2,
              backgroundColor: '#FAFAFA',
            }}
          >
            <GlobalSearchIcon />
            <InputBase
              id='searchBar'
              sx={{
                color: '#676767',
                fontFamily: 'Poppins',
                width: '100%',
                padding: 0.5,
              }}
              placeholder={t('header.SearchForSessions')}
              inputProps={{ 'aria-label': 'Search For Sessions' }}
              onChange={(e) => setGlobalSearchValue(e.target.value)}
              value={globalSearchValue}
            />
          </Box>
        )}

        <Box
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            width: '30%',
          }}
        ></Box>
        {ShowMenuIcons && (
          <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title={t('header.RecordVideo')}>
                <IconButton
                  size='large'
                  onClick={() =>
                    history.push({
                      pathname: ProtectedRoutes.recordRoute,
                      state: {
                        recordingType: recordingTypeList[0],
                      },
                    })
                  }
                  id='videoRecordButton'
                >
                  <img
                    src={VideoIcon}
                    width='30px'
                    height='25px'
                    style={{ padding: '2px' }}
                    alt='VideoIcon'
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('header.Upload')}>
                <IconButton
                  size='large'
                  id='headerUploadButton'
                  onClick={() =>
                    history.push({
                      pathname: ProtectedRoutes.uploadRoute,
                    })
                  }
                >
                  <img
                    src={UploadIcon}
                    width='25px'
                    height='28px'
                    style={{ padding: '2px' }}
                    alt='UploadIcon'
                  />
                </IconButton>
              </Tooltip>

              <IconButton
                size='large'
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                id='profilesettings'
              >
                <Tooltip title={t('header.OpenSettings')}>
                  <Avatar
                    alt={`${firstName?.charAt(0)}`}
                    src={`data:image/png;base64,${profileImage}`}
                  />
                </Tooltip>
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                id='MobileMenuOpenItemButton'
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Toolbar>

      {renderMenu}
      {renderMobileMenu}
      {ShowMenuIcons && (
        <LogOutModal
          logoutOpen={openLogoutModal}
          handleLogout={checkLog}
          handleLogoutClose={handleCLoseLogoutModal}
        />
      )}
    </AppBar>
  );
}
