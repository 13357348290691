/** @format */

import LearningsComponent from 'components/Learnings/Learnings';
import React from 'react';

function Learnings() {
  return <LearningsComponent />;
}

export default Learnings;
