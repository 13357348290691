/** @format */

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface DeleteModalProps {
  openDeleteCancelledModal: boolean;
  handleCloseDeleteCancelledModal: () => void;
}

function DeleteCancelledModal({
  openDeleteCancelledModal,
  handleCloseDeleteCancelledModal,
}: DeleteModalProps) {
  return (
    <Dialog
      open={openDeleteCancelledModal}
      onClose={handleCloseDeleteCancelledModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent sx={{ pl: 18, pr: 18, pt: 4 }}>
        <DialogContentText id='alert-dialog-description'>
          <Typography label='gallery.DeletionCancelled' />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',

          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            fontFamily: 'Poppins',
            backgroundColor: '#1206f9',
            color: '#fff',
          }}
          variant='contained'
          onClick={handleCloseDeleteCancelledModal}
          label='gallery.Ok'
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCancelledModal;
