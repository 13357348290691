type Environment = { [key: string]: string };
const isEnvProduction = process.env.NODE_ENV === "production";
const env: Environment =
  isEnvProduction &&
  typeof window !== "undefined" &&
  typeof window.ENV !== "undefined"
    ? window.ENV
    : process.env;

export default env;
