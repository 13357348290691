/** @format */

import StripeSubscriptionPaymentFailComponent from 'components/StripeSubscriptionPaymentFail/StripeSubscriptionPaymentFail';
import React from 'react';

function StripeSubscriptionPaymentFail() {
  return <StripeSubscriptionPaymentFailComponent />;
}

export default StripeSubscriptionPaymentFail;
