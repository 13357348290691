import React from "react";

function PauseIconForPatientQuestionaire() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="
  http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="40" cy="40" r="40" fill="white" />
      <rect x="30" y="28.75" width="7.5" height="22.5" rx="2" fill="white" />
      <rect x="42.5" y="28.75" width="7.5" height="22.5" rx="2" fill="white" />
    </svg>
  );
}

export default PauseIconForPatientQuestionaire;
