/** @format */

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import useProcessDataListTable from './hooks/useProcessDataListTable';
import Loader from 'widgets/Loader/Loader';
import {
  ProcessDataListTableProps,
  FilteredProcessDataList,
  HeadCell,
  Data,
} from 'types/Dashboard-types';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import ColumnsList from './ColumnsList';
import ProcessDataTableBody from './ProcessDataTableBody';
import DashboardFilter from './DashboardFilter';
import { useCallback, useEffect, useState } from 'react';
import { widgets } from 'constants/Widgets';
import { WidgetData } from 'types/Dashboard-types';
import EngagementWidget from './DashboardWidgets/EngagementWidget';
import { Grid, useMediaQuery } from '@mui/material';
import PositivityWidget from './DashboardWidgets/PositivityWidget';
import VariationWidget from './DashboardWidgets/VariationWidget';
import EnergyWidget from './DashboardWidgets/EnergyWidget';
import FlagsWidget from './DashboardWidgets/FlagsWidget';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUserWidgetPreferenceService } from 'services/UserPrefrence-service';
import { UserWidgetPreferenceSelectore } from 'redux/selectors/selectors';

export default function ProcessDataListTable({
  processDataListWithSession,
  setProcessDataListWithSession,
  onFilterDataChange,
  headCells,
}: ProcessDataListTableProps) {
  const {
    handleRequestSort,
    handleChangeRowsPerPage,
    handleChangePage,
    formatDate,
    page,
    emptyRows,
    rowsPerPage,
    visibleRows,
    order,
    orderBy,
    rows,
  } = useProcessDataListTable(processDataListWithSession);
  const dispatch = useAppDispatch();

  const { userWidgetPreference, isLoadingUserWidgetPreference } =
    useAppSelector(UserWidgetPreferenceSelectore);

  const initialColumnVisibility: Record<string, boolean> = {};
  const [columnVisibility, setColumnVisibility] = React.useState(
    initialColumnVisibility
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const [filteredDataWithInputs, setFilteredDataWithInputs] = useState<
    FilteredProcessDataList[]
  >([]);
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetData[]>([]);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');
  const isEngagementSelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 1
  );
  const isPositivitySelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 4
  );
  const isVariationSelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 3
  );
  const isEnergySelected = selectedWidgets.some(
    (widgetId) => widgetId.id === 2
  );
  const isFlagsSelected = selectedWidgets.some((widgetId) => widgetId.id === 5);

  headCells.forEach((cell: HeadCell) => {
    initialColumnVisibility[cell.id] = cell.showColumn !== false;
  });

  const handleClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.FormEvent<HTMLFormElement>
    ) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleColumnToggle = useCallback((columnId: string) => {
    setColumnVisibility((prevVisibility: Record<string, boolean>) => ({
      ...prevVisibility,
      [columnId]: !prevVisibility[columnId],
    }));
  }, []);

  const handleFilter = useCallback((payload: FilteredProcessDataList) => {
    setFilteredDataWithInputs([payload]);

    if (onFilterDataChange) {
      onFilterDataChange([payload]);
    }
  }, []);

  useEffect(() => {
    const userPreferences = userWidgetPreference[0];
    if (userPreferences) {
      const preferencedWidgets = widgets
        .filter((widget) => userPreferences[widget.name.trim()])
        .map((widget) => widget);

      setSelectedWidgets(preferencedWidgets);
    }
  }, [userWidgetPreference]);
  useEffect(() => {
    dispatch(getUserWidgetPreferenceService());
  }, []);

  const [selectedRows, setSelectedRows] = useState<Number[]>([]);

  function handleOnChange(session_id: number) {
    setSelectedRows((selectedRows) => {
      const selectedIndex = selectedRows.indexOf(session_id);
      if (selectedIndex === -1) {
        return [...selectedRows, session_id];
      } else {
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows.splice(selectedIndex, 1);
        return updatedSelectedRows;
      }
    });
  }

  const records = processDataListWithSession;

  function handleSelectAll() {
    if (selectedRows.length < records.length) {
      setSelectedRows([
        ...new Set(records.map((row) => row.coaching_session_reports_id)),
      ]);
    }
    if (selectedRows.length === records.length) {
      setSelectedRows([]);
    }
  }

  const hasSelectedAll = selectedRows.length === records.length;

  return isLoadingUserWidgetPreference ? (
    <Loader />
  ) : (
    <Box sx={{ maxWidth: '100%', width: '100%' }}>
      {processDataListWithSession.length < 0 ? (
        <Loader />
      ) : (
        <>
          {isMobile || isTablet ? (
            <Box sx={{ width: '100%', height: 'auto' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <DashboardFilter
                    onFilter={handleFilter}
                    processDataListWithSession={processDataListWithSession}
                    setProcessDataListWithSession={
                      setProcessDataListWithSession
                    }
                    selectedWidgets={selectedWidgets}
                    setSelectedWidgets={setSelectedWidgets}
                  />
                </Grid>

                {isFlagsSelected && (
                  <Grid item xs={12} sm={6}>
                    <FlagsWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {isEngagementSelected && (
                  <Grid item xs={12} sm={6}>
                    <EngagementWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {isEnergySelected && (
                  <Grid item xs={12} sm={6}>
                    <EnergyWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {isVariationSelected && (
                  <Grid item xs={12} sm={6}>
                    <VariationWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}

                {isPositivitySelected && (
                  <Grid item xs={12} sm={6}>
                    <PositivityWidget
                      processDataListWithSession={processDataListWithSession}
                      filteredDataWithInputs={filteredDataWithInputs}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Paper sx={{ width: '100%', mt: 2 }} elevation={1}>
                      <EnhancedTableToolbar
                        handleClick={handleClick}
                        selectedRows={selectedRows}
                      />
                      <TableContainer sx={{ maxWidth: '100vw' }}>
                        <Table stickyHeader aria-label='sticky table'>
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            columnVisibility={columnVisibility}
                            headCells={headCells}
                            handleSelectAll={handleSelectAll}
                            selectedRows={selectedRows}
                            hasSelectedAll={hasSelectedAll}
                          />
                          <ProcessDataTableBody
                            visibleRows={visibleRows}
                            columnVisibility={columnVisibility}
                            formatDate={formatDate}
                            emptyRows={emptyRows}
                            handleOnChange={handleOnChange}
                            selectedRows={selectedRows}
                          />
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />{' '}
                    </Paper>
                    <ColumnsList
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                      headCells={headCells}
                      columnVisibility={columnVisibility}
                      handleColumnToggle={handleColumnToggle}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                minHeight: '100vh',
                height: '100%',

                mt: 4,
              }}
            >
              <Box
                sx={{
                  Width: '100%',
                  height: 'auto',

                  backgroundColor: '#f9f9f9',
                }}
              >
                <DashboardFilter
                  onFilter={handleFilter}
                  processDataListWithSession={processDataListWithSession}
                  setProcessDataListWithSession={setProcessDataListWithSession}
                  selectedWidgets={selectedWidgets}
                  setSelectedWidgets={setSelectedWidgets}
                />
              </Box>

              <Box
                sx={{
                  Width: '100%',
                  height: 'auto',
                  display: 'flex',
                  mb: 5,
                }}
              >
                <Grid container spacing={1}>
                  {isEngagementSelected && (
                    <Grid item md={3}>
                      <EngagementWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isEnergySelected && (
                    <Grid item md={3}>
                      <EnergyWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isVariationSelected && (
                    <Grid item md={3}>
                      <VariationWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isPositivitySelected && (
                    <Grid item md={3}>
                      <PositivityWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                  {isFlagsSelected && (
                    <Grid item md={3}>
                      <FlagsWidget
                        processDataListWithSession={processDataListWithSession}
                        filteredDataWithInputs={filteredDataWithInputs}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Paper
                sx={{
                  overflow: 'hidden',
                  minHeight: '100vh',
                  height: '100%',
                  width: '100%',
                }}
              >
                <EnhancedTableToolbar
                  handleClick={handleClick}
                  selectedRows={selectedRows}
                />

                <TableContainer
                  sx={{
                    minHeight: '100vh',
                    height: '100%',
                    maxWidth: '92vw',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby='tableTitle'
                    size='small'
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      columnVisibility={columnVisibility}
                      headCells={headCells}
                      handleSelectAll={handleSelectAll}
                      selectedRows={selectedRows}
                      hasSelectedAll={hasSelectedAll}
                    />
                    <ProcessDataTableBody
                      visibleRows={visibleRows}
                      columnVisibility={columnVisibility}
                      formatDate={formatDate}
                      emptyRows={emptyRows}
                      handleOnChange={handleOnChange}
                      selectedRows={selectedRows}
                    />
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
              <ColumnsList
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                headCells={headCells}
                columnVisibility={columnVisibility}
                handleColumnToggle={handleColumnToggle}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
