import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Button from 'widgets/CustomButton/Button';

interface CountUpDownTimerProps {
  seconds: number;
  direction: string;
  style: object;
  isTimerRunning: boolean;
}

const CountUpDownTimer: React.FC<CountUpDownTimerProps> = ({
  seconds,
  direction,
  style,
  isTimerRunning,
}) => {
  const [currentTime, setCurrentTime] = useState<number>(
    direction === 'countUp' ? 0 : seconds
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isTimerRunning) {
      interval = setInterval(() => {
        if (direction === 'countUp') {
          setCurrentTime((prevTime) => prevTime + 1);
        } else {
          setCurrentTime((prevTime) => prevTime - 1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [direction, isTimerRunning]);

  return (
    <Box sx={style}>
      <Box
        sx={{
          backgroundColor: '#c0c1c4',
          border: '0.84px solid #000000',
          borderRadius: '7.54px 0px 7.54px 7.54px',
          height: '100px',
          width: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            WebkitTextStroke: '0.84px #000000',
            color: isTimerRunning
              ? (direction === 'countUp' && currentTime <= seconds) ||
                (direction === 'countDown' && currentTime >= 0)
                ? '#50C878'
                : 'red'
              : '#ffffff',
            fontFamily: '"Poppins-Black", Helvetica',
            fontSize: '53.6px',
            fontWeight: 900,
            m: 1,
          }}
        >
          {currentTime}
        </Typography>
      </Box>{' '}
    </Box>
  );
};

export default CountUpDownTimer;
