import React from 'react';

function StartAssessmentRecordIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
    >
      <path
        d='M15.577 6.61778C16.0067 6.84764 16.3662 7.19078 16.6169 7.61044C16.8676 8.03009 17 8.51044 17 9.00001C17 9.48958 16.8676 9.96993 16.6169 10.3896C16.3662 10.8092 16.0067 11.1524 15.577 11.3822L4.11324 17.6524C2.26734 18.663 0 17.3491 0 15.271V2.7299C0 0.650958 2.26734 -0.662104 4.11324 0.346766L15.577 6.61778Z'
        fill='white'
      />
    </svg>
  );
}

export default StartAssessmentRecordIcon;
