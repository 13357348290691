import i18n from 'i18next';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  EmotionTypeLoad,
  EmotionTypeFailure,
  EmotionTypeSuccess,
} from 'redux/slices/Emotion-slice';

export const GetEmotionTypeListService = (): AppThunk => async (dispatch) => {
  dispatch(EmotionTypeLoad());

  await commonAPI
    .get(ProtectedUrls.getEmotionTypes.url())
    .then((response) => {
      dispatch(EmotionTypeSuccess(response.data));
    })
    .catch((error) => {
      dispatch(
        EmotionTypeFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
      );
    });
};
