import React from 'react';

export default function AssessmentCompleteIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5ZM13.3288 6.62119C13.607 6.89943 13.607 7.35057 13.3288 7.62879L8.57879 12.3788C8.30053 12.657 7.84947 12.657 7.57119 12.3788L5.67119 10.4788C5.39294 10.2005 5.39294 9.74947 5.67119 9.47121C5.94943 9.19296 6.40057 9.19296 6.67881 9.47121L8.075 10.8673L10.1981 8.74428L12.3212 6.62119C12.5995 6.34294 13.0505 6.34294 13.3288 6.62119Z'
        fill='#1206F9'
      />
    </svg>
  );
}
