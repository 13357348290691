import { Radio, FormControlLabel, Box, Paper } from '@mui/material';
import React from 'react';
import Button from 'widgets/CustomButton/Button';

function AssessmentTypeCard({
  session,
  handleClickOpen,
}: {
  session: { id: number; name: string; description?: string };
  handleClickOpen: Function;
}) {
  return (
    <Box sx={{ width: 265, height: '35vh' }}>
      <Paper
        elevation={2}
        sx={{
          height: '35vh',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: '9.6px',
        }}
      >
        <Box
          sx={{
            height: '15%',
            display: 'flex',
            gap: 2,

            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Box
            style={{
              fontSize: '16px',
              fontWeight: '900',

              textAlign: 'left',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,

              textOverflow: 'ellipsis',
            }}
          >
            {session.name}
          </Box>

          <FormControlLabel
            sx={{ width: '5%', p: 0, m: 0 }}
            value={session.id}
            control={<Radio size='medium' sx={{ width: '10%', p: 0, m: 0 }} />}
            label=''
          />
        </Box>
        <Box
          sx={{
            height: '65%',
            overflow: 'hidden',
            '&:hover': {
              overflowY: 'auto',
            },
            fontSize: '14px',
          }}
        >
          {session.description}
        </Box>
        <Box sx={{ height: '10%', display: 'flex', justifyContent: 'end' }}>
          {' '}
          <Button
            size='small'
            label='View Prompts'
            variant='text'
            onClick={() => handleClickOpen(session.id)}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default AssessmentTypeCard;
