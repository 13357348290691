/** @format */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  styled,
  useMediaQuery,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  GetProcessedDataListForParticipantsVersion2ServiceAPI,
  getProcessStatusService,
  getTranscriptDataService,
  updateVideoTitleService,
} from 'services/VideoPreview-services';
import {
  getProcessDataListForParticipantsVersion2Selector,
  getLoginDataSelector,
  getSessionTypesSelector,
  getProcessStatusSelector,
  getTranscriptDataSelector,
} from 'redux/selectors/selectors';

import { useHistory } from 'react-router-dom';
import Loader from 'widgets/Loader/Loader';
import VideoPlayerVersion2 from './VideoPreviewAdvanceComponents/VideoPlayerVersion2';
import OverallSummaryVersion2 from './VideoPreviewAdvanceComponents/OverallSummaryVersion2';
import TipCascadeVersion2 from './VideoPreviewAdvanceComponents/TipCascadeVersion2';
import OverallSummaryDetailsVersion2 from './VideoPreviewAdvanceComponents/OverallSummaryDetailsVersion2';
import EditTitleIcon from 'icons/EditTitleIcon';
import VideoPreviewAdvanceFilter from '../VideoPreviewAdvanceFilter';

import { UploadMediaData } from 'types/Upload-types';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';

import SessionChangeModal from '../TipCascadeBulbAndModal/SessionChangeModal';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { FLAG_OPTIONS } from 'constants/Flags';
import { CoachingSessionReport, FlagOption } from 'types/VideoPreview-types';
import { useTranslation } from 'react-i18next';
import SaveIcon from 'icons/SaveIcon';
import CancelSaveIcon from 'icons/CancelSaveIcon';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import { truncateFilename } from 'utils/truncateFileNames';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import PromptsPane from '../Prompts';
import LockIcon from '@mui/icons-material/Lock';
import TranscriptIcon from 'icons/TranscriptIcon';
import TabsForPromptSection from '../TabsForPromptSection';
import PromptDetails from '../PromptDetails';
import TabsForTranscriptAndTips from '../TabsForTranscriptAndTips';
import TabsForOverallAndPromptPerformance from '../TabsForOverallAndPromptPerformance';
import VisitorFeedback from 'components/MirroSandbox/VisitorFeedback';
import { ProtectedRoutes } from 'routes/routes';
import TranscriptComponent from '../TranscriptTab';

interface VideoPrevieProps {
  isDisable?: boolean;
}

function VideoPreviewAdvanceVersion2({ isDisable = false }: VideoPrevieProps) {
  const history: {
    location: {
      state: {
        coaching_session_reports_id: number;
      };
    };
  } = useHistory();

  const previewHistory = useHistory();

  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [tipsPlaying, setTipsPlaying] = useState('');
  const [tipStartTime, setTipStartTime] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [reprocess, setReprocess] = useState(false);
  const [loadingReprocess, setLoadingReprocess] = useState(false);
  const historyId = history.location.state?.coaching_session_reports_id;
  const localStorageId = localStorage.getItem('coaching_session_reports_id');
  const finalId = localStorageId ? parseInt(localStorageId, 10) : 0;
  const [forceRender, setForceRender] = useState(false);
  const [selectedFlags, setSelectedFlags] =
    useState<FlagOption[]>(FLAG_OPTIONS);
  const [selectedUserRole, setSelectedUserRole] = useState<
    Array<{ id: number; name: string; index: number }>
  >([]);

  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPerformanceTab, setPerformanceTab] = useState(0);
  const [selectedSummaryTab, setSummaryTab] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState(0);
  const [componentTab, setComponentTab] = useState([0, 1]);
  const {
    ProcessDataListForParticipantsVersion2,
    isLoadingProcessDataListForParticipantsVersion2,
  } = useAppSelector(getProcessDataListForParticipantsVersion2Selector);

  useEffect(() => {
    if (historyId !== undefined) {
      dispatch(
        GetProcessedDataListForParticipantsVersion2ServiceAPI(historyId)
      );
    } else {
      dispatch(GetProcessedDataListForParticipantsVersion2ServiceAPI(finalId));
    }
  }, [dispatch, finalId, historyId]);

  useEffect(() => {
    if (ProcessDataListForParticipantsVersion2.length > 0) {
      dispatch(
        getSessionTypesServiceApi(
          -1,
          ProcessDataListForParticipantsVersion2[0]?.is_prompt_section || false
        )
      );
    }
  }, [ProcessDataListForParticipantsVersion2, dispatch]);

  let promptID;
  useEffect(() => {
    if (
      ProcessDataListForParticipantsVersion2.length > 0 &&
      ProcessDataListForParticipantsVersion2[0].is_prompt_section
    ) {
      promptID =
        ProcessDataListForParticipantsVersion2[0].prompt_sections[
          selectedPrompt
        ].coaching_session_reports_id;

      dispatch(getTranscriptDataService(promptID));
    } else {
      dispatch(getTranscriptDataService(finalId));
    }
  }, [ProcessDataListForParticipantsVersion2, selectedPrompt, dispatch]);

  const { SessionTypes } = useAppSelector(getSessionTypesSelector);

  const [editedTitle, setEditedTitle] = useState('');

  const CompanyFolder = useAppSelector(getLoginDataSelector);

  const handleVideoTimeUpdate = (time: number) => {
    setCurrentVideoTime(time);
  };
  const [videoPlaying, setVideoPlaying] = useState(false);

  const cascadeRef = useRef<HTMLDivElement | null>(null);
  const highlightedCardRef = useRef<HTMLDivElement | null>(null);
  const vidRef = useRef<HTMLVideoElement>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const { isLoadingTranscriptData, Data } = useAppSelector(
    getTranscriptDataSelector
  );

  useEffect(() => {
    if (ProcessDataListForParticipantsVersion2.length > 0) {
      setEditedTitle(
        ProcessDataListForParticipantsVersion2[0]?.session_title
          ? ProcessDataListForParticipantsVersion2[0]?.session_title
          : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
      );
    }
  }, [ProcessDataListForParticipantsVersion2, forceRender]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleEditClick = () => {
    setIsEditing(true);
    setEditedTitle(
      ProcessDataListForParticipantsVersion2.length > 0
        ? ProcessDataListForParticipantsVersion2[0]?.session_title
          ? ProcessDataListForParticipantsVersion2[0]?.session_title
          : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
        : ''
    );
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    const coachingSessionReportsId =
      history.location.state?.coaching_session_reports_id ||
      localStorage.getItem('coaching_session_reports_id');

    const payload = {
      coaching_session_reports_id:
        coachingSessionReportsId !== null
          ? Number(coachingSessionReportsId)
          : null,
      video_title: editedTitle,
    };

    await dispatch(updateVideoTitleService(payload));

    await dispatch(
      GetProcessedDataListForParticipantsVersion2ServiceAPI(finalId)
    );

    const updatedTitle =
      ProcessDataListForParticipantsVersion2.length > 0
        ? ProcessDataListForParticipantsVersion2[0]?.session_title
          ? ProcessDataListForParticipantsVersion2[0]?.session_title
          : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
        : '';

    setEditedTitle(updatedTitle);

    setForceRender((prev) => !prev);
  };

  useEffect(() => {
    if (!isLoadingProcessDataListForParticipantsVersion2) {
      const delay = setTimeout(() => {
        setForceRender(false);
      }, 500);
      return () => clearTimeout(delay);
    }
  }, [
    forceRender,
    editedTitle,
    isLoadingProcessDataListForParticipantsVersion2,
  ]);

  const currentSessionType: UploadMediaData | null =
    SessionTypes.find((sessionType: UploadMediaData) => {
      return (
        sessionType.id ===
        ProcessDataListForParticipantsVersion2[0]?.session_type_id
      );
    }) ?? null;

  const otherSessionTypes: UploadMediaData[] = SessionTypes.filter(
    (sessionType: UploadMediaData) => {
      return (
        sessionType.id !==
        ProcessDataListForParticipantsVersion2[0]?.session_type_id
      );
    }
  );
  const { processStatus, isLoadingProcessStatus } = useAppSelector(
    getProcessStatusSelector
  );

  const handleReprocess = useCallback(async () => {
    setShow(true);
  }, []);

  async function handleYesReprocess() {
    setLoadingReprocess(true);
    setShow(false);
    try {
      const reprocessResponse = await callReprocessAPI();

      if (reprocessResponse.data.Job_Id) {
        await checkStatus();
      }
    } catch (error) {
      console.error('Reprocessing failed:', error);
    }
  }
  const checkStatus = useCallback(async () => {
    try {
      const statusResponse = await callStatusAPI();
      if (statusResponse.data[0].process_status === 9) {
        setLoadingReprocess(false);
        dispatch(
          GetProcessedDataListForParticipantsVersion2ServiceAPI(finalId)
        );
      } else {
        await checkStatus();
      }
    } catch (error) {}
  }, []);

  const callReprocessAPI = async () => {
    const newReprocessVideoData = {
      video_title: editedTitle,
      metrics_processing_events_history_id_list: finalId,
      sessionDetails: {
        id: otherSessionTypes[0].id,
        name: otherSessionTypes[0].name,
      },
    };

    return await commonAPI
      .get(
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          newReprocessVideoData
        )
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error('Reprocessing API request failed', error);
      });
  };

  const callStatusAPI = useCallback(async () => {
    try {
      const response = await commonAPI.get(
        ProtectedUrls.getProcessStatus.URL(finalId)
      );
      if (!response.data) {
        throw new Error('Status API request failed');
      }
      return response;
    } catch (error) {
      throw new Error('Status API request failed');
    }
  }, [finalId]);

  const CustomTabs = styled(Tabs)({
    '& MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root': {
      backgroundColor: '#8F8AFC',
    },

    '& .Mui-selected': {
      color: '#1206F9 !important',
      fontWeight: 'bold',
    },
  });

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setPerformanceTab(newValue);
  };

  const handlePreviewBack = () => {
    setIsFeedbackVisible(true);
  };

  const handleTabChange = (event: any, newValue: number) => {
    if (newValue === 2) {
      setComponentTab([2]);
    } else {
      let updatedTabs = componentTab.includes(newValue)
        ? componentTab.filter((tab) => tab !== newValue)
        : [...componentTab.filter((tab) => tab !== 2), newValue];

      if (updatedTabs.length === 0) {
        updatedTabs = [0];
      }

      setComponentTab(updatedTabs);
    }
  };

  if (reprocess) {
    return <Loader />;
  }
  return (
    <Box style={{ maxWidth: '100%', width: '100%' }}>
      {isLoadingProcessStatus ||
      processStatus[0]?.process_status < 8 ||
      isLoadingProcessDataListForParticipantsVersion2 ||
      loadingReprocess ||
      isLoadingTranscriptData ? (
        <Loader />
      ) : (
        <Grid
          container
          spacing={1}
          sx={{
            right: 0,
            width: '100%',
            height: '100%',
          }}
          alignItems='stretch'
        >
          {isMobile || isTablet ? (
            <Box sx={{ width: '100%' }}>
              <Paper
                elevation={1}
                sx={{ width: '100%', height: 'fit-content', mt: 1 }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  {!isDisable ? (
                    <>
                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          maxWidth: '15%',
                          width: '100%',
                        }}
                      >
                        {!ProcessDataListForParticipantsVersion2[0]
                          ?.is_prompt_section ? (
                          <Autocomplete
                            id='combo-box-SessionType'
                            options={otherSessionTypes}
                            optionLabelKey='name'
                            label='record.SessionType'
                            value={currentSessionType}
                            onChange={handleReprocess}
                            disabled={isDisable}
                            size='small'
                            sx={{
                              border: '#1206f9',
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=''
                                sx={{ width: '100% !important' }}
                              />
                            )}
                          />
                        ) : (
                          <Autocomplete
                            id='combo-box-SessionType'
                            options={otherSessionTypes}
                            optionLabelKey='name'
                            label='record.SessionType'
                            value={currentSessionType}
                            onChange={handleReprocess}
                            size='small'
                            sx={{
                              border: '#1206f9',
                            }}
                            disabled
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=''
                                sx={{ width: '100% !important' }}
                              />
                            )}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          maxWidth: '35%',
                          width: '100%',
                        }}
                      >
                        {isEditing ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              alignItems: 'center',
                              alignSelf: 'flex-start',
                              left: '0',
                              gap: '5px',
                            }}
                          >
                            <TextField
                              value={editedTitle}
                              size='small'
                              sx={{ width: '100%' }}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              fullWidth
                            />

                            <Box
                              onClick={handleSaveClick}
                              sx={{
                                width: 'min-content',
                                height: 'min-content',
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <SaveIcon />
                            </Box>
                            <Box
                              onClick={(event) => {
                                setIsEditing(false);
                              }}
                              sx={{
                                width: 'min-content',
                                height: 'min-content',
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <CancelSaveIcon />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',

                              alignItems: 'center',
                              alignSelf: 'flex-start',

                              p: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#33344B',
                                fontFamily: 'Poppins',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '20px',
                              }}
                              label={truncateFilename(
                                editedTitle || // Display session_title if editedTitle is null
                                  (ProcessDataListForParticipantsVersion2.length >
                                  0
                                    ? ProcessDataListForParticipantsVersion2[0]
                                        ?.session_title
                                    : ProcessDataListForParticipantsVersion2[0]
                                        ?.processing_event_name),
                                35
                              )}
                            />

                            <Box
                              sx={{
                                alignItems: 'center',
                                maxWidth: '50%',
                                width: '100%',
                                display: 'flex',
                              }}
                            >
                              {!isDisable && (
                                <>
                                  <Button
                                    sx={{
                                      borderRadius: '40px',
                                      background: '#FFF',
                                      color: '#1206F9',
                                      width: 'auto',
                                    }}
                                    onClick={handleEditClick}
                                  >
                                    <EditTitleIcon />
                                    <Typography
                                      sx={{
                                        color: '#000',
                                        fontFamily: 'Poppins',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        lineHeight: 'normal',
                                      }}
                                      label='videoPreviewAdvance.Edit'
                                    />
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        width: 'auto',
                        height: 'fit-content',
                        display: 'flex',
                        gap: 2,
                        minWidth: '40%',
                        textAlign: 'center',

                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant='text'
                        sx={{
                          backgroundColor: '#FFF',
                          width: 'auto',

                          ml: 3,
                          color: '#1206f9',
                          border: '1px solid #1206f9',
                        }}
                        onClick={handlePreviewBack}
                      >
                        Back
                      </Button>
                      <Typography
                        label='Preview.MediaPreview'
                        sx={{
                          color: '#2C2C2C',
                          fontSize: '18px',
                          fontWeight: 600,
                          paddingLeft: 2,
                        }}
                      />
                    </Box>
                  )}

                  <Box
                    sx={{
                      width: '100%',
                      height: 'fit-content',
                      display: 'flex',
                    }}
                  >
                    <VideoPreviewAdvanceFilter
                      selectedFlags={selectedFlags}
                      setSelectedFlags={setSelectedFlags}
                      selectedUserRole={selectedUserRole}
                      setSelectedUserRole={setSelectedUserRole}
                    />
                  </Box>
                </Box>
              </Paper>
              <Grid item xs={12} sm={12}>
                <VideoPlayerVersion2
                  ProcessDataListForParticipants={
                    ProcessDataListForParticipantsVersion2
                  }
                  onTimeUpdate={handleVideoTimeUpdate}
                  CompanyDetails={CompanyFolder}
                  videoPlaying={videoPlaying}
                  setVideoPlaying={setVideoPlaying}
                  tipsPlaying={tipsPlaying}
                  tipStartTime={tipStartTime}
                  videoData={ProcessDataListForParticipantsVersion2[0]}
                  currentVideoTime={currentVideoTime}
                  handleVideoTips={setTipsPlaying}
                  setTipStartTime={setTipStartTime}
                  cascadeRef={cascadeRef}
                  highlightedCardRef={highlightedCardRef}
                  vidRef={vidRef}
                  highlightedIndex={highlightedIndex}
                  setHighlightedIndex={setHighlightedIndex}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  selectedUserRole={selectedUserRole}
                  selectedFlags={selectedFlags}
                  selectedPrompt={selectedPrompt}
                  isDisable={isDisable}
                />
                <br />
                {<Divider />}
                <br />
                <TabsForPromptSection
                  selectedSummaryTab={selectedSummaryTab}
                  setSummaryTab={setSummaryTab}
                  ProcessDataListForParticipantsVersion2={
                    ProcessDataListForParticipantsVersion2
                  }
                  selectedPrompt={selectedPrompt}
                />
                {<Divider />}
                <Box
                  sx={{
                    background: '#FFF',
                    width: '100%',
                    height: 'fit-content',
                    mt: 2,
                    mb: 2,
                    display: 'inline-block',
                  }}
                >
                  <Tabs
                    value={false}
                    onChange={handleTabChange}
                    aria-label='Inline tabs example'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Tab
                      label='Analysis'
                      value={0}
                      sx={{
                        backgroundColor: componentTab.includes(0)
                          ? '#1206f9'
                          : '#F2F2F2',
                        color: componentTab.includes(0) ? '#fff' : '#000',
                        fontWeight: 700,
                        flexGrow: 1,
                        padding: '5.75px 5.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',
                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',
                        textAlign: 'center',
                      }}
                    />
                    <Tab
                      label='Performance'
                      value={1}
                      sx={{
                        backgroundColor: componentTab.includes(1)
                          ? '#1206f9'
                          : '#F2F2F2',
                        color: componentTab.includes(1) ? '#fff' : '#000',
                        fontWeight: 700,
                        flexGrow: 1,
                        padding: '5.75px 5.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',
                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',
                        textAlign: 'center',
                      }}
                    />
                    <Tab
                      label='Transcript'
                      value={2}
                      sx={{
                        backgroundColor: componentTab.includes(2)
                          ? '#1206f9'
                          : '#F2F2F2',
                        color: componentTab.includes(2) ? '#fff' : '#000',
                        fontWeight: 700,
                        flexGrow: 1,
                        padding: '5.75px 5.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',
                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',
                        textAlign: 'center',
                      }}
                    />
                  </Tabs>
                </Box>

                {componentTab.includes(0) && (
                  <>
                    {ProcessDataListForParticipantsVersion2[0]
                      ?.is_prompt_section && (
                      <Box
                        sx={{
                          background: '#FFF',
                          width: '100%',
                          height: 'fit-content',
                          mt: 1,
                        }}
                      >
                        <PromptsPane
                          Data={
                            ProcessDataListForParticipantsVersion2[0]
                              ?.prompt_sections || []
                          }
                          setSelectedPrompt={setSelectedPrompt}
                          selectedPrompt={selectedPrompt}
                          SessionId={
                            ProcessDataListForParticipantsVersion2[0]
                              ?.session_type_id
                          }
                        />
                      </Box>
                    )}
                    <TabsForTranscriptAndTips
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                    {selectedTab === 0 && (
                      <TipCascadeVersion2
                        videoData={ProcessDataListForParticipantsVersion2[0]}
                        currentVideoTime={currentVideoTime}
                        ProcessDataListForParticipants={
                          ProcessDataListForParticipantsVersion2
                        }
                        videoPlaying={videoPlaying}
                        handleVideoTips={setTipsPlaying}
                        setTipStartTime={setTipStartTime}
                        cascadeRef={cascadeRef}
                        highlightedCardRef={highlightedCardRef}
                        vidRef={vidRef}
                        highlightedIndex={highlightedIndex}
                        setHighlightedIndex={setHighlightedIndex}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        selectedUserRole={selectedUserRole}
                        selectedFlags={selectedFlags}
                        selectedPrompt={selectedPrompt}
                      />
                    )}
                  </>
                )}
                {<Divider />}
                {componentTab.includes(1) && (
                  <TabsForOverallAndPromptPerformance
                    selectedPerformanceTab={selectedPerformanceTab}
                    setPerformanceTab={setPerformanceTab}
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                    selectedPrompt={selectedPrompt}
                    selectedUserRole={selectedUserRole}
                    selectedFlags={selectedFlags}
                  />
                )}
                {componentTab.includes(2) && (
                  <Paper
                    sx={{
                      background: '#FFF',
                      width: '100%',
                      height: 'fit-content',
                      top: '0',
                      bottom: '0',
                      mb: 1,

                      p: 2,
                    }}
                    elevation={2}
                  >
                    <TranscriptComponent Data={Data} />
                  </Paper>
                )}
              </Grid>
            </Box>
          ) : (
            <>
              <Paper
                elevation={1}
                sx={{ width: '100%', height: 'fit-content', mt: 2, p: 2 }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  {!isDisable ? (
                    <>
                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          maxWidth: '15%',
                          width: '100%',
                        }}
                      >
                        {!ProcessDataListForParticipantsVersion2[0]
                          ?.is_prompt_section ? (
                          <Autocomplete
                            id='combo-box-SessionType'
                            options={otherSessionTypes}
                            optionLabelKey='name'
                            label='record.SessionType'
                            value={currentSessionType}
                            onChange={handleReprocess}
                            disabled={isDisable}
                            size='small'
                            sx={{
                              border: '#1206f9',
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=''
                                sx={{ width: '100% !important' }}
                              />
                            )}
                          />
                        ) : (
                          <Autocomplete
                            id='combo-box-SessionType'
                            options={otherSessionTypes}
                            optionLabelKey='name'
                            label='record.SessionType'
                            value={currentSessionType}
                            onChange={handleReprocess}
                            size='small'
                            sx={{
                              border: '#1206f9',
                            }}
                            disabled
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=''
                                sx={{ width: '100% !important' }}
                              />
                            )}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          maxWidth: '35%',
                          width: '100%',
                        }}
                      >
                        {isEditing ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              justifyItems: 'center',
                              alignItems: 'center',
                              alignSelf: 'flex-start',
                              left: '0',
                              gap: '5px',
                            }}
                          >
                            <TextField
                              value={editedTitle}
                              size='small'
                              sx={{ width: '100%' }}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              fullWidth
                            />

                            <Box
                              onClick={handleSaveClick}
                              sx={{
                                width: 'min-content',
                                height: 'min-content',
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <SaveIcon />
                            </Box>
                            <Box
                              onClick={(event) => {
                                setIsEditing(false);
                              }}
                              sx={{
                                width: 'min-content',
                                height: 'min-content',
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <CancelSaveIcon />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',

                              alignItems: 'center',
                              alignSelf: 'flex-start',

                              p: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#33344B',
                                fontFamily: 'Poppins',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '20px',
                              }}
                              label={truncateFilename(
                                editedTitle || // Display session_title if editedTitle is null
                                  (ProcessDataListForParticipantsVersion2.length >
                                  0
                                    ? ProcessDataListForParticipantsVersion2[0]
                                        ?.session_title
                                    : ProcessDataListForParticipantsVersion2[0]
                                        ?.processing_event_name),
                                35
                              )}
                            />

                            <Box
                              sx={{
                                alignItems: 'center',
                                maxWidth: '50%',
                                width: '100%',
                                display: 'flex',
                              }}
                            >
                              {!isDisable && (
                                <>
                                  <Button
                                    sx={{
                                      borderRadius: '40px',
                                      background: '#FFF',
                                      color: '#1206F9',
                                      width: 'auto',
                                    }}
                                    onClick={handleEditClick}
                                  >
                                    <EditTitleIcon />
                                    <Typography
                                      sx={{
                                        color: '#000',
                                        fontFamily: 'Poppins',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        lineHeight: 'normal',
                                      }}
                                      label='videoPreviewAdvance.Edit'
                                    />
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        width: 'auto',
                        height: 'fit-content',
                        display: 'flex',
                        gap: 2,
                        minWidth: '40%',
                        textAlign: 'center',

                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant='text'
                        sx={{
                          backgroundColor: '#FFF',
                          width: 'auto',
                          ml: 3,
                          color: '#1206f9',
                          border: '1px solid #1206f9',
                        }}
                        onClick={handlePreviewBack}
                      >
                        Back
                      </Button>
                      <Typography
                        label='Preview.MediaPreview'
                        sx={{
                          color: '#2C2C2C',
                          fontSize: '18px',
                          fontWeight: 600,
                          paddingLeft: 2,
                        }}
                      />
                    </Box>
                  )}

                  <Box
                    sx={{
                      width: '100%',
                      height: 'fit-content',
                      display: 'flex',
                    }}
                  >
                    <VideoPreviewAdvanceFilter
                      selectedFlags={selectedFlags}
                      setSelectedFlags={setSelectedFlags}
                      selectedUserRole={selectedUserRole}
                      setSelectedUserRole={setSelectedUserRole}
                    />
                  </Box>
                </Box>
              </Paper>
              <Grid item md={6} sx={{ p: 1 }}>
                <VideoPlayerVersion2
                  ProcessDataListForParticipants={
                    ProcessDataListForParticipantsVersion2
                  }
                  onTimeUpdate={handleVideoTimeUpdate}
                  CompanyDetails={CompanyFolder}
                  videoPlaying={videoPlaying}
                  setVideoPlaying={setVideoPlaying}
                  tipsPlaying={tipsPlaying}
                  tipStartTime={tipStartTime}
                  videoData={ProcessDataListForParticipantsVersion2[0]}
                  currentVideoTime={currentVideoTime}
                  handleVideoTips={setTipsPlaying}
                  setTipStartTime={setTipStartTime}
                  cascadeRef={cascadeRef}
                  highlightedCardRef={highlightedCardRef}
                  vidRef={vidRef}
                  highlightedIndex={highlightedIndex}
                  setHighlightedIndex={setHighlightedIndex}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  selectedUserRole={selectedUserRole}
                  selectedFlags={selectedFlags}
                  selectedPrompt={selectedPrompt}
                  isDisable={isDisable}
                />

                <TabsForPromptSection
                  selectedSummaryTab={selectedSummaryTab}
                  setSummaryTab={setSummaryTab}
                  ProcessDataListForParticipantsVersion2={
                    ProcessDataListForParticipantsVersion2
                  }
                  selectedPrompt={selectedPrompt}
                />
              </Grid>
              {componentTab.includes(0) && (
                <Grid
                  alignItems='stretch'
                  md={componentTab.length === 1 ? 5.5 : 3}
                  sx={{
                    marginRight: 0,
                    gap: 2,
                    height: 'auto',
                  }}
                >
                  <Paper
                    sx={{
                      flexGrow: 1,
                      background: '#FFF',
                      border: '1px solid #D4D4D4',
                      width: '100%',
                      minHeight: '130vh',

                      height: 'fit-content',

                      top: '0',
                      bottom: '0',
                      p: 1,
                      mt: 1,
                    }}
                    elevation={2}
                  >
                    {ProcessDataListForParticipantsVersion2[0]
                      ?.is_prompt_section && (
                      <Box
                        sx={{
                          background: '#FFF',
                          width: '100%',
                          height: 'fit-content',
                          mt: 1,
                        }}
                      >
                        <PromptsPane
                          Data={
                            ProcessDataListForParticipantsVersion2[0]
                              ?.prompt_sections || []
                          }
                          setSelectedPrompt={setSelectedPrompt}
                          selectedPrompt={selectedPrompt}
                          SessionId={
                            ProcessDataListForParticipantsVersion2[0]
                              ?.session_type_id
                          }
                        />
                      </Box>
                    )}

                    <Box
                      sx={{
                        background: '#FFF',
                        width: '100%',
                        height: 'fit-content',
                      }}
                    >
                      <TipCascadeVersion2
                        videoData={ProcessDataListForParticipantsVersion2[0]}
                        currentVideoTime={currentVideoTime}
                        ProcessDataListForParticipants={
                          ProcessDataListForParticipantsVersion2
                        }
                        videoPlaying={videoPlaying}
                        handleVideoTips={setTipsPlaying}
                        setTipStartTime={setTipStartTime}
                        cascadeRef={cascadeRef}
                        highlightedCardRef={highlightedCardRef}
                        vidRef={vidRef}
                        highlightedIndex={highlightedIndex}
                        setHighlightedIndex={setHighlightedIndex}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        selectedUserRole={selectedUserRole}
                        selectedFlags={selectedFlags}
                        selectedPrompt={selectedPrompt}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}
              {componentTab.includes(1) && (
                <Grid
                  item
                  md={componentTab.length === 1 ? 5.5 : 2.5}
                  sx={{ marginLeft: 0 }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      border: '1px solid #D4D4D4',
                      background: '#FFF',
                      minHeight: '130vh',
                      width: '100%',
                      height: 'fit-content',
                      justifyContent: 'center',
                    }}
                  >
                    <TabsForOverallAndPromptPerformance
                      selectedPerformanceTab={selectedPerformanceTab}
                      setPerformanceTab={setPerformanceTab}
                      ProcessDataListForParticipantsVersion2={
                        ProcessDataListForParticipantsVersion2
                      }
                      selectedPrompt={selectedPrompt}
                      selectedUserRole={selectedUserRole}
                      selectedFlags={selectedFlags}
                    />
                  </Paper>
                </Grid>
              )}

              {componentTab.includes(2) && (
                <Grid item md={5.5} sx={{ marginRight: 0, gap: 2 }}>
                  <Paper
                    sx={{
                      background: '#FFF',
                      minHeight: '130vh',
                      width: '100%',
                      height: 'fit-content',
                      top: '0',
                      bottom: '0',
                      mb: 1,

                      p: 3,
                    }}
                    elevation={2}
                  >
                    <TranscriptComponent Data={Data} />
                  </Paper>
                </Grid>
              )}

              <Grid item md={0.5}>
                <Tabs
                  orientation='vertical'
                  value={false}
                  onChange={handleTabChange}
                  aria-label='Vertical tabs example'
                  sx={{
                    borderRight: 1,

                    border: 'none',

                    alignItems: 'flex-start',
                    textAlign: 'left',
                    width: '50px',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Tab
                    label='Analysis'
                    value={0}
                    sx={{
                      position: 'relative',
                      right: 20,
                      writingMode: 'vertical-lr',
                      textOrientation: 'updown',
                      backgroundColor: componentTab.includes(0)
                        ? '#1206f9'
                        : '#F2F2F2',
                      color: componentTab.includes(0) ? '#fff' : '#000',
                      fontWeight: 700,
                      width: 'auto',
                      height: 'auto',
                      padding: '10.75px 35.47px',
                      borderRadius: '4.3px 4.3px 0px 0px',

                      borderBottom: 'none',
                      opacity: 'var(--Tabs)',

                      textAlign: 'left',
                      justifyContent: 'flex-start',
                      display: 'flex',
                      marginBottom: '10px',
                      minHeight: '150px',
                    }}
                  />
                  <Tab
                    label='Performance'
                    value={1}
                    sx={{
                      position: 'relative',
                      right: 20,
                      writingMode: 'vertical-lr',
                      textOrientation: 'updown',
                      backgroundColor: componentTab.includes(1)
                        ? '#1206f9'
                        : '#F2F2F2',
                      color: componentTab.includes(1) ? '#fff' : '#000',
                      fontWeight: 700,
                      width: 'auto',
                      height: 'auto',
                      padding: '10.75px 35.47px',
                      borderRadius: '4.3px 4.3px 0px 0px',

                      borderBottom: 'none',
                      opacity: 'var(--Tabs)',

                      textAlign: 'left',
                      justifyContent: 'flex-start',
                      display: 'flex',
                      marginBottom: '10px',
                      minHeight: '150px',
                    }}
                  />

                  <Tab
                    label='Transcript'
                    value={2}
                    sx={{
                      position: 'relative',
                      right: 20,
                      writingMode: 'vertical-lr',
                      textOrientation: 'updown',
                      backgroundColor: componentTab.includes(2)
                        ? '#1206f9'
                        : '#F2F2F2',
                      color: componentTab.includes(2) ? '#fff' : '#000',
                      fontWeight: 700,
                      width: 'auto',
                      height: 'auto',
                      padding: '10.75px 35.47px',
                      borderRadius: '4.3px 4.3px 0px 0px',

                      borderBottom: 'none',
                      opacity: 'var(--Tabs)',

                      textAlign: 'left',
                      justifyContent: 'flex-start',
                      display: 'flex',
                      marginBottom: '10px',
                      minHeight: '150px',
                    }}
                  />
                </Tabs>
              </Grid>
            </>
          )}
        </Grid>
      )}
      {show && (
        <SessionChangeModal
          setShow={setShow}
          setReprocess={setReprocess}
          handleYesReprocess={handleYesReprocess}
        />
      )}
      {isDisable && isFeedbackVisible && (
        <VisitorFeedback
          Onopen={isFeedbackVisible}
          onClose={() => {
            setIsFeedbackVisible(false);
            setTipsPlaying('false');
          }}
        />
      )}
    </Box>
  );
}
export default VideoPreviewAdvanceVersion2;
