/** @format */

import axios from 'axios';

import env from 'envConfig';

const commonAPI = axios.create({
  baseURL: env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
commonAPI.interceptors.request.use((config) => {
  const apiKey = sessionStorage.getItem('apiKey');

  if (apiKey) {
    config.headers['x-api-key'] = apiKey;
  }

  return config;
});

export default commonAPI;
