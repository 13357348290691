import { createSlice } from '@reduxjs/toolkit';
import {
  GetAssessmentListInitialStateType,
  createAssessmentInitialStateTypes,
} from '../../../types/DeepRecruiterTypes/SelectAssessment-types';

const GetAssessmentListInitialState: GetAssessmentListInitialStateType = {
  isLoadingGetAssessmentList: false,
  getAssessmentList: [],
  errorGetAssessmentList: false,
};

const getAssessmentListSlice = createSlice({
  name: ' getPositionIdentifierSlice',
  initialState: GetAssessmentListInitialState,
  reducers: {
    getAssessmentListLoad(state) {
      state.isLoadingGetAssessmentList = true;
    },
    getAssessmentListSuccess(state, action) {
      state.isLoadingGetAssessmentList = false;
      state.getAssessmentList = action.payload;
    },
    getAssessmentListFailure(state, action) {
      state.isLoadingGetAssessmentList = false;
      state.errorGetAssessmentList = action.payload;
    },
  },
});

export const {
  getAssessmentListLoad,
  getAssessmentListSuccess,
  getAssessmentListFailure,
} = getAssessmentListSlice.actions;

export { getAssessmentListSlice };

const createAssessmentInitialState: createAssessmentInitialStateTypes = {
  isLoadingCreateAssessment: false,
  createAssignment: [],
  errorCreateAssessment: false,
};

const createAssignmentSlice = createSlice({
  name: 'createAssignmentSlice',
  initialState: createAssessmentInitialState,
  reducers: {
    createAssignmentLoad(state) {
      state.isLoadingCreateAssessment = true;
    },
    createAssignmentSuccess(state, action) {
      state.isLoadingCreateAssessment = false;
      state.createAssignment = action.payload;
    },
    createAssignmentFailure(state, action) {
      state.isLoadingCreateAssessment = false;
      state.errorCreateAssessment = action.payload;
    },
  },
});

export const {
  createAssignmentLoad,
  createAssignmentSuccess,
  createAssignmentFailure,
} = createAssignmentSlice.actions;

export { createAssignmentSlice };
