import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QuestionVideoPlayer from './QuestionVideoPlayer';
import Loader from 'widgets/Loader/Loader';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import env from 'envConfig';
import YoutubeSpinner from './Spinner';

function VideoQuestionaire({
  question,
  currentQuestionIndex,
  status,
}: {
  question: QuestionnaireData;
  currentQuestionIndex: number;
  status: string;
}) {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string>('');

  useEffect(() => {
    if (question.media_link) {
      setVideoUrl(`${env.REACT_APP_BASE_URL}/${question.media_link}`);
      setIsVideoLoading(true);
    } else {
      setIsVideoLoading(false);
    }
  }, [question]);

  const handleVideoLoaded = () => {
    setIsVideoLoading(false);
  };

  return (
    <Paper>
      <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        {isVideoLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1,
            }}
          >
            <YoutubeSpinner />
          </Box>
        )}

        <QuestionVideoPlayer
          key={videoUrl}
          questionData={{
            question: question.question,
            questionNum: currentQuestionIndex,
            followUpQuestions: question.description,
          }}
          url={videoUrl}
          onCanPlay={handleVideoLoaded}
          isVideoLoading={isVideoLoading}
          status={status}
        />
      </Box>
    </Paper>
  );
}

export default VideoQuestionaire;
