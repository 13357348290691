import { createSlice } from '@reduxjs/toolkit';
import { ForgetPasswordInitialStateTypes } from 'types/common/forgetpassword-types';

const ForgetPasswordInitialState: ForgetPasswordInitialStateTypes = {
  isLoadingForgetPassword: false,
  errorForgetPassword: '',
  ForgetPasswordData: {
    userHash: '',
    newPassword: '',
  },
};

const setForgetPasswordSlice = createSlice({
  name: 'api',
  initialState: ForgetPasswordInitialState,
  reducers: {
    getForgetPasswordLoad(state) {
      state.isLoadingForgetPassword = true;
    },
    getForgetPasswordSuccess(state, action) {
      state.isLoadingForgetPassword = false;
      state.ForgetPasswordData = action.payload;
    },
    getForgetPasswordFailure(state, action) {
      state.isLoadingForgetPassword = false;
      state.errorForgetPassword = action.payload;
    },
  },
});

export const {
  getForgetPasswordLoad,
  getForgetPasswordSuccess,
  getForgetPasswordFailure,
} = setForgetPasswordSlice.actions;
export { setForgetPasswordSlice };
