import { i18n } from 'i18n/i18n';
import { AppThunk } from 'redux/store';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';
import { ProtectedUrls, PublicUrls } from 'routes/urls';
import {
  getAIPermissionsFailure,
  getAIPermissionsLoad,
  getAIPermissionsSuccess,
} from 'redux/slices/UploadMedia-slice';

export const getAiPermissionServiceApi = (): AppThunk => async (dispatch) => {
  dispatch(getAIPermissionsLoad());

  await commonAPI
    .get(ProtectedUrls.getAIPermissions.url())
    .then((response) => {
      dispatch(getAIPermissionsSuccess(response.data));

      if (response.data) {
      } else {
        Toast(response.data.error, 'error');
      }
    })
    .catch((error) => {
      Toast(error.response.data.error, 'error');
      dispatch(
        getAIPermissionsFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    });
};
