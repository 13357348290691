/** @format */

import { createSlice } from '@reduxjs/toolkit';
import {
  LoginDataInitialStateTypes,
  MicrosoftLoginDataInitialStateTypes,
  MicrosoftSignUpDataInitialStateTypes,
  SignUpDataInitialStateTypes,
} from 'types/Auth/Auth-types';

const loginDataInitialState: LoginDataInitialStateTypes = {
  isLoadingLoginData: false,
  errorLoginData: '',
  loginData: [
    {
      username: '',
      id: -1,
      apikey: '',
      planid: -1,
      first_name: '',
      last_name: '',
      is_verified: false,
      version_number: '',
      tos_id: -1,
      cookie_accepted: false,
      role: '',
      resolution: -1,
    },
  ],
};
const getLoginDataSlice = createSlice({
  name: 'api',
  initialState: loginDataInitialState,
  reducers: {
    loginDataLoad(state) {
      state.isLoadingLoginData = true;
    },
    loginDataSuccess(state, action) {
      state.isLoadingLoginData = false;
      state.loginData = action.payload;
    },
    loginDataFailure(state, action) {
      state.isLoadingLoginData = false;
      state.errorLoginData = action.payload;
    },
  },
});

const signUpDataInitialState: SignUpDataInitialStateTypes = {
  isLoadingSignUpData: false,
  errorSignUpData: '',
  signUpData: [],
};
const getSignUpDataSlice = createSlice({
  name: 'api',
  initialState: signUpDataInitialState,
  reducers: {
    signUpDataLoad(state) {
      state.isLoadingSignUpData = true;
    },
    signUpDataSuccess(state, action) {
      state.isLoadingSignUpData = false;
      state.signUpData = action.payload;
    },
    signUpDataFailure(state, action) {
      state.isLoadingSignUpData = false;
      state.errorSignUpData = action.payload;
    },
  },
});

export const { loginDataLoad, loginDataSuccess, loginDataFailure } =
  getLoginDataSlice.actions;
export { getLoginDataSlice };
export const { signUpDataLoad, signUpDataSuccess, signUpDataFailure } =
  getSignUpDataSlice.actions;
export { getSignUpDataSlice };

/** @format */

const microsoftLoginDataInitialState: MicrosoftLoginDataInitialStateTypes = {
  isLoadingMicrosoftLoginData: false,
  errorMicrosoftLoginData: '',
  microsoftLoginData: [],
};
const getMicrosoftLoginDataSlice = createSlice({
  name: 'getMicrosoftLoginDataSlice',
  initialState: microsoftLoginDataInitialState,
  reducers: {
    microsoftLoginDataLoad(state) {
      state.isLoadingMicrosoftLoginData = true;
    },
    microsoftLoginDataSuccess(state, action) {
      state.isLoadingMicrosoftLoginData = false;
      state.microsoftLoginData = action.payload;
    },
    microsoftLoginDataFailure(state, action) {
      state.isLoadingMicrosoftLoginData = false;
      state.errorMicrosoftLoginData = action.payload;
    },
  },
});

const microsoftMicrosoftSignUpDataInitialState: MicrosoftSignUpDataInitialStateTypes =
  {
    isLoadingMicrosoftSignUpData: false,
    errorMicrosoftSignUpData: '',
    microsoftSignUpData: [],
  };
const getMicrosoftSignUpDataSlice = createSlice({
  name: 'getMicrosoftSignUpDataSlice',
  initialState: microsoftMicrosoftSignUpDataInitialState,
  reducers: {
    microsoftSignUpDataLoad(state) {
      state.isLoadingMicrosoftSignUpData = true;
    },
    microsoftSignUpDataSuccess(state, action) {
      state.isLoadingMicrosoftSignUpData = false;
      state.microsoftSignUpData = action.payload;
    },
    microsoftSignUpDataFailure(state, action) {
      state.isLoadingMicrosoftSignUpData = false;
      state.errorMicrosoftSignUpData = action.payload;
    },
  },
});

export const {
  microsoftLoginDataLoad,
  microsoftLoginDataSuccess,
  microsoftLoginDataFailure,
} = getMicrosoftLoginDataSlice.actions;

export { getMicrosoftLoginDataSlice };
export const {
  microsoftSignUpDataLoad,
  microsoftSignUpDataSuccess,
  microsoftSignUpDataFailure,
} = getMicrosoftSignUpDataSlice.actions;
export { getMicrosoftSignUpDataSlice };

interface GetAuthProviderDetailsInitialStateTypes {
  authProviderDetailsList: {
    Google?: number;
    Microsoft?: number;
    Personal?: number;
  };
  isLoadingGetAuthProviderDetails: Boolean;
  errorGetAuthProviderDetails: Boolean;
}

const getAuthProviderDetailsInitialState: GetAuthProviderDetailsInitialStateTypes =
  {
    authProviderDetailsList: {},
    isLoadingGetAuthProviderDetails: false,
    errorGetAuthProviderDetails: false,
  };
const getAuthProviderDetailsSlice = createSlice({
  name: 'getAuthProviderDetailsSlice',
  initialState: getAuthProviderDetailsInitialState,
  reducers: {
    getAuthProviderDetailsLoad(state) {
      state.isLoadingGetAuthProviderDetails = true;
    },
    getAuthProviderDetailsSuccess(state, action) {
      state.isLoadingGetAuthProviderDetails = false;
      state.authProviderDetailsList = action.payload;
    },
    getAuthProviderDetailsFailure(state, action) {
      state.isLoadingGetAuthProviderDetails = false;
      state.errorGetAuthProviderDetails = action.payload;
    },
  },
});

export const {
  getAuthProviderDetailsLoad,
  getAuthProviderDetailsSuccess,
  getAuthProviderDetailsFailure,
} = getAuthProviderDetailsSlice.actions;

export { getAuthProviderDetailsSlice };
const requestProfileDataInitialState = {
  isLoadingProfileData: false,
  profileData: [],
  errorProfileData: '',
};

const requestProfileDataSlice = createSlice({
  name: 'profile',
  initialState: requestProfileDataInitialState,
  reducers: {
    requestProfileDataLoad(state) {
      state.isLoadingProfileData = true;
    },
    requestProfileDataSuccess(state, action) {
      state.isLoadingProfileData = false;
      state.profileData = action.payload;
    },
    requestProfileDataFailure(state, action) {
      state.isLoadingProfileData = false;
      state.errorProfileData = action.payload;
    },
  },
});
export const {
  requestProfileDataLoad,
  requestProfileDataSuccess,
  requestProfileDataFailure,
} = requestProfileDataSlice.actions;

export { requestProfileDataSlice };
