/** @format */

import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import React from 'react';
import Loader from 'widgets/Loader/Loader';
import { OpenRoutes } from 'routes/routes';

const PreviewRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAuthenticated = sessionStorage.getItem('isOTPAuthenticated');

  if (!Component) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={OpenRoutes.MirroSandboxRoute} />
        )
      }
    />
  );
};

export default PreviewRoute;
