/** @format */

import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import Typography from 'widgets/Typography/Typography';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; isCanceled?: boolean }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        sx={{ color: props.isCanceled ? 'red' : '' }}
        variant='determinate'
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color={props.isCanceled ? 'red' : 'inherit'}
          label=''
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
