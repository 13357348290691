/** @format */

import { createSlice } from '@reduxjs/toolkit';

const GoogleSSOInitialState = {
  isLoadingGoogleSSO: false,
  errorGoogleSSO: '',
  GoogleSSOData: {},
};

const GoogleSSOSlice = createSlice({
  name: 'googleSSO',
  initialState: GoogleSSOInitialState,
  reducers: {
    googleSSOLoad(state) {
      state.isLoadingGoogleSSO = true;
    },
    googleSSOSuccess(state, action) {
      state.isLoadingGoogleSSO = false;
      state.GoogleSSOData = action.payload;
    },
    googleSSOFailure(state, action) {
      state.isLoadingGoogleSSO = false;
      state.errorGoogleSSO = action.payload;
    },
  },
});

export const { googleSSOLoad, googleSSOSuccess, googleSSOFailure } =
  GoogleSSOSlice.actions;

export { GoogleSSOSlice };

const GoogleSSOSignInInitialState = {
  isLoadingGoogleSSOSignIn: false,
  errorGoogleSSOSignIn: '',
  GoogleSSOSignInData: {},
};

const GoogleSSOSignInSlice = createSlice({
  name: 'GoogleSSOSignInSlice',
  initialState: GoogleSSOSignInInitialState,
  reducers: {
    googleSSOSignInLoad(state) {
      state.isLoadingGoogleSSOSignIn = true;
    },
    googleSSOSignInSuccess(state, action) {
      state.isLoadingGoogleSSOSignIn = false;
      state.GoogleSSOSignInData = action.payload;
    },
    googleSSOSignInFailure(state, action) {
      state.isLoadingGoogleSSOSignIn = false;
      state.errorGoogleSSOSignIn = action.payload;
    },
  },
});
export const {
  googleSSOSignInLoad,
  googleSSOSignInSuccess,
  googleSSOSignInFailure,
} = GoogleSSOSignInSlice.actions;

export { GoogleSSOSignInSlice };
