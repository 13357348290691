import { createSlice } from '@reduxjs/toolkit';
import { EmotionInitialTypes } from 'types/common/Emotion-types';

const EmotionInitialState: EmotionInitialTypes = {
  isLoadingEmotionType: false,
  errorEmotionType: '',
  EmotionTypeList: [],
};

const getEmotionTypeSlice = createSlice({
  name: 'getEmotionTypeList',
  initialState: EmotionInitialState,
  reducers: {
    EmotionTypeLoad(state) {
      state.isLoadingEmotionType = true;
    },
    EmotionTypeSuccess(state, action) {
      state.isLoadingEmotionType = false;
      state.EmotionTypeList = action.payload;
    },
    EmotionTypeFailure(state, action) {
      state.isLoadingEmotionType = false;
      state.errorEmotionType = action.payload;
    },
  },
});

export const { EmotionTypeLoad, EmotionTypeSuccess, EmotionTypeFailure } =
  getEmotionTypeSlice.actions;
export { getEmotionTypeSlice };
