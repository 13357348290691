import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import CommonRecorderSettings from 'shared/CommonRecorderSettings/CommonRecorderSettings';
import { AdvancedSettingsOptions } from 'types/Record-types';
import {
  CandidateDetailsType,
  MandatoryDataForCandidateAssessmentSubmissionType,
} from 'types/SendAssessments-types';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import generateCandidateVideoDesc from 'utils/generateCandidateVideoDesc';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';

function RecorderSettingsForAssessment({
  handleTogglePromptSection,
  setMainRecordingSettings,
  isFromCandidateAssessment,
  mandatoryDataForCandidateAssessmentSubmission,
  candidateDetails,
}: {
  handleTogglePromptSection: () => void;
  setMainRecordingSettings: Function;
  isFromCandidateAssessment: boolean;
  mandatoryDataForCandidateAssessmentSubmission: MandatoryDataForCandidateAssessmentSubmissionType;
  candidateDetails: CandidateDetailsType;
}) {
  const initialValuesForRecordings: CamerasType | MicrophonesType = {
    deviceId: '',
    groupId: '',
    kind: '',
    label: '',
  };
  const [videoDesc, setVideoDesc] = useState(
    isFromCandidateAssessment
      ? generateCandidateVideoDesc(candidateDetails)
      : getDefaultVideoDescription()
  );

  const [selectedAssessmentType, setSelectedAssessmentType] = useState<{
    id: number;
    name: string;
    type: string;
    description: string;
  }>({
    id: -1,
    name: '',
    type: '',
    description: '',
  });

  useEffect(() => {
    if (
      isFromCandidateAssessment &&
      mandatoryDataForCandidateAssessmentSubmission
    ) {
      setSelectedAssessmentType(
        mandatoryDataForCandidateAssessmentSubmission.selectedAssessmentType
      );
    }
  }, [
    isFromCandidateAssessment,
    mandatoryDataForCandidateAssessmentSubmission,
  ]);
  const [recordingType, setRecordingType] = useState<{
    id: number;
    name: string;
  }>({ id: 1, name: t('record.Video') });
  const [advancedSettingsOptions, setAdvancedSettingsOptions] =
    useState<AdvancedSettingsOptions>({
      controlMenu: true,
      recordingCountdown: true,
      showRecordingTime: true,
    });
  const [selectedCamera, setSelectedCamera] = useState<CamerasType>(
    initialValuesForRecordings
  );

  const [selectedMicrophone, setSelectedMicrophone] = useState<MicrophonesType>(
    initialValuesForRecordings
  );
  const status = '';

  useEffect(() => {
    setMainRecordingSettings({
      videoDescription: videoDesc,
      asessmentType: selectedAssessmentType,
      recordingType: recordingType,
      cameraDetails: selectedCamera,
      microphoneDetails: selectedMicrophone,
    });
  }, [
    videoDesc,
    selectedAssessmentType,
    recordingType,
    selectedCamera,
    selectedMicrophone,
  ]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CommonRecorderSettings
        setVideoTitleDescription={setVideoDesc}
        videoTitleDescription={videoDesc}
        setSelectedAssessmentType={setSelectedAssessmentType}
        selectedAssessmentType={selectedAssessmentType}
        setRecordingType={setRecordingType}
        recordingType={recordingType}
        setSelectedCamera={setSelectedCamera}
        selectedCamera={selectedCamera}
        setSelectedMicrophone={setSelectedMicrophone}
        selectedMicrophone={selectedMicrophone}
        setAdvancedSettingsOptions={setAdvancedSettingsOptions}
        advancedSettingsOptions={advancedSettingsOptions}
        mediaRecorderStatus={status}
        isMyAssessment={true}
        handleTogglePromptSection={handleTogglePromptSection}
        isFromCandidateAssessment={isFromCandidateAssessment}
        candidateDetails={candidateDetails}
      />
    </Box>
  );
}

export default RecorderSettingsForAssessment;
