import {
  Paper,
  Grid,
  Box,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import Loader from 'widgets/Loader/Loader';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import {
  AttendeeRecordForDashboard,
  FilteredProcessDataList,
} from 'types/Dashboard-types';
import NoDataFoundForSelectedFilters from 'widgets/NoDataFoundForSelectedFilters';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface FlagData {
  name: string;
  value: number;
  color: string;
}

function FlagsWidget({
  processDataListWithSession,
  filteredDataWithInputs,
}: {
  processDataListWithSession: Array<ProcessDataListVersion2>;
  filteredDataWithInputs: FilteredProcessDataList[];
}) {
  const [activeChart, setActiveChart] = useState('chart1');
  const [flagCount, setFlagCount] = useState(0);

  const [totalGreenZoneTime, setTotalGreenZoneTime] = useState(0);
  const [totalYellowZoneTime, setTotalYellowZoneTime] = useState(0);
  const [totalRedZoneTime, setTotalRedZoneTime] = useState(0);
  const [flagDataTimeCount, setFlagDataTimeCount] = useState<FlagData[]>([]);

  const [totalTalkTime, setTotalTalkTime] = useState(0);
  const [flagData, setFlagData] = useState([
    { label: 'Red', value: totalRedZoneTime, color: 'red' }, //totalRedZoneTime
    {
      label: 'Yellow ',
      value: totalYellowZoneTime, //totalYellowZoneTime
      color: 'yellow',
    },
    {
      name: 'Green',
      value: totalGreenZoneTime, // Use totalTalkTime as the value for "Green Flags" totalGreenZoneTime
      color: 'green',
    },
  ]);

  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const handleChartSwitch = (chartName: string) => {
    setActiveChart(chartName);
  };

  const formatSecondsToMinSec = (seconds: number): number => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let result;

    // Round differently based on the condition
    if (remainingSeconds > 30) {
      result = Math.ceil(
        parseFloat(`${minutes}.${remainingSeconds.toFixed(2)}`)
      );
    } else {
      result = Math.floor(
        parseFloat(`${minutes}.${remainingSeconds.toFixed(2)}`)
      );
    }

    return result;
  };

  useEffect(() => {
    const filteredData =
      filteredDataWithInputs.length > 0
        ? filteredDataWithInputs[0]?.FilteredData
        : processDataListWithSession;

    if (filteredData && filteredData.length > 0) {
      const filteredTotalFlagsScore = filteredData.reduce(
        (accumulator: number, data: ProcessDataListVersion2) =>
          accumulator + data.red_flags + data.yellow_flags + data.green_flags,
        0
      );

      setFlagCount(() => filteredTotalFlagsScore);

      const filteredTotalFlags = filteredData.reduce(
        (
          accumulator: { red: number; yellow: number; green: number },
          data: ProcessDataListVersion2
        ) => {
          return {
            red: accumulator.red + data.red_flags,
            yellow: accumulator.yellow + data.yellow_flags,
            green: accumulator.green + data.green_flags,
          };
        },
        { red: 0, yellow: 0, green: 0 }
      );
      setFlagData([
        { label: 'Red', value: filteredTotalFlags.red, color: '#D62D2D' },
        {
          label: 'Yellow',
          value: filteredTotalFlags.yellow,
          color: '#FFCA28',
        },
        {
          label: 'Green',
          value: filteredTotalFlags.green,
          color: '#62C960',
        },
      ]);
    }
  }, [filteredDataWithInputs, processDataListWithSession]);

  useEffect(() => {
    const calculateTotals = (records: Array<AttendeeRecordForDashboard>) => {
      let greenZoneTime = 0;
      let yellowZoneTime = 0;
      let redZoneTime = 0;

      records.forEach((record: AttendeeRecordForDashboard) => {
        greenZoneTime += record.talk_time_green_zone;
        yellowZoneTime += record.talk_time_yellow_zone;
        redZoneTime += record.talk_time_red_zone;
      });

      return {
        totalGreenZoneTime: greenZoneTime,
        totalYellowZoneTime: yellowZoneTime,
        totalRedZoneTime: redZoneTime,
      };
    };

    // Your data processing logic
    const arraysToProcess =
      filteredDataWithInputs[0]?.FilteredData?.length > 0
        ? [filteredDataWithInputs[0]?.FilteredData]
        : [processDataListWithSession];

    let cumulativeTotals = {
      totalGreenZoneTime: 0,
      totalYellowZoneTime: 0,
      totalRedZoneTime: 0,
    };

    arraysToProcess.forEach((dataArray) => {
      if (dataArray && Array.isArray(dataArray)) {
        dataArray.forEach((data) => {
          if (
            data &&
            data.attendees_record &&
            Array.isArray(data.attendees_record)
          ) {
            const totals = calculateTotals(data.attendees_record);
            cumulativeTotals.totalGreenZoneTime += totals.totalGreenZoneTime;
            cumulativeTotals.totalYellowZoneTime += totals.totalYellowZoneTime;
            cumulativeTotals.totalRedZoneTime += totals.totalRedZoneTime;
          }
        });
      }
    });

    const totalRecordedDuration =
      filteredDataWithInputs.length > 0
        ? filteredDataWithInputs[0]?.FilteredData.reduce(
            (accumulator: number, data: ProcessDataListVersion2) =>
              accumulator + data.duration_msec,
            0
          )
        : processDataListWithSession.reduce(
            (accumulator: number, data: ProcessDataListVersion2) =>
              accumulator + data.duration_msec,
            0
          );

    const totalRecordedDurationSeconds = totalRecordedDuration / 1000;

    // Calculate total grey zone time in seconds
    const totalGreyZoneTime =
      totalRecordedDurationSeconds -
      Math.floor(
        cumulativeTotals.totalGreenZoneTime +
          cumulativeTotals.totalRedZoneTime +
          cumulativeTotals.totalYellowZoneTime
      );

    const newFlagData = [
      {
        name: `${formatSecondsToMinSec(cumulativeTotals.totalRedZoneTime)} min`,
        value: formatSecondsToMinSec(cumulativeTotals.totalRedZoneTime),
        color: '#D62D2D',
      },
      {
        name: `${formatSecondsToMinSec(
          cumulativeTotals.totalYellowZoneTime
        )} min`,
        value: formatSecondsToMinSec(cumulativeTotals.totalYellowZoneTime),
        color: '#FFCA28',
      },
      {
        name: `${formatSecondsToMinSec(
          cumulativeTotals.totalGreenZoneTime
        )} min`,
        value: formatSecondsToMinSec(cumulativeTotals.totalGreenZoneTime),
        color: '#62C960',
      },
      {
        name: `${formatSecondsToMinSec(totalGreyZoneTime)} min`,
        value: formatSecondsToMinSec(totalGreyZoneTime),
        color: 'grey',
      },
    ];

    // Update state variables
    setTotalGreenZoneTime(cumulativeTotals.totalGreenZoneTime);
    setTotalYellowZoneTime(cumulativeTotals.totalYellowZoneTime);
    setTotalRedZoneTime(cumulativeTotals.totalRedZoneTime);
    setFlagDataTimeCount(newFlagData);

    setLoading(false);
  }, [
    processDataListWithSession,
    filteredDataWithInputs,
    totalRedZoneTime,
    totalYellowZoneTime,
    totalGreenZoneTime,
  ]);

  useEffect(() => {
    const filteredData =
      filteredDataWithInputs.length > 0
        ? filteredDataWithInputs[0]?.FilteredData
        : processDataListWithSession;

    if (filteredData && filteredData.length > 0) {
      const totalRecordedDuration = filteredData.reduce(
        (accumulator: number, data: ProcessDataListVersion2) =>
          accumulator + data.duration_msec,
        0
      );

      setTotalTalkTime(formatSecondsToMinSec(totalRecordedDuration / 1000));
    }
  }, [filteredDataWithInputs, processDataListWithSession]);

  const sortedFlagData = [...flagData].sort((a, b) => b.value - a.value);
  const radius = [45, 25, 20];

  return (
    <Paper elevation={1} sx={{ width: '100%', height: '100%' }}>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: 'var(--Body-Text-Light, #605E5E)',
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.132px',
                mt: 1,
                ml: 2,
              }}
              label='Overall'
            />

            <Typography
              sx={{
                color: 'var(--Body-Text, #33344B)',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.198px',
                ml: 2,
              }}
              label='Flags'
            />
          </Grid>
          {processDataListWithSession.length > 0 && (
            <Grid item xs={12}>
              {activeChart === 'chart1' ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'block',

                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Body-Text-Light, #605E5E)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.132px',
                      mt: 1,
                      ml: 2,
                    }}
                    label='Total Flags'
                  />

                  <Typography
                    sx={{
                      color: 'var(--Body-Text, #33344B)',
                      fontFamily: 'Poppins',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      letterSpacing: '-0.198px',
                      ml: 2,
                    }}
                    label=''
                  >
                    {flagCount}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'block',

                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Body-Text-Light, #605E5E)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.132px',
                      mt: 1,
                      ml: 2,
                    }}
                    label='Total Recording'
                  />

                  <Box sx={{ display: 'flex', gap: '1%' }}>
                    <Typography
                      sx={{
                        color: 'var(--Body-Text, #33344B)',
                        fontFamily: 'Poppins',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.198px',
                        ml: 2,
                      }}
                      label=''
                    >
                      {`${totalTalkTime}`}
                    </Typography>
                    <Typography sx={{ fontSize: '10px', mt: 3 }} label=''>
                      min.
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {processDataListWithSession.length > 0 && (
          <Grid item xs={8}>
            <Grid item xs={12}>
              <Box
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: 'fit-content',
                  display: 'flex',
                  mt: 0.5,
                }}
              >
                <Button
                  onClick={() => handleChartSwitch('chart1')}
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    lineHeight: 'normal',
                    letterSpacing: '-0.132px',
                    width: 'fit-content',
                    textTransform: 'none',
                    color: activeChart === 'chart1' ? '#1206f9' : 'inherit',
                    backgroundColor:
                      activeChart === 'chart1' ? 'transparent' : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        activeChart === 'chart1' ? 'transparent' : 'inherit',
                    },
                  }}
                  label='Total Flags'
                />

                <Button
                  onClick={() => handleChartSwitch('chart2')}
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '10px',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    lineHeight: 'normal',
                    letterSpacing: '-0.132px',
                    width: 'fit-content',
                    textTransform: 'none',
                    color: activeChart === 'chart2' ? '#1206f9' : 'inherit',
                    backgroundColor:
                      activeChart === 'chart2' ? 'transparent' : 'inherit',
                    '&:hover': {
                      backgroundColor:
                        activeChart === 'chart2' ? 'transparent' : 'inherit',
                    },
                  }}
                  label='min/Flag'
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%', height: '100%', mt: 1 }}>
                {activeChart === 'chart2' ? (
                  loading ? (
                    <Loader />
                  ) : (
                    <PieChart
                      series={[
                        {
                          data: flagDataTimeCount,
                          innerRadius: 20,
                          outerRadius: 40,
                        },
                      ]}
                      margin={{ left: 90 }}
                      width={200}
                      height={90}
                      slotProps={{ legend: { hidden: true } }}
                    ></PieChart>
                  )
                ) : (
                  !loading && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        ml: 3,
                      }}
                    >
                      {sortedFlagData.every(
                        (item) => item.value === sortedFlagData[0].value
                      ) ? (
                        <svg width='100%' height='100'>
                          {sortedFlagData.map((item, index) => {
                            let centerX, centerY;

                            if (index === 0) {
                              centerX = 50;
                              centerY = 30;
                            } else if (index === 1) {
                              centerX = 70;
                              centerY = 65;
                            } else {
                              centerX = 95;
                              centerY = 30;
                            }

                            return (
                              <g key={item.name}>
                                <circle
                                  cx={centerX}
                                  cy={centerY}
                                  r={25}
                                  fill={item.color}
                                  stroke='white'
                                  strokeWidth='1'
                                />
                                <text
                                  x={centerX}
                                  y={centerY}
                                  textAnchor='middle'
                                  dominantBaseline='middle'
                                  fill={
                                    item.label === 'Red' ? 'white' : 'black'
                                  }
                                >
                                  {item.value}
                                </text>
                              </g>
                            );
                          })}
                        </svg>
                      ) : (
                        <svg width='100%' height='100'>
                          {sortedFlagData.map((item, index) => {
                            let centerX, centerY;

                            if (index === 0) {
                              centerX = 50;
                              centerY = 50;
                            } else if (index === 1) {
                              centerX = 100;
                              centerY = 65;
                            } else {
                              centerX = 95;
                              centerY = 30;
                            }

                            return (
                              <g key={item.name}>
                                <circle
                                  cx={centerX}
                                  cy={centerY}
                                  r={radius[index]}
                                  fill={item.color}
                                  stroke='white'
                                  strokeWidth='1'
                                />
                                <text
                                  x={centerX}
                                  y={centerY}
                                  textAnchor='middle'
                                  dominantBaseline='middle'
                                  fill={
                                    item.label === 'Red' ? 'white' : 'black'
                                  }
                                >
                                  {item.value}
                                </text>
                              </g>
                            );
                          })}
                        </svg>
                      )}
                    </Box>
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {processDataListWithSession.length > 0 && (
          <Grid item xs={12}>
            {activeChart === 'chart1' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center', // Align items to center vertically
                  ml: 1,
                  top: 0,
                }}
              >
                {flagData.map((item, index) => (
                  <Box
                    key={item.label}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center', // Align items to center vertically
                      width: '100%',
                      height: 'fit-content',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: item.color,
                        width: '17px', // Set the width as needed
                        height: '15px', // Set the height as needed
                      }}
                    />
                    <List>
                      <ListItem key={item.label}>
                        <ListItemText
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '10px',
                              color: 'black',
                              lineHeight: '10px',
                            },
                          }}
                        >
                          {item.label}
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: 1,
                  top: 0,
                }}
              >
                {activeChart === 'chart1'
                  ? flagData.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '100%',
                          height: 'fit-content',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: item.color,
                            width: '17px',
                            height: '15px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <List sx={{ margin: 0, padding: 0, ml: 1 }}>
                            <ListItem key={item.label}>
                              <ListItemText
                                sx={{
                                  '& .MuiTypography-root': {
                                    fontSize: '10px',
                                    color: 'black',
                                    lineHeight: '10px',
                                  },
                                }}
                              >
                                {item.label}
                              </ListItemText>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                    ))
                  : flagDataTimeCount.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '100%',
                          height: 'fit-content',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: item.color,
                            width: '17px',
                            height: '15px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <List
                            sx={{
                              margin: 0,
                              padding: 0,
                              ml: 1,
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <ListItem>
                              <ListItemText
                                sx={{
                                  '& .MuiTypography-root': {
                                    fontSize: '12px',
                                    color: 'black',
                                    lineHeight: '10px',
                                  },
                                }}
                                key={item.name}
                              >
                                {item.name}
                              </ListItemText>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                    ))}
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          mt: 2,
          ml: 1,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {processDataListWithSession.length <= 0 && (
          <NoDataFoundForSelectedFilters />
        )}
      </Grid>
    </Paper>
  );
}

export default FlagsWidget;
