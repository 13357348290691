import {
  Paper,
  Box,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { recordingTypeList } from 'constants/Record';
import { t } from 'i18next';
import RecordVideoButtonIcon from 'icons/RecordVideoButtonIcon';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';
import Clock from 'widgets/Clock';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useAppSelector } from 'redux/store';
import { getSessionTypesSelector } from 'redux/selectors/selectors';
import CustomSwitchButton from 'widgets/CustomSwitchButtonForRecord/CustomSwitchButtonForRecord';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import { Toast } from 'widgets/Toast/Toast';
import { AdvancedSettingsOptions } from 'types/Record-types';
import getMediaDevices from 'utils/getMediaDevices';
import CheckCameraAndMicPermissionDialog from 'widgets/CheckCameraAndMicPermissionDialog/CheckCameraAndMicPermissionDialog';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import Typography from 'widgets/Typography/Typography';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import generateCandidateVideoDesc from 'utils/generateCandidateVideoDesc';
import { CandidateDetailsType } from 'types/SendAssessments-types';

function CommonRecorderSettings({
  setVideoTitleDescription,
  videoTitleDescription,
  setSelectedSessionType,
  selectedSessionType,
  setRecordingType,
  recordingType,
  setSelectedCamera,
  selectedCamera,
  setSelectedMicrophone,
  selectedMicrophone,
  setAdvancedSettingsOptions,
  advancedSettingsOptions,
  handleStartRecordingWithCountDown,
  handleStartRecordingWithoutCountDown,
  stopRecording,
  mediaRecorderStatus,
  isMyAssessment,
  handleTogglePromptSection,
  setSelectedAssessmentType,
  selectedAssessmentType,
  isFromCandidateAssessment,
  candidateDetails,
}: {
  setVideoTitleDescription: Function;
  videoTitleDescription?: string;
  setSelectedSessionType?: Function;
  selectedSessionType?: { id: number; name: string };
  setRecordingType: Function;
  recordingType?: { id: number; name: string };
  setSelectedCamera: Function;
  selectedCamera: CamerasType;
  setSelectedMicrophone: Function;
  selectedMicrophone: MicrophonesType;
  setAdvancedSettingsOptions: Function;
  advancedSettingsOptions: AdvancedSettingsOptions;
  handleStartRecordingWithCountDown?: () => void;
  handleStartRecordingWithoutCountDown?: () => void;
  stopRecording?: () => void;
  mediaRecorderStatus?: String;
  isMyAssessment?: boolean;
  handleTogglePromptSection?: () => void;
  setSelectedAssessmentType?: Function;
  selectedAssessmentType?: {
    id: number;
    name: string;
    type: string;
    description: string;
  };
  isFromCandidateAssessment: boolean;
  candidateDetails: CandidateDetailsType;
}) {
  const listItems = [
    {
      id: 'switch-list-label-control-menu',
      text: 'record.ControlMenu',
      stateKey: 'controlMenu',
    },
    {
      id: 'switch-list-label-recording-countdown',
      text: 'record.RecordingCountdown',
      stateKey: 'recordingCountdown',
    },
    {
      id: 'switch-list-label-show-recording-time',
      text: 'record.ShowRecordingTime',
      stateKey: 'showRecordingTime',
    },
  ];

  const { SessionTypes } = useAppSelector(getSessionTypesSelector);
  const [cameras, setCameras] = useState<Array<CamerasType>>([]);
  const [microphones, setMicrophones] = useState<Array<MicrophonesType>>([]);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  useEffect(() => {
    getMediaDevices(setCameras, setMicrophones);
  }, []);

  useEffect(() => {
    if (cameras.length > 0) setSelectedCamera(cameras[0]);
  }, [cameras]);
  useEffect(() => {
    if (microphones.length > 0) setSelectedMicrophone(microphones[0]);
  }, [microphones]);
  const handleSwitchChange = (key: keyof AdvancedSettingsOptions) => {
    setAdvancedSettingsOptions((prevOptions: AdvancedSettingsOptions) => ({
      ...prevOptions,
      [key]: !prevOptions[key],
    }));
  };
  const [isAssessmentTypeTouched, setIsAssessmentTypeTouched] = useState(false);

  const handleInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.name === 'combo-box-assessment-types') {
      setIsAssessmentTypeTouched(true);
    }
  };
  const mappedSelectedAssessmentType = selectedAssessmentType
    ? { id: selectedAssessmentType.id, name: selectedAssessmentType.name }
    : null;

  const handleAssementType = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue && typeof setSelectedAssessmentType === 'function')
        setSelectedAssessmentType(newValue);
    },
    []
  );

  const handleSessionType = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue && typeof setSelectedSessionType === 'function')
        setSelectedSessionType(newValue);
    },
    []
  );

  const handleRecordingType = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue) setRecordingType(newValue);
    },
    []
  );

  const handleCameraType = useCallback(
    (_event: SyntheticEvent<Element, Event>, newVal: CamerasType | null) => {
      if (newVal) setSelectedCamera(newVal);
    },
    []
  );

  const handleMicroPhoneType = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newVal: MicrophonesType | null
    ) => {
      if (newVal) setSelectedMicrophone(newVal);
    },
    []
  );

  return (
    <Paper
      elevation={5}
      sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: isMyAssessment ? 6 : '20px',
          width:
            isMobile || isTablet ? '100%' : isMyAssessment ? '40%' : '100%',
          justifyContent: 'center',
        }}
      >
        <TextField
          name='videoDescription'
          size='small'
          variant='outlined'
          fullWidth
          id='videoDescription'
          className='overlap-2'
          InputProps={{
            style: {
              borderRadius: '35px',
              fontFamily: 'poppins',
            },
            endAdornment: (
              <InputAdornment position='end' sx={{ opacity: '0.7' }}>
                <IconButton
                  aria-label='video-description'
                  onClick={() => {
                    setVideoTitleDescription(
                      isFromCandidateAssessment
                        ? generateCandidateVideoDesc(candidateDetails)
                        : getDefaultVideoDescription()
                    );
                  }}
                >
                  <RefreshOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          defaultValue={
            isFromCandidateAssessment
              ? generateCandidateVideoDesc(candidateDetails)
              : getDefaultVideoDescription()
          }
          label={t('record.VideoDescription')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVideoTitleDescription(event.target.value);
          }}
          value={videoTitleDescription}
          helperText={
            videoTitleDescription === '' ? 'Video description is required.' : ''
          }
          error={videoTitleDescription === ''}
        />

        {isMyAssessment ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.3 }}>
            <Autocomplete
              disablePortal
              id='combo-box-assessment-types'
              size='small'
              options={SessionTypes}
              getOptionLabel={(option) => option.name}
              sx={{ fontFamily: 'Poppins' }}
              onBlur={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='combo-box-assessment-types'
                  label={'MyAssessments.AssessmentType'}
                  helperText={
                    isAssessmentTypeTouched &&
                    selectedAssessmentType &&
                    selectedAssessmentType.id === -1
                      ? t('MyAssessments.AssessmentTypeIsRequired')
                      : ''
                  }
                  error={
                    isAssessmentTypeTouched &&
                    selectedAssessmentType &&
                    selectedAssessmentType.id === -1
                  }
                />
              )}
              onChange={handleAssementType}
              optionLabelKey='name'
              value={mappedSelectedAssessmentType}
              label={'MyAssessments.AssessmentType'}
              disabled={isFromCandidateAssessment}
            />
            <Typography
              sx={{
                color: '#676767',
                fontFamily: 'Poppins',
                fontSize: '12.344px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                ml: 2,
              }}
              label=''
            >
              {selectedAssessmentType &&
                selectedAssessmentType.description &&
                `${t('MyAssessments.Description')} : ${
                  selectedAssessmentType.description
                }`}
            </Typography>
          </Box>
        ) : (
          <Autocomplete
            disablePortal
            id='combo-box-sessions'
            size='small'
            options={SessionTypes}
            getOptionLabel={(option) => option.name}
            sx={{ fontFamily: 'Poppins' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'record.SessionType'}
                helperText={
                  selectedSessionType && selectedSessionType.id === -1
                    ? t('MyAssessments.SessionTypeIsRequired')
                    : ''
                }
                error={selectedSessionType && selectedSessionType.id === -1}
              />
            )}
            onChange={handleSessionType}
            value={selectedSessionType || null} // Set value to null when selectedSessionType is undefined
            optionLabelKey='name'
            label='record.SessionType'
          />
        )}
        <Autocomplete
          disablePortal
          disabled={
            mediaRecorderStatus === 'recording' || isFromCandidateAssessment
              ? true
              : false
          }
          id='combo-box-CommonRecorderSettings-recording-types'
          size='small'
          options={recordingTypeList}
          getOptionLabel={(option) => t(option.name)}
          sx={{ fontFamily: 'Poppins' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'record.RecordingType'}
              helperText={
                recordingType && recordingType.id === -1
                  ? t('MyAssessments.RecordingTypeIsRequired')
                  : ''
              }
              error={recordingType && recordingType.id === -1}
            />
          )}
          onChange={handleRecordingType}
          value={recordingType || null}
          optionLabelKey='name'
          label='record.RecordingType'
        />
        <Typography
          sx={{
            color: '#33344B ',
            fontFamily: 'Poppins ',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '23px',
          }}
          label='record.RecordingSettings'
        />

        <Autocomplete
          disabled={recordingType && recordingType.id === 2}
          onChange={handleCameraType}
          size='small'
          value={selectedCamera}
          options={cameras}
          renderInput={(params) => (
            <TextField
              {...params}
              id='camera-select'
              label={'record.SelectCamera'}
              variant='outlined'
              helperText={
                selectedCamera.deviceId === ''
                  ? t('MyAssessments.PleaseSelectCamera')
                  : ''
              }
              error={selectedCamera.deviceId === ''}
            />
          )}
          id='camera-autocomplete'
          aria-labelledby='camera-label'
          optionLabelKey='label'
          label='record.SelectCamera'
        />

        <Autocomplete
          onChange={handleMicroPhoneType}
          size='small'
          value={selectedMicrophone}
          options={microphones}
          renderInput={(params) => (
            <TextField
              {...params}
              id='microphone-select'
              label={'record.SelectMicrophone'}
              variant='outlined'
              helperText={
                selectedMicrophone.deviceId === ''
                  ? t('MyAssessments.PleaseSelectMicrophone')
                  : ''
              }
              error={selectedMicrophone.deviceId === ''}
            />
          )}
          id='microphone-autocomplete'
          aria-labelledby='microphone-label'
          optionLabelKey='label'
          label='record.SelectMicrophone'
        />

        {isMyAssessment ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Button
              id='apply-recorder-settings-button'
              aria-label='apply-recorder-settings-button'
              variant='contained'
              sx={{
                padding: 1,
                borderRadius: 20,
                backgroundColor: '#1206f9',
              }}
              size='small'
              onClick={handleTogglePromptSection}
              disabled={
                videoTitleDescription === '' ||
                (selectedAssessmentType && selectedAssessmentType.id === -1) ||
                (recordingType && recordingType.id === -1) ||
                selectedCamera.deviceId === '' ||
                selectedMicrophone.deviceId === ''
              }
              label={t('MyAssessments.Apply')}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {' '}
            <Typography
              sx={{
                color: '#33344B ',
                fontFamily: 'Poppins ',
                fontSize: '18px',
                fontStyle: 'normal',

                fontWeight: 600,
                lineHeight: '23px',
              }}
              label='record.AdvancedSettings'
            />
            <List
              sx={{
                width: '50%',
                maxWidth: 400,
                bgcolor: 'background.paper',
              }}
            >
              {listItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText id={item.id}>
                    <Typography
                      sx={{
                        color: '#33344B',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                      }}
                      label={item.text}
                    />
                  </ListItemText>

                  <CustomSwitchButton
                    onChange={() =>
                      handleSwitchChange(
                        item.stateKey as keyof AdvancedSettingsOptions
                      )
                    }
                    checked={
                      advancedSettingsOptions[
                        item.stateKey as keyof AdvancedSettingsOptions
                      ]
                    }
                    inputProps={{ 'aria-label': 'ant design' }}
                    disabled={mediaRecorderStatus === 'recording'}
                  />
                </ListItem>
              ))}
            </List>
            {mediaRecorderStatus === 'idle' && (
              <Button
                disabled={
                  mediaRecorderStatus === 'acquiring_media' ||
                  videoTitleDescription === '' ||
                  (selectedSessionType && selectedSessionType.id === -1) ||
                  (recordingType && recordingType.id === -1) ||
                  selectedCamera.deviceId === '' ||
                  selectedMicrophone.deviceId === ''
                }
                onClick={() => {
                  if (recordingType && advancedSettingsOptions) {
                    if (recordingType.id === -1) {
                      Toast(
                        t(
                          'toastMessageNotifications.PleaseSelectRecordingType'
                        ),
                        'error'
                      );
                    } else if (
                      advancedSettingsOptions.recordingCountdown &&
                      typeof handleStartRecordingWithCountDown === 'function'
                    ) {
                      handleStartRecordingWithCountDown();
                    } else if (
                      typeof handleStartRecordingWithoutCountDown === 'function'
                    ) {
                      handleStartRecordingWithoutCountDown();
                    }
                  } else {
                    console.error(
                      'recordingType or advancedSettingsOptions is undefined'
                    );
                  }
                }}
                id='start-recording-right-button'
                aria-label='start-recording-right-button'
                variant='contained'
                startIcon={<RecordVideoButtonIcon />}
                sx={{
                  padding: 1,
                  borderRadius: 20,
                  backgroundColor: '#1206f9',
                }}
                size='small'
                label='record.StartRecording'
              />
            )}
            {mediaRecorderStatus === 'recording' &&
              (mediaRecorderStatus === 'acquiring_media' ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={mediaRecorderStatus === 'acquiring_media'}
                  onClick={() => {
                    if (typeof stopRecording === 'function') stopRecording();
                  }}
                  id='start-recording-right-button'
                  aria-label='start-recording-right-button'
                  variant='contained'
                  startIcon={<RecordVideoButtonIcon />}
                  sx={{
                    padding: 1,
                    borderRadius: 20,
                    backgroundColor: '#1206f9',
                  }}
                  size='small'
                  label='record.Stop'
                />
              ))}
            {mediaRecorderStatus === 'stopped' && (
              <Button
                disabled={
                  mediaRecorderStatus === 'acquiring_media' ||
                  videoTitleDescription === '' ||
                  (selectedSessionType && selectedSessionType.id === -1) ||
                  (recordingType && recordingType.id === -1) ||
                  selectedCamera.deviceId === '' ||
                  selectedMicrophone.deviceId === ''
                }
                onClick={() => {
                  if (recordingType && advancedSettingsOptions) {
                    if (recordingType.id === -1) {
                      Toast(
                        t(
                          'toastMessageNotifications.PleaseSelectRecordingType'
                        ),
                        'error'
                      );
                    } else if (
                      advancedSettingsOptions.recordingCountdown &&
                      typeof handleStartRecordingWithCountDown === 'function'
                    ) {
                      handleStartRecordingWithCountDown();
                    } else if (
                      typeof handleStartRecordingWithoutCountDown === 'function'
                    ) {
                      handleStartRecordingWithoutCountDown();
                    }
                  } else {
                    console.error(
                      'recordingType or advancedSettingsOptions is undefined'
                    );
                  }
                }}
                id='start-recording-right-button'
                aria-label='start-recording-right-button'
                variant='contained'
                startIcon={<RecordVideoButtonIcon />}
                sx={{
                  padding: 1,
                  borderRadius: 20,
                  backgroundColor: '#1206f9',
                }}
                size='small'
                label='record.StartRecording'
              />
            )}
            <Typography
              sx={{
                color: '#33344B ',
                fontFamily: 'Poppins ',
                fontSize: '14px',

                textAlign: 'center',
              }}
              label='record.RecordingLimitIs10Minutes'
            />
          </Box>
        )}
      </Box>{' '}
      <CheckCameraAndMicPermissionDialog
        setCameras={setCameras}
        setMicrophones={setMicrophones}
      />
    </Paper>
  );
}

export default React.memo(CommonRecorderSettings);
