/** @format */

import BillingComponent from 'components/Billing/Billing';
import React from 'react';

function Billing() {
  return <BillingComponent />;
}

export default Billing;
