interface FacebookResponse {
  error?: Error;
  authResponse?: {
    accessToken: string;
  };
}

interface LoginResponse {
  authResponse: {
    userID: string;
    expiresIn: number;
    accessToken: string;
    signedRequest: string;
    graphDomain: string;
    data_access_expiration_time: number;
  };
  status: string;
}
interface ParamsFBAsyncInit {
  appId: string;
  cookie: boolean;
  xfbml: boolean;
  version: string;
}
declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      init: (params: ParamsFBAsyncInit) => void;
      api: (
        path: string,
        method: string,
        params: ParamsFBAsyncInit | null,
        callback: (response: FacebookResponse) => void
      ) => void;
      getLoginStatus: (
        callback: (response: FacebookResponse) => void,
        force?: boolean
      ) => void;
      login: (
        callback: (response: LoginResponse) => void,
        options?: { scope: string }
      ) => void;
      logout: () => void;
    };
  }
}

export const facebookAuthConfig = {
  appId: '340102071842949',
  cookie: true,
  xfbml: true,
  version: 'v18.0',
};

export function initializeFacebookSsoConfig() {
  return new Promise<void>((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init(facebookAuthConfig);
      resolve();
    };

    ((d: Document, s: string, id: string) => {
      let js: HTMLScriptElement,
        fjs: HTMLScriptElement | null = d.getElementsByTagName(
          s
        )[0] as HTMLScriptElement | null;
      if (!fjs) {
        return;
      }
      if (!fjs.parentNode) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}

export async function FacebookSsoLogout() {
  return new Promise((resolve, reject) => {
    window.FB.api(
      '/me/permissions',
      'delete',
      null,
      (response: FacebookResponse) => {
        if (response && !response.error) {
          window.FB.logout();
          resolve('fb logout success');
        } else {
          reject('error fb logout');
        }
      }
    );
  });
}
