/** @format */

import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from '@mui/material';
import { dashboardTableHeaderIds } from 'constants/Dashboard';
import SortTableDataIcon from 'icons/SortTableDataIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Data, EnhancedTableHeadProps, HeadCell } from 'types/Dashboard-types';
import Typography from 'widgets/Typography/Typography';

function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  columnVisibility,
  headCells,
  selectedRows,
  handleSelectAll,
}: EnhancedTableHeadProps) {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const { t } = useTranslation();
  function getColumnVisibilityCount() {
    let count = 0;

    if (columnVisibility.green) count++;
    if (columnVisibility.yellow) count++;
    if (columnVisibility.red) count++;

    return count;
  }

  function customHeaderLabel(headCell: HeadCell): string {
    switch (headCell.id) {
      case dashboardTableHeaderIds.intervieweeTalkTimePercentage:
      case dashboardTableHeaderIds.confidentTone:
      case dashboardTableHeaderIds.stressedTone:
      case dashboardTableHeaderIds.fatiguedTone: {
        return t(headCell.label) + ' %';
      }
      default:
        return t(headCell.label);
    }
  }

  return (
    <TableHead
      sx={{
        backgroundColor: '#F7F7F9',
        color: '605E5E',
      }}
    >
      <TableRow
        style={{
          borderBottom: '2px solid #F7F7F9',
        }}
      >
        <TableCell id='dropdown' colSpan={1}></TableCell>
        {columnVisibility.recordings && (
          <TableCell id='recordings' colSpan={1}></TableCell>
        )}
        {columnVisibility.eventType && (
          <TableCell id='eventType' colSpan={1}></TableCell>
        )}
        {columnVisibility.attendees && (
          <TableCell id='attendees' colSpan={1}></TableCell>
        )}
        {columnVisibility.emotion && (
          <TableCell id='emotion' colSpan={1}></TableCell>
        )}
        {(columnVisibility.engagement || columnVisibility.positivity) && (
          <TableCell
            colSpan={
              columnVisibility.engagement && columnVisibility.positivity ? 2 : 1
            }
            align='center'
          >
            <Typography label='dashboard.OverallScore' />
          </TableCell>
        )}
        {(columnVisibility.green ||
          columnVisibility.red ||
          columnVisibility.yellow) && (
          <TableCell
            align='center'
            colSpan={getColumnVisibilityCount()}
            sx={{}}
          >
            <Typography label='dashboard.Flags' />
          </TableCell>
        )}
        {columnVisibility.intervieweeTalkTimePercentage && (
          <TableCell id='ConfidentTone' colSpan={1}></TableCell>
        )}
        {columnVisibility.intervieweeTalkTimePercentage && (
          <TableCell id='StressedTone' colSpan={1}></TableCell>
        )}
        {columnVisibility.intervieweeTalkTimePercentage && (
          <TableCell id='FatiguedTone' colSpan={1}></TableCell>
        )}
        {columnVisibility.intervieweeTalkTimePercentage && (
          <TableCell id='intervieweeTalkTimePercentage' colSpan={1}></TableCell>
        )}
      </TableRow>
      <TableRow>
        {headCells.map((headCell: HeadCell) => {
          return (
            columnVisibility[headCell.id] && (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                align={headCell.numeric ? 'center' : 'left'}
                sx={{
                  maxWidth: headCell.id === 'recordings' ? '360px' : '',
                  minWidth: headCell.id === 'recordings' ? '360px' : '',
                }}
              >
                {headCell.id === dashboardTableHeaderIds.checkbox ? (
                  <Checkbox onChange={handleSelectAll} />
                ) : (
                  ''
                )}
                {customHeaderLabel(headCell)}
                {headCell.label.length > 0 && (
                  <IconButton
                    onClick={createSortHandler(headCell.id)}
                    aria-label='sort-by'
                  >
                    <SortTableDataIcon />
                  </IconButton>
                )}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
