import React from 'react';
import { TextField as DefaultTextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TextFieldProps extends React.ComponentProps<typeof DefaultTextField> {
  label?: string;
  width?: string;
  height?: string;
  id?: string;
  borderRadius?: string;
  background?: string;
  float?: string;
  textTransform?: string;
  marginTop?: number;
  flexShrink?: number;
  textAlign?: string;
  fontFamily?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: number;
  lineHeight?: string;
  mb?: number;
  maxWidth?: number | string;
}

const TextField: React.FC<TextFieldProps> = ({
  label = ' ',
  width = '100%',
  height = 'auto',
  id = '',
  borderRadius = 'none',
  background = 'transparent',
  float = 'none',
  textTransform = 'none',
  marginTop = 0,
  flexShrink = 0,
  textAlign = 'left',
  fontFamily = 'Poppins',
  fontSize = '1rem',
  fontStyle = 'normal',
  fontWeight = 400,
  lineHeight = '1rem',
  mb = 0,
  maxWidth = '100%',
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <DefaultTextField
      id={id}
      label={t(label)}
      sx={{
        mt: marginTop,
        width: width,
        height: height,
        borderRadius: borderRadius,
        background: background,
        float: float,
        textTransform: textTransform,
        flexShrink: flexShrink,
        textAlign: textAlign,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        mb: mb,
        maxWidth: maxWidth,
      }}
      {...restProps}
    />
  );
};

export default TextField;
