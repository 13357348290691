/** @format */

import FeedbackComponent from 'components/Feedback/Feedback';
import React from 'react';

function Feedback() {
  return <FeedbackComponent />;
}

export default Feedback;
