/** @format */

export function uuidGenerator() {
  const hashTable = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ];

  const uuid = [];
  const dashIndices = [8, 13, 18, 23];

  for (let i = 0; i < 36; i++) {
    uuid[i] = dashIndices.includes(i)
      ? '-'
      : hashTable[Math.floor(Math.random() * hashTable.length)];
  }

  return uuid.join('');
}
