import React, { useRef } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  Link,
  Box,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Bowser from 'bowser';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from './requestMediaPermissions';
import getMediaDevices from 'utils/getMediaDevices';
import Typography from 'widgets/Typography/Typography';
import { useTranslation } from 'react-i18next';

const browser = Bowser.getParser(window.navigator.userAgent);

enum DialogType {
  explanation = 'explanation',
  systemDenied = 'systemDenied',
  userDenied = 'userDenied',
  trackError = 'trackError',
}

const CheckCameraAndMicPermissionDialog = ({
  setCameras,
  setMicrophones,
}: {
  setCameras: Function;
  setMicrophones: Function;
}) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = React.useState<DialogType | boolean>(
    false
  );
  const [audioAllowed, setAudioAllowed] = React.useState<boolean>(false);
  const [videoAllowed, setVideoAllowed] = React.useState<boolean>(false);
  const [errorDetails, setErrorDetails] =
    React.useState<MediaPermissionsError>();

  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;
  const audioAllowedRef = useRef(audioAllowed);
  audioAllowedRef.current = audioAllowed;
  const videoAllowedRef = useRef(videoAllowed);
  videoAllowedRef.current = videoAllowed;

  React.useEffect(() => {
    checkMediaPermissions();
  }, []);

  React.useEffect(() => {
    if (audioAllowed || videoAllowed) {
    }
  }, [audioAllowed, videoAllowed]);

  const checkForExplanationDialog = () => {
    if (
      (!audioAllowedRef.current || !videoAllowedRef.current) &&
      showDialogRef.current === null
    )
      setShowDialog(DialogType.explanation);
  };

  const checkMediaPermissions = async () => {
    await navigator.permissions
      .query({ name: 'camera' as PermissionName })
      .then(async (cameraPermission) => {
        await navigator.permissions
          .query({ name: 'microphone' as PermissionName })
          .then(async (microphonePermission) => {
            if (
              cameraPermission.state === 'granted' &&
              microphonePermission.state === 'granted'
            ) {
              setAudioAllowed(true);
              setVideoAllowed(true);
              setShowDialog(false);
              getMediaDevices(setCameras, setMicrophones);
            } else {
              await requestMediaPermissions()
                .then(() => {
                  setAudioAllowed(true);
                  setVideoAllowed(true);
                  setShowDialog(false);
                  getMediaDevices(setCameras, setMicrophones);
                })
                .catch((error: MediaPermissionsError) => {
                  if (
                    error.type ===
                    MediaPermissionsErrorType.SystemPermissionDenied
                  ) {
                    setShowDialog(DialogType.systemDenied);
                  } else if (
                    error.type ===
                    MediaPermissionsErrorType.UserPermissionDenied
                  ) {
                    setShowDialog(DialogType.userDenied);
                  } else if (
                    error.type ===
                    MediaPermissionsErrorType.CouldNotStartVideoSource
                  ) {
                    setShowDialog(DialogType.trackError);
                  } else {
                  }
                  setErrorDetails(error);
                });
            }
          });
      })
      .catch((error: Error) => {
        console.error('error checkMediaPermissions ', error);
      });

    setTimeout(() => {
      checkForExplanationDialog();
    }, 500);
  };

  const _renderTryAgain = (text?: string) => {
    return (
      <Box style={{ width: '100%', marginTop: 20 }}>
        <Button
          onClick={() => {
            if (browser.getBrowserName() === 'Safari') {
              window.location.reload();
            } else {
              checkMediaPermissions();
            }
          }}
          color='primary'
          style={{ float: 'right' }}
        >
          {text ? text : 'Retry'}
        </Button>
      </Box>
    );
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return <></>;
    return (
      <Box style={{ marginTop: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography
              variant='caption'
              style={{ color: 'red' }}
              label={t('CheckCameraAndMicPermissionDialog.ErrorDetails')}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='caption' label=''>
              {errorDetails.name}: {errorDetails.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const _renderExplanationDialog = () => {
    return (
      <Box sx={{ p: 3 }}>
        <Typography
          variant='h5'
          label={t(
            'CheckCameraAndMicPermissionDialog.AllowAppToUseYourCameraAndMicrophone'
          )}
        />

        <Typography
          variant='subtitle1'
          label={t(
            'CheckCameraAndMicPermissionDialog.AppNeedsAccessToYourCameraAndMicrophoneSoThatOtherParticipantsCanSeeAndHearYou'
          )}
        />
      </Box>
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <Box sx={{ p: 3 }}>
        <Typography
          variant='h5'
          label={t(
            'CheckCameraAndMicPermissionDialog.CameraAndMicrophoneAreBlocked'
          )}
        />
        <Typography
          sx={{ fontFamily: 'poppins' }}
          label={t(
            'CheckCameraAndMicPermissionDialog.AppRequiresAccessToYourCameraAndMicrophone'
          )}
        >
          {browser.getBrowserName() !== 'Safari' && (
            <Typography
              sx={{ fontFamily: 'poppins' }}
              label='Click the camera blocked icon'
            >
              {' '}
              <img
                alt='icon'
                src={
                  'https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg'
                }
                style={{ display: 'inline' }}
              />{' '}
              {t('CheckCameraAndMicPermissionDialog.InYourBrowsesAddressBar')}
            </Typography>
          )}
        </Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Box>
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: 'System Preferences',
        link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
      },
    };

    return (
      <Box sx={{ p: 3 }}>
        <Typography
          variant='h5'
          label={t(
            'CheckCameraAndMicPermissionDialog.CantUseYourCameraOrMicrophone'
          )}
        />

        <Typography
          sx={{ fontFamily: 'poppins' }}
          label={t(
            'CheckCameraAndMicPermissionDialog.YourBrowserMightNotHaveAccessToYourCameraOrMicrophoneToFixThisProblemOpen'
          )}
        >
          {
            // @ts-ignore
            settingsDataByOS[browser.getOSName()] ? (
              <Link
                onClick={() => {
                  window.open(
                    // @ts-ignore
                    settingsDataByOS[browser.getOSName()].link,
                    '_blank'
                  );
                }}
              >
                {
                  // @ts-ignore
                  settingsDataByOS[browser.getOSName()].name
                }
              </Link>
            ) : (
              t('CheckCameraAndMicPermissionDialog.Settings')
            )
          }
          .
        </Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Box>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <Box sx={{ p: 3 }}>
        <Typography
          variant='h5'
          label={t(
            'CheckCameraAndMicPermissionDialog.CanNotStartYourCameraOrMicrophone'
          )}
        />

        <Typography
          sx={{ fontFamily: 'poppins' }}
          label={t(
            'CheckCameraAndMicPermissionDialog.AnotherApplicationZoomWebexOrBrowserTabGoogleMeetMessengerVideoMightAlreadyBeUsingYourWebcamPleaseTurnOffOtherCamerasBeforeProceeding'
          )}
        />

        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Box>
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case DialogType.explanation:
        return _renderExplanationDialog();
      case DialogType.systemDenied:
        return _renderSystemDeniedDialog();
      case DialogType.userDenied:
        return _renderUserDeniedDialog();
      case DialogType.trackError:
        return _renderTrackErrorDialog();
    }
  };

  return (
    <Dialog
      sx={{
        zIndex: (theme) => {
          return theme.zIndex.drawer - 1;
        },
      }}
      open={!!showDialog}
    >
      {showDialog && _renderDialogContent()}
    </Dialog>
  );
};

export default CheckCameraAndMicPermissionDialog;
