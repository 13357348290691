/** @format */

import i18next from 'i18next';
import {
  billingDataFailure,
  billingDataLoad,
  billingDataSuccess,
} from 'redux/slices/Billing-slice';
import { AppThunk } from 'redux/store';
import { AdminUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { BillingDataArray, BillingDataPayload } from 'types/Billing-types';

export const getBillingDataServiceApi =
  (values: BillingDataPayload): AppThunk =>
  async (dispatch) => {
    dispatch(billingDataLoad());

    await commonAPI
      .get(AdminUrls.getBillingApiUrl.url(values))
      .then((response) => {
        const formatDate = (dateString: string) => {
          const datetimeObj = new Date(dateString);
          const formattedDate = datetimeObj.toISOString().slice(0, 10);
          return formattedDate;
        };
        const modifiedData = response.data.map((item: BillingDataArray) => ({
          ...item,
          used_in_what_month: formatDate(item.used_in_what_month),
        }));
        dispatch(billingDataSuccess(modifiedData));
      })
      .catch((error) =>
        dispatch(
          billingDataFailure(error || i18next.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
        )
      );
  };
