/** @format */

import { Modal, Box } from '@mui/material';
import React from 'react';
import Button from './CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function LogOutModal({ logoutOpen, handleLogout, handleLogoutClose }: any) {
  return (
    <Modal
      open={logoutOpen}
      onClose={handleLogoutClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 200,
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: 24,
          fontFamily: 'Poppins',
          p: 4,
        }}
      >
        <Typography
          sx={{ textAlign: 'center' }}
          id='modal-modal-title'
          variant='h6'
          component='h2'
          label='Are you sure you want to sign out?'
        />

        <Box
          sx={{
            position: 'relative',
            top: '25%',
          }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          gap={3}
        >
          <Button
            sx={{ backgroundColor: '#1206f9', color: '#fff' }}
            variant='contained'
            onClick={handleLogout}
            label='Yes'
            id='logoutBtnid'
          />
          <Button
            sx={{ backgroundColor: '#1206f9', color: '#fff' }}
            variant='contained'
            onClick={handleLogoutClose}
            label='No'
            id=''
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default LogOutModal;
