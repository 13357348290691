/** @format */

import { createSlice } from '@reduxjs/toolkit';

interface AcceptCookiesSliceInitialState {
  isLoadingAcceptCookies: boolean;
  acceptCookiesData: Array<any>;
  errorAcceptCookies: string;
}

const acceptCookiesSliceInitialState: AcceptCookiesSliceInitialState = {
  isLoadingAcceptCookies: false,
  acceptCookiesData: [],
  errorAcceptCookies: '',
};

const acceptCookiesSlice = createSlice({
  name: 'acceptCookiesSlice',
  initialState: acceptCookiesSliceInitialState,
  reducers: {
    acceptCookiesLoad(state) {
      state.isLoadingAcceptCookies = true;
    },
    acceptCookiesSuccess(state, action) {
      state.isLoadingAcceptCookies = false;
      state.acceptCookiesData = action.payload;
    },
    acceptCookiesFailure(state, action) {
      state.isLoadingAcceptCookies = false;
      state.errorAcceptCookies = action.payload;
    },
  },
});

export const { acceptCookiesLoad, acceptCookiesSuccess, acceptCookiesFailure } =
  acceptCookiesSlice.actions;
export { acceptCookiesSlice };
