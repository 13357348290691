/** @format */

import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const FeedBackSchema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    NiceToHave: Yup.string().min(1).required(t('feedback.Name')),

    Name: Yup.string()
      .required(t('feedback.NameRequired'))
      .min(6, t('feedback.NameMustBeAtLeast6Characters')),

    Email: Yup.string().email().required(t('feedback.EmailRequired')),
    Subject: Yup.string().min(2).required(t('feedback.SubJectCanNotBeEmpty')),
    DetailOfResponse: Yup.string()
      .min(2)
      .required(t('feedback.DetailsCanNotBeEmpty')),
  });
};
