/** @format */

import { i18n } from 'i18n/i18n';
import {
  getAvailableSubscriptionPlansFailure,
  getAvailableSubscriptionPlansLoad,
  getAvailableSubscriptionPlansSuccess,
  getStripeSessionIdFailure,
  getStripeSessionIdLoad,
  getStripeSessionIdSuccess,
  getSubscriptionFailure,
  getSubscriptionLoad,
  getSubscriptionSuccess,
  validateCouponCodeFailure,
  validateCouponCodeLoad,
  validateCouponCodeSuccess,
} from 'redux/slices/Subscriptions-slice';
import { AppThunk } from 'redux/store';
import { ProtectedRoutes } from 'routes/routes';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { GetStripeSessionIdPayloadType } from 'types/Subscription-types';
import { Toast } from 'widgets/Toast/Toast';
import { History } from 'history';

export const getSubscriptionServiceApi = (): AppThunk => async (dispatch) => {
  dispatch(getSubscriptionLoad());

  await commonAPI
    .get(ProtectedUrls.getSubscriptionUrl.url())
    .then((response) => {
      dispatch(getSubscriptionSuccess(response.data));
    })
    .catch((error) =>
      dispatch(
        getSubscriptionFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
      )
    );
};

export const getStripeSessionIdServiceApi =
  (payload: GetStripeSessionIdPayloadType): AppThunk =>
  async (dispatch) => {
    dispatch(getStripeSessionIdLoad());

    await commonAPI
      .get(ProtectedUrls.getStripeSessionIdUrl.url(payload))
      .then((response) => {
        dispatch(getStripeSessionIdSuccess(response.data));
      })
      .catch((error) =>
        dispatch(
          getStripeSessionIdFailure(
            error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
          )
        )
      );
  };

export const getAvailableSubscriptionPlansServiceApi =
  (): AppThunk => async (dispatch) => {
    dispatch(getAvailableSubscriptionPlansLoad());

    await commonAPI
      .get(ProtectedUrls.getAvailableSubscriptionPlansUrl.url())
      .then((response) => {
        dispatch(getAvailableSubscriptionPlansSuccess(response.data));
      })
      .catch((error) =>
        dispatch(
          getAvailableSubscriptionPlansFailure(
            error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
          )
        )
      );
  };

export const validateCouponCodeServiceApi =
  (couponCode: string, history: History): AppThunk =>
  async (dispatch) => {
    dispatch(validateCouponCodeLoad());

    await commonAPI
      .get(ProtectedUrls.validateCouponCode.url(couponCode))
      .then((response) => {
        // setTimeout(() => {
        dispatch(validateCouponCodeSuccess(response.data));
        history.push({
          pathname: ProtectedRoutes.StripeSubscriptionPaymentSuccess,
          state: { status: 'success' },
        });
        // }, 5000);
      })
      .catch((error) => {
        Toast(error.response.data.message, 'error');
        dispatch(
          validateCouponCodeFailure(
            error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
          )
        );
      });
  };
