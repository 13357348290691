import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Toast } from 'widgets/Toast/Toast';
import { useTranslation } from 'react-i18next';

export default function CopyLinkSettingsModal({
  openCopyLinkSettingsModal,
  handleCloseCopyLinkSettingsModal,
  selectedAssessmentType,
  generateSendCandidateAssessmentLinkToSend,
}: {
  openCopyLinkSettingsModal: boolean;
  handleCloseCopyLinkSettingsModal: () => void;
  selectedAssessmentType: {
    id: number;
    name: string;
    type?: string;
    description?: string;
  };
  generateSendCandidateAssessmentLinkToSend: Function;
}) {
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await generateSendCandidateAssessmentLinkToSend();
  };

  return (
    <Dialog
      open={openCopyLinkSettingsModal}
      onClose={handleCloseCopyLinkSettingsModal}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle> {t('SendAssessments.LinkSettings')} </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>{selectedAssessmentType.name}</DialogContentText>

          {/* <TextField
            onBlur={handleBlur}
            type='number'
            value={value}
            onChange={handleChange}
            inputProps={{ min: 0 }}
            placeholder={t('SendAssessments.EnterHrs')}
            label={t('SendAssessments.EnterExpirationInHrs')}
            sx={{ mt: 3 }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={isInvalid}
            helperText={
              isInvalid
                ? t(
                    'SendAssessments.PleaseEnsureYouProvideTheLinkExpirationDuration'
                  )
                : ''
            }
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCopyLinkSettingsModal}>
            {' '}
            {t('SendAssessments.Close')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            sx={{
              borderRadius: '1rem',
              background: '#1206F9',
              pl: 4,
              pr: 4,
              width: 'fit-content',
              height: 'fit-content',

              fontSize: '0.75rem',
              fontWeight: 500,

              color: '#fff',
            }}
          >
            {t('SendAssessments.Copy')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
