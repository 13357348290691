import { Box } from '@mui/material';
import React from 'react';
import Typography from 'widgets/Typography/Typography';
import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isSafari,
} from 'react-device-detect';
import PatientWorkflowIosRecord from './PatientWorkflowIosRecord';
import PatientWorkflowWebRecord from './PatientWorkflowWebRecord';
import {
  DeviceInfo,
  QuestionnaireData,
} from 'types/TeleHealth/PatientWorkflow-types';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';

function RecorderSettingComponent({
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  setVideoDevices,
  setAudioDevices,
}: {
  goToPatientWorkflowStep: Function;
  videoDevices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  selectedVideoDevice: CameraDetails;
  selectedAudioDevice: MicrophoneDetails;
  setSelectedVideoDevice: Function;
  setSelectedAudioDevice: Function;
  setVideoDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  setAudioDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
}) {
  return isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13 ? (
    <PatientWorkflowIosRecord
      goToPatientWorkflowStep={goToPatientWorkflowStep}
      videoDevices={videoDevices}
      audioDevices={audioDevices}
      selectedVideoDevice={selectedVideoDevice}
      selectedAudioDevice={selectedAudioDevice}
      setSelectedVideoDevice={setSelectedVideoDevice}
      setSelectedAudioDevice={setSelectedAudioDevice}
      setVideoDevices={setVideoDevices}
      setAudioDevices={setAudioDevices}
    />
  ) : (
    <PatientWorkflowWebRecord
      goToPatientWorkflowStep={goToPatientWorkflowStep}
      videoDevices={videoDevices}
      audioDevices={audioDevices}
      selectedVideoDevice={selectedVideoDevice}
      selectedAudioDevice={selectedAudioDevice}
      setSelectedVideoDevice={setSelectedVideoDevice}
      setSelectedAudioDevice={setSelectedAudioDevice}
      setVideoDevices={setVideoDevices}
      setAudioDevices={setAudioDevices}
    />
  );
}

export default RecorderSettingComponent;
