import { ProcessDataListVersion2InitialStateTypes } from 'types/Gallery.types';
import { createSlice } from '@reduxjs/toolkit';
import { ReprocessVersion2VideoInitialStateTypes } from 'types/Gallery.types';
const ProcessDataListVersion2InitialState: ProcessDataListVersion2InitialStateTypes =
  {
    isLoadingProcessDataListVersion2: false,
    errorProcessDataListVersion2: '',
    processDataListVersion2: [],
  };
const ReprocessVersion2VideoInitialState: ReprocessVersion2VideoInitialStateTypes =
  {
    isLoadingReprocessVersion2Video: false,
    errorReprocessVersion2Video: '',
    reprocessVersion2VideoList: [],
  };

const getProcessDataListVersion2Slice = createSlice({
  name: 'getProcessDataListSlice',
  initialState: ProcessDataListVersion2InitialState,
  reducers: {
    ProcessDataListVersion2Load(state) {
      state.isLoadingProcessDataListVersion2 = true;
    },
    ProcessDataListVersion2Success(state, action) {
      state.isLoadingProcessDataListVersion2 = false;
      state.processDataListVersion2 = action.payload;
    },
    ProcessDataListVersion2Failure(state, action) {
      state.isLoadingProcessDataListVersion2 = false;
      state.errorProcessDataListVersion2 = action.payload;
    },
  },
});

export const {
  ProcessDataListVersion2Load,
  ProcessDataListVersion2Success,
  ProcessDataListVersion2Failure,
} = getProcessDataListVersion2Slice.actions;
export { getProcessDataListVersion2Slice };

const getReprocessVersion2VideoSlice = createSlice({
  name: 'getReprocessVersion2VideoSlice',
  initialState: ReprocessVersion2VideoInitialState,
  reducers: {
    ReprocessVersion2VideoLoad(state) {
      state.isLoadingReprocessVersion2Video = true;
    },
    ReprocessVersion2VideoSuccess(state, action) {
      state.isLoadingReprocessVersion2Video = false;
      state.reprocessVersion2VideoList = action.payload;
    },
    ReprocessVersion2VideoFailure(state, action) {
      state.isLoadingReprocessVersion2Video = false;
      state.errorReprocessVersion2Video = action.payload;
    },
  },
});

export const {
  ReprocessVersion2VideoLoad,
  ReprocessVersion2VideoSuccess,
  ReprocessVersion2VideoFailure,
} = getReprocessVersion2VideoSlice.actions;
export { getReprocessVersion2VideoSlice };

const DeleteProcessVideoV2InitialState = {
  isDeletingProcessVideo: false,
  deleteProcessVideoError: '',
};

const deleteProcessVideoV2Slice = createSlice({
  name: 'deleteProcessVideoSlice',
  initialState: DeleteProcessVideoV2InitialState,
  reducers: {
    deleteProcessVideoV2Load(state) {
      state.isDeletingProcessVideo = true;
      state.deleteProcessVideoError = '';
    },
    deleteProcessVideoV2Success(state) {
      state.isDeletingProcessVideo = false;
    },
    deleteProcessVideoV2Failure(state, action) {
      state.isDeletingProcessVideo = false;
      state.deleteProcessVideoError = action.payload;
    },
  },
});

export const {
  deleteProcessVideoV2Load,
  deleteProcessVideoV2Success,
  deleteProcessVideoV2Failure,
} = deleteProcessVideoV2Slice.actions;

export { deleteProcessVideoV2Slice };
