import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from 'widgets/Typography/Typography';
import Button from 'widgets/CustomButton/Button';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SubmitAssessmentConfirmationDialog({
  openSubmitAssessmentConfirmationDialog,
  handleCloseSubmitAssessmentConfirmationDialog,
  isFromCandidateAssessment,
}: {
  openSubmitAssessmentConfirmationDialog: boolean;
  handleCloseSubmitAssessmentConfirmationDialog: () => void;
  isFromCandidateAssessment: boolean;
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleCloseSubmitAssessmentConfirmationDialog}
        aria-labelledby='customized-dialog-title'
        open={openSubmitAssessmentConfirmationDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          {t('MyAssessments.MyAssessments')}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleCloseSubmitAssessmentConfirmationDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            gutterBottom
            label={
              isFromCandidateAssessment
                ? t('MyAssessments.YourAssessmentHasBeenSubmitted')
                : t(
                    'MyAssessments.YourAssessmentIsBeingUploadedAndAnalyzedAndWillBeAvailableInGalleryAfterAWhile'
                  )
            }
          ></Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            autoFocus
            onClick={handleCloseSubmitAssessmentConfirmationDialog}
            label={t('MyAssessments.Close')}
          ></Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
