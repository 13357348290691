import {
  ProcessDataListForParticipantLoad,
  ProcessDataListForParticipantSuccess,
  ProcessDataListForParticipantFailure,
} from 'redux/slices/VideoPreview-slice';

import i18n from 'i18next';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  ProcessDataListForParticipantVersion2Failure,
  ProcessDataListForParticipantVersion2Load,
  ProcessDataListForParticipantVersion2Success,
  updateVideoTitleLoad,
  updateVideoTitleFailure,
  updateVideoTitleSuccess,
  processStatusFailure,
  processStatusLoad,
  processStatusSuccess,
  UpdateVideoTitleForIframeLoad,
  getTranscriptDataLoad,
  getTranscriptDataSuccess,
  getTranscriptDataFailure,
} from 'redux/slices/videoPreviewVersion2-slice';
import {
  CoachingSessionReport,
  updateVideoTitlePayload,
} from 'types/VideoPreview-types';
import { Toast } from 'widgets/Toast/Toast';
import axios, { AxiosHeaderValue } from 'axios';

export const GetProcessedDataListForParticipantServiceAPI =
  (metricsProcessingId: number): AppThunk =>
  async (dispatch) => {
    dispatch(ProcessDataListForParticipantLoad());

    await commonAPI
      .get(
        ProtectedUrls.getProcessedDataListForParticipantService.URL(
          metricsProcessingId
        )
      )
      .then((response) => {
        dispatch(ProcessDataListForParticipantSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          ProcessDataListForParticipantFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };

export const GetProcessedDataListForParticipantsVersion2ServiceAPI =
  (coaching_session_reports_id: number): AppThunk =>
  async (dispatch) => {
    dispatch(ProcessDataListForParticipantVersion2Load());

    await commonAPI
      .get(
        ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
          coaching_session_reports_id
        )
      )
      .then((response) => {
        if (response.data?.overall && response.data?.prompt_sections) {
          const temp = [
            {
              ...response.data.overall[0],
              prompt_sections: response.data.prompt_sections,
            },
          ];

          dispatch(ProcessDataListForParticipantVersion2Success(temp));
        } else {
          dispatch(ProcessDataListForParticipantVersion2Success(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          ProcessDataListForParticipantVersion2Failure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };

export const updateVideoTitleService =
  (payload: updateVideoTitlePayload): AppThunk =>
  async (dispatch) => {
    dispatch(updateVideoTitleLoad());
    try {
      const response = await commonAPI.post(
        ProtectedUrls.updateVideoTitle.url(payload)
      );

      dispatch(updateVideoTitleSuccess(response.data));
      Toast(
        i18n.t('toastMessageNotifications.YourVideoTitleHasBeenUpdated'),
        'success'
      );
    } catch (error) {
      dispatch(
        updateVideoTitleFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };

export const getProcessStatusService =
  (coaching_session_reports_id: number): AppThunk =>
  async (dispatch) => {
    dispatch(processStatusLoad());

    await commonAPI
      .get(ProtectedUrls.getProcessStatus.URL(coaching_session_reports_id))
      .then((response) => {
        dispatch(processStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          processStatusFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };
export const handleUpdateVideoTitleForIframeService =
  (
    setIsEditing: Function,
    setLoading: Function,
    sessionId: number,
    editedTitle: string,
    apiKey: AxiosHeaderValue,
    env: String,
    setProcessDataListForParticipantsVersion2: Function,
    ProcessDataListForParticipantsVersion2: CoachingSessionReport[],
    setEditedTitle: Function,
    setForceRender: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(UpdateVideoTitleForIframeLoad());
    setIsEditing(false);
    setLoading(true);
    const payload = {
      coaching_session_reports_id: sessionId,
      video_title: editedTitle,
    };

    if (sessionId && apiKey) {
      let updateUrl = '';
      if (env === 'pd') {
        updateUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.updateVideoTitle.url(payload)
        }`;
      } else if (env === 'ci') {
        updateUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.updateVideoTitle.url(payload)
        }`;
      } else if (env === 'qa') {
        updateUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.updateVideoTitle.url(payload)
        }`;
      } else if (env === 'ua') {
        updateUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.updateVideoTitle.url(payload)
        }`;
      } else {
        updateUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.updateVideoTitle.url(payload)
        }`;
      }
      let processDataUrl = '';
      if (env === 'pd') {
        processDataUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'ci') {
        processDataUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'qa') {
        processDataUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'ua') {
        processDataUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else {
        processDataUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      }

      const [, processDataResponse] = await axios.all([
        await axios.post(updateUrl, {}, { headers: { 'x-api-key': apiKey } }),
        await axios.get(processDataUrl, {
          headers: { 'x-api-key': apiKey, Accept: 'application/json' },
        }),
      ]);
      if (processDataResponse.status === 200) {
        Toast('Session title updated successfully', 'success');
        setProcessDataListForParticipantsVersion2(processDataResponse.data);
        const updatedTitle =
          ProcessDataListForParticipantsVersion2.length > 0
            ? ProcessDataListForParticipantsVersion2[0]?.session_title
              ? ProcessDataListForParticipantsVersion2[0]?.session_title
              : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
            : '';

        setEditedTitle(updatedTitle);
        setForceRender((prev: Boolean) => !prev);
        setLoading(false);
      } else {
        Toast('Some Error occured. Please Try Again', 'error');
        setLoading(false);
      }
    }
  };

export const getTranscriptDataService =
  (coaching_session_reports_id: number): AppThunk =>
  async (dispatch) => {
    dispatch(getTranscriptDataLoad());

    await commonAPI
      .get(ProtectedUrls.getTranscriptData.url(coaching_session_reports_id))
      .then((response) => {
        dispatch(getTranscriptDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          getTranscriptDataFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };
