/** @format */

import React from 'react';

function DashboardTablePlayVideoIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
    >
      <circle cx='7' cy='7' r='6.5' stroke='#1206F9' />
      <path
        d='M5.76465 9.88234V4.94116L9.8823 7.41175L5.76465 9.88234Z'
        fill='#1206F9'
      />
    </svg>
  );
}

export default DashboardTablePlayVideoIcon;
