/** @format */

import { createSlice } from '@reduxjs/toolkit';
import {
  FeatureFlagsInitialStateTypes,
  UpdateFeatureFlagsInitialStateTypes,
} from 'types/common/Feature-flags-types';

const featureFlagsInitialState: FeatureFlagsInitialStateTypes = {
  isLoadingFeatureFlags: false,
  errorFeatureFlags: '',
  featureFlags: [],
};
const getFeatureFlagsSlice = createSlice({
  name: 'api',
  initialState: featureFlagsInitialState,
  reducers: {
    getFeatureFlagsLoad(state) {
      state.isLoadingFeatureFlags = true;
    },
    getFeatureFlagsSuccess(state, action) {
      state.isLoadingFeatureFlags = false;
      state.featureFlags = action.payload;
    },
    getFeatureFlagsFailure(state, action) {
      state.isLoadingFeatureFlags = false;
      state.errorFeatureFlags = action.payload;
    },
  },
});

export const {
  getFeatureFlagsLoad,
  getFeatureFlagsSuccess,
  getFeatureFlagsFailure,
} = getFeatureFlagsSlice.actions;
export { getFeatureFlagsSlice };

//update
const updateFeatureFlagsInitialState: UpdateFeatureFlagsInitialStateTypes = {
  isLoadingUpdateFeatureFlags: false,
  errorUpdateFeatureFlags: '',
  updateFeatureFlags: [],
};
const updateFeatureFlagsSlice = createSlice({
  name: 'api',
  initialState: updateFeatureFlagsInitialState,
  reducers: {
    updateFeatureFlagsLoad(state) {
      state.isLoadingUpdateFeatureFlags = true;
    },
    updateFeatureFlagsSuccess(state, action) {
      state.isLoadingUpdateFeatureFlags = false;
      state.updateFeatureFlags = action.payload;
    },
    updateFeatureFlagsFailure(state, action) {
      state.isLoadingUpdateFeatureFlags = false;
      state.errorUpdateFeatureFlags = action.payload;
    },
  },
});

export const {
  updateFeatureFlagsLoad,
  updateFeatureFlagsSuccess,
  updateFeatureFlagsFailure,
} = updateFeatureFlagsSlice.actions;
export { updateFeatureFlagsSlice };
