import { createSlice } from '@reduxjs/toolkit';
import {
  AddUserToAssignmentListInitialStateType,
  SendMailOfAssignmentsInitialStateType,
} from 'types/DeepRecruiterTypes/InviteCandidateForInterview-types';

const AddUserToAssignmentListInitialState: AddUserToAssignmentListInitialStateType =
  {
    isLoadingAddUserToAssignmentList: false,
    addUserToAssignmentList: '',
    errorAddUserToAssignmentList: false,
  };

const addUserToAssignmentListSlice = createSlice({
  name: ' addUserToAssignmentListSlice',
  initialState: AddUserToAssignmentListInitialState,
  reducers: {
    addUserToAssignmentListLoad(state) {
      state.isLoadingAddUserToAssignmentList = true;
    },
    addUserToAssignmentListSuccess(state, action) {
      state.isLoadingAddUserToAssignmentList = false;
      state.addUserToAssignmentList = action.payload;
    },
    addUserToAssignmentListFailure(state, action) {
      state.isLoadingAddUserToAssignmentList = false;
      state.errorAddUserToAssignmentList = action.payload;
    },
  },
});

export const {
  addUserToAssignmentListLoad,
  addUserToAssignmentListSuccess,
  addUserToAssignmentListFailure,
} = addUserToAssignmentListSlice.actions;

export { addUserToAssignmentListSlice };

const SendMailToUserInitialState: SendMailOfAssignmentsInitialStateType = {
  isLoadingSendMailToUser: false,
  SendMailToUser: [],
  errorSendMailToUser: false,
};

const SendMailToUserSlice = createSlice({
  name: ' SendMailToUserInitialState',
  initialState: SendMailToUserInitialState,
  reducers: {
    SendMailToUserLoad(state) {
      state.isLoadingSendMailToUser = true;
    },
    SendMailToUserSuccess(state, action) {
      state.isLoadingSendMailToUser = false;
      state.SendMailToUser = action.payload;
    },
    SendMailToUserFailure(state, action) {
      state.isLoadingSendMailToUser = false;
      state.errorSendMailToUser = action.payload;
    },
  },
});

export const {
  SendMailToUserLoad,
  SendMailToUserSuccess,
  SendMailToUserFailure,
} = SendMailToUserSlice.actions;

export { SendMailToUserSlice };
