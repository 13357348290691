/** @format */

import React from 'react';

function RecordVideoButtonIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <circle cx='11' cy='11' r='10.5' fill='white' stroke='#D81722' />
      <circle cx='11.2222' cy='11.2222' r='6.22222' fill='#D81722' />
    </svg>
  );
}

export default RecordVideoButtonIcon;
