import React from 'react';

function MyAssessmentsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
    >
      <g clip-path='url(#clip0_116_191)'>
        <path
          d='M3.92116 2.29605V0.936523H1.15034C0.846918 0.936523 0.55061 1.04494 0.336376 1.23411C0.122465 1.42327 0 1.68519 0 1.95253V18.9841C0 19.2517 0.122465 19.5137 0.336699 19.7025C0.550933 19.892 0.846918 20.0004 1.15066 20.0004H14.8497C15.1524 20.0004 15.4494 19.892 15.6627 19.7025C15.8769 19.5137 16 19.252 16 18.9841V1.95253C16 1.6849 15.8769 1.42327 15.6627 1.23411C15.4491 1.04494 15.1524 0.936523 14.8497 0.936523H12.2433V2.29605H14.8497V18.4563H1.15034V2.29605H3.92116Z'
          fill='#1906F9'
        />
        <path
          d='M11.5373 0.936523H4.62686V2.29605H11.5373V0.936523Z'
          fill='#616E7D'
        />
        <path
          d='M1.15035 2.78436V17.968C1.15035 18.2376 1.39786 18.4564 1.70354 18.4564H14.2965C14.6018 18.4564 14.8497 18.2379 14.8497 17.968V2.78436C14.8497 2.51473 14.6022 2.2959 14.2965 2.2959H12.7962C12.4908 2.2959 12.243 2.51445 12.243 2.78436V3.2634C12.243 3.34529 12.2055 3.42546 12.1399 3.48366C12.0737 3.54158 11.9832 3.57496 11.8901 3.57496H4.2737C4.18096 3.57496 4.08952 3.54187 4.02392 3.48366C3.95833 3.42546 3.92085 3.34529 3.92085 3.2634V2.78436C3.92085 2.51473 3.67333 2.2959 3.36765 2.2959H1.70322C1.39786 2.2959 1.15002 2.51445 1.15002 2.78436H1.15035ZM13.1452 15.4563H2.8542C2.54885 15.4563 2.30101 15.2378 2.30101 14.9679V14.8634C2.30101 14.5938 2.54852 14.375 2.8542 14.375H13.1455C13.4509 14.375 13.6987 14.5935 13.6987 14.8634V14.9679C13.6987 15.2375 13.4512 15.4563 13.1455 15.4563H13.1452ZM13.1452 13.0098H2.8542C2.54885 13.0098 2.30101 12.7912 2.30101 12.5213V12.4172C2.30101 12.1475 2.54852 11.9287 2.8542 11.9287H13.1455C13.4509 11.9287 13.6987 12.1472 13.6987 12.4172V12.5213C13.6987 12.7909 13.4512 13.0098 13.1455 13.0098H13.1452ZM13.1452 10.5635H2.8542C2.54885 10.5635 2.30101 10.3449 2.30101 10.075V9.97087C2.30101 9.70125 2.54852 9.48241 2.8542 9.48241H13.1455C13.4509 9.48241 13.6987 9.70096 13.6987 9.97087V10.075C13.6987 10.3446 13.4512 10.5635 13.1455 10.5635H13.1452ZM13.1452 8.11718H2.8542C2.54885 8.11718 2.30101 7.89863 2.30101 7.62872V7.52401C2.30101 7.25438 2.54852 7.03555 2.8542 7.03555H13.1455C13.4509 7.03555 13.6987 7.2541 13.6987 7.52401V7.62872C13.6987 7.89834 13.4512 8.11718 13.1455 8.11718H13.1452ZM13.6984 5.078V5.18243C13.6984 5.45205 13.4509 5.67089 13.1452 5.67089H2.8542C2.54885 5.67089 2.30101 5.45234 2.30101 5.18243V5.078C2.30101 4.80838 2.54852 4.58954 2.8542 4.58954H13.1455C13.4509 4.58954 13.6987 4.8081 13.6987 5.078H13.6984Z'
          fill='white'
        />
        <path
          d='M11.5373 2.2959H4.62686V2.95184H11.5373V2.2959Z'
          fill='white'
        />
        <path
          d='M4.274 4.11742e-05C4.18126 4.11742e-05 4.08982 0.0331377 4.02422 0.0913419C3.95863 0.149261 3.92114 0.229434 3.92114 0.311605V3.26376C3.92114 3.34564 3.95863 3.42582 4.02422 3.48402C4.08982 3.54194 4.18126 3.57532 4.274 3.57532H11.8904C11.9835 3.57532 12.074 3.54223 12.1402 3.48402C12.2058 3.42582 12.2433 3.34564 12.2433 3.26376V0.31132C12.2433 0.229434 12.2058 0.148976 12.1402 0.0910566C12.074 0.0328524 11.9835 -0.000244141 11.8904 -0.000244141H4.274V4.11742e-05ZM11.5376 0.936445V2.95191H4.62718V0.623169H11.5376V0.936445Z'
          fill='#07DCAA'
        />
        <path
          d='M13.086 7.03613H2.91332C2.57514 7.03613 2.30099 7.2782 2.30099 7.5768C2.30099 7.87541 2.57514 8.11748 2.91332 8.11748H13.086C13.4242 8.11748 13.6984 7.87541 13.6984 7.5768C13.6984 7.2782 13.4242 7.03613 13.086 7.03613Z'
          fill='#07DCAA'
        />
        <path
          d='M13.0864 9.48267H2.913C2.575 9.48267 2.30099 9.72461 2.30099 10.0231C2.30099 10.3215 2.575 10.5634 2.913 10.5634H13.0864C13.4244 10.5634 13.6984 10.3215 13.6984 10.0231C13.6984 9.72461 13.4244 9.48267 13.0864 9.48267Z'
          fill='#07DCAA'
        />
        <path
          d='M13.0864 11.929H2.913C2.575 11.929 2.30099 12.1709 2.30099 12.4693C2.30099 12.7678 2.575 13.0097 2.913 13.0097H13.0864C13.4244 13.0097 13.6984 12.7678 13.6984 12.4693C13.6984 12.1709 13.4244 11.929 13.0864 11.929Z'
          fill='#07DCAA'
        />
        <path
          d='M13.0864 14.3752H2.913C2.575 14.3752 2.30099 14.6172 2.30099 14.9156V14.9159C2.30099 15.2144 2.575 15.4563 2.913 15.4563H13.0864C13.4244 15.4563 13.6984 15.2144 13.6984 14.9159V14.9156C13.6984 14.6172 13.4244 14.3752 13.0864 14.3752Z'
          fill='#07DCAA'
        />
      </g>
      <defs>
        <clipPath id='clip0_116_191'>
          <rect width='16' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MyAssessmentsIcon;
