/** @format */

import { useMsal } from '@azure/msal-react';
import useAuth from 'hooks/useAuth';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';

export async function getUserAndCheckAuthProviderOfUser(
  authProvider: string,
  defaultMsg: string
) 
{
  switch (authProvider) {
    case 'Mirro':
      if (JSON.parse(localStorage.getItem('isSSO') as string)) {
        Toast(i18n.t('toastMessageNotifications.EmailAlreadyLinkedToMirroLoginPleaseUseADifferentEmailOrLoginWithYourMirroAccount'),
          'error'
        );
      } else {
        Toast(defaultMsg, 'error');
      }
      sessionStorage.clear();
      localStorage.clear();
      break;
    case 'Microsoft':
      Toast(
        i18n.t('toastMessageNotifications.EmailAlreadyLinkedToMicrosoftLoginPleaseUseADifferentEmailOrLoginWithYourMicrosoftAccount'),
        'error'
      );
      sessionStorage.clear();
      localStorage.clear();
      break;
    case 'Google':
      Toast(
        i18n.t('toastMessageNotifications.EmailAlreadyLinkedToGoogleLoginPleaseUseADifferentEmailOrLogInWithYourGoogleAccount'),
        'error'
      );
      sessionStorage.clear();
      localStorage.clear();
      break;
    case 'Facebook':
      Toast(
        i18n.t('toastMessageNotifications.EmailAlreadyLinkedToFacebookLoginPleaseUseADifferentEmailOrLogInWithYourFacebookAccount'),
        'error'
      );
      sessionStorage.clear();
      localStorage.clear();
      break;
    default:
      Toast(defaultMsg, 'error');
      sessionStorage.clear();
      localStorage.clear();
  }
}

export async function handleSuccessfulLogin(userData: {
  [key: string]: string;
}) {
  const { data } = await commonAPI.get(
    ProtectedUrls.getAuthProviderDetails.url()
  );

  const authProviderName = Object.keys(data).find(
    (key) => data[key] === userData.auth_provider
  );

  const userSessionData: {
    [key: string]: string;
  } = {
    first_name: userData.first_name,
    last_name: userData.last_name,
    username: userData.username,
    profile_image_bytes: userData.profile_image_bytes,
    apiKey: userData.apikey,
    userRole: userData.role,
    userName: userData.username,
    loggedInUserDetails: JSON.stringify(userData),
    authProviderName: authProviderName
      ? authProviderName
      : 'NoAuthProviderFound',
  };

  Object.keys(userSessionData).forEach((key) => {
    sessionStorage.setItem(key, userSessionData[key]);
  });
}
