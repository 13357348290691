import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Paper,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getSessionTypesSelector,
  sendAssessmentEmailToCandidatesSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import PromptsModal from './PromptsModal';
import AddEmailComponent from './AddEmailComponent';
import { Toast } from 'widgets/Toast/Toast';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import AssessmentTypeCard from './AssessmentTypeCard';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Loader from 'widgets/Loader/Loader';
import { sendAssessmentEmailToCandidatesServiceApi } from 'services/SendAssessments-service';
import env from 'envConfig';
import { UploadMediaData } from 'types/Upload-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyLinkSettingsModal from './CopyLinkSettingsModal';
import { useTranslation } from 'react-i18next';
const linkExpiryHrs = 24;

function SendCandidateAssessments() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedAssessmentType, setSelectedAssessmentType] = useState<{
    id: number;
    name: string;
    type?: string;
    description?: string;
  }>({
    id: -1,
    name: '',
    type: '',
    description: '',
  });

  const { SessionTypes, isLoadingSessionTypes } = useAppSelector(
    getSessionTypesSelector
  );

  const handleSetAssementType = useCallback(
    (newValue: {
      id: number;
      name: string;
      type?: string;
      description?: string;
    }) => {
      if (newValue && typeof setSelectedAssessmentType === 'function') {
        setSelectedAssessmentType(newValue);
      }
    },
    [setSelectedAssessmentType]
  );

  useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1, true));
  }, []);
  const [open, setOpen] = React.useState(false);
  const [currentlySelectedViewPromptsId, setCurrentlySelectedViewPromptsId] =
    useState(-1);

  const handleClickOpen = (id: number) => {
    setCurrentlySelectedViewPromptsId(id);
    setOpen(true);
  };

  const handleClosePromptsModal = () => {
    setOpen(false);
  };

  const [candidateEmailList, setCandidateEmailList] = useState<string[]>([]);

  const [email, setEmail] = useState('');
  function handleAddCandidate(email: string) {
    if (
      candidateEmailList.includes(email.trim().toLowerCase().replace(/\s/g, ''))
    ) {
      Toast(t('SendAssessments.EmailAlreadyExistsInTheList'), 'error');
      return;
    }

    setCandidateEmailList((prevList: string[]) => {
      return [...prevList, email.trim().toLowerCase().replace(/\s/g, '')];
    });
    setEmail('');
  }
  function handleRemoveCandidate(email: string) {
    const updatedList = candidateEmailList.filter(
      (candidateEmail) => candidateEmail !== email
    );
    setCandidateEmailList(updatedList);
  }

  // const origin = window.location.origin;

  function handleSendAssessmentInvitationToCandidate() {
    if (selectedAssessmentType.id === -1) {
      Toast(t('SendAssessments.PleaseSelectAssessmentType'), 'error');
    } else {
      const utcDateTimeString = new Date(
        new Date().getTime() + linkExpiryHrs * 60 * 60 * 1000
      ).toISOString();

      const arrayToEncrypt = {
        senderApiKey: sessionStorage.getItem('apiKey')
          ? sessionStorage.getItem('apiKey')
          : '',
        selectedAssessmentType: selectedAssessmentType,
        expirationDateTimeStampInUtc: utcDateTimeString,
      };

      const encryptedArray = btoa(JSON.stringify(arrayToEncrypt));
      const link = `${window.location.origin}/#/candidateAssessment?token=${encryptedArray}`;
      const payload = {
        candidateAssessmentLinkRedirect: link,
        email_ids: candidateEmailList,
        mirroLogoPath:
          env.REACT_APP_BASE_URL + '/comonImg/mirro-logo-with-namee.png',
        mirroBannerPath:
          env.REACT_APP_BASE_URL + '/comonImg/mirroBannerImage.png',
        hrsToComplete: linkExpiryHrs.toString(),
      };

      dispatch(sendAssessmentEmailToCandidatesServiceApi(payload));
    }
  }
  const [
    currentlySelectedAssessmentToSendvalue,
    setCurrentlySelectedAssessmentToSendvalue,
  ] = React.useState(-1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentlySelectedAssessmentToSendvalue(
      parseInt((event.target as HTMLInputElement).value)
    );
    if (SessionTypes) {
    }
    const sessionTypeDetails: UploadMediaData | undefined = SessionTypes.find(
      (session) =>
        session.id === parseInt((event.target as HTMLInputElement).value)
    );
    if (sessionTypeDetails) handleSetAssementType(sessionTypeDetails);
  };

  const flexContainerRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [isScrollableLeft, setIsScrollableLeft] = React.useState(true);
  const [isScrollableRight, setIsScrollableRight] = React.useState(true);
  React.useEffect(() => {
    const handleScroll = () => {
      if (flexContainerRef.current) {
        setIsScrollableLeft(flexContainerRef.current.scrollLeft > 0);
        setIsScrollableRight(
          flexContainerRef.current.scrollLeft <
            flexContainerRef.current.scrollWidth -
              flexContainerRef.current.offsetWidth
        );
      }
    };

    if (flexContainerRef.current) {
      flexContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (flexContainerRef.current) {
        flexContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  const clickLeft = () => {
    if (flexContainerRef.current) {
      flexContainerRef.current.scrollTo({
        left: flexContainerRef.current.scrollLeft - 250,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const clickRight = () => {
    if (flexContainerRef.current) {
      flexContainerRef.current.scrollTo({
        left: flexContainerRef.current.scrollLeft + 250,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const {
    isLoadingsendAssessmentEmailToCandidates,
    sendAssessmentEmailToCandidatesSuccessData,
  } = useAppSelector(sendAssessmentEmailToCandidatesSelector);

  function handleSuccessSendAssessmentInvitationToCandidate() {
    setCurrentlySelectedAssessmentToSendvalue(-1);
    setCandidateEmailList([]);
    setEmail('');
  }

  useEffect(() => {
    if (Object.keys(sendAssessmentEmailToCandidatesSuccessData).length !== 0) {
      let failedEmails = false;
      for (const email in sendAssessmentEmailToCandidatesSuccessData) {
        const status = sendAssessmentEmailToCandidatesSuccessData[email];
        if (status === 'Failure') {
          failedEmails = true;
          break;
        }
      }
      if (!failedEmails) handleSuccessSendAssessmentInvitationToCandidate();
    }
  }, [sendAssessmentEmailToCandidatesSuccessData]);

  const [openCopyLinkSettingsModal, setOpenCopyLinkSettingsModal] =
    useState(false);

  function handleopenCopyLinkSettingsModal() {
    setOpenCopyLinkSettingsModal(true);
  }
  function handleCloseCopyLinkSettingsModal() {
    setOpenCopyLinkSettingsModal(false);
  }

  async function generateSendCandidateAssessmentLinkToSend() {
    const arrayToEncrypt = {
      senderApiKey: sessionStorage.getItem('apiKey')
        ? sessionStorage.getItem('apiKey')
        : '',
      selectedAssessmentType: selectedAssessmentType,
    };

    const encryptedArray = btoa(JSON.stringify(arrayToEncrypt));
    const link = `${window.location.origin}/#/candidateAssessment?token=${encryptedArray}`;

    await navigator.clipboard
      .writeText(link)
      .then(() => {
        Toast(t('SendAssessments.LinkCopiedToClipboard'), 'success');
      })
      .catch(() => {
        Toast(t('SendAssessments.FailedToCopyLink'), 'error');
      });
  }
  return (
    <AuthWrapper>
      {isLoadingSessionTypes ? (
        <Loader />
      ) : (
        <Paper
          elevation={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'left',
            p: 3,
          }}
        >
          <Typography
            sx={{
              color: ' var(--body-text, #33344B)',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.176p',
            }}
            label={t('SendAssessments.SendAssessments')}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 4,
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={clickLeft}
              disabled={!isScrollableLeft}
              sx={{ height: '10%' }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={currentlySelectedAssessmentToSendvalue}
                onChange={handleChange}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    mt: 4,

                    p: 1,
                    maxWidth: '75vw',
                    overflowX: 'auto',
                  }}
                  ref={flexContainerRef}
                >
                  {SessionTypes.map((session: UploadMediaData) => {
                    return (
                      <AssessmentTypeCard
                        session={session}
                        handleClickOpen={handleClickOpen}
                      />
                    );
                  })}{' '}
                </Box>
              </RadioGroup>
            </FormControl>
            <IconButton
              onClick={clickRight}
              disabled={!isScrollableRight}
              sx={{ height: '10%' }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,

              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddEmailComponent
              handleAddCandidate={handleAddCandidate}
              email={email}
              setEmail={setEmail}
            />

            <Box
              sx={{
                width: '85%',
                height: 150,
                border: '1px solid #BEC1C4',
                p: 1,
                overflow: 'hidden',
                '&:hover': {
                  overflowY: 'auto',
                  borderColor: 'black',
                },
                borderRadius: 2,
              }}
            >
              {candidateEmailList.length === 0 ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{ fontStyle: 'italic', color: 'lightgray', p: 2 }}
                    label={t('SendAssessments.NoEmailAddedYet')}
                  ></Typography>
                </Box>
              ) : (
                candidateEmailList.map((email, index) => (
                  <Chip
                    key={index}
                    label={email}
                    onDelete={() => handleRemoveCandidate(email)}
                    sx={{ m: 1 }}
                  />
                ))
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',

              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              gap: 3,
            }}
          >
            <Button
              id='inviteCandidateButton'
              variant='contained'
              disabled={candidateEmailList.length === 0}
              onClick={handleSendAssessmentInvitationToCandidate}
              sx={{
                borderRadius: '1rem',
                background: '#1206F9',
                pl: 4,
                pr: 4,
                width: 'fit-content',
                height: 'fit-content',

                fontSize: '0.75rem',
                fontWeight: 500,

                color: '#fff',
              }}
              label={t('SendAssessments.Invite')}
            />
            <Button
              id='copyCandidateAssessmentLinkButton'
              variant='contained'
              disabled={selectedAssessmentType.id === -1}
              onClick={handleopenCopyLinkSettingsModal}
              sx={{
                borderRadius: '1rem',
                background: '#1206F9',
                pl: 4,
                pr: 4,
                width: 'fit-content',
                height: 'fit-content',

                fontSize: '0.75rem',
                fontWeight: 500,

                color: '#fff',
              }}
              label={t('SendAssessments.CopyLink')}
              startIcon={<ContentCopyIcon />}
            />
          </Box>
          <PromptsModal
            open={open}
            handleClosePromptsModal={handleClosePromptsModal}
            selectedAssessmentType={currentlySelectedViewPromptsId}
          />

          <CopyLinkSettingsModal
            openCopyLinkSettingsModal={openCopyLinkSettingsModal}
            handleCloseCopyLinkSettingsModal={handleCloseCopyLinkSettingsModal}
            selectedAssessmentType={selectedAssessmentType}
            generateSendCandidateAssessmentLinkToSend={
              generateSendCandidateAssessmentLinkToSend
            }
          />
        </Paper>
      )}

      {isLoadingsendAssessmentEmailToCandidates && <Loader />}
    </AuthWrapper>
  );
}

export default SendCandidateAssessments;
