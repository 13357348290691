/** @format */

import Box from '@mui/material/Box/Box';
import Modal from '@mui/material/Modal/Modal';
import React, { useState } from 'react';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import { useAppDispatch } from 'redux/store';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import useMediaQuery from '@mui/material/useMediaQuery';

const style = {
  position: 'absolute' as 'absolute',
  width: '95%',
  height: '90vh',
  bgcolor: '#FFF',
  boxShadow: 24,
  px: 4,
  p: 5,
};

interface VisitorFeedbackProps {
  Onopen: boolean;
  onClose: () => void;
}

function PreviewTermsAndConditions({ Onopen, onClose }: VisitorFeedbackProps) {
  const [open, setOpen] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted(event.target.checked);
  };

  const handleTermsAccepted = () => {
    history.push({
      pathname: ProtectedRoutes.SandboxGallery,
    });
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        sx={{ border: 'none' }}
      >
        <Box
          sx={{
            ...style,

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'left',
            gap: '15px',
          }}
        >
          <Typography
            label='Preview.TermsandConditions'
            sx={{
              color: '#1206F9',
              fontSize: '24px',
              fontWeight: 700,
            }}
          />

          <Box
            sx={{
              height: '500px',
              overflowY: 'scroll',
              backgroundColor: '#F2F2F2',
              p: 3,
            }}
          >
            <Typography
              label='Preview.Introduction'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
              }}
            />
            <Typography
              label='Preview.IntroductionSubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
                mb: 2,
              }}
            />
            <Typography
              label='Preview.ConsenttoDataUsage'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
              }}
            />
            <Typography
              label='Preview.ConsenttoDataUsageSubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
                mb: 1,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                  mb: 1,
                }}
                label='Preview.NoTradingorSellingofData'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    mb: 1,
                  }}
                  label='Preview.NoTradingorSellingofDataSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                  mb: 1,
                }}
                label='Preview.DataUtilization'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    mb: 2,
                  }}
                  label='Preview.DataUtilizationSubPara'
                ></Typography>
              </Typography>
            </Box>

            <Typography
              label='Preview.DataSecurity'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
                mt: 3,
              }}
            />
            <Typography
              label='Preview.DataSecuritySubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
                mb: 1,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                  mb: 1,
                }}
                label='Preview.SecureStorage'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    mb: 1,
                  }}
                  label='Preview.SecureStorageSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                  mb: 1,
                }}
                label='Preview.DataMaintenance'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    mb: 1,
                  }}
                  label='Preview.DataMaintenanceSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                }}
                label='Preview.DataDestruction'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                  }}
                  label='Preview.DataDestructionSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Typography
              label='Preview.UserResponsibilities'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
                mt: 3,
              }}
            />
            <Typography
              label='Preview.UserResponsibilitiesSubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
                mb: 1,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                }}
                label='Preview.Compliance'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                  }}
                  label='Preview.ComplianceSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                  marginRight: '2px',
                  mb: 1,
                }}
                label='Preview.Accuracy:'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    mb: 1,
                  }}
                  label='Preview.AccuracySubPara'
                ></Typography>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Typography
                component='span'
                sx={{
                  color: '#132F41',
                  fontSize: '15px',
                  fontWeight: 700,
                }}
                label='Preview.Feedback'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#6B6B6B',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                  }}
                  label='Preview.FeedbackSubPara'
                ></Typography>
              </Typography>
            </Box>
            <Typography
              label='Preview.LimitationofLiability'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
                mt: 3,
                mb: 1,
              }}
            />
            <Typography
              label='Preview.LimitationofLiabilitySubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
              }}
            />
            <Typography
              label='Preview.ChangestotheTerms'
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
                mt: 3,
                mb: 1,
              }}
            />
            <Typography
              label='Preview.ChangestotheTermsSubPara'
              sx={{
                color: '#6B6B6B',
                fontSize: '14px',
                fontWeight: 400,
              }}
            />
            <Typography
              label='Contact us  '
              sx={{
                color: '#132F41',
                fontSize: '18px',
                fontWeight: 700,
                mt: 3,
                mb: 1,
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component='span'
                sx={{
                  color: '#6B6B6B',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
                label='Preview.IfyouhaveanyquestionsorconcernsabouttheseTermspleasecontactusat'
              >
                <Typography
                  component='span'
                  sx={{
                    color: '#1206f9',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginLeft: '2px',
                    textDecoration: 'underline',
                  }}
                  label='Preview.hello@mirro.ai'
                ></Typography>
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              width: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAccepted}
                  onChange={handleCheckboxChange}
                />
              }
              label='By using the mirro Preview, you acknowledge that you have read, understood, and agree to be bound by the aforementioned Terms and Conditions here, as well as the mirro Terms of Service. '
              sx={{
                color: '#000',
                fontSize: '14px',
                fontWeight: 600,
                flex: 1,
                gap: 2,
              }}
            />

            <Button
              variant='text'
              sx={{
                p: 1,
                color: '#1206F9',
                flex: '0 1 auto',
              }}
              onClick={() => {
                setOpen(false);
                onClose();
              }}
              label='Preview.Cancel'
            />

            <Button
              sx={{
                p: 1,
                color: '#FFF',
                backgroundColor: '#1206F9',
                opacity: isAccepted ? 1 : 0.9,
                flex: '0 1 auto',
              }}
              disabled={!isAccepted}
              onClick={handleTermsAccepted}
              label='Preview.Accept'
            />
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default PreviewTermsAndConditions;
