export default function checkLinkStatus(expirationDatetimeStr: string) {
  var expirationDatetime: Date = new Date(expirationDatetimeStr);
  var currentUtcTime: Date = new Date();
  var remainingTime: number =
    expirationDatetime.getTime() - currentUtcTime.getTime();
  var expiredTime: number =
    currentUtcTime.getTime() - expirationDatetime.getTime();
  var expired: boolean = currentUtcTime > expirationDatetime;
  var remainingHours: number = Math.floor(remainingTime / (1000 * 60 * 60));
  var remainingMinutes: number = Math.floor(
    (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
  );
  var remainingSeconds: number = Math.floor(
    (remainingTime % (1000 * 60)) / 1000
  );
  var expiredHours: number = Math.floor(expiredTime / (1000 * 60 * 60));
  var expiredMinutes: number = Math.floor(
    (expiredTime % (1000 * 60 * 60)) / (1000 * 60)
  );
  var expiredSeconds: number = Math.floor((expiredTime % (1000 * 60)) / 1000);

  return {
    expired: expired,
    remaining: {
      hours: remainingHours,
      minutes: remainingMinutes,
      seconds: remainingSeconds,
    },
    expiredSince: {
      hours: expiredHours,
      minutes: expiredMinutes,
      seconds: expiredSeconds,
    },
  };
}
