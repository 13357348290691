/** @format */

import React from 'react';
import FacebookIcon from 'icons/FacebookIcon';
import Button from 'widgets/CustomButton/Button';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  FacebookSSOSigOutnSelector,
  FacebookSSOSignInSelector,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import { handleSignInFacebookSuccessServiceApi } from 'services/Facebook-sso-service';
import SsoIconButton from '../SsoIconButton';

export default function FacebookSigninButton({
  isCandidateScreen,
}: {
  isCandidateScreen: boolean;
}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoadingFacebookSSOSignIn } = useAppSelector(
    FacebookSSOSignInSelector
  );

  function handleFbSsoLogin() {
    localStorage.setItem('isSSO', 'true');
    dispatch(handleSignInFacebookSuccessServiceApi(history));
  }

  return isLoadingFacebookSSOSignIn ? (
    <Loader />
  ) : isCandidateScreen ? (
    <SsoIconButton onCLickSsoButton={handleFbSsoLogin} SsoIcon={FacebookIcon} />
  ) : (
    <Button
      onClick={handleFbSsoLogin}
      startIcon={<FacebookIcon />}
      id='btnSigninfacebook'
      variant='outlined'
      sx={{
        textTransform: 'capitalize',
        width: '100%',
        height: '100%',
        padding: 2,
        borderRadius: '25px',
      }}
      label='Facebook'
    />
  );
}
