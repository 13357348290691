/** @format */

import useFeatureFlag from 'hooks/useFeatureFlag';
import React from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './VideoPreviewAdvance.css';
import IFrameVideoPreviewAdvanceVersion2 from './VideoPreviewAdvanceVersion2/IFrameVideoPreviewAdvanceVersion2';

export default function IFrameVideoPreviewAdvance() {
  return <IFrameVideoPreviewAdvanceVersion2 />;
}
