/** @format */

import { Typography } from '@mui/material';
import React from 'react';

function BulbOff() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='41'
      viewBox='0 0 40 41'
      fill='none'
    >
      <path
        d='M14.4739 30.6277C14.4739 26.7693 11.9356 23.5315 10.567 21.1388C9.80663 19.8101 9.40755 18.3254 9.40527 16.8168C9.40527 11.7396 14.3466 7.61951 19.7494 7.61951'
        stroke='white'
      />
      <path
        d='M25.0245 30.6277C25.0245 26.7693 27.5628 23.5315 28.9314 21.1388C29.6918 19.8101 30.0909 18.3254 30.0931 16.8168C30.0931 11.7396 25.1518 7.61951 19.749 7.61951'
        stroke='white'
      />
      <path
        d='M25.7888 30.4185H14.1C13.1683 30.4185 12.4131 31.1282 12.4131 32.0037V32.0112C12.4131 32.8867 13.1683 33.5964 14.1 33.5964H25.7888C26.7205 33.5964 27.4757 32.8867 27.4757 32.0112V32.0037C27.4757 31.1282 26.7205 30.4185 25.7888 30.4185Z'
        stroke='white'
      />
      <path
        d='M24.8657 33.5887H15.0228C14.0912 33.5887 13.3359 34.2985 13.3359 35.174V35.1814C13.3359 36.0569 14.0912 36.7667 15.0228 36.7667H24.8657C25.7973 36.7667 26.5525 36.0569 26.5525 35.1814V35.174C26.5525 34.2985 25.7973 33.5887 24.8657 33.5887Z'
        stroke='white'
      />
      <path
        d='M22.5102 36.7667V37.3948C22.5381 37.727 22.4925 38.0609 22.3764 38.3757C22.2603 38.6905 22.0761 38.9794 21.8355 39.224C21.5948 39.4687 21.3029 39.664 20.9781 39.7975C20.6533 39.931 20.3026 40 19.948 40C19.5935 40 19.2428 39.931 18.918 39.7975C18.5932 39.664 18.3012 39.4687 18.0606 39.224C17.82 38.9794 17.6358 38.6905 17.5197 38.3757C17.4036 38.0609 17.358 37.727 17.3859 37.3948V36.7667'
        stroke='white'
      />
      <path
        d='M13.1606 16.2559C13.2004 15.5198 13.3941 14.7982 13.7308 14.1325C14.0675 13.4668 14.5406 12.87 15.1229 12.3762C15.7053 11.8825 16.3855 11.5014 17.1247 11.2549C17.8638 11.0084 18.6475 10.9012 19.4308 10.9395'
        stroke='white'
      />
    </svg>
  );
}

export default BulbOff;
