/** @format */

import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from 'widgets/Typography/Typography';
import { useTranslation } from 'react-i18next';

export default function RecordedSessionUploadCompleteModal({
  openRecordedSessionUploadCompleteModal,
  handleCloseRecordedSessionUploadCompleteModal,
}: any) {
  return (
    <Dialog
      open={openRecordedSessionUploadCompleteModal}
      onClose={handleCloseRecordedSessionUploadCompleteModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent sx={{ pl: 8, pr: 8, pt: 4 }}>
        <DialogContentText id='alert-dialog-description'>
          <Typography
            sx={{ textAlign: 'center' }}
            label='record.YourSessionHasBeenSaved'
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',

          justifyContent: 'center',
        }}
      >
        <Button
          variant='contained'
          onClick={handleCloseRecordedSessionUploadCompleteModal}
          sx={{ backgroundColor: '#1206f9' }}
          label='record.Ok'
        />
      </DialogActions>
    </Dialog>
  );
}
