/** @format */

import React from 'react';

function MicrosoftIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='11.4786' height='11.4783' fill='#F35220' />
      <rect x='12.5215' width='11.4786' height='11.4783' fill='#82BD01' />
      <rect
        x='12.5215'
        y='12.5217'
        width='11.4786'
        height='11.4783'
        fill='#FFBB02'
      />
      <rect y='12.5217' width='11.4786' height='11.4783' fill='#00A7F0' />
    </svg>
  );
}

export default MicrosoftIcon;
