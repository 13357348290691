import { i18n } from 'i18n/i18n';
import {
  VerifyOTPFailure,
  VerifyOTPLoad,
  VerifyOTPSuccess,
  VisitorFeedbackFailure,
  VisitorFeedbackLoad,
  VisitorFeedbackSuccess,
  sendOtpFailure,
  sendOtpLoad,
  sendOtpSuccess,
} from 'redux/slices/Preview/UserValidation-slice';
import { AppThunk } from 'redux/store';
import { PublicUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';
import { Payload } from '../../types/DeepRecruiterTypes/SelectAssessment-types';
import {
  FeedbackData,
  FormValues,
} from 'types/PreviewTypes/UserValidation-types';
import { useHistory } from 'react-router-dom';
import { OpenRoutes } from 'routes/routes';

export const SendUserOtpServiceApi =
  (Email: string): AppThunk =>
  async (dispatch) => {
    dispatch(sendOtpLoad());

    await commonAPI
      .post(PublicUrls.sendOTPToUser.url(Email))
      .then((response) => {
        sessionStorage.setItem('VisitorsID', response.data.id);
        dispatch(sendOtpSuccess(response.data));
        Toast('One time access code  has been send to mail', 'success');
      })
      .catch((error) => {
        dispatch(
          sendOtpFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };

export const VerifyOtpServiceApi =
  (Payload: FormValues, setIsVisible: Function): AppThunk =>
  async (dispatch) => {
    dispatch(VerifyOTPLoad());

    try {
      const response = await commonAPI.get(PublicUrls.verifyOtp.url(Payload));
      if (response.data) {
        sessionStorage.setItem('isOTPAuthenticated', 'true');
        dispatch(VerifyOTPSuccess(response.data));

        Toast(i18n.t('Preview.Acesscodeverifiedsuccessfully'), 'success');
        setIsVisible(true);
      } else {
        dispatch(VerifyOTPFailure('Invalid OTP'));
        Toast(i18n.t('Preview.Invalidtimeaccesscode'), 'error');
      }
    } catch (error) {
      dispatch(
        VerifyOTPFailure(
          i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
      Toast(
        i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'),
        'error'
      );
    }
  };

export const VisitorFeedbackSubmitApi =
  (visitorID: number, FeedbackDataValues: FeedbackData): AppThunk =>
  async (dispatch) => {
    dispatch(VisitorFeedbackLoad());

    try {
      const response = await commonAPI
        .post(PublicUrls.VisitorFeedbackurl.url(visitorID), FeedbackDataValues)
        .then((response) => {
          dispatch(VisitorFeedbackSuccess(response.data));
          Toast('Feedback submitted successfully', 'success');
        });
    } catch (error) {
      dispatch(
        VisitorFeedbackFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };
