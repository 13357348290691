/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionInitialStateTypes } from 'types/Subscription-types';

const subscriptionInitialState: SubscriptionInitialStateTypes = {
  isLoadingGetSubscription: false,
  errorGetSubscription: '',
  getSubscriptionData: [],
};

const getSubscriptionSlice = createSlice({
  name: 'getSubscriptionSlice',
  initialState: subscriptionInitialState,
  reducers: {
    getSubscriptionLoad(state) {
      state.isLoadingGetSubscription = true;
    },
    getSubscriptionSuccess(state, action) {
      state.isLoadingGetSubscription = false;
      state.getSubscriptionData = action.payload;
    },
    getSubscriptionFailure(state, action) {
      state.isLoadingGetSubscription = false;
      state.errorGetSubscription = action.payload;
    },
  },
});

export const {
  getSubscriptionLoad,
  getSubscriptionSuccess,
  getSubscriptionFailure,
} = getSubscriptionSlice.actions;
export { getSubscriptionSlice };

const stripeSessionIdInitialState = {
  isLoadingGetStripeSessionId: false,
  errorGetStripeSessionId: '',
  getStripeSessionIdData: { url: '' },
};

const getStripeSessionIdSlice = createSlice({
  name: 'getStripeSessionIdSlice',
  initialState: stripeSessionIdInitialState,
  reducers: {
    getStripeSessionIdLoad(state) {
      state.isLoadingGetStripeSessionId = true;
    },
    getStripeSessionIdSuccess(state, action) {
      state.isLoadingGetStripeSessionId = false;
      state.getStripeSessionIdData = action.payload;
    },
    getStripeSessionIdFailure(state, action) {
      state.isLoadingGetStripeSessionId = false;
      state.errorGetStripeSessionId = action.payload;
    },
  },
});

export const {
  getStripeSessionIdLoad,
  getStripeSessionIdSuccess,
  getStripeSessionIdFailure,
} = getStripeSessionIdSlice.actions;

export { getStripeSessionIdSlice };

const availableSubscriptionPlansInitialState = {
  isLoadingGetAvailableSubscriptionPlans: false,
  getAvailableSubscriptionPlansData: [],
  errorGetAvailableSubscriptionPlans: '',
};

const getAvailableSubscriptionPlansSlice = createSlice({
  name: 'getAvailableSubscriptionPlansSlice',
  initialState: availableSubscriptionPlansInitialState,
  reducers: {
    getAvailableSubscriptionPlansLoad(state) {
      state.isLoadingGetAvailableSubscriptionPlans = true;
    },
    getAvailableSubscriptionPlansSuccess(state, action) {
      state.isLoadingGetAvailableSubscriptionPlans = false;
      state.getAvailableSubscriptionPlansData = action.payload;
    },
    getAvailableSubscriptionPlansFailure(state, action) {
      state.isLoadingGetAvailableSubscriptionPlans = false;
      state.errorGetAvailableSubscriptionPlans = action.payload;
    },
  },
});

export const {
  getAvailableSubscriptionPlansLoad,
  getAvailableSubscriptionPlansSuccess,
  getAvailableSubscriptionPlansFailure,
} = getAvailableSubscriptionPlansSlice.actions;

export { getAvailableSubscriptionPlansSlice };

const validateCouponCodeInitialState = {
  isLoadingValidateCouponCode: false,
  validateCouponCodeData: [],
  errorValidateCouponCode: '',
};
const validateCouponCodeSlice = createSlice({
  name: 'validateCouponCodeSlice',
  initialState: validateCouponCodeInitialState,
  reducers: {
    validateCouponCodeLoad(state) {
      state.isLoadingValidateCouponCode = true;
    },
    validateCouponCodeSuccess(state, action) {
      state.isLoadingValidateCouponCode = false;
      state.validateCouponCodeData = action.payload;
    },
    validateCouponCodeFailure(state, action) {
      state.isLoadingValidateCouponCode = false;
      state.errorValidateCouponCode = action.payload;
    },
  },
});

export const {
  validateCouponCodeLoad,
  validateCouponCodeSuccess,
  validateCouponCodeFailure,
} = validateCouponCodeSlice.actions;

export { validateCouponCodeSlice };
