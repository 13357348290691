/** @format */

import { Box, IconButton, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RedirectUrls } from 'routes/urls';
import Typography from 'widgets/Typography/Typography';

function MirroBranding() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const history = useHistory();
  return (
    <Box
      sx={{
        width: isMobile || isTablet ? '100%' : '60%',
        ml: -1,
        mt: -2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 5,
          gap: isMobile || isTablet ? 5 : 5,
          alignItems: isMobile || isTablet ? 'center' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: isMobile || isTablet ? 'center' : 'left',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '12px' }} label='loginpage.PoweredBy' />

          <Box
            onClick={() => (window.location.href = RedirectUrls.mirroAi)}
            component='img'
            alt='mirro-logo-with-nam'
            src='./comonImg/mirro-logo-with-namee.png'
            sx={{
              width: isMobile ? '55%' : isTablet ? '45%' : '15%',
              cursor: 'pointer',
            }}
          />
        </Box>
        {/* <Box
          component='img'
          alt='image-205'
          src='./LoginImg/image-205.png'
          sx={{
            width: isMobile ? '55%' : isTablet ? '45%' : '15%',
          }}
        /> */}
        <Typography
          sx={{ textAlign: isMobile || isTablet ? 'center' : 'left' }}
          variant={isMobile || isTablet ? 'h5' : 'h4'}
          label='loginpage.ConvocoachOurAIPoweredCommunicationCoach'
        />

        <Typography
          sx={{
            width: isMobile ? '100%' : '70%',
            fontSize: '18px',
            textAlign: 'justify',
          }}
          label='loginpage.RefineYourInterpersonalSkillsWithOurAIPoweredPrivateFeedbackAndPersonalizedInsightsOnHowYouCommunicateAndMakeAnImpact'
        />
      </Box>
    </Box>
  );
}

export default MirroBranding;
