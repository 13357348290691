/** @format */

// /** @format */
import React from 'react';
import DashboardIcon from 'icons/DashboardIcon';
import GalleryIcon from 'icons/GalleryIcon';
import HelpIcon from 'icons/HelpIcon';
// import LearningsIcon from 'icons/LearningsIcon';
import SubscriptionsIcon from 'icons/SubscriptionsIcon';
import { AdminRoutes, ProtectedRoutes } from 'routes/routes';
import VideoIcon from 'icons/VideoIcon.png';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import FeatureFlagIcon from 'icons/FeatureFlagIcon';
import FeedbackIcon from 'icons/FeedbackIcon';
import UploadIcon from 'icons/UploadIcon';
import { useTranslation } from 'react-i18next';
import MyAssessmentsIcon from 'icons/MyAssessmentsIcon';
import SendAssessments from 'components/SendCandidateAssessments/SendCandidateAssessments';
import SendAssessmentIcon from 'icons/SendAssessmentIcon';

export const DrawerItemsArray = [
  {
    id: 'dashboardIcon',
    text: 'drawer.Dashboard',
    icon: <DashboardIcon />,
    route: ProtectedRoutes.dashboardRoute,
  },
  {
    id: 'galleryIcon',
    text: 'drawer.Gallery',
    icon: <GalleryIcon />,
    route: ProtectedRoutes.galleryRoute,
  },

  {
    id: 'recordIcon',
    text: 'drawer.Record',
    icon: <img src={VideoIcon} width='25px' height='20px' alt='VideoIcon' />,
    route: ProtectedRoutes.recordRoute,
  },

  {
    id: 'uploadIcon',
    text: 'drawer.Upload',
    icon: <UploadIcon />,
    route: ProtectedRoutes.uploadRoute,
  },

  {
    id: 'myAssessmentsIcon',
    text: 'My Assessments',
    icon: <MyAssessmentsIcon />,
    route: ProtectedRoutes.myAssessmentsRoute,
  },
  {
    id: 'sendAssessmentsIcon',
    text: 'Send Assessments',
    icon: <SendAssessmentIcon />,
    route: ProtectedRoutes.SendAssessmentsRoute,
  },

  {
    id: 'subscriptionIcon',
    text: 'drawer.MySubscriptions',
    icon: <SubscriptionsIcon />,
    route: ProtectedRoutes.subscriptionsRoute,
  },
  {
    id: 'helpSupportIcon',
    text: 'drawer.HelpSupport',
    icon: <HelpIcon />,
    route: ProtectedRoutes.helpRoute,
  },
  {
    id: 'userFeedbackIcon',
    text: 'drawer.Feedback',
    icon: <FeedbackIcon />,
    route: ProtectedRoutes.feedback,
  },
];

export const AdminDrawerItemsArray = [
  {
    id: 'featureflagIcon',
    text: 'drawer.FeatureFlags',
    icon: <FeatureFlagIcon />,
    route: AdminRoutes.featureRoute,
  },
  {
    id: 'billing',
    text: 'drawer.Billings',
    icon: <PriceCheckOutlinedIcon sx={{ color: '#1131F9' }} />,
    route: AdminRoutes.billingRoute,
  },
];
