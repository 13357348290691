import { useEffect, useState } from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';

type CustomSeriesType = {
  data: number[];
  label: string;
  id: string;
  stack: string;
  color: string;
};

function useToneStatSeriesData(
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>
): CustomSeriesType[] {
  const [seriesData, setSeriesData] = useState<CustomSeriesType[]>([]);

  useEffect(() => {
    if (ProcessDataListForParticipantsVersion2.length > 0) {
      const stressTones: number[] = [];
      const fatiguedTones: number[] = [];
      const confidentTones: number[] = [];
      const promptCounts: string[] = [];

      const promptSections =
        ProcessDataListForParticipantsVersion2[0]?.prompt_sections;

      if (Array.isArray(promptSections)) {
        promptSections.forEach((section: any) => {
          const prompt = section;

          stressTones.push(prompt?.stressed_tone || 0);
          fatiguedTones.push(prompt?.fatigued_tone || 0);
          confidentTones.push(prompt?.confident_tone || 0);

          const promptLabel = `P${promptCounts.length + 1}`;
          promptCounts.push(promptLabel);
        });
      }

      setSeriesData([
        {
          data: confidentTones,
          label: 'Confident Tone',
          id: 'confident_tone',
          stack: 'stack1',
          color: '#62C960',
        },
        {
          data: fatiguedTones,
          label: 'Fatigued Tone',
          id: 'fatigued_tone',
          stack: 'stack1',
          color: '#FFCA28',
        },
        {
          data: stressTones,
          label: 'Stressed Tone',
          id: 'stressed_tone',
          stack: 'stack1',
          color: '#D62D2D',
        },
        
        
      ]);
    }
  }, [ProcessDataListForParticipantsVersion2]);

  return seriesData;
}

export default useToneStatSeriesData;
