/** @format */

import React from 'react';
import { Toolbar } from '@mui/material';
import ColumnsListTableIcon from 'icons/ColumnsListTableIcon';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function EnhancedTableToolbar({
  handleClick,
}: {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Toolbar
      sx={{
        p: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: '1 1 100%',
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
        }}
        variant='h6'
        id='tableTitle'
        component='div'
        label='dashboard.MyRecordings'
      />

      <Button
        variant='outlined'
        startIcon={<ColumnsListTableIcon />}
        sx={{
          borderRadius: '25px',
          color: '#1131F9',
          border: '1px solid #1131F9',
          fontWeight: 600,
          width: 'fit-content',
          // margin: 2,
        }}
        id='basic-button'
        aria-haspopup='true'
        onClick={handleClick}
        label='dashboard.Columns'
      />
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
