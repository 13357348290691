/** @format */

import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import DeleteCancelledModal from './DeleteCancelledModal';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface DeleteModalProps {
  openDeleteModal: boolean;
  onCloseDeleteModal: () => void;
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
}

function DeleteModal({
  openDeleteModal,
  onCloseDeleteModal,
  onConfirmDelete,
  onCancelDelete,
}: DeleteModalProps) {
  const [openDeleteCancelledModal, setOpenDeleteCancelledModal] =
    useState(false);

  function handleCloseDeleteCancelledModal() {
    setOpenDeleteCancelledModal(false);
    onCancelDelete();
  }
  function handleOpenDeleteCancelledModal() {
    setOpenDeleteCancelledModal(true);
  }
  return (
    <Dialog
      open={openDeleteModal}
      onClose={onCloseDeleteModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {/* <DialogTitle id='alert-dialog-title'>
        {"Use Google's location service?"}
      </DialogTitle> */}
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          sx={{ fontFamily: 'Poppins' }}
        >
          <Typography label='gallery.AreYouSureYouWantToRemoveThisFile' />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {' '}
        <Button
          sx={{
            fontFamily: 'Poppins',
            backgroundColor: '#1206f9',
            color: '#fff',
          }}
          onClick={onConfirmDelete}
          variant='contained'
          autoFocus
          label='gallery.Yes'
        />
        <Button
          sx={{
            fontFamily: 'Poppins',
            backgroundColor: '#1206f9',
            color: '#fff',
          }}
          onClick={handleOpenDeleteCancelledModal}
          variant='contained'
          label='gallery.No'
        />
      </DialogActions>
      <DeleteCancelledModal
        openDeleteCancelledModal={openDeleteCancelledModal}
        handleCloseDeleteCancelledModal={handleCloseDeleteCancelledModal}
      />
    </Dialog>
  );
}

export default DeleteModal;
