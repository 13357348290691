/** @format */

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function UploadStatusTableDesktopView({
  files,
  handleCancel,
  uploadMultipleFilesError,
  handleRetry,
}: any) {
  const { t } = useTranslation();
  return (
    <Table sx={{ width: '90%' }}>
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              textAlign: 'left',
            }}
          >
            {t('upload.FileName')}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {' '}
            {t('upload.Status')}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {t('upload.Actions')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file: any, index: number) => {
          return (
            <TableRow key={index}>
              <TableCell
                style={{
                  textAlign: 'left',
                  color: file.isCanceled ? 'red' : '',
                }}
              >
                {file.name}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                  color: file.isCanceled ? 'red' : '',
                }}
              >
                {file.progress === 100 ? (
                  <Typography
                    sx={{ color: '#00D8A5' }}
                    label='upload.Uploaded'
                  />
                ) : file.isCanceled ? (
                  <Typography sx={{ color: 'red' }} label='upload.Canceled' />
                ) : (
                  <CircularProgressWithLabel
                    value={file.progress ?? 0}
                    isCanceled={file.isCanceled}
                  />
                )}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {!file.isCanceled ? (
                  <Button
                    onClick={() => handleCancel(index)}
                    sx={{ color: 'black' }}
                    disabled={
                      file.isCancel ||
                      file.progress === 100 ||
                      uploadMultipleFilesError.message
                    }
                    label='upload.Cancel'
                  />
                ) : (
                  <Button
                    onClick={() => handleRetry(index)}
                    disabled={
                      file.isCancel ||
                      file.progress === 100 ||
                      uploadMultipleFilesError.message
                    }
                    sx={{ color: '#1206f9' }}
                    label='upload.Retry'
                  />
                )}{' '}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default UploadStatusTableDesktopView;
