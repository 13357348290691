import { createSlice } from '@reduxjs/toolkit';
import {
  UpdateJobStatusInitialStateTypes,
  getRecruiterRecordInitialStateTypes,
  getRecruiterWidgetDataInitialStateTypes,
} from 'types/DeepRecruiterTypes/DrDashboard-types';

const getRecruiterRecordInitialState: getRecruiterRecordInitialStateTypes = {
  isLoadingGetRecruiterRecord: false,
  RecruiterRecord: [],
  errorGetRecruiterRecord: false,
};

const getRecruiterRecordSlice = createSlice({
  name: ' get Recruiter Record',
  initialState: getRecruiterRecordInitialState,
  reducers: {
    getRecruiterRecordLoad(state) {
      state.isLoadingGetRecruiterRecord = true;
    },
    getRecruiterRecordSuccess(state, action) {
      state.isLoadingGetRecruiterRecord = false;
      state.RecruiterRecord = action.payload;
    },
    getRecruiterRecordFailure(state, action) {
      state.isLoadingGetRecruiterRecord = false;
      state.errorGetRecruiterRecord = action.payload;
    },
  },
});

export const {
  getRecruiterRecordLoad,
  getRecruiterRecordSuccess,
  getRecruiterRecordFailure,
} = getRecruiterRecordSlice.actions;

export { getRecruiterRecordSlice };

const UpdateJobStatusInitialState: UpdateJobStatusInitialStateTypes = {
  isLoadingUpdateJobStatus: false,
  UpdateJobStatus: [],
  errorUpdateJobStatus: false,
};

const UpdateJobStatusSlice = createSlice({
  name: ' update job status',
  initialState: UpdateJobStatusInitialState,
  reducers: {
    UpdateJobStatusLoad(state) {
      state.isLoadingUpdateJobStatus = true;
    },
    UpdateJobStatusSuccess(state, action) {
      state.isLoadingUpdateJobStatus = false;
      state.UpdateJobStatus = action.payload;
    },
    UpdateJobStatusFailure(state, action) {
      state.isLoadingUpdateJobStatus = false;
      state.errorUpdateJobStatus = action.payload;
    },
  },
});

export const {
  UpdateJobStatusLoad,
  UpdateJobStatusSuccess,
  UpdateJobStatusFailure,
} = UpdateJobStatusSlice.actions;

export { UpdateJobStatusSlice };

const DeleteRecordsInitialState = {
  isDeletingRecords: false,
  deleteRecordsError: '',
};

const deleteRecordSlice = createSlice({
  name: 'deleteRecordSlice',
  initialState: DeleteRecordsInitialState,
  reducers: {
    deleteRecordLoad(state) {
      state.isDeletingRecords = true;
      state.deleteRecordsError = '';
    },
    deleteRecordSuccess(state) {
      state.isDeletingRecords = false;
    },
    deleteRecordFailure(state, action) {
      state.isDeletingRecords = false;
      state.deleteRecordsError = action.payload;
    },
  },
});

export const { deleteRecordLoad, deleteRecordSuccess, deleteRecordFailure } =
  deleteRecordSlice.actions;

export { deleteRecordSlice };

const getRecruiterWidgetDataInitialState: getRecruiterWidgetDataInitialStateTypes =
  {
    isLoadinggetRecruiterWidgetData: false,
    getRecruiterWidgetData: [],
    errorGetRecruiterWidgetData: false,
  };

const getRecruiterWidgetDataSlice = createSlice({
  name: 'deleteRecordSlice',
  initialState: getRecruiterWidgetDataInitialState,
  reducers: {
    getRecruiterWidgetDataLoad(state) {
      state.isLoadinggetRecruiterWidgetData = true;
    },
    getRecruiterWidgetDataSuccess(state, action) {
      state.isLoadinggetRecruiterWidgetData = false;
      state.getRecruiterWidgetData = action.payload;
    },
    getRecruiterWidgetDataFailure(state, action) {
      state.isLoadinggetRecruiterWidgetData = false;
      state.errorGetRecruiterWidgetData = action.payload;
    },
  },
});

export const {
  getRecruiterWidgetDataLoad,
  getRecruiterWidgetDataSuccess,
  getRecruiterWidgetDataFailure,
} = getRecruiterWidgetDataSlice.actions;

export { getRecruiterWidgetDataSlice };
