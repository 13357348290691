/** @format */

export const googleSsoConfig = {
  web: {
    client_id:
      '716446440188-qnl72jghl99rge624gpv5ieasfrp0c75.apps.googleusercontent.com',
    project_id: 'clean-pilot-401508',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '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',
    redirect_uris: ['http://localhost:3000'],
    javascript_origins: ['http://localhost:3000'],
  },
};
