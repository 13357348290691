/** @format */

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Switch, TableHead } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { FeatureFlagsData } from 'types/common/Feature-flags-types';
import Loader from 'widgets/Loader/Loader';
import { updateFeatureFlagsServiceApi } from 'services/FeatureFlags-services';
import { useTranslation } from 'react-i18next';
import { TablePaginationActionsProps } from 'types/Features-flags-types';
import {
  getFeatureFlagsSelector,
  updateFeatureFlagsSelector,
} from 'redux/selectors/selectors';
import Typography from 'widgets/Typography/Typography';
import { useCallback } from 'react';

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
    },
    [onPageChange]
  );

  const handleBackButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    },
    [onPageChange, page]
  );

  const handleNextButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    },
    [onPageChange, page]
  );

  const handleLastPageButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    },
    [onPageChange, count, rowsPerPage]
  );

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableRows, setTableRows] = React.useState<Array<FeatureFlagsData>>([]);
  const { featureFlags, isLoadingFeatureFlags } = useAppSelector(
    getFeatureFlagsSelector
  );

  const { isLoadingUpdateFeatureFlags } = useAppSelector(
    updateFeatureFlagsSelector
  );
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    setTableRows(featureFlags);
  }, [featureFlags]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleFeatureFlagUpdate(event: React.ChangeEvent<HTMLInputElement>) {
    const payload = {
      feature_name: event.target.name,
      state: event.target.checked,
    };
    dispatch(updateFeatureFlagsServiceApi(payload));
  }

  return (
    <TableContainer
      sx={{
        minHeight: '70vh',
        alignContent: 'center',
      }}
    >
      {isLoadingFeatureFlags || isLoadingUpdateFeatureFlags ? (
        <Loader />
      ) : (
        <Table
          sx={{ minHeight: '100%', fontFamily: 'Poppins' }}
          aria-label='custom pagination table'
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Poppins' }}>
                <b>
                  {' '}
                  <Typography label={'featureFlags.FeatureName'} />
                </b>
              </TableCell>
              <TableCell sx={{ fontFamily: 'Poppins' }} align='right'>
                <b>
                  <Typography label={'featureFlags.EnableDisable'} />
                </b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableRows
            ).map((row) => (
              <TableRow key={row.feature_name}>
                <TableCell
                  component='th'
                  scope='row'
                  sx={{ fontFamily: 'Poppins' }}
                >
                  {row.feature_name}
                </TableCell>
                <TableCell
                  style={{ width: 160 }}
                  align='right'
                  sx={{ fontFamily: 'Poppins' }}
                >
                  <Switch
                    id={`toggleSwitch_${row.feature_name}`}
                    checked={row._is_enabled}
                    onChange={handleFeatureFlagUpdate}
                    name={row.feature_name}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t('featureFlags.RowsPerPage')}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                    fontFamily: 'Poppins',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                id='tablepagination'
                sx={{ fontFamily: 'Poppins' }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
}
