/** @format */

import React, { useCallback } from 'react';
import './recordaudioios.css';
import RecordVideoButtonIcon from 'icons/RecordVideoButtonIcon';
import { Box } from '@mui/material';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import PauseIcon from '@mui/icons-material/Pause';
import {
  uploadSingleFileSelector,
  getSessionTypesSelector,
} from 'redux/selectors/selectors';
import { useAppSelector } from 'redux/store';

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseRecordingIcon from 'icons/PauseRecordingIcon';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

interface AudioRecorderProps {
  state: any;
  handleOpenAfterRecordConfirmModal: any;
  startRecordingInternal: any;
  startTimer: any;
  pauseTimer: any;
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({
  state,
  handleOpenAfterRecordConfirmModal,
  startRecordingInternal,
  startTimer,
  pauseTimer,
}) => {
  const handlePauseAudio = useCallback(() => {
    if (state.recorder && state.recorder.state === 'recording') {
      state.recorder.pause();
      pauseTimer();
    }
  }, [state, pauseTimer]);

  const handleResumeAudio = useCallback(() => {
    if (state.recorder && state.recorder.state === 'paused') {
      state.recorder.resume();
      startTimer();
    }
  }, [state, startTimer]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          minWidth: '280px',
        }}
      >
        {state.recorder && state.recorder.state === 'recording' && (
          <Typography label='record.RecordingStatus'>
            <Typography className='recording' label='record.Recording' />
          </Typography>
        )}
        {state.recorder && state.recorder.state === 'paused' && (
          <span style={{ display: 'flex' }}>
            {' '}
            <Typography
              sx={{ color: 'firebrick' }}
              label='record.RecordingStatus'
            >
              {' '}
              :
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <PauseIcon sx={{ color: 'red' }} />
              <Typography sx={{ color: 'firebrick' }} label='record.Paused' />
            </Box>
          </span>
        )}
        {(state.recorder && state.recorder.state === 'recording') ||
        (state.recorder && state.recorder.state === 'paused') ? (
          <Button
            onClick={handleOpenAfterRecordConfirmModal}
            id='stop-recording-left-button'
            variant='contained'
            startIcon={<StopRecordingIcon />}
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 20,
              backgroundColor: '#1206f9',
            }}
            label='record.StopRecording'
          />
        ) : (
          <Button
            onClick={startRecordingInternal}
            id='recordButtonStart'
            variant='contained'
            startIcon={<RecordVideoButtonIcon />}
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 20,
              backgroundColor: '#1206f9',
            }}
            label='record.ClickRecordButtonToRecordAudio'
          />
        )}
        {state.recorder && state.recorder.state === 'paused' && (
          <Button
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 20,
              color: '#1206f9',
              border: '1px solid #1206f9',
            }}
            variant='outlined'
            onClick={handleResumeAudio}
            startIcon={<PlayArrowOutlinedIcon sx={{ color: '#1206f9' }} />}
            label='record.Resume'
          />
        )}
        {state.recorder && state.recorder.state === 'recording' && (
          <Button
            sx={{
              padding: 0.5,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 20,
              color: '#1206f9',
              border: '1px solid #1206f9',
            }}
            variant='outlined'
            onClick={handlePauseAudio}
            startIcon={<PauseRecordingIcon />}
            label='record.Pause'
          />
        )}
      </Box>
    </Box>
  );
};

export default AudioRecorder;
