import fixWebmDuration from 'fix-webm-duration';

export async function fixVideoDuration(
  totalSeconds: number,
  mediaBlobUrl: string | null,
  setFixedBlobUrl: Function
) {
  if (totalSeconds > 0 && mediaBlobUrl) {
    if (totalSeconds > 0) {
      try {
        const response = await fetch(mediaBlobUrl);
        const blob = await response.blob();
        fixWebmDuration(blob, totalSeconds * 1000, function (fixedBlob) {
          const url = URL.createObjectURL(fixedBlob);
          setFixedBlobUrl(url);
        });
      } catch (error) {
        console.error('fixVideoDuration Error fetching blob:', error);
      }
    } else {
      console.error('fixVideoDuration Stored start time or end time is null.');
    }
  }
}
