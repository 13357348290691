import React from 'react';

export default function TranscriptIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_670_816'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='2'
        width='14'
        height='11'
      >
        <path
          d='M0.835961 6.56045C0.355643 6.0587 0.315833 5.28065 0.742429 4.7325L2.1882 2.87476C2.45345 2.53393 2.86116 2.33459 3.29305 2.33459H10.7357C11.171 2.33459 11.5815 2.53705 11.8465 2.88238L13.2691 4.7366C13.6889 5.28367 13.6478 6.05491 13.1722 6.55426L8.02857 11.9558C7.47783 12.5341 6.55565 12.5354 6.0034 11.9585L0.835961 6.56045Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_670_816)'>
        <path
          d='M0 5.68687L2.60888 2.33459H11.4265L13.9985 5.68687L7.01768 13.0177L0 5.68687Z'
          fill='url(#paint0_linear_670_816)'
        />
        <path
          d='M0 5.68701L3.23071 6.75385L7.01768 13.0178L0 5.68701Z'
          fill='black'
          fill-opacity='0.1'
        />
        <path
          d='M13.9798 5.68701L10.6953 6.75385L7.0166 13.0178L13.9798 5.68701Z'
          fill='black'
          fill-opacity='0.1'
        />
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d='M6.96383 3.06781L2.58008 2.33325L3.23064 6.75384H6.94541H6.98225H10.6963L11.3468 2.33325L6.96383 3.06781Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'soft-light' }}>
          <path
            d='M2.58008 2.33325L7.01761 3.80678L11.4264 2.33473L2.58008 2.33325Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'overlay' }}>
          <path
            d='M6.96365 3.06775L3.23047 6.75378H10.6961L6.96365 3.06775Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'soft-light' }}>
          <path
            d='M7.01744 3.80676L3.23047 6.75382H10.6961L7.01744 3.80676Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'soft-light' }}>
          <path
            d='M3.23071 6.75384L0 5.687L2.58015 2.33325H3.23071V6.75384Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'soft-light' }}>
          <path
            d='M10.6953 6.75384L13.999 5.687L11.4188 2.33325H10.7683L10.6953 6.75384Z'
            fill='white'
          />
        </g>
        <g style={{ mixBlendMode: 'soft-light' }} opacity='0.5'>
          <path
            d='M3.23047 6.75378L6.99902 13.0082L10.6961 6.75378H3.23047Z'
            fill='white'
          />
        </g>
        <g
          style={{ mixBlendMode: 'soft-light' }}
          opacity='0.3'
          filter='url(#filter0_i_670_816)'
        >
          <path
            d='M3.23047 6.75378L6.99902 13.0082L10.6961 6.75378H3.23047Z'
            fill='black'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_i_670_816'
          x='3.23047'
          y='1.77601'
          width='7.46582'
          height='11.2322'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-4.97778' />
          <feGaussianBlur stdDeviation='18.6667' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_670_816'
          />
        </filter>
        <linearGradient
          id='paint0_linear_670_816'
          x1='6.99926'
          y1='2.33459'
          x2='6.99926'
          y2='13.0177'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#0047FF' />
          <stop offset='0.401042' stop-color='#003B95' />
          <stop offset='1' stop-color='#00E0FF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
