/** @format */

import React from 'react';

function HelpIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2501 15.7502C11.2501 16.1644 10.9143 16.5002 10.5001 16.5002C10.0859 16.5002 9.75012 16.1644 9.75012 15.7502C9.75012 15.3361 10.0859 15.0002 10.5001 15.0002C10.9143 15.0002 11.2501 15.3361 11.2501 15.7502Z'
        fill='#07C7D7'
      />
      <path
        d='M10.5 0.000244141C4.71 0.000244141 0 4.71025 0 10.5003C0 16.2903 4.71 21.0003 10.5 21.0003C16.29 21.0003 21 16.2903 21 10.5003C21 4.71025 16.29 0.000244141 10.5 0.000244141ZM10.5 1.50025C15.4796 1.50025 19.5 5.52076 19.5 10.5003C19.5 15.4797 15.4795 19.5003 10.5 19.5003C5.52052 19.5003 1.5 15.4797 1.5 10.5003C1.5 5.52076 5.52052 1.50025 10.5 1.50025Z'
        fill='#1206F9'
      />
      <path
        d='M10.4998 4.50024C8.43802 4.50024 6.74983 6.18843 6.74983 8.25025C6.74699 8.45097 6.82483 8.6445 6.96579 8.78746C7.10675 8.93043 7.29911 9.01095 7.49984 9.01095C7.70057 9.01095 7.89292 8.93043 8.03388 8.78746C8.17484 8.6445 8.25269 8.45097 8.24984 8.25025C8.24984 7.00002 9.24961 6.00025 10.4998 6.00025C11.7501 6.00025 12.7498 7.00002 12.7498 8.25025C12.7498 9.50048 11.7501 10.5003 10.4998 10.5003C10.301 10.5003 10.1101 10.5793 9.96949 10.7199C9.82886 10.8605 9.74984 11.0514 9.74984 11.2503V12.7503C9.747 12.951 9.82484 13.1445 9.9658 13.2875C10.1068 13.4304 10.2991 13.511 10.4998 13.511C10.7006 13.511 10.8929 13.4304 11.0339 13.2875C11.1749 13.1445 11.2527 12.951 11.2499 12.7503V11.8479C12.9434 11.4881 14.2499 10.0466 14.2499 8.25025C14.2499 6.18843 12.5617 4.50024 10.4998 4.50024Z'
        fill='#07DCAA'
      />
    </svg>
  );
}

export default HelpIcon;
