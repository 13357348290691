/** @format */

import React from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './help.css';
import { Box, Container, Divider, useMediaQuery } from '@mui/material';
import HelpAccordion from './HelpAccordian';
import HelpVideos from './HelpVideos';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

function HelpComponent() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  return (
    <AuthWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          p: isMobile || isTablet ? 10 : 15,
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#33344B',
            fontFamily: 'Poppins',
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '-1.76px',
            textAlign: 'center',
          }}
          label='helpandsupport.HiHowCanWeHelp'
        />

        <Divider />
        <HelpVideos />
        <Divider />
        <HelpAccordion />
      </Box>
    </AuthWrapper>
  );
}

export default HelpComponent;
