import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { uploadStatusList } from 'constants/MyAssessment';
import fixWebmDuration from 'fix-webm-duration';
import { i18n } from 'i18n/i18n';
import {
  allPromptsSectionsStatusFailure,
  allPromptsSectionsStatusLoad,
  allPromptsSectionsStatusSuccess,
  clearUploadStatusArray,
  getPractiseSectionsFailure,
  getPractiseSectionsLoad,
  getPractiseSectionsSuccess,
  sectionUploadSuccess,
  setIsFirstReupload,
  setIsFirstUnskipped,
  setIsFirstVideo,
  setIsLastVideo,
  setRelatedToId,
  setTotalDuration,
  setVideoTitle,
  uploadSectionVideoFailure,
  uploadSectionVideoLoad,
  uploadSectionVideoSuccess,
} from 'redux/slices/MyAssessment-slice';
import store, { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  THSType,
  TempPromptQuestionsArrayType,
  UploadStatusType,
} from 'types/MyAssessments-types';
import { Toast } from 'widgets/Toast/Toast';

export const getPractiseSectionsServiceApi =
  (sessionId: number): AppThunk =>
  async (dispatch) => {
    dispatch(getPractiseSectionsLoad());

    await commonAPI
      .get(ProtectedUrls.getPractiseSections.url(sessionId))
      .then((response) => {
        dispatch(getPractiseSectionsSuccess(response.data));
      })
      .catch((error) =>
        dispatch(
          getPractiseSectionsFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const uploadSectionVideoServiceApi =
  (
    mainRecordingSettings: THSType,
    isFirstUpload: boolean,
    totalSeconds: number,
    clearBlobUrl: Function,
    relatedToJobId: number,
    setrelatedToJobId: Function,
    isSkipped: boolean,
    setSuccessfullyUploadedSectionVideos: Function,
    setskippedSectionVideos: Function,
    isLastVideo: boolean,
    setUploadProgressStatus: Function,
    setIsLastVideoUploaded: Function,
    uploadStatus: UploadStatusType,
    item: UploadStatusType,
    candidateDetails: {
      firstName: string;
      lastName: string;
      email: string;
      assessmentId: number;
    },
    dynamic_prediction: boolean
  ): AppThunk =>
  async (dispatch) => {
    const uploadData = item.currentDataToUpload;
    const payload = {
      ...uploadData,
      ...mainRecordingSettings,
      totalSeconds,
    };

    dispatch(uploadSectionVideoLoad());
    dispatch(
      setSectionUploadStatusServiceApi(
        payload.id,
        uploadStatusList.uploading,
        item.mediaBlobUrl,
        item.currentDataToUpload,
        false
      )
    );

    const backendEndpoint = ProtectedUrls.uploadSectionVideo.url(
      payload,
      isFirstUpload,
      relatedToJobId,
      isSkipped,
      candidateDetails,
      dynamic_prediction
    );
    if (isSkipped) {
      try {
        const response = await commonAPI.post(backendEndpoint);
        const responseJson = response.data;
        if (responseJson.Job_Id) {
          if (
            isFirstUpload &&
            !store.getState().api.sectionUploadStatusReducer.isFirstUnskiped
          ) {
            setrelatedToJobId(responseJson.Job_Id);
            dispatch(setRelatedToId(responseJson.Job_Id));
          }
          dispatch(uploadSectionVideoSuccess(responseJson));
          const itemsQueued = store
            .getState()
            .api.sectionUploadStatusReducer.uploadStatus.filter(
              (item: UploadStatusType) => {
                return item.status === uploadStatusList.queued;
              }
            );

          if (itemsQueued.length > 0) {
            dispatch(
              uploadSectionVideoServiceApi(
                mainRecordingSettings,
                false,
                store.getState().api.sectionUploadStatusReducer.totalSeconds,
                clearBlobUrl,
                store.getState().api.sectionUploadStatusReducer.related_to,
                setrelatedToJobId,
                itemsQueued[0].mediaBlobUrl === uploadStatusList.skipped
                  ? true
                  : false,
                setSuccessfullyUploadedSectionVideos,
                setskippedSectionVideos,
                store.getState().api.sectionUploadStatusReducer.isLastVideo,
                setUploadProgressStatus,
                setIsLastVideoUploaded,
                uploadStatus,
                itemsQueued[0],
                candidateDetails,
                dynamic_prediction
              )
            );
          }

          if (item.mediaBlobUrl) {
            dispatch(
              setSectionUploadStatusServiceApi(
                payload.id,
                uploadStatusList.uploadedSkippedAPI,
                item.mediaBlobUrl,
                item.currentDataToUpload,
                false
              )
            );
          }
          setskippedSectionVideos((prev: number[]) => [...prev, payload.id]);
        } else {
          Toast(
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            ),
            'error'
          );
          dispatch(uploadSectionVideoFailure(response));

          if (item.mediaBlobUrl) {
            dispatch(
              setSectionUploadStatusServiceApi(
                payload.id,
                uploadStatusList.failedSkippedAPI,
                item.mediaBlobUrl,
                item.currentDataToUpload,
                false
              )
            );
          }
        }
      } catch (error) {
        dispatch(uploadSectionVideoFailure(error));

        dispatch(
          setSectionUploadStatusServiceApi(
            payload.id,
            uploadStatusList.failed,
            item.mediaBlobUrl,
            item.currentDataToUpload,
            false
          )
        );
      }
    } else {
      const config: AxiosRequestConfig<FormData> = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (
            progressEvent.total !== null &&
            progressEvent.total !== undefined
          ) {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgressStatus({
              [item.sectionId]: progress,
            });
          }
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let fileName =
        payload.recordingType.id === 1
          ? `${store.getState().api.sectionUploadStatusReducer.videoTitle}.mp4`
          : `${store.getState().api.sectionUploadStatusReducer.videoTitle}.mp3`;
      const formData = new FormData();
      if (item.mediaBlobUrl instanceof Blob) {
        fixWebmDuration(
          item?.mediaBlobUrl,
          totalSeconds * 1000,
          async function (fixedBlob) {
            formData.append('files', fixedBlob, fileName);

            try {
              const response = await commonAPI.post(
                backendEndpoint,
                formData,
                config
              );
              const responseJson = response.data;

              if (responseJson.Job_Id) {
                setSuccessfullyUploadedSectionVideos((prev: number[]) => [
                  ...prev,
                  payload.id,
                ]);

                if (
                  isFirstUpload &&
                  !store.getState().api.sectionUploadStatusReducer
                    .isFirstUnskiped
                ) {
                  setrelatedToJobId(responseJson.Job_Id);
                  dispatch(setRelatedToId(responseJson.Job_Id));
                }
                dispatch(uploadSectionVideoSuccess(responseJson));

                const itemsQueued = store
                  .getState()
                  .api.sectionUploadStatusReducer.uploadStatus.filter(
                    (item: UploadStatusType) => {
                      return item.status === uploadStatusList.queued;
                    }
                  );

                if (itemsQueued.length > 0) {
                  dispatch(
                    uploadSectionVideoServiceApi(
                      mainRecordingSettings,
                      false,
                      store.getState().api.sectionUploadStatusReducer
                        .totalSeconds,
                      clearBlobUrl,
                      store.getState().api.sectionUploadStatusReducer
                        .related_to,
                      setrelatedToJobId,
                      itemsQueued[0].mediaBlobUrl === uploadStatusList.skipped
                        ? true
                        : false,
                      setSuccessfullyUploadedSectionVideos,
                      setskippedSectionVideos,
                      store.getState().api.sectionUploadStatusReducer
                        .isLastVideo,

                      setUploadProgressStatus,
                      setIsLastVideoUploaded,
                      uploadStatus,
                      itemsQueued[0],
                      candidateDetails,
                      dynamic_prediction
                    )
                  );
                }
                dispatch(
                  setSectionUploadStatusServiceApi(
                    payload.id,
                    uploadStatusList.uploaded,
                    item.mediaBlobUrl,
                    item.currentDataToUpload,
                    false
                  )
                );

                if (
                  store.getState().api.sectionUploadStatusReducer.isLastVideo
                ) {
                  setIsLastVideoUploaded(true);
                }
              } else {
                Toast(
                  i18n.t(
                    'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
                  ),
                  'error'
                );
                dispatch(uploadSectionVideoFailure(response));

                dispatch(
                  setSectionUploadStatusServiceApi(
                    payload.id,
                    uploadStatusList.failed,
                    item.mediaBlobUrl,
                    item.currentDataToUpload,
                    false
                  )
                );
              }
            } catch (error) {
              dispatch(uploadSectionVideoFailure(error));

              dispatch(
                setSectionUploadStatusServiceApi(
                  payload.id,
                  uploadStatusList.failed,
                  item.mediaBlobUrl,
                  item.currentDataToUpload,
                  false
                )
              );
            }
          }
        );
      }
    }
  };

export const getAllPromptsSectionsStatusServiceApi =
  (
    coachingSessionReportsId: number,
    handleClickOpenSubmitAssessmentConfirmationDialog: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(allPromptsSectionsStatusLoad());

    await commonAPI
      .post(
        ProtectedUrls.allPromptsSectionsStatus.url(coachingSessionReportsId)
      )
      .then((response) => {
        const { sections } = response.data;

        if (sections.length > 0) {
          dispatch(
            getAllPromptsSectionsStatusServiceApi(
              coachingSessionReportsId,
              handleClickOpenSubmitAssessmentConfirmationDialog
            )
          );
        } else {
          dispatch(allPromptsSectionsStatusSuccess(response.data));
          handleClickOpenSubmitAssessmentConfirmationDialog();
        }
      })
      .catch((error) =>
        dispatch(
          allPromptsSectionsStatusFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const setSectionUploadStatusServiceApi =
  (
    sectionId: number,
    status: string,
    mediaBlobUrl: Blob | string,
    currentDataToUpload: TempPromptQuestionsArrayType,
    clearUploadStatus: boolean
  ): AppThunk =>
  async (dispatch) => {
    if (clearUploadStatus) {
      dispatch(clearUploadStatusArray());
      dispatch(setTotalDuration(0));
      dispatch(setIsLastVideo(false));
      dispatch(setRelatedToId(-1));
      dispatch(setIsFirstVideo(false));
      dispatch(setVideoTitle(''));
      dispatch(setIsFirstReupload(false));
      dispatch(setIsFirstUnskipped(false));
    } else {
      dispatch(
        sectionUploadSuccess({
          sectionId,
          status,
          mediaBlobUrl,
          currentDataToUpload,
        })
      );
    }
  };
