import React from 'react';

export default function CancelSaveIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <circle
        cx='15'
        cy='15'
        r='14.75'
        fill='white'
        stroke='#EA3323'
        stroke-width='0.5'
      />
      <path
        d='M20.4001 20.4L9.6001 9.59998M20.4001 9.59998L9.6001 20.4'
        stroke='#EA3323'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
