/** @format */
import {
  Autocomplete as IosRecordComplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { SyntheticEvent } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import Loader from 'widgets/Loader/Loader';
import Clock from 'widgets/Clock';
import { recordingTypeList } from 'constants/Record';
import { t } from 'i18next';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  getSessionTypesSelector,
  uploadSingleFileSelector,
} from 'redux/selectors/selectors';
import { useAppSelector } from 'redux/store';
import RecordedSessionUploadCompleteModal from './RecordedSessionUploadCompleteModal';
import AudioRecorder from './IosRecordAudio';
import IosRecordVideo from './IosRecordVideo';
import DialogModal from 'widgets/Dialog/DialogModal';
import useIosRecord from './hooks/useIosRecord';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';
import Typography from 'widgets/Typography/Typography';

function IosRecord() {
  const { isLoadingUploadSingleFile } = useAppSelector(
    uploadSingleFileSelector
  );
  const { SessionTypes, isLoadingSessionTypes } = useAppSelector(
    getSessionTypesSelector
  );
  const {
    videoDesc,
    selectedSessionType,
    recordingType,
    state,
    openRecordedSessionUploadCompleteModal,
    openAfterRecordConfirmModal,
    setVideoDesc,

    setSelectedSessionType,
    setRecordingType,
    handleOpenAfterRecordConfirmModal,
    startRecordingInternal,
    handleCloseRecordedSessionUploadCompleteModal,
    handleCloseAfterRecordConfirmModal,
    handleUploadAudioWithDurationFIx,
    handleOpenRecordedSessionUploadCompleteModal,
    startTimer,
    pauseTimer,
  } = useIosRecord();
  const { t } = useTranslation();
  return (
    <AuthWrapper>
      {isLoadingUploadSingleFile || isLoadingSessionTypes ? (
        <Loader />
      ) : (
        <Box>
          <Box
            sx={{
              minHeight: '100vh',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Clock />
            <TextField
              name='videoDescription'
              size='small'
              variant='outlined'
              fullWidth
              id='videoDescription'
              className='overlap-2'
              InputProps={{
                style: {
                  borderRadius: '35px',
                  fontFamily: 'poppins',
                },
                endAdornment: (
                  <InputAdornment position='end' sx={{ opacity: '0.7' }}>
                    <IconButton
                      onClick={() => {
                        setVideoDesc(getDefaultVideoDescription());
                      }}
                    >
                      <RefreshOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              defaultValue={getDefaultVideoDescription()}
              label={t('record.VideoDescription')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVideoDesc(event.target.value);
              }}
              value={videoDesc}
            />
            <IosRecordComplete
              fullWidth
              disablePortal
              id='combo-box-demo'
              size='small'
              options={SessionTypes}
              getOptionLabel={(option) => option.name}
              sx={{ fontFamily: 'Poppins' }}
              renderInput={(params) => (
                <TextField {...params} label={t('record.SessionType')} />
              )}
              onChange={(
                _event: SyntheticEvent<Element, Event>,
                newValue: { id: number; name: string } | null
              ) => {
                if (newValue) setSelectedSessionType(newValue);
              }}
              value={selectedSessionType}
              popupIcon={
                <ExpandMoreIcon
                  style={{
                    color: 'blue',
                    backgroundColor: 'rgba(18, 6, 249, 0.07)',
                    borderRadius: '50%',
                  }}
                />
              }
            />
            <IosRecordComplete
              fullWidth
              disablePortal
              id='combo-box-demo'
              size='small'
              options={recordingTypeList}
              getOptionLabel={(option) => t(option.name)}
              sx={{ fontFamily: 'Poppins' }}
              renderInput={(params) => (
                <TextField {...params} label={t('record.RecordingType')} />
              )}
              onChange={(
                _event: SyntheticEvent<Element, Event>,
                newValue: { id: number; name: string } | null
              ) => {
                if (newValue) setRecordingType(newValue);
              }}
              value={recordingType}
              popupIcon={
                <ExpandMoreIcon
                  style={{
                    color: 'blue',
                    backgroundColor: 'rgba(18, 6, 249, 0.07)',
                    borderRadius: '50%',
                  }}
                />
              }
            />
            <Box
              sx={{
                minHeight: '90px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {recordingType.id === 1 ? (
                <IosRecordVideo
                  videoDesc={videoDesc}
                  recordingType={recordingType}
                  selectedSessionType={selectedSessionType}
                  handleOpenRecordedSessionUploadCompleteModal={
                    handleOpenRecordedSessionUploadCompleteModal
                  }
                />
              ) : (
                <AudioRecorder
                  state={state}
                  handleOpenAfterRecordConfirmModal={
                    handleOpenAfterRecordConfirmModal
                  }
                  startRecordingInternal={startRecordingInternal}
                  startTimer={startTimer}
                  pauseTimer={pauseTimer}
                />
              )}
            </Box>

            <Typography
              sx={{
                color: '#33344B ',
                fontFamily: 'Poppins ',
                fontSize: '14px',
                textAlign: 'center',
              }}
              label='record.RecordingLimitIs10Minutes'
            />
          </Box>
        </Box>
      )}
      <RecordedSessionUploadCompleteModal
        openRecordedSessionUploadCompleteModal={
          openRecordedSessionUploadCompleteModal
        }
        handleCloseRecordedSessionUploadCompleteModal={
          handleCloseRecordedSessionUploadCompleteModal
        }
      />

      <DialogModal
        onClickCancelButton={handleCloseAfterRecordConfirmModal}
        confirmButtonLabel={t('record.YesSaveIt')}
        cancelButtonLabel={t('record.NoRemoveIt')}
        dialogModalTitle={t('record.UploadRecordedSession')}
        dialogModalBody={t('record.YourSessionHasBeenRecordedDoYouWantToSave')}
        openDialogModal={openAfterRecordConfirmModal}
        onClickConfirmButton={handleUploadAudioWithDurationFIx}
      />
    </AuthWrapper>
  );
}

export default IosRecord;
