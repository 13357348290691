/** @format */

import { Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAvailableSubscriptionPlansSelector,
  getSubscriptionSelector,
} from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getAvailableSubscriptionPlansServiceApi,
  getSubscriptionServiceApi,
} from 'services/Subscription-service';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import Loader from 'widgets/Loader/Loader';
import Typography from 'widgets/Typography/Typography';

function StripeSubscriptionPaymentSuccessComponent() {
  const dispatch = useAppDispatch();

  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({
    userPlanCreatedDate: '',
    userPlanExpiryDate: '',
    currentSubscriptionPlan: {},
  });

  const { getSubscriptionData, isLoadingGetSubscription } = useAppSelector(
    getSubscriptionSelector
  );

  useEffect(() => {
    dispatch(getSubscriptionServiceApi());
    dispatch(getAvailableSubscriptionPlansServiceApi());
  }, [dispatch]);
  const {
    getAvailableSubscriptionPlansData,
    isLoadingGetAvailableSubscriptionPlans,
  } = useAppSelector(getAvailableSubscriptionPlansSelector);

  useEffect(() => {
    if (
      getSubscriptionData.length > 0 &&
      getAvailableSubscriptionPlansData.length > 0
    ) {
      const currentSubscriptionPlan = getAvailableSubscriptionPlansData.find(
        (availableSubscriptionPlan: any) => {
          return availableSubscriptionPlan.id === getSubscriptionData[0].planid;
        }
      );

      setSubscriptionDetails({
        ...subscriptionDetails,

        userName:
          sessionStorage.getItem('first_name') +
          ' ' +
          sessionStorage.getItem('last_name'),
        userEmail: sessionStorage.getItem('userName'),

        currentSubscriptionPlan: currentSubscriptionPlan,

        userPlanCreatedDate: new Date(
          getSubscriptionData[0]?.createddate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
        userPlanExpiryDate: new Date(
          getSubscriptionData[0]?.expirydate
        ).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      });
    }
  }, [getSubscriptionData, getAvailableSubscriptionPlansData]);

  return (
    <AuthWrapper>
      {isLoadingGetSubscription || isLoadingGetAvailableSubscriptionPlans ? (
        <Loader />
      ) : (
        <Box sx={{ minHeight: '100vh', height: '100%' }}>
          <Box>
            {' '}
            <Typography
              sx={{
                color: '#2C2C2C',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.198px',
                marginTop: 2,
                marginLeft: 2,
              }}
              label='mysubcriptions.SUBSCRIPTIONPAYMENTSTATUS'
            />
            <Typography
              sx={{ marginTop: 2, marginLeft: 2 }}
              label='mysubcriptions.YourSubscriptionHasBeenSuccessfullyProcessed'
            />
          </Box>

          <Box sx={{ marginTop: 2, marginLeft: 2 }}>
            <Typography
              variant='h5'
              sx={{ marginBottom: 2 }}
              label='mysubcriptions.SubscriptionDetails'
            />

            <Divider sx={{ marginBottom: 2 }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '40%' }}>
                  <List>
                    <ListItem>
                      {' '}
                      <Typography label='mysubcriptions.MemberDetails' />
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ width: '60%' }}>
                  <List>
                    <ListItem>
                      <Typography label='mysubcriptions.Name'>
                        {' '}
                        :{subscriptionDetails.userName}
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography label='mysubcriptions.Email'>
                        {' '}
                        :{subscriptionDetails.userEmail}{' '}
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '40%' }}>
                  <List>
                    <ListItem>
                      {' '}
                      <Typography label='mysubcriptions.PlanDetails' />
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ width: '60%' }}>
                  <List>
                    <ListItem>
                      <Typography label='mysubcriptions.PlanName'>
                        :
                        {subscriptionDetails.currentSubscriptionPlan &&
                          subscriptionDetails.currentSubscriptionPlan
                            .planname}{' '}
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography label='mysubcriptions.Status'> :</Typography>
                      <Typography
                        label={
                          new Date(subscriptionDetails.userPlanExpiryDate) <
                          new Date()
                            ? 'mysubcriptions.Expired'
                            : 'mysubcriptions.Active'
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography label='mysubcriptions.StartDate'>
                        :{subscriptionDetails.userPlanCreatedDate}
                      </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Typography label='mysubcriptions.EndDate'>
                        :{subscriptionDetails.userPlanExpiryDate}
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Divider />
            </Box>
          </Box>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default StripeSubscriptionPaymentSuccessComponent;
