/** @format */

import axios from 'axios';

import env from 'envConfig';

const sandboxPreviewAPI = axios.create({
  baseURL: env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
sandboxPreviewAPI.interceptors.request.use((config) => {
  const apiKey = 'f7833e18-3c34-5f2a-b6fa-73b3129a5e03';

  if (apiKey) {
    config.headers['x-api-key'] = apiKey;
  }

  return config;
});

export default sandboxPreviewAPI;
