/** @format */

import React from 'react';

function UploadIcon() {
  return (
    <svg
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 6.90906L2.25046 6.95065C1.55728 6.95952 1 7.52397 1 8.21721V17.7333C1 18.4329 1.56711 19 2.26667 19H14.7333C15.4329 19 16 18.4329 16 17.7333V8.17572C16 7.47616 15.4329 6.90906 14.7333 6.90906H11.5'
        stroke='#4027FE'
        stroke-width='1.26667'
      />
      <path
        d='M6 4L8.66667 1M8.66667 1L11.5 4M8.66667 1V9'
        stroke='#23C290'
        stroke-width='1.27'
      />
    </svg>
  );
}

export default UploadIcon;
