/** @format */
import i18n from 'i18next';
export const subscriptionsArrayStatus: Array<{
  message: string;
  status: string;
}> = [
  {
    status:'subscriptionNotFound',
    message:
    'mysubcriptions.YouDoNotHaveAnyActiveSubscriptionPleaseUpgradeAndProceed',
  },

  {
    status: 'expired',
    message: 'mysubcriptions.YourPlanIsExpiredPleaseUpgradeAndProceed',
  },
];
