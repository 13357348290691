import toast, { ToastOptions } from "react-hot-toast";

export const Toast = (
  message: string,
  type: "success" | "error" = "success",
  config: ToastOptions = {}
) =>
  toast[type](message, {
    duration: 2000,
    position: "top-right",
    className: `toast ${type === "success" ? "success" : "error"}`,
    ...config,
  });
