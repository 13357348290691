import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Paper,
  Autocomplete,
  TextField,
  Divider,
  Box,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Typography from "widgets/Typography/Typography";
import { PatientWorkFlowStepComponentList } from "constants/PatientWorkflow";
import Loader from "widgets/Loader/Loader";
import { CameraDetails, MicrophoneDetails } from "types/MyAssessments-types";

interface DeviceInfo {
  deviceId: string;
  label: string;
}

function PatientWorkflowWebRecord({
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  setVideoDevices,
  setAudioDevices,
}: {
  goToPatientWorkflowStep: Function;
  videoDevices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  selectedVideoDevice: CameraDetails;
  selectedAudioDevice: MicrophoneDetails;
  setSelectedVideoDevice: Function;
  setSelectedAudioDevice: Function;
  setVideoDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
  setAudioDevices: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
}) {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => {
        setPermissionGranted(true);
        return navigator.mediaDevices.enumerateDevices();
      })
      .then((devices) => {
        const videoOptions = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const audioOptions = devices.filter(
          (device) => device.kind === "audioinput"
        );

        setVideoDevices(
          videoOptions.map((device) => ({
            deviceId: device.deviceId,
            label: device.label || `Video Device ${videoOptions.length}`,
          }))
        );

        setAudioDevices(
          audioOptions.map((device) => ({
            deviceId: device.deviceId,
            label: device.label || `Audio Device ${audioOptions.length}`,
          }))
        );

        if (videoOptions.length > 0) {
          const selectedVideo = {
            deviceId: videoOptions[0].deviceId,
            label:
              videoOptions[0].label || `Video Device ${videoOptions.length}`,
          };
          setSelectedVideoDevice(selectedVideo);

          startStream(selectedVideo, audioOptions[0]);
        }
        if (audioOptions.length > 0) {
          setSelectedAudioDevice({
            deviceId: audioOptions[0].deviceId,
            label:
              audioOptions[0].label || `Audio Device ${audioOptions.length}`,
          });
        }
      })
      .catch(() => {
        setError("Unable to access devices. Please check permissions.");
      });
  }, []);

  const startStream = (
    videoDevice: DeviceInfo | null,
    audioDevice: DeviceInfo | null
  ) => {
    setError(null);

    navigator.mediaDevices
      .getUserMedia({
        video: videoDevice
          ? { deviceId: { exact: videoDevice.deviceId } }
          : false,
        audio: false,
      })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch(() => {
        setError("Error starting media stream. Check devices and permissions.");
      });
  };

  const handlestart = () => {
    if (!selectedAudioDevice) {
      setError("Audio device is not found. Please select an audio device.");
      return;
    } else {
      goToPatientWorkflowStep(PatientWorkFlowStepComponentList.Quesionnaire);
    }
  };
  useEffect(() => {
    if (selectedVideoDevice) {
      // Start video stream with the selected camera
      navigator.mediaDevices

        .getUserMedia({
          video: { deviceId: selectedVideoDevice.deviceId },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        });
    }
  }, [selectedVideoDevice]);
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {/* Video Stream Section */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "60vh",
            height: "100%",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "black",
            }}
          />
        </Box>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Paper
          elevation={3}
          sx={{
            padding: isMobile ? 6 : isTablet ? 8 : 10,
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "60vh",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              mt: 1,
              mb: 1,
              display: "flex",
              alignItems: "flex-start",

              width: "100%",
            }}
          >
            <Typography
              label="Device Settings"
              sx={{ color: "#374151", fontSize: "20px", fontWeight: 600 }}
            />
          </Box>
          <Box
            sx={{
              mt: 1,
              mb: 2,
              width: "100%",
              borderBottom: "1px solid #1D1F27",
            }}
          ></Box>
          <Autocomplete
            options={videoDevices}
            getOptionLabel={(option) => option.label}
            value={selectedVideoDevice}
            onChange={(event, newValue) => setSelectedVideoDevice(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Video Devices" variant="outlined" />
            )}
            sx={{ width: "100%", marginBottom: 2, mt: 2 }}
          />
          <Box
            sx={{
              mt: 1,
              mb: 2,
              width: "100%",
              borderBottom: "1px solid #1D1F27",
              opacity: 0.8,
            }}
          ></Box>
          <Autocomplete
            options={audioDevices}
            getOptionLabel={(option) => option.label}
            value={selectedAudioDevice}
            onChange={(event, newValue) => setSelectedAudioDevice(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Audio Devices" variant="outlined" />
            )}
            sx={{ width: "100%", marginBottom: 2, mt: 2 }}
          />
          <Box
            sx={{
              mt: 1,
              mb: 2,
              width: "100%",
              borderBottom: "1px solid #1D1F27",
              opacity: 0.8,
            }}
          ></Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handlestart}
            sx={{ width: "100%", mt: 1 }}
            disabled={videoDevices.length === 0}
          >
            {videoDevices.length === 0 ? <CircularProgress /> : "Start"}
          </Button>
          {error && (
            <Typography color="error" label="" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PatientWorkflowWebRecord;
