import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface CustomButtonProps
  extends Omit<MuiButtonProps, 'color' | 'variant' | 'onSubmit'> {
  label?: string;
  backgroundColor?: MuiButtonProps['color'] | 'transparent';
  variant?: MuiButtonProps['variant'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<CustomButtonProps> = ({
  label = 'Button',
  onClick,
  backgroundColor = 'transparent',
  variant = 'contained',
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <MuiButton
      onClick={onClick}
      variant={variant}
      sx={{
        '&:hover': {
          backgroundColor: 'inherit !important',
          color: 'inherit',
        },
      }}
      {...restProps}
    >
      {t(label)}
    </MuiButton>
  );
};

export default Button;
