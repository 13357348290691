/** @format */

import { onChangeGlobalSearch } from 'redux/slices/Global-Search-slice';
import { AppThunk } from 'redux/store';

export const globalSearchService =
  (values: string): AppThunk =>
  async (dispatch) => {
    dispatch(onChangeGlobalSearch(values));
  };
