import React, { useState } from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import { Box, Grid, Paper, Button as MuiButton } from '@mui/material';
import Typography from 'widgets/Typography/Typography';
import { PerformanceStatGraph } from 'widgets/PerformanceStatGraph';
import { ToneStatGraph } from 'widgets/ToneStatGraph';
import { usePDF } from 'react-to-pdf';
import Button from 'widgets/CustomButton/Button';
import CloseIcon from '@mui/icons-material/Close';

const PdfGenerator = ({
  ProcessDataListForParticipantsVersion2,
  setShowPdfGenerator,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  setShowPdfGenerator: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { toPDF, targetRef } = usePDF({ filename: 'Report.pdf' });

  const closeOverlay = () => {
    setShowOverlay(false);
    setShowPdfGenerator(false);
  };
  const [showOverlay, setShowOverlay] = useState(true);

  const [lastName, setLastName] = useState(sessionStorage.getItem('last_name'));
  const [userName, setUserName] = useState(
    sessionStorage.getItem('first_name')
  );
  return (
    <>
      {showOverlay && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              m: 2,
            }}
          >
            <MuiButton onClick={closeOverlay}>
              <CloseIcon />
            </MuiButton>
            <Button
              sx={{
                backgroundColor: '#1206f9',
                padding: 2,
                textAlign: 'center',
                size: 'small',
                borderRadius: '25px',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#1206f9',
                },
              }}
              id='btnForgotPassword'
              variant='contained'
              onClick={() => toPDF()}
              label='Download'
            />
          </Box>

          <div
            ref={targetRef}
            style={{ width: '210mm', minHeight: '297mm', padding: '10px' }}
          >
            <Box sx={{ width: '100%', height: '20px' }} />

            <img
              alt='mirro-logo-with-name'
              src='./comonImg/mirro-logo-with-namee.png'
              style={{
                width: '121.586px',
                height: '20.264px',
                margin: '20px auto',
                display: 'block',
              }}
            />

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <Typography
                sx={{ color: 'black', display: 'flex', gap: '10px', ml: 2 }}
                label='Media ID/Name:'
              >
                <Typography
                  sx={{ color: 'black' }}
                  label={`${userName} ${lastName}`}
                />
              </Typography>
              <Typography
                sx={{ color: 'black', display: 'flex', gap: '10px', ml: 2 }}
                label='Session ID:'
              >
                <Typography
                  sx={{ color: 'black' }}
                  label={`${ProcessDataListForParticipantsVersion2[0]?.coaching_session_reports_id}`}
                />
              </Typography>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{ color: 'black', fontSize: '14px', fontWeight: 500 }}
                  label='Performance Stats'
                />
                <Paper elevation={2}>
                  <PerformanceStatGraph
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 500,
                    alignSelf: 'left',
                  }}
                  label='Tone Stats'
                />
                <Paper elevation={2}>
                  <ToneStatGraph
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                  />
                </Paper>
              </Grid>
            </Grid>

            <div style={{ margin: '20px 0', pageBreakInside: 'avoid' }}>
              <Box sx={{ padding: '20px' }}>
                <Typography
                  sx={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
                  label='Overall Summary'
                />
                <div style={{ marginTop: 3, gap: 2 }}>
                  {ProcessDataListForParticipantsVersion2?.[0]
                    ?.session_type_id === 58 ||
                  ProcessDataListForParticipantsVersion2?.[0]
                    ?.session_type_id === 59 ? (
                    <div>
                      {ProcessDataListForParticipantsVersion2?.[0]
                        ?.detailed_summary ? (
                        <div>
                          <section style={{ marginTop: 1, marginBottom: 1 }}>
                            <Typography
                              label='Patient Overview'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <Typography label='' sx={{ fontSize: '14px' }}>
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.patient_overview
                                  ?.relevant_info
                              }
                            </Typography>
                            <Typography label='' sx={{ fontSize: '14px' }}>
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.patient_overview
                                  ?.medical_history
                              }
                            </Typography>
                            <Typography label='' sx={{ fontSize: '14px' }}>
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.patient_overview
                                  ?.current_health_status
                              }
                            </Typography>
                          </section>

                          <section style={{ marginTop: 2, marginBottom: 2 }}>
                            <Typography
                              label='Energy Levels'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <Typography
                              label='Dominant Emotion:'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.dominant_emotion
                              }
                            </Typography>
                            <Typography
                              label='Energy Levels:'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.energy_levels
                              }
                            </Typography>
                            <Typography
                              label='Engagement Score:'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.engagement_score
                              }
                            </Typography>
                            <Typography
                              label='confident tone :'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.confidence_fatigue_stress?.confident_tone
                              }
                            </Typography>
                            <Typography
                              label=' fatigued tone :'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.confidence_fatigue_stress?.fatigued_tone
                              }
                            </Typography>
                            <Typography
                              label='stressed tone:'
                              sx={{ fontSize: '14px' }}
                            >
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.overall_sentiment
                                  ?.confidence_fatigue_stress?.stressed_tone
                              }
                            </Typography>
                          </section>

                          <section style={{ marginTop: 2, marginBottom: 1 }}>
                            <Typography
                              label='Emotional Discrepancies'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <Typography label='' sx={{ fontSize: '14px' }}>
                              {
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.detailed_tone_analysis
                                  ?.emotional_discrepancies
                              }
                            </Typography>
                          </section>

                          <section style={{ marginTop: 1, marginBottom: 1 }}>
                            <Typography
                              label='Stress Indicators'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            {
                              ProcessDataListForParticipantsVersion2?.[0]
                                ?.detailed_summary?.detailed_tone_analysis
                                ?.stress_indicators
                            }
                          </section>

                          <section style={{ marginTop: 1, marginBottom: 1 }}>
                            <Typography
                              label='Recovery Plan'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>

                            <Typography
                              label='Immediate Actions'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <ul>
                              {(
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.recovery_plan
                                  ?.immediate_actions || []
                              ).map((action: any, index: number) => (
                                <li key={index} style={{ fontSize: '14px' }}>
                                  {action}
                                </li>
                              ))}
                            </ul>

                            <Typography
                              label='Short-term Goals'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <ul>
                              {(
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.recovery_plan
                                  ?.short_term_goals || []
                              ).map((goal: any, index: number) => (
                                <li key={index} style={{ fontSize: '14px' }}>
                                  {goal}
                                </li>
                              ))}
                            </ul>

                            <Typography
                              label='Long-term Strategies'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <ul>
                              {(
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.recovery_plan
                                  ?.long_term_strategies || []
                              ).map((strategy: any, index: number) => (
                                <li key={index} style={{ fontSize: '14px' }}>
                                  {strategy}
                                </li>
                              ))}
                            </ul>

                            <Typography
                              label='Emotional Support'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <ul>
                              {(
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.recovery_plan
                                  ?.emotional_support || []
                              ).map((support: any, index: number) => (
                                <li key={index} style={{ fontSize: '14px' }}>
                                  {support}
                                </li>
                              ))}
                            </ul>

                            <Typography
                              label='Progress Monitoring'
                              sx={{ fontSize: '15px', fontWeight: 600 }}
                            ></Typography>
                            <ul>
                              {(
                                ProcessDataListForParticipantsVersion2?.[0]
                                  ?.detailed_summary?.recovery_plan
                                  ?.progress_monitoring || []
                              ).map((monitoring: any, index: number) => (
                                <li key={index} style={{ fontSize: '14px' }}>
                                  {monitoring}
                                </li>
                              ))}
                            </ul>
                          </section>
                        </div>
                      ) : (
                        <Typography label=''>
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                            }}
                          >
                            {
                              ProcessDataListForParticipantsVersion2[0]
                                .overall_summary
                            }
                          </pre>
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <Typography label=''>
                      <pre
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                        }}
                      >
                        {
                          ProcessDataListForParticipantsVersion2[0]
                            .overall_summary
                        }
                      </pre>
                    </Typography>
                  )}
                </div>
              </Box>
              {ProcessDataListForParticipantsVersion2[0]?.is_prompt_section && (
                <Box sx={{ padding: '20px', marginTop: '5%' }}>
                  <Typography
                    sx={{ color: 'black', fontSize: '14px', fontWeight: 600 }}
                    label='Prompt-wise Summary'
                  />
                  {ProcessDataListForParticipantsVersion2.length > 0 &&
                    ProcessDataListForParticipantsVersion2[0]?.prompt_sections.map(
                      (prompt: any, index: number) => (
                        <div key={index} style={{ pageBreakInside: 'avoid' }}>
                          <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            label=''
                          >
                            <Typography
                              variant='h5'
                              sx={{
                                color: 'black',
                                fontSize: '12px',
                                lineHeight: '24px',
                                fontWeight: 600,
                              }}
                              label={`Prompt ${index + 1}`}
                            />
                            :
                            <Typography
                              sx={{ fontSize: '12px', fontWeight: 400 }}
                              label={prompt.prompt_question}
                            />
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              color: 'black',
                              fontSize: '12px',
                              lineHeight: '24px',
                              fontWeight: 600,
                              mt: 1,
                            }}
                            label=''
                          >
                            Summary:
                          </Typography>
                          <div style={{ marginTop: 3, gap: 2 }}>
                            {prompt?.session_type_id === 58 ||
                            prompt?.session_type_id === 59 ? (
                              <div>
                                {prompt?.detailed_summary ? (
                                  <div>
                                    <section
                                      style={{ marginTop: 1, marginBottom: 1 }}
                                    >
                                      <Typography
                                        label='Patient Overview'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <Typography
                                        label=''
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.patient_overview?.relevant_info
                                        }
                                      </Typography>
                                      <Typography
                                        label=''
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.patient_overview?.medical_history
                                        }
                                      </Typography>
                                      <Typography
                                        label=''
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.patient_overview
                                            ?.current_health_status
                                        }
                                      </Typography>
                                    </section>

                                    <section
                                      style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                      <Typography
                                        label='Energy Levels'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <Typography
                                        label='Dominant Emotion:'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment
                                            ?.dominant_emotion
                                        }
                                      </Typography>
                                      <Typography
                                        label='Energy Levels:'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment?.energy_levels
                                        }
                                      </Typography>
                                      <Typography
                                        label='Engagement Score:'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment
                                            ?.engagement_score
                                        }
                                      </Typography>
                                      <Typography
                                        label='confident tone :'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment
                                            ?.confidence_fatigue_stress
                                            ?.confident_tone
                                        }
                                      </Typography>
                                      <Typography
                                        label=' fatigued tone :'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment
                                            ?.confidence_fatigue_stress
                                            ?.fatigued_tone
                                        }
                                      </Typography>
                                      <Typography
                                        label='stressed tone:'
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.overall_sentiment
                                            ?.confidence_fatigue_stress
                                            ?.stressed_tone
                                        }
                                      </Typography>
                                    </section>

                                    <section
                                      style={{ marginTop: 2, marginBottom: 1 }}
                                    >
                                      <Typography
                                        label='Emotional Discrepancies'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <Typography
                                        label=''
                                        sx={{ fontSize: '14px' }}
                                      >
                                        {
                                          prompt?.detailed_summary
                                            ?.detailed_tone_analysis
                                            ?.emotional_discrepancies
                                        }
                                      </Typography>
                                    </section>

                                    <section
                                      style={{ marginTop: 1, marginBottom: 1 }}
                                    >
                                      <Typography
                                        label='Stress Indicators'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      {
                                        prompt?.detailed_summary
                                          ?.detailed_tone_analysis
                                          ?.stress_indicators
                                      }
                                    </section>

                                    <section
                                      style={{ marginTop: 1, marginBottom: 1 }}
                                    >
                                      <Typography
                                        label='Recovery Plan'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>

                                      <Typography
                                        label='Immediate Actions'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <ul>
                                        {(
                                          prompt?.detailed_summary
                                            ?.recovery_plan
                                            ?.immediate_actions || []
                                        ).map((action: any, index: number) => (
                                          <li
                                            key={index}
                                            style={{ fontSize: '14px' }}
                                          >
                                            {action}
                                          </li>
                                        ))}
                                      </ul>

                                      <Typography
                                        label='Short-term Goals'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <ul>
                                        {(
                                          prompt?.detailed_summary
                                            ?.recovery_plan?.short_term_goals ||
                                          []
                                        ).map((goal: any, index: number) => (
                                          <li
                                            key={index}
                                            style={{ fontSize: '14px' }}
                                          >
                                            {goal}
                                          </li>
                                        ))}
                                      </ul>

                                      <Typography
                                        label='Long-term Strategies'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <ul>
                                        {(
                                          prompt?.detailed_summary
                                            ?.recovery_plan
                                            ?.long_term_strategies || []
                                        ).map(
                                          (strategy: any, index: number) => (
                                            <li
                                              key={index}
                                              style={{ fontSize: '14px' }}
                                            >
                                              {strategy}
                                            </li>
                                          )
                                        )}
                                      </ul>

                                      <Typography
                                        label='Emotional Support'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <ul>
                                        {(
                                          prompt?.detailed_summary
                                            ?.recovery_plan
                                            ?.emotional_support || []
                                        ).map((support: any, index: number) => (
                                          <li
                                            key={index}
                                            style={{ fontSize: '14px' }}
                                          >
                                            {support}
                                          </li>
                                        ))}
                                      </ul>

                                      <Typography
                                        label='Progress Monitoring'
                                        sx={{
                                          fontSize: '15px',
                                          fontWeight: 600,
                                        }}
                                      ></Typography>
                                      <ul>
                                        {(
                                          prompt?.detailed_summary
                                            ?.recovery_plan
                                            ?.progress_monitoring || []
                                        ).map(
                                          (monitoring: any, index: number) => (
                                            <li
                                              key={index}
                                              style={{ fontSize: '14px' }}
                                            >
                                              {monitoring}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </section>
                                  </div>
                                ) : (
                                  <Typography label=''>
                                    <pre
                                      style={{
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {prompt.overall_summary}
                                    </pre>
                                  </Typography>
                                )}
                              </div>
                            ) : (
                              <Typography label=''>
                                <pre
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {prompt.overall_summary}
                                </pre>
                              </Typography>
                            )}
                          </div>
                        </div>
                      )
                    )}
                </Box>
              )}
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default PdfGenerator;
