import { styled, Tabs, Box, Tab } from '@mui/material';
import React from 'react';
import {
  CoachingSessionReport,
  FlagOption,
  SelectedUserRoleTypes,
} from 'types/VideoPreview-types';
import Typography from 'widgets/Typography/Typography';
import PromptDetails from './PromptDetails';
import OverallSummaryDetailsVersion2 from './VideoPreviewAdvanceVersion2/VideoPreviewAdvanceComponents/OverallSummaryDetailsVersion2';

function TabsForOverallAndPromptPerformance({
  selectedPerformanceTab,
  setPerformanceTab,
  ProcessDataListForParticipantsVersion2,
  selectedPrompt,
  selectedUserRole,
  selectedFlags,
}: {
  selectedPerformanceTab: number;
  setPerformanceTab: Function;
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
  selectedPrompt: number;
  selectedFlags: FlagOption[];
  selectedUserRole: Array<SelectedUserRoleTypes>;
}) {
  const CustomTabs = styled(Tabs)({
    '& MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root': {
      backgroundColor: '#8F8AFC',
      width: '100% !important',
    },

    '& .Mui-selected': {
      color: '#1206F9 !important',
      fontWeight: 'bold',
    },
  });

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setPerformanceTab(newValue);
  };

  return (
    <Box sx={{ mt: 2, p: 2 }}>
      <Typography
        sx={{
          color: '#33344B',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.32px',
          ml: 2,
        }}
        label='Performance'
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          width: '100%',
        }}
      >
        {ProcessDataListForParticipantsVersion2[0]?.is_prompt_section ? (
          <CustomTabs
            value={selectedPerformanceTab}
            onChange={handleChange}
            aria-label='custom tabs example'
            sx={{
              width: '100% !important',
              textAlign: 'center',
            }}
          >
            {ProcessDataListForParticipantsVersion2[0]?.is_prompt_section && (
              <Tab value={1} label='Prompt' />
            )}
            <Tab value={0} label=' Overall' />
          </CustomTabs>
        ) : (
          <>
            {selectedUserRole.length === 0 ||
              (selectedUserRole.length >= 2 && (
                <Box
                  sx={{
                    width: '100%',
                    alignItems: 'left',
                    textAlign: 'left',
                    ml: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: '#6C6C6C',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.154px',
                    }}
                    label='Overall Score'
                  />
                </Box>
              ))}
          </>
        )}
      </Box>
      {selectedPerformanceTab === 0 && (
        <OverallSummaryDetailsVersion2
          processDataListForParticipants={
            ProcessDataListForParticipantsVersion2
          }
          selectedUserRole={selectedUserRole}
          selectedFlags={selectedFlags}
          selectedPrompt={selectedPrompt}
        />
      )}
      {selectedPerformanceTab === 1 &&
        ProcessDataListForParticipantsVersion2[0]?.is_prompt_section && (
          <PromptDetails
            processDataListForParticipants={
              ProcessDataListForParticipantsVersion2[0].prompt_sections
            }
            selectedUserRole={selectedUserRole}
            selectedFlags={selectedFlags}
            selectedPrompt={selectedPrompt}
          />
        )}
    </Box>
  );
}

export default TabsForOverallAndPromptPerformance;
