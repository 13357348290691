import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SettingsIcon from 'icons/SettingsIcon';
import React, { useRef, useState } from 'react';
import Typography from 'widgets/Typography/Typography';
import PatientVideoRecorder from './PatientVideoRecorder';
import VideoQuestionaire from './VideoQuestionaire';
import {
  DeviceInfo,
  QuestionnaireData,
  UploadQuestionnairePayload,
} from 'types/TeleHealth/PatientWorkflow-types';
import axios from 'axios';
import fixWebmDuration from 'fix-webm-duration';
import Loader from 'widgets/Loader/Loader';
import { PatientWorkFlowStepComponentList } from 'constants/PatientWorkflow';
import store, { useAppDispatch } from 'redux/store';
import {
  setIsLastVideo,
  setRelatedToId,
} from 'redux/slices/MyAssessment-slice';
import { useReactMediaRecorder } from 'react-media-recorder';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';

function QuestionnaireComponent({
  goToPatientWorkflowStep,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  setSelectedVideoDevice,
  setSelectedAudioDevice,
  apiKey,
  Questionnaire,
  patientID,
  providerID,
  sessionId,
  customfilename,
}: {
  goToPatientWorkflowStep: Function;
  videoDevices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  selectedVideoDevice: CameraDetails;
  selectedAudioDevice: MicrophoneDetails;
  setSelectedVideoDevice: Function;
  setSelectedAudioDevice: Function;
  apiKey: string;
  Questionnaire: Array<QuestionnaireData>;
  patientID: string;
  providerID: number;
  sessionId: number;
  customfilename: string;
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const uploadQueue = useRef<
    Array<{
      payload: UploadQuestionnairePayload;
      blob: Blob;
      duration: number;
    }>
  >([]);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');
  const [anchorElRecorderSettingsDialog, setAnchorElRecorderSettingsDialog] =
    useState<null | HTMLElement>(null);
  const [isFirstUpload, setIsFirstUpload] = useState(true);
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
  }: any = useReactMediaRecorder({
    audio: selectedAudioDevice as MicrophoneDetails,
    video: selectedVideoDevice as CameraDetails,
  });
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLastUploading, setIsLastUploading] = useState(false);

  const handleRecordingComplete = async (
    blobUrl: string | null,
    duration: number
  ) => {
    if (!blobUrl) return;

    if (currentQuestionIndex === Questionnaire.length - 1) {
      dispatch(setIsLastVideo(true));
      setIsLoading(true);
    }

    const currentQuestion = Questionnaire[currentQuestionIndex];

    const uploadPayload = {
      id: currentQuestion.id,
      videoDescription: currentQuestion.question,
      duration_in_msec: duration * 1000,
      Details: {
        patientID: patientID,
        ProviderID: providerID,
      },
      last_section: store.getState().api.sectionUploadStatusReducer.isLastVideo,
    };

    await fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        uploadQueue.current.push({
          payload: uploadPayload,
          blob,
          duration,
        });

        processUploadQueue();
      });

    clearBlobUrl();
    setCurrentQuestionIndex((prevIndex) => {
      if (prevIndex < Questionnaire.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  const processUploadQueue = async () => {
    if (isUploading || uploadQueue.current.length === 0) return;

    setIsUploading(true);

    try {
      while (uploadQueue.current.length > 0) {
        const { payload, blob, duration } = uploadQueue.current[0];

        await uploadVideo(payload, blob, duration);

        uploadQueue.current.shift();
      }
    } finally {
      setIsUploading(false);
    }
  };

  const uploadVideo = async (
    payload: UploadQuestionnairePayload,
    blob: Blob,
    duration: number
  ) => {
    try {
      const detailsString = encodeURIComponent(JSON.stringify(payload.Details));
      let apiUrl;
      let relatedToId =
        store.getState().api.sectionUploadStatusReducer.related_to;

      if (window.location.origin !== 'https://personal.mirro.ai') {
        apiUrl =
          apiUrl = `${process.env.REACT_APP_API_BASE_URL}/UploadSectionVideo?session_type_id=${sessionId}&section_id=${payload.id}&title=${customfilename}&output_period_msec=3072&video_analysis=false&text_analysis=true&dynamic_prediction=true&duration_in_msec=${payload.duration_in_msec}&is_skipped=false&language=English&candidate_details=${detailsString}&last_section=${payload.last_section}`;
      } else {
        apiUrl =
          apiUrl = `${process.env.REACT_APP_API_BASE_URL_PD}/UploadSectionVideo?session_type_id=${sessionId}&section_id=${payload.id}&title=${customfilename}&output_period_msec=3072&video_analysis=false&text_analysis=true&dynamic_prediction=true&duration_in_msec=${payload.duration_in_msec}&is_skipped=false&language=English&candidate_details=${detailsString}&last_section=${payload.last_section}`;
      }

      if (relatedToId !== -1) {
        apiUrl += `&related_to=${relatedToId}`;
      }

      const fixedBlob = await fixWebmDuration(blob, duration * 1000);

      const formData = new FormData();
      formData.append('files', fixedBlob, `${customfilename}.mp4`);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'multipart/form-data',
        },
      });

      const newRelatedToId = response.data.Job_Id;

      if (isFirstUpload && relatedToId === -1) {
        dispatch(setRelatedToId(newRelatedToId));
        setIsFirstUpload(false);
      }

      if (currentQuestionIndex === Questionnaire.length - 1) {
        setIsLoading(false);
        goToPatientWorkflowStep(PatientWorkFlowStepComponentList.Confirmation);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClickRecorderSettingsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElRecorderSettingsDialog(event.currentTarget);
  };
  const handleCloseRecorderSettingsDialog = () => {
    setAnchorElRecorderSettingsDialog(null);
  };

  return (
    <Box sx={{ minHeight: '97.5vh' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            m: isMobileOrTablet ? 1 : 5,
            mt: isMobileOrTablet ? 1 : 5,
            mb: isMobileOrTablet ? 1 : 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {Questionnaire.length > 0 && Questionnaire[currentQuestionIndex] ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: isSmallDevice ? 'column' : 'row',
                }}
              >
                <Typography
                  label='Questionnaire'
                  sx={{ color: '#33344B', fontSize: '28px' }}
                />
                <IconButton
                  onClick={handleClickRecorderSettingsDialog}
                  disabled={status === 'recording'}
                  sx={{ opacity: status === 'recording' ? 0.4 : 1 }}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>

              <Grid
                container
                alignItems='stretch'
                spacing={1}
                sx={{
                  mt: 1,
                }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <VideoQuestionaire
                    question={Questionnaire[currentQuestionIndex]}
                    currentQuestionIndex={currentQuestionIndex}
                    status={status}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <PatientVideoRecorder
                    onRecordingComplete={handleRecordingComplete}
                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                    currentQuestionIndex={currentQuestionIndex}
                    Questionnaire={Questionnaire}
                    selectedVideoDevice={selectedVideoDevice}
                    selectedAudioDevice={selectedAudioDevice}
                    status={status}
                    startRecording={startRecording}
                    stopRecording={stopRecording}
                    mediaBlobUrl={mediaBlobUrl}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      )}
      <Menu
        id='questionaire-component-recorderSettings-menu'
        anchorEl={anchorElRecorderSettingsDialog}
        open={Boolean(anchorElRecorderSettingsDialog)}
        onClose={handleCloseRecorderSettingsDialog}
        PaperProps={{
          style: {
            width: isMobileOrTablet ? '100%' : '30%',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
          <MenuItem>
            <Autocomplete
              options={videoDevices}
              getOptionLabel={(option) => option.label}
              value={selectedVideoDevice}
              onChange={(_event, newValue) => setSelectedVideoDevice(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Video Devices'
                  variant='outlined'
                />
              )}
              sx={{ width: '100%', marginBottom: 2, mt: 2 }}
            />
          </MenuItem>
          <MenuItem sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Autocomplete
              options={audioDevices}
              getOptionLabel={(option) => option.label}
              value={selectedAudioDevice}
              onChange={(event, newValue) => setSelectedAudioDevice(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Audio Devices'
                  variant='outlined'
                />
              )}
              sx={{ width: '100%', marginBottom: 2, mt: 2 }}
            />
          </MenuItem>{' '}
        </Box>
      </Menu>
    </Box>
  );
}

export default QuestionnaireComponent;
