/** @format */

import React, { useCallback, useMemo } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import { Box, CircularProgress, Paper, useMediaQuery } from '@mui/material';
import RecordVideoButtonIcon from 'icons/RecordVideoButtonIcon';

import { useTranslation } from 'react-i18next';
import RecordedVideoPreview from './RecordedVideoPreview';
import './record.css';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import PauseRecordingIcon from 'icons/PauseRecordingIcon';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CustomVideoPlayer from './CustomVideoPlayer';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import {
  getSessionTypesSelector,
  getSubscriptionSelector,
  uploadSingleFileSelector,
} from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import { Toast } from 'widgets/Toast/Toast';
import CountDownModal from './CountDownModal';
import AudioPlayer from 'components/Record/CustomAudioPlayer';
import { useHistory } from 'react-router-dom';
import useSubscription from 'hooks/useSubscription';
import SubscriptionStatusMessageModal from 'widgets/SubscriptionStatusMessageModal/SubscriptionStatusMessageModal';
import { subscriptionsArrayStatus } from 'constants/Subscriptions';
import Timer from './Timer';
import RecordedSessionUploadCompleteModal from './RecordedSessionUploadCompleteModal';
import Button from 'widgets/CustomButton/Button';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import CommonRecorderSettings from 'shared/CommonRecorderSettings/CommonRecorderSettings';
import getMediaDevices from 'utils/getMediaDevices';
import Typography from 'widgets/Typography/Typography';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};
function WebRecord() {
  const { t } = useTranslation();
  const { userSubscription } = useSubscription();

  const dispatch = useAppDispatch();
  const history: {
    location: { state: { recordingType: { id: number; name: string } } };
  } = useHistory();

  const [cameras, setCameras] = useState<Array<CamerasType>>([]);
  const [microphones, setMicrophones] = useState<Array<MicrophonesType>>([]);
  const [selectedCamera, setSelectedCamera] = useState<CamerasType>(
    initialValuesForRecordings
  );
  const [selectedMicrophone, setSelectedMicrophone] = useState<MicrophonesType>(
    initialValuesForRecordings
  );
  const [videoDesc, setVideoDesc] = useState<string>(
    getDefaultVideoDescription()
  );

  const [selectedSessionType, setSelectedSessionType] = useState<{
    id: number;
    name: string;
  }>({
    id: 3,
    name: 'Casual Session',
  });
  const [openCountDownModal, setOpenCountDownModal] = useState(false);

  const [recordingType, setRecordingType] = useState<{
    id: number;
    name: string;
  }>({ id: 1, name: t('record.Video') });

  const [advancedSettingsOptions, setAdvancedSettingsOptions] = useState<{
    controlMenu: boolean;
    recordingCountdown: boolean;
    showRecordingTime: boolean;
  }>({
    controlMenu: true,
    recordingCountdown: true,
    showRecordingTime: true,
  });
  const [
    openRecordedSessionUploadCompleteModal,
    setOpenRecordedSessionUploadCompleteModal,
  ] = React.useState(false);

  const handleClickOpenRecordedSessionUploadCompleteModal = () => {
    setOpenRecordedSessionUploadCompleteModal(true);
  };

  const handleCloseRecordedSessionUploadCompleteModal = () => {
    setOpenRecordedSessionUploadCompleteModal(false);
  };

  const { SessionTypes, isLoadingSessionTypes } = useAppSelector(
    getSessionTypesSelector
  );

  const { isLoadingUploadSingleFile } = useAppSelector(
    uploadSingleFileSelector
  );

  const [countdown, setCountdown] = useState(5);
  const [recording, setRecording] = useState(false);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    pauseRecording,
    previewStream,
    resumeRecording,
    clearBlobUrl,
  }: any = useReactMediaRecorder({
    audio: selectedMicrophone,
    video: recordingType.id === 1 ? selectedCamera : false,
  });

  const { uploadSingleFile } = useAppSelector(uploadSingleFileSelector);

  useEffect(() => {
    if (uploadSingleFile && uploadSingleFile.Job_Id) {
      clearBlobUrl();
    }
  }, [uploadSingleFile]);

  const handleStartRecording = () => {
    setRecording(true);
    setOpenCountDownModal(true);
  };

  const matchingSubscription = subscriptionsArrayStatus.find(
    (subscriptionStatus) => subscriptionStatus.status === userSubscription
  );

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (recording && countdown === 0) {
      startRecording();
      setRecording(false);
      setCountdown(5);
      setOpenCountDownModal(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line
  }, [countdown, recording]);
  useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1));
  }, [dispatch]);

  useEffect(() => {
    if (history.location.state && history.location.state.recordingType)
      setRecordingType(history.location.state.recordingType);
  }, [history]);

  useEffect(() => {
    getMediaDevices(setCameras, setMicrophones);
  }, []);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        const newMinutes = Math.floor(totalSeconds / 60);
        const newSeconds = totalSeconds % 60;
        setMinutes(newMinutes);
        setSeconds(newSeconds);
        setTotalSeconds(totalSeconds + 1);
      }, 1000);
      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    }
    if (status === 'paused') {
      pauseTimer();
    }
  }, [status]);

  const startTimer = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = useCallback(() => {
    setIsRunning(false);
    setTotalSeconds(0);
    setMinutes(0);
    setSeconds(0);
  }, []);

  const MemoizedVideoPreview = useMemo(() => {
    return (
      <div
        style={{
          height: '450px',
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {status === 'stopped' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '98%',
            }}
          >
            {recordingType.id === 1 ? (
              <CustomVideoPlayer
                mediaBlobUrl={mediaBlobUrl}
                videoDesc={videoDesc}
                selectedSessionType={selectedSessionType}
                recordingType={recordingType}
                clearBlobUrl={clearBlobUrl}
                totalSeconds={totalSeconds}
                handleClickOpenRecordedSessionUploadCompleteModal={
                  handleClickOpenRecordedSessionUploadCompleteModal
                }
                resetTimer={resetTimer}
              />
            ) : (
              <AudioPlayer
                mediaBlobUrl={mediaBlobUrl}
                videoDesc={videoDesc}
                selectedSessionType={selectedSessionType}
                recordingType={recordingType}
                clearBlobUrl={clearBlobUrl}
                totalSeconds={totalSeconds}
                handleClickOpenRecordedSessionUploadCompleteModal={
                  handleClickOpenRecordedSessionUploadCompleteModal
                }
                resetTimer={resetTimer}
              />
            )}
          </Box>
        ) : recordingType.id === 1 ? (
          <RecordedVideoPreview stream={previewStream} />
        ) : (
          <div>
            <p>
              {status === 'recording'
                ? t('record.RecordingAudio')
                : t('record.ClickRecordButtonToRecordAudio')}{' '}
            </p>
          </div>
        )}
      </div>
    );
  }, [status, recordingType, mediaBlobUrl, videoDesc, selectedSessionType]);

  const { isLoadingGetSubscription } = useAppSelector(getSubscriptionSelector);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  return (
    <AuthWrapper>
      {isLoadingSessionTypes || isLoadingGetSubscription ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%', height: 'auto' }}>
          {isMobile || isTablet ? (
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              <Box
                sx={{
                  display: 'flex',

                  height: '100%',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: isMobile || isTablet ? 2 : 15,
                    flexDirection: isMobile || isTablet ? 'column' : 'row',
                    height: 'auto',
                    width: '100%',
                    gap: isMobile || isTablet ? 4 : 0,
                    alignItems: isMobile || isTablet ? 'center' : 'left',
                    left: 0,
                    top: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      padding: isMobile || isTablet ? 2 : 15,
                      flexDirection: isMobile || isTablet ? 'column' : 'row',
                      height: 'auto',
                      width: '100%',
                      gap: isMobile || isTablet ? 4 : 0,
                      alignItems: isMobile || isTablet ? 'center' : 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        color: ' var(--body-text, #33344B)',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '-0.176p',
                      }}
                      label='record.RecordNewSession'
                    />
                    {t('record.RecordingStatus')} :
                    {(status === 'idle' && t('record.Idle')) ||
                      (status === 'acquiring_media' &&
                        t('record.AcquiringMedia')) ||
                      (status === 'recording' && t('record.Recording')) ||
                      (status === 'stopped' && t('record.Stopped')) ||
                      (status === 'paused' && t('record.Paused')) ||
                      status}
                    <Paper>
                      {MemoizedVideoPreview}

                      {advancedSettingsOptions.controlMenu && (
                        <Box
                          sx={{
                            marginBottom: 2,
                            marginLeft: 2,
                            marginRight: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            {status !== 'stopped' &&
                              advancedSettingsOptions.showRecordingTime && (
                                <Typography label=''>
                                  {' '}
                                  <Timer minutes={minutes} seconds={seconds} />
                                </Typography>
                              )}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              gap: '10px',
                            }}
                          >
                            {status === 'acquiring_media' && (
                              <CircularProgress />
                            )}
                            {status === 'idle' && (
                              <Button
                                disabled={
                                  status === 'acquiring_media' ||
                                  videoDesc === '' ||
                                  selectedSessionType.id === -1 ||
                                  recordingType.id === -1 ||
                                  selectedCamera.deviceId === '' ||
                                  selectedMicrophone.deviceId === ''
                                }
                                onClick={() => {
                                  recordingType.id === -1
                                    ? Toast(
                                        t(
                                          'toastMessageNotifications.PleaseSelectRecordingType'
                                        ),
                                        'error'
                                      )
                                    : advancedSettingsOptions.recordingCountdown
                                    ? handleStartRecording()
                                    : startRecording();
                                }}
                                id='start-recording-left-button'
                                variant='contained'
                                startIcon={<RecordVideoButtonIcon />}
                                sx={{
                                  padding: 0.5,
                                  paddingLeft: 2,
                                  paddingRight: 2,
                                  borderRadius: 20,
                                  backgroundColor: '#1206f9',
                                  '&:hover': {
                                    backgroundColor: '#1206f9',
                                  },
                                }}
                                label='record.Record'
                              />
                            )}

                            {status === 'paused' && (
                              <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Button
                                  disabled={status === 'acquiring_media'}
                                  onClick={() => {
                                    stopRecording();
                                  }}
                                  id='stop-recording-left-button'
                                  variant='contained'
                                  startIcon={<StopRecordingIcon />}
                                  sx={{
                                    padding: 0.5,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    borderRadius: 20,
                                    backgroundColor: '#1206f9',
                                    '&:hover': {
                                      backgroundColor: '#1206f9',
                                    },
                                  }}
                                  label='record.Stop'
                                />

                                <Button
                                  sx={{
                                    padding: 0.5,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    borderRadius: 20,
                                    color: '#1206f9',
                                    border: '1px solid #1206f9',
                                  }}
                                  variant='outlined'
                                  onClick={resumeRecording}
                                  startIcon={
                                    <PlayArrowOutlinedIcon
                                      sx={{ color: '#1206f9' }}
                                    />
                                  }
                                  label='record.Resume'
                                />
                              </Box>
                            )}

                            {status === 'recording' && (
                              <Button
                                disabled={status === 'acquiring_media'}
                                onClick={() => {
                                  stopRecording();
                                }}
                                id='stop-recording-left-button'
                                variant='contained'
                                startIcon={<StopRecordingIcon />}
                                sx={{
                                  padding: 0.5,
                                  paddingLeft: 2,
                                  paddingRight: 2,
                                  borderRadius: 20,
                                  backgroundColor: '#1206f9',
                                  '&:hover': {
                                    backgroundColor: '#1206f9',
                                  },
                                }}
                                label='record.Stop'
                              />
                            )}

                            {status === 'recording' && (
                              <Button
                                sx={{
                                  padding: 0.5,
                                  paddingLeft: 2,
                                  paddingRight: 2,
                                  borderRadius: 20,
                                  color: '#1206f9',
                                  border: '1px solid #1206f9',
                                  '&:hover': {
                                    backgroundColor: '#1206f9',
                                  },
                                }}
                                variant='outlined'
                                onClick={pauseRecording}
                                startIcon={<PauseRecordingIcon />}
                                label='record.Pause'
                              />
                            )}
                          </div>
                          <div></div>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: isMobile || isTablet ? 2 : 15,
                      flexDirection: isMobile || isTablet ? 'column' : 'row',
                      height: 'auto',
                      width: '100%',
                      gap: isMobile || isTablet ? 4 : 0,
                      alignItems: isMobile || isTablet ? 'center' : 'left',
                    }}
                  >
                    <CommonRecorderSettings
                      setVideoTitleDescription={setVideoDesc}
                      videoTitleDescription={videoDesc}
                      setSelectedSessionType={setSelectedSessionType}
                      selectedSessionType={selectedSessionType}
                      setRecordingType={setRecordingType}
                      recordingType={recordingType}
                      setSelectedCamera={setSelectedCamera}
                      selectedCamera={selectedCamera}
                      setSelectedMicrophone={setSelectedMicrophone}
                      selectedMicrophone={selectedMicrophone}
                      setAdvancedSettingsOptions={setAdvancedSettingsOptions}
                      advancedSettingsOptions={advancedSettingsOptions}
                      handleStartRecordingWithCountDown={handleStartRecording}
                      handleStartRecordingWithoutCountDown={startRecording}
                      mediaRecorderStatus={status}
                      stopRecording={stopRecording}
                      isFromCandidateAssessment={false}
                      candidateDetails={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        assessmentId: -1,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: '100%', height: 'auto' }}>
              <div
                style={{ display: 'flex', minHeight: '100vh', height: '100%' }}
              >
                <div
                  style={{
                    width: '60%',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      color: ' var(--body-text, #33344B)',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '24px',
                      letterSpacing: '-0.176p',
                    }}
                    label='record.RecordNewSession'
                  />
                  {t('record.RecordingStatus')} :{' '}
                  {(status === 'idle' && t('record.Idle')) ||
                    (status === 'acquiring_media' &&
                      t('record.AcquiringMedia')) ||
                    (status === 'recording' && t('record.Recording')) ||
                    (status === 'stopped' && t('record.Stopped')) ||
                    (status === 'paused' && t('record.Paused')) ||
                    status}
                  <Paper>
                    {MemoizedVideoPreview}

                    {advancedSettingsOptions.controlMenu && (
                      <Box
                        sx={{
                          marginBottom: 2,
                          marginLeft: 2,
                          marginRight: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          {status !== 'stopped' &&
                            advancedSettingsOptions.showRecordingTime && (
                              <Typography label=''>
                                {' '}
                                <Timer minutes={minutes} seconds={seconds} />
                              </Typography>
                            )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                          }}
                        >
                          {status === 'acquiring_media' && <CircularProgress />}
                          {status === 'idle' && (
                            <Button
                              disabled={
                                status === 'acquiring_media' ||
                                videoDesc === '' ||
                                selectedSessionType.id === -1 ||
                                recordingType.id === -1 ||
                                selectedCamera.deviceId === '' ||
                                selectedMicrophone.deviceId === ''
                              }
                              onClick={() => {
                                recordingType.id === -1
                                  ? Toast(
                                      t(
                                        'toastMessageNotifications.PleaseSelectRecordingType'
                                      ),
                                      'error'
                                    )
                                  : advancedSettingsOptions.recordingCountdown
                                  ? handleStartRecording()
                                  : startRecording();
                              }}
                              id='start-recording-left-button'
                              variant='contained'
                              startIcon={<RecordVideoButtonIcon />}
                              sx={{
                                padding: 0.5,
                                paddingLeft: 2,
                                paddingRight: 2,
                                borderRadius: 20,
                                backgroundColor: '#1206f9',
                                '&:hover': {
                                  backgroundColor: '#1206f9',
                                },
                              }}
                              label='record.Record'
                            />
                          )}

                          {status === 'paused' && (
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                              <Button
                                disabled={status === 'acquiring_media'}
                                onClick={() => {
                                  stopRecording();
                                }}
                                id='stop-recording-left-button'
                                variant='contained'
                                startIcon={<StopRecordingIcon />}
                                sx={{
                                  padding: 0.5,
                                  paddingLeft: 2,
                                  paddingRight: 2,
                                  borderRadius: 20,
                                  backgroundColor: '#1206f9',
                                }}
                                label='record.Stop'
                              />

                              <Button
                                sx={{
                                  padding: 0.5,
                                  paddingLeft: 2,
                                  paddingRight: 2,
                                  borderRadius: 20,
                                  color: '#1206f9',
                                  border: '1px solid #1206f9',
                                }}
                                variant='outlined'
                                onClick={resumeRecording}
                                startIcon={
                                  <PlayArrowOutlinedIcon
                                    sx={{ color: '#1206f9' }}
                                  />
                                }
                                label='record.Resume'
                              />
                            </Box>
                          )}

                          {status === 'recording' && (
                            <Button
                              disabled={status === 'acquiring_media'}
                              onClick={() => {
                                stopRecording();
                              }}
                              id='stop-recording-left-button'
                              variant='contained'
                              startIcon={<StopRecordingIcon />}
                              sx={{
                                padding: 0.5,
                                paddingLeft: 2,
                                paddingRight: 2,
                                borderRadius: 20,
                                backgroundColor: '#1206f9',
                                '&:hover': {
                                  backgroundColor: '#1206f9',
                                },
                              }}
                              label='record.Stop'
                            />
                          )}

                          {status === 'recording' && (
                            <Button
                              sx={{
                                padding: 0.5,
                                paddingLeft: 2,
                                paddingRight: 2,
                                borderRadius: 20,
                                color: '#1206f9',
                                border: '1px solid #1206f9',
                              }}
                              variant='outlined'
                              onClick={pauseRecording}
                              startIcon={<PauseRecordingIcon />}
                              label='record.Pause'
                            />
                          )}
                        </div>
                        <div></div>
                      </Box>
                    )}
                  </Paper>
                </div>
                <div style={{ width: '40%', height: '100%' }}>
                  <CommonRecorderSettings
                    setVideoTitleDescription={setVideoDesc}
                    videoTitleDescription={videoDesc}
                    setSelectedSessionType={setSelectedSessionType}
                    selectedSessionType={selectedSessionType}
                    setRecordingType={setRecordingType}
                    recordingType={recordingType}
                    setSelectedCamera={setSelectedCamera}
                    selectedCamera={selectedCamera}
                    setSelectedMicrophone={setSelectedMicrophone}
                    selectedMicrophone={selectedMicrophone}
                    setAdvancedSettingsOptions={setAdvancedSettingsOptions}
                    advancedSettingsOptions={advancedSettingsOptions}
                    handleStartRecordingWithCountDown={handleStartRecording}
                    handleStartRecordingWithoutCountDown={startRecording}
                    mediaRecorderStatus={status}
                    stopRecording={stopRecording}
                    isFromCandidateAssessment={false}
                    candidateDetails={{
                      firstName: '',
                      lastName: '',
                      email: '',
                      assessmentId: -1,
                    }}
                  />
                </div>
              </div>
            </Box>
          )}
        </Box>
      )}
      {openCountDownModal && (
        <CountDownModal
          openCountDownModal={openCountDownModal}
          countdown={countdown}
        />
      )}
      {matchingSubscription && (
        <SubscriptionStatusMessageModal
          message={t(matchingSubscription.message)}
        />
      )}
      <RecordedSessionUploadCompleteModal
        openRecordedSessionUploadCompleteModal={
          openRecordedSessionUploadCompleteModal
        }
        handleCloseRecordedSessionUploadCompleteModal={
          handleCloseRecordedSessionUploadCompleteModal
        }
      />{' '}
    </AuthWrapper>
  );
}

export default WebRecord;
