/** @format */

// import ReCaptchaComponent from "./ForgotPassword/ForgotPassword";
import Loader from 'widgets/Loader/Loader';
import './signup.css';
import React, { useEffect } from 'react';
import Footer from 'shared/Footer/Footer';
import useAuth from 'hooks/useAuth';
import { Box, useMediaQuery } from '@mui/material';
import MirroBranding from 'components/SignIn/MirroBranding';
import SignUpForm from './SignUpForm';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getAuthProviderDetailsSelector } from 'redux/selectors/selectors';
import { getAuthProviderDetailsServiceApi } from 'services/Auth-service';

export default function SignUpComponent() {
  const dispatch = useAppDispatch();
  const { isLoadingGetAuthProviderDetails, authProviderDetailsList } =
    useAppSelector(getAuthProviderDetailsSelector);
  useEffect(() => {
    dispatch(getAuthProviderDetailsServiceApi());
  }, [dispatch]);
  const { handleSignUpFormSubmit, signUpStatus } = useAuth();

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  if (isLoadingGetAuthProviderDetails) {
    return <Loader />;
  }
  return (
    <Box
      sx={{
        width: '99vw',
        height: '100%',
        minHeight: '90vh',
        background: '#FBFBFF',
        mb: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: isMobile || isTablet ? 2 : 15,
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          height: '30%',
          gap: isMobile || isTablet ? 4 : 0,
          alignItems: isMobile || isTablet ? 'center' : 'left',
        }}
      >
        <MirroBranding />
        <SignUpForm
          handleSignUpFormSubmit={handleSignUpFormSubmit}
          signUpStatus={signUpStatus}
          authProviderDetailsList={authProviderDetailsList}
        />
      </Box>
      {!isMobile && !isTablet && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '30rem',
          }}
        >
          <Box sx={{ width: '80%' }}>
            <img
              src='./LoginImg/mirro-ribbon-1.png'
              alt='mirro-ribbon'
              width={'100%'}
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </Box>

          <Box>
            <img
              src='./LoginImg/mirro-ribbon-2.png'
              alt='mirro-ribbon'
              width={'100%'}
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
