import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RecordedVideoPreview from 'components/Record/RecordedVideoPreview';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import getMediaDevices from 'utils/getMediaDevices';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import QuestionVideoPlayer from './QuestionVideoPlayer';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};
function PatientVideoRecorder({
  onRecordingComplete,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  Questionnaire,
  selectedVideoDevice,
  selectedAudioDevice,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
}: {
  onRecordingComplete: (blobUrl: string | null, duration: number) => void;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  Questionnaire: Array<QuestionnaireData>;
  selectedVideoDevice: MicrophoneDetails;
  selectedAudioDevice: CameraDetails;
  status: string;
  startRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: string;
}) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');

  const [recording, setRecording] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording) {
      startRecording();
      setRecording(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line
  }, [recording]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        const newMinutes = Math.floor(totalSeconds / 60);
        const newSeconds = totalSeconds % 60;
        setMinutes(newMinutes);
        setSeconds(newSeconds);
        setTotalSeconds(totalSeconds + 1);
      }, 1000);
      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    }
    if (status === 'paused') {
      pauseTimer();
    }
    if (status === 'stopped') {
      pauseTimer();
    }
  }, [status]);

  const startTimer = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = useCallback(() => {
    setIsRunning(false);
    setTotalSeconds(0);
    setMinutes(0);
    setSeconds(0);
  }, []);

  const handleStopRecording = () => {
    if (status === 'recording') {
      stopRecording();
    }

    onRecordingComplete(mediaBlobUrl, totalSeconds);
    resetTimer();
  };
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (selectedVideoDevice) {
      // Start video stream with the selected camera
      navigator.mediaDevices

        .getUserMedia({
          video: { deviceId: selectedVideoDevice.deviceId },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        });
    }
  }, [selectedVideoDevice]);
  const MemoizedVideoPreview = useMemo(() => {
    return (
      <video
        ref={videoRef}
        autoPlay
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          aspectRatio: 16 / 9,
          objectFit: 'cover',
        }}
      />
    );
  }, [status, mediaBlobUrl]);

  return (
    <Paper sx={{ height: '100%' }}>
      <Box sx={{ Height: '70%' }}>{MemoizedVideoPreview}</Box>{' '}
      <Box
        sx={{
          width: '95%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          p: 1,
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          {status === 'recording' ? (
            <Button
              sx={{
                backgroundColor: '#1206F9',
                borderRadius: '30px',
                border: '1px solid #1206F9',
              }}
              variant='contained'
              startIcon={<StopRecordingIcon />}
              onClick={stopRecording}
            >
              Stop
            </Button>
          ) : (
            <Box>
              {status === 'acquiring_media' ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={startRecording}
                  sx={{
                    backgroundColor: '#1206F9',
                    borderRadius: '30px',
                  }}
                  size='small'
                  variant='contained'
                  startIcon={<StartAssessmentRecordIcon />}
                >
                  Record
                </Button>
              )}
            </Box>
          )}
          <Button
            sx={{ color: '#1206F9' }}
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleStopRecording}
            disabled={status === 'recording' || !mediaBlobUrl}
          >
            {currentQuestionIndex === Questionnaire.length - 1
              ? 'Finish'
              : 'Next'}
          </Button>{' '}
        </Box>
        <Typography
          label=''
          sx={{
            position: isMobileOrTablet ? '' : 'absolute',
            right: isMobileOrTablet ? '' : '20px',
            top: isMobileOrTablet ? '' : '50%',
            transform: isMobileOrTablet ? '' : 'translateY(-50%)',
            fontSize: 12,
            mt: isMobileOrTablet ? 1 : 0,
            animation:
              status === 'recording' ? 'blinker 2s linear infinite' : '',
            '@keyframes blinker': {
              '50%': { opacity: 0 },
            },
          }}
        >
          {status}
        </Typography>
      </Box>
    </Paper>
  );
}

export default PatientVideoRecorder;
