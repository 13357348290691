/** @format */

import React from 'react';

function DashboardIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='20'
      viewBox='0 0 27 20'
      fill='none'
    >
      <path
        d='M23.7876 1H2.55612C1.53277 1 0.703186 1.82959 0.703186 2.85293V16.4214C0.703186 17.4447 1.52978 18.2743 2.55312 18.2743H23.7961C24.8194 18.2743 25.6406 17.4471 25.6406 16.4238V2.85105C25.6406 1.8277 24.811 1 23.7876 1Z'
        stroke='#4027FE'
        stroke-width='2'
      />

      <path
        d='M18.9006 6.45483H22.6075M18.9006 11.9826H21.9335'
        stroke='#23C290'
        stroke-width='1.5'
      />

      <path
        d='M16.5958 18.4425C16.6816 18.6612 16.7884 19.0222 16.8622 19.3486L13.1597 19.356L9.42649 19.3634C9.50797 19.0489 9.62891 18.6912 9.72009 18.4561C9.90941 17.968 10.1865 17.5267 10.5338 17.1565C10.881 16.7863 11.2914 16.4946 11.7405 16.296C12.1895 16.0975 12.6694 15.9954 13.1533 15.9944C13.6372 15.9935 14.1175 16.0937 14.5673 16.2904C15.0171 16.4872 15.4286 16.7773 15.7772 17.1461C16.1259 17.515 16.4047 17.9552 16.5958 18.4425ZM9.3427 19.8102C9.34311 19.8151 9.3431 19.8178 9.343 19.818C9.34289 19.8181 9.34269 19.8156 9.3427 19.8102ZM16.9427 19.8442C16.9426 19.8441 16.9426 19.8424 16.9428 19.8393L16.9427 19.8442Z'
        fill='white'
        stroke='#23C290'
        stroke-width='2'
      />

      <mask id='path-4-inside-1_65_8' fill='white'>
        <path d='M11.2877 9.73928C11.2877 10.6074 11.0366 11.456 10.5662 12.1778C10.0957 12.8996 9.42707 13.4622 8.64476 13.7944C7.86245 14.1266 7.00161 14.2136 6.17112 14.0442C5.34062 13.8748 4.57776 13.4568 3.97901 12.8429C3.38025 12.2291 2.9725 11.447 2.8073 10.5956C2.64211 9.74415 2.72689 8.86162 3.05093 8.05959C3.37498 7.25756 3.92373 6.57205 4.62779 6.08976C5.33185 5.60746 6.1596 5.35004 7.00636 5.35004L7.00636 9.73928H11.2877Z' />
      </mask>

      <path
        d='M11.2877 9.73928C11.2877 10.6074 11.0366 11.456 10.5662 12.1778C10.0957 12.8996 9.42707 13.4622 8.64476 13.7944C7.86245 14.1266 7.00161 14.2136 6.17112 14.0442C5.34062 13.8748 4.57776 13.4568 3.97901 12.8429C3.38025 12.2291 2.9725 11.447 2.8073 10.5956C2.64211 9.74415 2.72689 8.86162 3.05093 8.05959C3.37498 7.25756 3.92373 6.57205 4.62779 6.08976C5.33185 5.60746 6.1596 5.35004 7.00636 5.35004L7.00636 9.73928H11.2877Z'
        fill='white'
        stroke='#23C290'
        stroke-width='1.6'
        mask='url(#path-4-inside-1_65_8)'
      />

      <mask id='path-5-inside-2_65_8' fill='white'>
        <path d='M7.20546 5.14581C7.76769 5.14581 8.32442 5.25934 8.84385 5.47992C9.36329 5.7005 9.83526 6.02381 10.2328 6.43139C10.6304 6.83897 10.9457 7.32284 11.1609 7.85537C11.376 8.3879 11.4868 8.95866 11.4868 9.53506L7.20546 9.53506L7.20546 5.14581Z' />
      </mask>

      <path
        d='M7.20546 5.14581C7.76769 5.14581 8.32442 5.25934 8.84385 5.47992C9.36329 5.7005 9.83526 6.02381 10.2328 6.43139C10.6304 6.83897 10.9457 7.32284 11.1609 7.85537C11.376 8.3879 11.4868 8.95866 11.4868 9.53506L7.20546 9.53506L7.20546 5.14581Z'
        fill='white'
        stroke='#4027FE'
        stroke-width='1.6'
        mask='url(#path-5-inside-2_65_8)'
      />

      <path
        d='M14.1826 6.45483H19.9115M14.1826 11.9826H19.9115'
        stroke='#4027FE'
        stroke-width='1.5'
      />
    </svg>
  );
}

export default DashboardIcon;
