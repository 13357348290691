import {
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import Typography from 'widgets/Typography/Typography';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
function ReRecordPromptModal({
  setOpenReuploadConfirmationModal,
  openReuploadConfirmationModal,
  startRecording,
}: {
  setOpenReuploadConfirmationModal: Function;
  openReuploadConfirmationModal: boolean;
  startRecording: () => void;
}) {
  return (
    <BootstrapDialog
      onClose={() => setOpenReuploadConfirmationModal(false)}
      aria-labelledby='customized-dialog-title'
      open={openReuploadConfirmationModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
        {t('MyAssessments.ReplaceExistingPromptRecording')}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpenReuploadConfirmationModal(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography
          gutterBottom
          label={t(
            'MyAssessments.ThisActionWillReplaceThePreviousRecordedPromptDoYouWishToProceed'
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          autoFocus
          onClick={() => {
            startRecording();
            setOpenReuploadConfirmationModal(false);
          }}
          sx={{
            textTransform: 'none',

            backgroundColor: '#1206F9',
          }}
        >
          {t('MyAssessments.YesRerecordMyAnswer')}
        </Button>
        <Button
          autoFocus
          variant='outlined'
          onClick={() => setOpenReuploadConfirmationModal(false)}
          sx={{ textTransform: 'none' }}
        >
          {t('MyAssessments.NoKeepMyPreviousRecording')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default ReRecordPromptModal;
