/** @format */

import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';

import { Avatar, Box, Tooltip } from '@mui/material';
import ThumbsUpIcon from 'icons/ThumbsUpIcon';

import { useAppSelector } from 'redux/store';
import Loader from 'widgets/Loader/Loader';
import {
  AttendeeRecord,
  CoachingMoment,
  CoachingSessionReport,
  FlagOption,
  SelectedUserRoleTypes,
  TimeSegment,
  VideoData,
  knowledge_tips,
} from 'types/VideoPreview-types';
import { getEmotionTypeSelector } from 'redux/selectors/selectors';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TipsIcon from 'icons/TipsIcon';
import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { UserRoles } from 'types/Dashboard-types';
import Typography from 'widgets/Typography/Typography';

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement='top' title={value}>
      <Typography
        sx={{
          color: '#1206F9',
          fontFamily: 'Poppins',
          fontSize: '9.882px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.109px',
        }}
        label=''
      >
        {' '}
        {children}
      </Typography>
    </Tooltip>
  );
}

const CustomSlider = styled(Slider)({
  color: `linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(242,214,13,1) 50%, rgba(0,255,0,1) 100%);`,
  height: 10,

  '& .MuiSlider-rail': {
    opacity: 1,
    background: `linear-gradient(to right,
                rgba(255, 0, 0, 1) 0%,
                rgba(242, 164, 72, 1) 20%,
                rgba(243, 247, 45, 1) 50%,
                rgba(0, 255, 56, 1) 70%,
                rgba(0, 255, 56, 1) 100%
              )`,
  },
  '&:hover': {
    cursor: 'default',
  },
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    color: 'black',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',

    '&:before': {
      boxShadow: 'none',
    },

    '&:hover': {
      boxShadow: 'none',
    },

    '& .MuiSlider-track': {
      background: 'yellow',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    top: 0,
    backgroundColor: 'unset',
    color: '#1306f9',
    '&:before': {
      display: 'none',
    },
  },
});

function OverallSummaryDetailsVersion2({
  ProcessDataListForParticipants,
  selectedUserRole,
  selectedFlags,
  emoticonSwitch,
  tipsSwitch,
}: {
  ProcessDataListForParticipants: Array<CoachingSessionReport>;
  selectedFlags: Array<FlagOption>;
  selectedUserRole: Array<SelectedUserRoleTypes>;
  emoticonSwitch: number;
  tipsSwitch: number;
}) {
  const [loading, setLoading] = useState(true);
  const [filteredRoleData, setFilteredRoleData] = useState<
    Array<AttendeeRecord>
  >([]);
  const [filteredSegments, setFilteredSegments] = useState<Array<TimeSegment>>(
    []
  );
  const [kudosCount, SetKuodosCount] = useState(0);
  const [tipsCount, SetTipsCount] = useState(0);
  const videoData = ProcessDataListForParticipants[0];

  const attendeesRecord =
    ProcessDataListForParticipants[0]?.attendees_record || [];

  useEffect(() => {
    if (ProcessDataListForParticipants) {
      setLoading(false);
    }
  }, [ProcessDataListForParticipants]);

  useEffect(() => {
    if (videoData) {
      let tempKnowledgeTipsArray: knowledge_tips[] = [];

      for (const coachingMoment of videoData.coaching_moments) {
        const knowledgeTips = coachingMoment.knowledge_tips;

        if (knowledgeTips.length > 0) {
          tempKnowledgeTipsArray = [
            ...tempKnowledgeTipsArray,
            ...knowledgeTips,
          ];

          break;
        }
      }
    }
  }, [videoData]);

  const segments = videoData?.time_segments;

  const marks = [
    {
      value: 0,
      label: '0',
      color: 'black',
    },
    {
      value: 100,
      label: '100',
      color: 'black',
    },
  ];
  useEffect(() => {
    const filteredSegments = selectedUserRole.map(
      (role: SelectedUserRoleTypes) => {
        return attendeesRecord[role.index];
      }
    );

    setFilteredRoleData(filteredSegments);
  }, [selectedUserRole, ProcessDataListForParticipants, attendeesRecord]);

  useEffect(() => {
    const updateSpeakerTimeSegments = () => {
      setFilteredSegments(() => {
        const filteredByFlags = segments.filter((segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
        );

        if (selectedUserRole.length > 0) {
          const newFilteredSegments = selectedUserRole
            .flatMap((role: SelectedUserRoleTypes) => {
              const selectedAttendee = attendeesRecord[role.index];
              if (selectedAttendee) {
                return filteredByFlags
                  .filter((segment: TimeSegment) =>
                    selectedAttendee.time_segment_list.includes(segment.sno)
                  )
                  .map((segment: TimeSegment) => ({
                    ...segment,
                    updatedValue: 'yourUpdatedValue',
                  }));
              }
              return [];
            })
            .filter((segment) => segment !== null);

          const sortedNewFilteredSegments = newFilteredSegments.sort(
            (a, b) => (a.start_time_stamp || 0) - (b.start_time_stamp || 0)
          );

          return sortedNewFilteredSegments;
        }

        const sortedFilteredByFlags = filteredByFlags.sort(
          (a, b) => (a.start_time_stamp || 0) - (b.start_time_stamp || 0)
        );

        return sortedFilteredByFlags;
      });
    };
    updateSpeakerTimeSegments();
  }, [attendeesRecord, selectedUserRole, selectedFlags, segments]);

  useEffect(() => {
    let tipCount = 0;
    let kudosCountTemp = 0;

    if (videoData.coaching_moments) {
      filteredSegments.map((item: TimeSegment) => {
        if (item.flag_id === 3) kudosCountTemp++;
        return null;
      });

      const startStampsInFilteredSegments = filteredSegments.map(
        (segment: TimeSegment) => segment.start_time_stamp
      );

      const knowledgeTipsWithMedia = videoData.coaching_moments.map(
        (item: CoachingMoment) => {
          if (
            startStampsInFilteredSegments.includes(item.delivery_time_stamp)
          ) {
            tipCount++;

            const filteredTips = item.knowledge_tips.filter(
              (tip: knowledge_tips) => tip.media_link !== null
            );
            tipCount += filteredTips.length;

            return filteredTips;
          } else {
            return [];
          }
        }
      );
    }
    SetTipsCount(tipCount);
    SetKuodosCount(kudosCountTemp);
  }, [filteredSegments, videoData]);

  if (
    ProcessDataListForParticipants.length === 0 ||
    !ProcessDataListForParticipants
  ) {
    return <Loader />;
  }

  return (
    <Box sx={{ wdith: '100%', height: 'auto' }}>
      {filteredRoleData.length === 0 || filteredRoleData.length >= 2 ? (
        <Box sx={{ wdith: '100%', height: 'auto', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '100%',
              alignItems: 'left',
              textAlign: 'left',
              ml: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{
                color: '#33344B',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.32px',
              }}
              label='Performance'
            />

            <Typography
              sx={{
                color: '#6C6C6C',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.154px',
              }}
              label='Overall Score'
            />
          </Box>
          <Box>
            <Paper
              sx={{
                width: '95%',
                height: 'fit-content',
                border: '1px solid #D4D4D4',
                background: '#FFF',
                margin: '5px',
                p: 1,
                display: 'flex', // Add this to make the container a flex container
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '20px',
              }}
              elevation={1}
            >
              {emoticonSwitch === 1 && (
                <Box
                  sx={{
                    width: '100%',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    display: 'flex',
                  }}
                >
                  <Box sx={{ width: '50%' }}>
                    <img
                      src={`./comonImg/Emoticons-mirro-Default-en/${ProcessDataListForParticipants[0]?.overall_emotion_name}.png`}
                      alt='OverallEmotions'
                      className='overallEmotionImg'
                    />
                  </Box>

                  <Box
                    sx={{
                      width: '50%',
                      alignItems: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'var(--Body-Text-Light, #605E5E)',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '-0.132px',
                      }}
                      label='Overall Emotion'
                    >
                      <Typography
                        sx={{
                          color: 'var(--Body-Text, #33344B)',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.176px',
                        }}
                        label={
                          ProcessDataListForParticipants[0]
                            ?.overall_emotion_name
                        }
                      />
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: 'block',
                  width: '100%',
                  height: 'fit-content',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  label='Engagement'
                />

                <Box sx={{ pt: 2 }}>
                  <CustomSlider
                    aria-label='engagement_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    value={ProcessDataListForParticipants[0]?.engagement_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='positivityBox'>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  label='Positivity'
                />

                <Box sx={{ pt: 2 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    value={ProcessDataListForParticipants[0]?.positivity_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='energyBox'>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  label='Energy'
                />
                <Box sx={{ pt: 2 }}>
                  <CustomSlider
                    aria-label='overall_energy-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    value={ProcessDataListForParticipants[0]?.overall_energy}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>

              <Box className='variationBox'>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  label='Variation'
                />
                <Box sx={{ pt: 2 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    value={ProcessDataListForParticipants[0]?.variation_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ wdith: '100%', height: 'auto', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                alignItems: 'left',
                textAlign: 'left',
                ml: 1,
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  color: '#33344B',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '-0.32px',
                }}
                label='Performance'
              />

              <Typography
                sx={{
                  color: '#6C6C6C',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '-0.154px',
                }}
                label='Overall Score'
              />
            </Box>
            <Box>
              <Paper
                sx={{
                  width: '95%',
                  height: 'fit-content',
                  border: '1px solid #D4D4D4',
                  background: '#FFF',
                  margin: '5px',
                  p: 1,
                  display: 'flex', // Add this to make the container a flex container
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '20px',
                }}
                elevation={1}
              >
                {emoticonSwitch === 1 && (
                  <Box
                    sx={{
                      width: '100%',
                      height: 'auto',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      display: 'flex',
                    }}
                  >
                    <Box sx={{ width: '50%' }}>
                      <img
                        src={`./comonImg/Emoticons-mirro-Default-en/${filteredRoleData[0]?.overall_emotion_name}.png`}
                        alt='OverallEmotions'
                        className='overallEmotionImg'
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '50%',
                        alignItems: 'left',
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'var(--Body-Text-Light, #605E5E)',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          letterSpacing: '-0.132px',
                        }}
                        label='Overall Emotion'
                      >
                        <Typography
                          sx={{
                            color: 'var(--Body-Text, #33344B)',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            letterSpacing: '-0.176px',
                          }}
                          label={filteredRoleData[0]?.overall_emotion_name}
                        />
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box className='engagementBox'>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Engagement'
                  />

                  <Box sx={{ pt: 2 }}>
                    <CustomSlider
                      aria-label='engagement_score-indicator'
                      size='small'
                      min={0}
                      step={1}
                      max={100}
                      track={false}
                      value={filteredRoleData[0]?.total_score}
                      valueLabelDisplay='on'
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='0'
                      />

                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='100'
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='positivityBox'>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Positivity'
                  />
                  <Box sx={{ pt: 2 }}>
                    <CustomSlider
                      aria-label='engagement_score-indicator'
                      size='small'
                      min={0}
                      step={1}
                      max={100}
                      track={false}
                      value={filteredRoleData[0]?.avg_positivity_score}
                      valueLabelDisplay='on'
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='0'
                      />

                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='100'
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='energyBox'>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Energy'
                  />

                  <Box sx={{ pt: 2 }}>
                    <CustomSlider
                      aria-label='engagement_score-indicator'
                      size='small'
                      min={0}
                      step={1}
                      max={100}
                      track={false}
                      value={filteredRoleData[0]?.avg_energy}
                      valueLabelDisplay='on'
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='0'
                      />

                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='100'
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className='variationBox'>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Variation'
                  />

                  <Box sx={{ pt: 2 }}>
                    <CustomSlider
                      aria-label='engagement_score-indicator'
                      size='small'
                      min={0}
                      step={1}
                      max={100}
                      track={false}
                      value={filteredRoleData[0]?.variation_score}
                      valueLabelDisplay='on'
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='0'
                      />

                      <Typography
                        sx={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          letterSpacing: '-0.069px',
                        }}
                        label='100'
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
      {tipsSwitch === 1 && (
        <Box>
          <Paper
            sx={{
              width: '95%',
              height: 'fit-content',
              border: '1px solid #D4D4D4',
              margin: '5px',
              background: '#FFF',
              p: 1,
              gap: '10px',
            }}
            elevation={1}
          >
            <Box className='OtherDetails'>
              <Typography label='videoPreviewAdvance.Kudos'>
                &nbsp;
                <ThumbsUpIcon />
              </Typography>
              <Avatar
                sx={{
                  marginLeft: 'auto',
                  marginRight: '10px',
                  backgroundColor: '#EEF1FF',
                  color: '#1306f9',
                  width: 'auto',
                  height: 'auto',
                  minWidth: '30px',
                  minHeight: '30px',
                  border: '2px solid #CED7FF',
                }}
              >
                <Typography
                  sx={{ color: '#1306f9', fontSize: '12px' }}
                  label=''
                >
                  {kudosCount}
                </Typography>
              </Avatar>
            </Box>
            {/* <Box className='OtherDetails'>
              <Typography label='Tips'>
                &nbsp;
                <TipsIcon />
              </Typography>
              <Avatar
                sx={{
                  marginLeft: 'auto',
                  marginRight: '10px',
                  backgroundColor: '#EEF1FF',
                  color: '#1306f9',
                  width: 'auto',
                  height: 'auto',
                  minWidth: '30px',
                  minHeight: '30px',
                  border: '2px solid #CED7FF',
                }}
              >
                <Typography
                  sx={{ color: '#1306f9', fontSize: '12px' }}
                  label=''
                >
                  {kudosCount}
                </Typography>
              </Avatar>
            </Box> */}
          </Paper>
        </Box>
      )}
    </Box>
  );
}
export default OverallSummaryDetailsVersion2;
