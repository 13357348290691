import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPractiseSectionsServiceApi } from 'services/MyAssessment-service';
import { getPractiseSectionsSelector } from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import PromptsAccordation from './PromptsAccordation';
import Typography from 'widgets/Typography/Typography';
import { useTranslation } from 'react-i18next';

export default function PromptsModal({
  open,
  handleClosePromptsModal,
  selectedAssessmentType,
}: {
  open: boolean;
  handleClosePromptsModal: () => void;
  selectedAssessmentType: number;
}) {
  const { t } = useTranslation();
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();
  const { isLoadingPractiseSections, practiseSections } = useAppSelector(
    getPractiseSectionsSelector
  );
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      dispatch(getPractiseSectionsServiceApi(selectedAssessmentType));
    }
  }, [open]);

  return (
    <React.Fragment>
      {isLoadingPractiseSections ? (
        <Loader />
      ) : (
        <Dialog
          open={open}
          onClose={handleClosePromptsModal}
          scroll={'paper'}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle id='scroll-dialog-title'>
            {' '}
            {t('SendAssessments.Prompts')}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id='scroll-dialog-description'
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {practiseSections.length > 0 ? (
                <PromptsAccordation practiseSections={practiseSections} />
              ) : (
                <Typography
                  label={t(
                    'SendAssessments.CurrentlyPromptsAreNotAvailalbeForSelectedAssessmentType'
                  )}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePromptsModal}>
              {' '}
              {t('SendAssessments.Close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
