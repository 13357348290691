/** @format */

import React from 'react';

function PauseRecordingIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='9'
      viewBox='0 0 6 9'
      fill='none'
    >
      <rect x='5' width='1' height='9' rx='0.5' fill='#1206F9' />
      <rect width='1' height='9' rx='0.5' fill='#1206F9' />
    </svg>
  );
}

export default PauseRecordingIcon;
