import React from 'react';

function AudioRedFlagAnalysisIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#FF0000'
        d='M11.254 4.508a.804.804 0 011.353.587v11.809a.804.804 0 01-1.353.586L8.08 14.516a1.125 1.125 0 00-.769-.304H5.375a2.09 2.09 0 01-2.09-2.089V9.878a2.09 2.09 0 012.09-2.09H7.31c.286 0 .56-.108.77-.303l3.173-2.977zm4.66.825a.482.482 0 01.68.043A8.486 8.486 0 0118.714 11a8.487 8.487 0 01-2.12 5.623.484.484 0 01-.69.055.484.484 0 01-.034-.691A7.52 7.52 0 0017.75 11a7.52 7.52 0 00-1.88-4.987.484.484 0 01.044-.68zm-.83 2.005a.482.482 0 00-.771.578A5.117 5.117 0 0115.339 11a5.12 5.12 0 01-1.026 3.084.482.482 0 10.771.578A6.082 6.082 0 0016.304 11a6.082 6.082 0 00-1.22-3.662z'
      ></path>
    </svg>
  );
}

export default AudioRedFlagAnalysisIcon;
