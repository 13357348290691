/** @format */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from 'widgets/Typography/Typography';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function UploadStatusTableMobileView({
  files,
  handleCancel,
  uploadMultipleFilesError,
  handleRetry,
  uploadSuccess,
  totalCancelUploads,
}: any) {
  const isSmallMobile = useMediaQuery('(max-width: 281px)');
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '98%',
        mt:
          files.length !== uploadSuccess + totalCancelUploads ||
          files.length !== totalCancelUploads
            ? 5
            : 0,
      }}
    >
      {files.length !== uploadSuccess + totalCancelUploads && (
        <Typography
          sx={{ fontSize: '10px', p: 2 }}
          label='upload.ClickTheArrowToExpandAndCheckTheUploadStatus'
        />
      )}

      {files.map((file: any, index: number) => {
        return (
          <Accordion
            sx={{
              backgroundColor: '#f9f9f9',

              m: 2,
            }}
          >
            <AccordionSummary id={`accordingUploadFilesStatus${index}`}>
              <Typography label=''> {file.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {file.progress === 100 ? (
                <Box
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: !isSmallMobile ? 'row' : 'column',
                  }}
                >
                  <Typography label='upload.Status' />
                  <Typography
                    sx={{ color: '#00D8A5' }}
                    label='upload.Uploaded'
                  />
                </Box>
              ) : file.isCanceled ? (
                <Box
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: !isSmallMobile ? 'row' : 'column',
                  }}
                >
                  <Typography label='upload.Status' />
                  <Typography sx={{ color: 'red' }} label='upload.Canceled' />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: !isSmallMobile ? 'row' : 'column',
                  }}
                >
                  <Typography label='upload.Progress' />
                  <CircularProgressWithLabel
                    value={file.progress ?? 0}
                    isCanceled={file.isCanceled}
                  />
                </Box>
              )}
              {!file.isCanceled ? (
                <Box
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: !isSmallMobile ? 'row' : 'column',
                  }}
                >
                  {' '}
                  <Typography label='upload.Actions' />
                  <Button
                    onClick={() => handleCancel(index)}
                    disabled={
                      file.isCancel ||
                      file.progress === 100 ||
                      uploadMultipleFilesError.message
                    }
                    label='upload.Cancel'
                    sx={{ color: 'black' }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',

                    justifyContent: 'center',
                    flexDirection: !isSmallMobile ? 'row' : 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography label='upload.Actions' />
                  <Button
                    onClick={() => handleRetry(index)}
                    disabled={
                      file.isCancel ||
                      file.progress === 100 ||
                      uploadMultipleFilesError.message
                    }
                    label='upload.Retry'
                    sx={{ color: '#1206f9' }}
                  />
                </Box>
              )}{' '}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
