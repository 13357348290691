import { createSlice } from '@reduxjs/toolkit';
import { ProcessDataListForParticipantsInitialStateTypes } from 'types/VideoPreview-types';

const ProcessDataListForParticipantsInitialState: ProcessDataListForParticipantsInitialStateTypes =
  {
    isLoadingProcessDataListForParticipants: false,
    errorProcessDataListForParticipants: '',
    ProcessDataListForParticipants: [],
  };
const getProcessDataListForParticipantsSlice = createSlice({
  name: 'api',
  initialState: ProcessDataListForParticipantsInitialState,
  reducers: {
    ProcessDataListForParticipantLoad(state) {
      state.isLoadingProcessDataListForParticipants = true;
    },
    ProcessDataListForParticipantSuccess(state, action) {
      state.isLoadingProcessDataListForParticipants = false;
      state.ProcessDataListForParticipants = action.payload;
    },
    ProcessDataListForParticipantFailure(state, action) {
      state.isLoadingProcessDataListForParticipants = false;
      state.errorProcessDataListForParticipants = action.payload;
    },
  },
});

export const {
  ProcessDataListForParticipantLoad,
  ProcessDataListForParticipantSuccess,
  ProcessDataListForParticipantFailure,
} = getProcessDataListForParticipantsSlice.actions;
export { getProcessDataListForParticipantsSlice };
