import React from 'react';
import { styled, Tabs, Box, Tab, Tooltip, Paper } from '@mui/material';
import TranscriptIcon from 'icons/TranscriptIcon';
import Typography from 'widgets/Typography/Typography';

const TabsForTranscriptAndTips = React.memo(({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: number;
  setSelectedTab: Function;
}) => {
  const CustomTabs = styled(Tabs)({
    '& MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root': {
      backgroundColor: '#8F8AFC',
    },
    '& .Mui-selected': {
      color: '#1206F9 !important',
      fontWeight: 'bold',
    },
  });

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const isUserPremium = false;

  return (
    <Box sx={{ mt: 2 }}>
      <Paper
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          backgroundColor: '#605E5E0A',
        }}
        elevation={2}
      >
        <CustomTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label='custom tabs example'
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              width: '50%',
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'center',
              height: 'fit-content',
            }}
          >
            <Tab
              value={0}
              label={
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: selectedTab === 0 ? '#1206F9 !important' : 'black',
                    fontWeight: selectedTab === 0 ? '600' : 'none',
                    mt: 1.2,
                  }}
                  label='Tip'
                />
              }
            />
          </Box>
          <Tooltip title='Premium Feature'>
            <Box
              sx={{
                width: '50%',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'center',
                gap: '5px',
                height: 'fit-content',
              }}
            >
              <Tab
                value={1}
                icon={<TranscriptIcon />}
                iconPosition='start'
                sx={{ gap: '5px' }}
                label={
                  <Typography
                    sx={{
                      textTransform: 'none',
                      color: selectedTab === 1 ? '#1206F9 !important' : 'black',
                      fontWeight: selectedTab === 1 ? '600' : 'none',
                    }}
                    label='Transcript'
                  />
                }
                disabled
              />
            </Box>
          </Tooltip>
        </CustomTabs>
      </Paper>
    </Box>
  );
});

export default TabsForTranscriptAndTips;
