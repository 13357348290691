/** @format */

import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

interface Video {
  id: number;
  title: string;
  url: string;
}

const videoData: Video[] = [
  {
    id: 1,
    title: 'helpandsupport.DemoOfTheWebsite',
    url: './comonImg/HelpVideos/Demo-of-the-Video.mp4',
  },

  {
    id: 2,
    title: 'helpandsupport.EmoJisExplained',
    url: './comonImg/HelpVideos/Emoji-Explain.mp4',
  },
  {
    id: 3,
    title: 'helpandsupport.SiteOverview',
    url: './comonImg/HelpVideos/Site-Overview.mp4',
  },
];

const VideoList: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile || isTablet ? 'column' : 'row',
        gap: 5,
      }}
    >
      {videoData.map((video) => (
        <Paper
          elevation={5}
          sx={{ borderRadius: 5, padding: 2 }}
          key={video.id}
        >
          <video
            id={`video${video.id}`}
            width='100%'
            style={{ borderRadius: '15px' }}
            controls
            controlsList='nodownload '
          >
            <source src={video.url} type='video/mp4' />
            <Typography label='helpandsupport.YourBrowserDoesNotSupportTheVideoTag' />
          </video>{' '}
          <Typography label={video.title} />
        </Paper>
      ))}
    </Box>
    // </Paper>
  );
};

export default VideoList;
