/** @format */

import SubscriptionsComponent from 'components/Subscriptions/Subscriptions';
import React from 'react';

function Subscriptions() {
  return <SubscriptionsComponent />;
}

export default Subscriptions;
