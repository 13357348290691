/** @format */

import { Box, useMediaQuery } from '@mui/material';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import LoginForm from './LoginForm';
import MirroBranding from './MirroBranding';

function SignInComponent() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const { handleSignInFormSubmit, loginStatus } = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    var currentUrl = window.location.href;
    const modifiedUrl =
      currentUrl.substring(
        0,
        currentUrl.indexOf(
          '/',
          currentUrl.indexOf('/', currentUrl.indexOf('/') + 1) + 1
        ) + 1
      ) + currentUrl.substring(currentUrl.indexOf('#'));
    window.history.replaceState({}, '', modifiedUrl);
  }, []);
  return (
    <Box
      sx={{
        width: '99vw',
        height: '100%',
        minHeight: '90vh',
        background: '#FBFBFF',
        mb: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: isMobile || isTablet ? 2 : 15,
          flexDirection: isMobile || isTablet ? 'column' : 'row',
          height: '30%',
          gap: isMobile || isTablet ? 4 : 0,
          alignItems: isMobile || isTablet ? 'center' : 'left',
        }}
      >
        <MirroBranding />
        <LoginForm
          handleSignInFormSubmit={handleSignInFormSubmit}
          loginStatus={loginStatus}
          showPassword={showPassword}
          handleClickShowPassword={handleClickShowPassword}
        />
      </Box>
      {!isMobile && !isTablet && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '30rem',
          }}
        >
          <Box sx={{ width: '80%' }}>
            <img
              src='./LoginImg/mirro-ribbon-1.png'
              alt='mirro-ribbon'
              width={'100%'}
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </Box>

          <Box>
            <img
              src='./LoginImg/mirro-ribbon-2.png'
              alt='mirro-ribbon'
              width={'100%'}
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SignInComponent;
