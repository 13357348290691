/** @format */

import { featureFlagList } from 'constants/FeatureFlags';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const SignInSchema = (t: TFunction<'translation', undefined>) => {
  return Yup.object().shape({
    username: Yup.string()
      .email(t('loginpage.InvalidEmail'))
      .required(t('loginpage.EmailIsRequired')),
    password: Yup.string()
      .min(6, t('loginpage.PasswordMustBeAtleast6Characters'))
      .required(t('loginpage.PasswordIsRequired')),
  });
};

export const SignUpSchema = (
  t: TFunction<'translation', undefined>,
  isFeatureEnable: (featureName: string) => boolean
) =>
  Yup.object().shape({
    firstName: Yup.string().required(t('signuppage.FirstNameIsRequired')),
    lastName: Yup.string().required(t('signuppage.LastNameIsRequired')),
    email: Yup.string()
      .email(t('signuppage.InvalidEmail'))
      .required(t('signuppage.EmailIsRequired')),
    password: Yup.string()
      .min(6, t('signuppage.PasswordMustBeAtleast6Characters'))
      .required(t('signuppage.PasswordIsRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('signuppage.PasswordsMustMatch'))
      .required(t('signuppage.PasswordIsRequired')),
    agreeToTerms: Yup.boolean().oneOf(
      [true],
      t('signuppage.YouMustAgreeToTheTermsOfServiceAndPrivacyPolicy')
    ),
    captcha: isFeatureEnable(featureFlagList.recaptcha)
      ? Yup.string().test(
          'recaptcha',
          t('signuppage.PleasecompletethereCAPTCHA'),
          (value) => !!value
        )
      : Yup.string(),
  });

export const DCSignUpSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    firstName: Yup.string().required(t('signuppage.FirstNameIsRequired')),
    lastName: Yup.string().required(t('signuppage.LastNameIsRequired')),

    email: Yup.string()
      .email(t('signuppage.InvalidEmail'))
      .required(t('signuppage.EmailIsRequired')),
    password: Yup.string()
      .min(6, t('signuppage.PasswordMustBeAtleast6Characters'))
      .required(t('signuppage.PasswordIsRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('signuppage.PasswordsMustMatch'))
      .required(t('signuppage.PasswordIsRequired')),
  });
