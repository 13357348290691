/** @format */

import { i18n } from 'i18n/i18n';
import {
  acceptCookiesFailure,
  acceptCookiesLoad,
  acceptCookiesSuccess,
} from 'redux/slices/Cookies-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';

export const acceptCookiesService = (): AppThunk => async (dispatch) => {
  dispatch(acceptCookiesLoad());

  await commonAPI
    .post(ProtectedUrls.acceptCookies.url())
    .then((response) => {
      dispatch(acceptCookiesSuccess(response.data));
    })
    .catch((error) =>
      dispatch(
        acceptCookiesFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
      )
    );
};
