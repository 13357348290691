import React from 'react'

function DeleteBulkActiveIcon() {
    return (
        <svg 
            width="14" 
            height="15" 
            viewBox="0 0 14 15" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M3.26003 14.7788C2.81051 14.7788 2.42583 14.6189 2.10599 14.2991C1.78615 13.9792 1.62596 13.5943 1.62541 13.1442V2.51923H0.808105V0.884614H4.89464V0.0673065H9.79849V0.884614H13.885V2.51923H13.0677V13.1442C13.0677 13.5937 12.9078 13.9787 12.588 14.2991C12.2681 14.6195 11.8832 14.7794 11.4331 14.7788H3.26003ZM11.4331 2.51923H3.26003V13.1442H11.4331V2.51923ZM4.89464 11.5096H6.52926V4.15384H4.89464V11.5096ZM8.16387 11.5096H9.79849V4.15384H8.16387V11.5096Z" 
                fill="#EA3323"
            />
        </svg>
    )
}

export default DeleteBulkActiveIcon;