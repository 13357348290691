/** @format */

import GalleryComponent from 'components/Gallery/Gallery';
import React from 'react';

function Gallery() {
  return <GalleryComponent />;
}

export default Gallery;
