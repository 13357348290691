import { i18n } from 'i18n/i18n';
import {
  getForgetPasswordLoad,
  getForgetPasswordSuccess,
  getForgetPasswordFailure,
} from 'redux/slices/ForgetPassword-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { Toast } from 'widgets/Toast/Toast';

export const forgetPasswordService =
  (userHash: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    dispatch(getForgetPasswordLoad());
    console.log('ppp', sessionStorage.getItem('apiKey'));

    await commonAPI
      .get(ProtectedUrls.forgetPasswordUrl.url(userHash, newPassword))
      .then((response) => {
        dispatch(getForgetPasswordSuccess(response.data));
        Toast(i18n.t('toastMessageNotifications.PasswordResetSuccessfully'), 'success');
      })
      .catch((error) =>
        dispatch(
          getForgetPasswordFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
        )
      );
  };
