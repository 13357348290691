import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { TempPromptQuestionsArrayType } from 'types/MyAssessments-types';

export default function PromptsAccordation({
  practiseSections,
}: {
  practiseSections: TempPromptQuestionsArrayType[];
}) {
  return (
    <div>
      {practiseSections.map((section: TempPromptQuestionsArrayType) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${section.id}-content`}
              id={`panel${section.id}-header`}
              sx={{ backgroundColor: '#F6F5FF', mt: 1 }}
            >
              {section.question}
            </AccordionSummary>
            <AccordionDetails>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',

                  fontSize: '14px',
                  marginLeft: 0,
                }}
              >
                <span style={{ fontWeight: 'bold' }}> Hints:</span>{' '}
                {section.description}
              </pre>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
