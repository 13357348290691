/** @format */

import React from 'react';
import './pageNotFound.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProtectedRoutes } from 'routes/routes';
import Loader from 'widgets/Loader/Loader';
import Typography from 'widgets/Typography/Typography';

const PageNotFoundComponent = () => {
  const history = useHistory();

  if (/\/code/.test(history.location.pathname)) {
    return <Loader />;
  } else
    return (
      <div className='not-found-container'>
        <Typography variant='h1' label='pageNotFound.404PageNotFound' />
        <Typography label='pageNotFound.OopsThePageYouAreLookingForDoesNotExist' />
        <span style={{ textAlign: 'center' }}>
          <Typography label='pageNotFound.Please' />
          <a
            onClick={() => history.push(ProtectedRoutes.dashboardRoute)}
            className='click-here-text'
          >
            <Typography label='pageNotFound.ClickHere' />
          </a>
          <Typography label='pageNotFound.ToGoToDashboard' />
        </span>
      </div>
    );
};

export default PageNotFoundComponent;
