/** @format */

import {
  getAuthProviderDetailsFailure,
  getAuthProviderDetailsLoad,
  getAuthProviderDetailsSuccess,
  loginDataFailure,
  loginDataLoad,
  loginDataSuccess,
  requestProfileDataFailure,
  requestProfileDataLoad,
  requestProfileDataSuccess,
  signUpDataFailure,
  signUpDataLoad,
  signUpDataSuccess,
} from 'redux/slices/Auth-slice';
import { AppThunk } from 'redux/store';
import { DeepCandidateUrls, ProtectedUrls, PublicUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { SignInFormModel, SignupFormModel } from 'types/Auth/Auth-types';
import { Toast } from 'widgets/Toast/Toast';
import { History } from 'history';
import i18n from 'i18next';
import {
  ProtectedRoutes,
  OpenRoutes,
  deepCandidateOpenRoutes,
} from 'routes/routes';
import {
  getUserAndCheckAuthProviderOfUser,
  handleSuccessfulLogin,
} from 'utils/AuthenticationHelpers';
import { AxiosResponse } from 'axios';
import { AccountInfo } from '@azure/msal-common';
import { IPublicClientApplication } from '@azure/msal-browser';

async function handleOtherCasesOfGetLoginDataServiceApi(
  message: string,
  username: string
) {
  const response = await commonAPI.get(
    ProtectedUrls.getUserDetailsAsUserUrl.url({
      email_address: username,
    })
  );
  await getUserAndCheckAuthProviderOfUser(
    response.data[0]?.auth_provider,
    message
  );
}
export const getLoginDataServiceApi =
  (
    values: SignInFormModel,
    history: History,
    isMicrosoftLogin: Boolean,
    payloadSignUp: SignupFormModel
  ): AppThunk =>
  async (dispatch) => {
    dispatch(loginDataLoad());

    try {
      const response = await commonAPI.get(
        PublicUrls.loginApiRoute.url(values)
      );
      dispatch(loginDataSuccess(response.data));

      if (response.data[0]?.apikey) {
        await handleSuccessfulLogin(response.data[0]);
        Toast(i18n.t('toastMessageNotifications.LoginSuccessful'), 'success');
        history.push(ProtectedRoutes.galleryRoute);
      } else {
        await handleOtherCasesOfGetLoginDataServiceApi(
          response.data.message,
          values.username
        );
      }
    } catch (error) {
      dispatch(
        loginDataFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };

export const getSignUpDataServiceApi =
  (
    values: SignupFormModel,

    isMicrosoftSignUP: Boolean,
    payloadSignUp: SignupFormModel,
    history: History
  ): AppThunk =>
  async (dispatch) => {
    dispatch(signUpDataLoad());

    await commonAPI
      .post(PublicUrls.signUpApiRoute.url(values))
      .then((response) => {
        dispatch(signUpDataSuccess(response.data));
        if (response.data[0]?.apikey) {
          Toast(
            i18n.t('toastMessageNotifications.AccountCreatedSuccessfully'),
            'success'
          );

          const payloadSignIn = {
            username: payloadSignUp.email,
            password: payloadSignUp.password,
          };

          isMicrosoftSignUP
            ? dispatch(
                getLoginDataServiceApi(
                  payloadSignIn,
                  history,
                  false,
                  payloadSignUp
                )
              )
            : history.push(OpenRoutes.signUpSuccessPage);
        } else {
          Toast(response.data.message, 'error');
        }
      })
      .catch((error) =>
        dispatch(
          signUpDataFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const getAuthProviderDetailsServiceApi =
  (): AppThunk => async (dispatch) => {
    dispatch(getAuthProviderDetailsLoad());

    await commonAPI
      .get(ProtectedUrls.getAuthProviderDetails.url())
      .then((response) => {
        dispatch(getAuthProviderDetailsSuccess(response.data));
      })
      .catch((error) =>
        dispatch(
          getAuthProviderDetailsFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const RequestProfileDataServiceApi =
  (
    callMsGraph: Function,
    setLoading: Function,
    instance: IPublicClientApplication,
    loginRequest: {
      scopes: Array<string>;
    },
    accounts: AccountInfo[],
    history: History
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestProfileDataLoad());

    setLoading(true);

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const profileResponse = await callMsGraph(response.accessToken);

      const responseAuth = await commonAPI.get(
        ProtectedUrls.getAuthProviderDetails.url()
      );

      const payloadSignUp = {
        firstName: profileResponse.givenName,
        lastName: profileResponse.surname,
        email: profileResponse.userPrincipalName,
        password: profileResponse.id,
        authProvider: responseAuth.data['Microsoft'],
      };

      const payloadSignIn: SignInFormModel = {
        username: profileResponse.userPrincipalName,
        password: profileResponse.id,
      };

      if (
        history.location.pathname.toLowerCase() ===
          OpenRoutes.signInRoute.toLowerCase() ||
        history.location.pathname.toLowerCase() ===
          deepCandidateOpenRoutes.DCSignInRoute.toLowerCase()
      ) {
        dispatch(
          getLoginDataServiceApi(payloadSignIn, history, true, payloadSignUp)
        );
      } else {
        const signUpResponse = await commonAPI.post(
          history.location.pathname.toLowerCase() ===
            deepCandidateOpenRoutes.DCSignInRoute.toLowerCase()
            ? DeepCandidateUrls.deepCandidateSignUpApiRoute.url(payloadSignUp)
            : PublicUrls.signUpApiRoute.url(payloadSignUp)
        );

        if (signUpResponse.data[0]?.apikey) {
          Toast(
            i18n.t('toastMessageNotifications.AccountCreatedSuccessfully'),
            'success'
          );
          history.push(OpenRoutes.signUpSuccessPage);
        } else {
          switch (signUpResponse.data.message) {
            case 'Email-id Already exists':
              const userDetails = await commonAPI.get(
                ProtectedUrls.getUserDetailsAsUserUrl.url({
                  email_address: payloadSignUp.email,
                })
              );
              await getUserAndCheckAuthProviderOfUser(
                userDetails.data[0]?.auth_provider,
                signUpResponse.data.message
              );

              break;
            default:
              Toast(signUpResponse.data.message, 'error');
          }
        }
      }
      dispatch(requestProfileDataSuccess(response));
    } catch (error) {
      dispatch(
        getAuthProviderDetailsFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
      dispatch(requestProfileDataFailure(error));
    } finally {
      setLoading(false);
    }
  };
