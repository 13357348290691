import { Box } from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from 'widgets/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { AttendeeRecordForDashboard, Data } from 'types/Dashboard-types';
import Typography from 'widgets/Typography/Typography';

function DetailsTable(props: { processDataList: Data }) {
  if (
    !props.processDataList ||
    !Array.isArray(props.processDataList.attendees_record)
  ) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: '100%', height: 'auto' }}>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', maxWidth: 'none', overflowX: 'auto' }}
      >
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.Role' />
              </TableCell>

              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.Emotion' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.EngagementScore' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.VariationScore' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.PositivityScore' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.EnergyScore' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.RedFlags' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.YellowFlags' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.GreenFlags' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.ConfidentTone' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.StressedTone' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                <Typography label='dashboard.FatiguedTone' />
              </TableCell>
              <TableCell align='center' colSpan={15}>
                Vocal Variety Score
              </TableCell>
              <TableCell align='center' colSpan={15}>
                Vocal Alteration Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.processDataList.attendees_record.map(
              (record: AttendeeRecordForDashboard, index: number) => (
                <TableRow key={index}>
                  <TableCell align='center' colSpan={15}>
                    {record.session_role_id === 3
                      ? 'Participant'
                      : record.session_role_id === 5
                      ? 'Interviewer'
                      : record.session_role_id === 6
                      ? 'Interviewee'
                      : ''}
                  </TableCell>

                  <TableCell align='center' colSpan={15}>
                    {record.overall_emotion_name}
                  </TableCell>

                  <TableCell align='center' colSpan={15}>
                    {record.total_score}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.variation_score}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.avg_positivity_score}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.avg_energy}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.red_flags}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.yellow_flags}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.green_flags}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.confident_tone.toFixed(2)}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.stressed_tone.toFixed(2)}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.fatigued_tone.toFixed(2)}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.vocal_variety_score.toFixed(2)}
                  </TableCell>
                  <TableCell align='center' colSpan={15}>
                    {record.vocal_alteration_score.toFixed(2)}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DetailsTable;
