import {
  sendAssessmentEmailToCandidatesError,
  sendAssessmentEmailToCandidatesLoad,
  sendAssessmentEmailToCandidatesSuccess,
} from 'redux/slices/SendAssessment-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { PayloadForsendAssessmentEmailToCandidates } from 'types/SendAssessments-types';
import { Toast } from 'widgets/Toast/Toast';

export const sendAssessmentEmailToCandidatesServiceApi =
  (payload: PayloadForsendAssessmentEmailToCandidates): AppThunk =>
  async (dispatch) => {
    dispatch(sendAssessmentEmailToCandidatesLoad());
    try {
      const response = await commonAPI.post(
        ProtectedUrls.sendAssessmentEmailToCandidates.url(),
        payload
      );
      const failedEmails = [];
      for (const email in response.data) {
        const status = response.data[email];
        if (status === 'Failure') {
          failedEmails.push(email);
        }
      }
      if (failedEmails.length > 0) {
        console.error(
          'error - sendAssessmentEmailToCandidatesServiceApi -The following email IDs have failed:',
          failedEmails
        );
      } else {
        dispatch(sendAssessmentEmailToCandidatesSuccess(response.data));
        Toast('Assessment Send to selected Email ids', 'success');
      }
    } catch (error) {
      console.error('error - sendAssessmentEmailToCandidatesServiceApi', error);
      dispatch(sendAssessmentEmailToCandidatesError(error));
      Toast('Something went wrong. Please try again', 'error');
    }
  };
