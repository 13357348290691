/** @format */

import ProfileComponent from 'components/AccountSettings/Profile';
import SidebarComponent from 'components/AccountSettings/Sidebar';
import React from 'react';

function Profile() {
  return (
    <div>
      <SidebarComponent />
    </div>
    
  );
}

export default Profile;