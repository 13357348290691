/** @format */

import { dashboardTableHeaderIds } from 'constants/Dashboard';
import i18n from 'i18next';
import { HeadCell } from 'types/Dashboard-types';

export const headCells: HeadCell[] = [
  {
    id: dashboardTableHeaderIds.dropdown,
    numeric: false,
    disablePadding: true,
    label: '',
    colSpan: 1,
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.checkbox,
    numeric: false,
    disablePadding: true,
    label: '',
    colSpan: 1,
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.recordings,
    numeric: false,
    disablePadding: true,
    label: i18n.t('dashboard.Recordings'),
    colSpan: 10,
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.eventType,
    numeric: false,
    disablePadding: true,
    label: i18n.t('dashboard.EventType'),
    colSpan: 2,
    showColumn: true,
  },

  {
    id: dashboardTableHeaderIds.attendees,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Attendees'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.emotion,
    numeric: false,
    disablePadding: false,
    label: i18n.t('dashboard.Emotion'),
    colSpan: 2,
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.engagement,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Engagement'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.positivity,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Positivity'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.green,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Green'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.yellow,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Yellow'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.red,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.Red'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.confidentTone,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.ConfidentTone'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.stressedTone,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.StressedTone'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.fatiguedTone,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.FatiguedTone'),
    showColumn: true,
  },
  {
    id: dashboardTableHeaderIds.intervieweeTalkTimePercentage,
    numeric: true,
    disablePadding: false,
    label: i18n.t('dashboard.IntervieweeTalkTime'),

    showColumn: true,
  },
];
