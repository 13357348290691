import { Box } from '@mui/material';
import PromptsComponent from 'components/MyAssessments/PromptsComponent/PromptsComponent';
import RecorderSettingsForAssessment from 'components/MyAssessments/RecorderSettingsForAssessment/RecorderSettingsForAssessment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { setVideoTitle } from 'redux/slices/MyAssessment-slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPractiseSectionsServiceApi } from 'services/MyAssessment-service';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import {
  CandidateDetailsType,
  MandatoryDataForCandidateAssessmentSubmissionType,
} from 'types/SendAssessments-types';
import CandidateDetailsForm from './CandidateDetailsForm';
import Typography from 'widgets/Typography/Typography';
import AssessmentLinkValidation from 'widgets/AssessmentLinkValidation/AssessmentLinkValidation';
import { candidateAssignmentSubmissionCheckSelector } from 'redux/selectors/selectors';
import Loader from 'widgets/Loader/Loader';
import { useTranslation } from 'react-i18next';

function CandidateAssessment() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [mainRecordingSettings, setMainRecordingSettings] = useState({
    videoDescription: '',
    asessmentType: {
      id: -1,
      name: '',
      type: '',
      description: '',
      timerdirection: '',
    },
    recordingType: { id: -1, name: '' },
    cameraDetails: { deviceId: '', kind: '', label: '', groupId: '' },
    microphoneDetails: { deviceId: '', kind: '', label: '', groupId: '' },
  });
  const [
    mandatoryDataForCandidateAssessmentSubmission,
    setMandatoryDataForCandidateAssessmentSubmission,
  ] = useState<MandatoryDataForCandidateAssessmentSubmissionType>({
    senderApiKey: '',
    selectedAssessmentType: {
      id: -1,
      name: '',
      type: '',
      description: '',
      timerdirection: '',
    },
    expirationDateTimeStampInUtc: '',
    user_mail_id: '',
  });

  const [candidateDetails, setCandidateDetails] =
    useState<CandidateDetailsType>({
      firstName: '',
      lastName: '',
      email: '',
      assessmentId: -1,
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get('token');

    if (successParam) {
      const decryptedData = JSON.parse(atob(successParam));
      if (decryptedData) {
        setMandatoryDataForCandidateAssessmentSubmission(decryptedData);
        sessionStorage.setItem('apiKey', decryptedData.senderApiKey);
        setMainRecordingSettings({
          ...mainRecordingSettings,
          asessmentType: decryptedData.selectedAssessmentType,
        });
      }
    }
  }, [location.search]);

  const [togglePromptSection, setTogglePromptSection] =
    useState<Boolean>(false);

  function handleTogglePromptSection() {
    setTogglePromptSection(!togglePromptSection);
    dispatch(
      getPractiseSectionsServiceApi(mainRecordingSettings.asessmentType.id)
    );
  }

  useEffect(() => {
    dispatch(setVideoTitle(mainRecordingSettings.videoDescription));
  }, [mainRecordingSettings.videoDescription]);

  const history = useHistory();

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.clear();
    };
    const unlisten = history.listen(handleRouteChange);

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const confirmExit = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', confirmExit);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, []);

  const { isLoadingcandidateAssignmentSubmissionCheck } = useAppSelector(
    candidateAssignmentSubmissionCheckSelector
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgb(248, 251, 255)',
      }}
    >
      {mandatoryDataForCandidateAssessmentSubmission.senderApiKey === '' ? (
        <AssessmentLinkValidation
          heading={t('CandidateAssessments.ApiKeyNotFound')}
          description={''}
        />
      ) : mandatoryDataForCandidateAssessmentSubmission.selectedAssessmentType
          .id === -1 ? (
        <AssessmentLinkValidation
          heading={t('CandidateAssessments.InvalidAssessmentType')}
          description={t(
            'CandidateAssessments.ApiKeyNotThisAssessmentIsNotValidPleaseCloseTheBrowserFound'
          )}
        />
      ) : (
        <Box
          sx={{
            width: '80%',
            minHeight: '80vh',

            display:
              candidateDetails.email === '' &&
              candidateDetails.firstName === '' &&
              candidateDetails.lastName === ''
                ? 'flex'
                : '',
            justifyContent:
              candidateDetails.email === '' &&
              candidateDetails.firstName === '' &&
              candidateDetails.lastName === ''
                ? 'center'
                : '',
            alignItems:
              candidateDetails.email === '' &&
              candidateDetails.firstName === '' &&
              candidateDetails.lastName === ''
                ? 'center'
                : '',
          }}
        >
          {candidateDetails.email === '' &&
          candidateDetails.firstName === '' &&
          candidateDetails.lastName === '' ? (
            <CandidateDetailsForm
              setCandidateDetails={setCandidateDetails}
              mandatoryDataForCandidateAssessmentSubmission={
                mandatoryDataForCandidateAssessmentSubmission
              }
            />
          ) : togglePromptSection ? (
            <PromptsComponent
              mainRecordingSettings={mainRecordingSettings}
              setMainRecordingSettings={setMainRecordingSettings}
              handleTogglePromptSection={handleTogglePromptSection}
              isFromCandidateAssessment={true}
              candidateDetails={candidateDetails}
            />
          ) : (
            <RecorderSettingsForAssessment
              handleTogglePromptSection={handleTogglePromptSection}
              setMainRecordingSettings={setMainRecordingSettings}
              isFromCandidateAssessment={true}
              mandatoryDataForCandidateAssessmentSubmission={
                mandatoryDataForCandidateAssessmentSubmission
              }
              candidateDetails={candidateDetails}
            />
          )}
        </Box>
      )}
      {isLoadingcandidateAssignmentSubmissionCheck && <Loader />}
    </Box>
  );
}

export default CandidateAssessment;
