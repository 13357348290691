import React from 'react';

function VideoGreyFlagAnalysisIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='18'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#808080'
        d='M15.5 7.25c0-.827-.673-1.5-1.5-1.5H5c-.827 0-1.5.673-1.5 1.5v7.5c0 .827.673 1.5 1.5 1.5h9c.827 0 1.5-.673 1.5-1.5v-2.5l3 2.5v-7.5l-3 2.5v-2.5z'
      ></path>
    </svg>
  );
}

export default VideoGreyFlagAnalysisIcon;
