/** @format */

import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { AttendeeRecordForDashboard, Data, Order } from 'types/Dashboard-types';
import { ProcessDataListVersion2 } from 'types/Gallery.types';

function useProcessDataListTable(
  processDataListWithSession: Array<ProcessDataListVersion2>
) {
  const [rows, setRows] = useState<Array<Data>>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>(
    'processing_end_date_time'
  );
  const [page, setPage] = useState(
    Number(localStorage.getItem('currentPageDashboardPagination'))
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState<Array<Data>>([]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof Data>(
    order: Order,
    orderBy: Key
  ): (
    a: {
      [key in Key]:
        | number
        | string
        | boolean
        | Array<AttendeeRecordForDashboard>;
    },
    b: {
      [key in Key]:
        | number
        | string
        | boolean
        | Array<AttendeeRecordForDashboard>;
    }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function handleRequestSort(event: MouseEvent<unknown>, property: keyof Data) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(_event: unknown, newPage: number) {
    localStorage.setItem('currentPageDashboardPagination', newPage.toString());
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function formatDate(dateString: string) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleString('en-US', options);
    const formattedTime = dateObject.toLocaleString('en-US', optionsTime);

    return `${formattedDate} | ${formattedTime}`;
  }

  useEffect(() => {
    if (rows) {
      const temp = stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      setVisibleRows(temp);
    }
    // eslint-disable-next-line
  }, [order, orderBy, page, rowsPerPage, rows]);

  useEffect(() => {
    const updatedRows = processDataListWithSession.map(
      (tableData: ProcessDataListVersion2) => {
        return {
          dropdown: '',
          recordings:
            tableData.session_title || tableData.processing_event_name,
          eventType: tableData.session_name,
          attendees: tableData.total_attendees,
          emotion: tableData.overall_emotion_name,
          engagement: tableData.engagement_score,
          positivity: tableData.positivity_score,
          green: tableData.green_flags,
          yellow: tableData.yellow_flags,
          red: tableData.red_flags,
          processingStartDateTime: tableData.processing_start_date_time,
          intervieweeTalkTimePercentage:
            tableData.interviewee_talk_time_percentage ?? null,
          confidentTone: tableData.confident_tone,
          stressedTone: tableData.stressed_tone,
          fatiguedTone: tableData.fatigued_tone,

          ...tableData,
        };
      }
    );

    setRows(updatedRows);
  }, [processDataListWithSession]);

  return {
    handleRequestSort,
    handleChangeRowsPerPage,
    handleChangePage,
    formatDate,
    rows,
    page,
    emptyRows,
    rowsPerPage,
    visibleRows,
    order,
    orderBy,
  };
}

export default useProcessDataListTable;
