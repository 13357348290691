import { createSlice } from '@reduxjs/toolkit';
import { SignUpDataInitialStateTypes } from 'types/Auth/Auth-types';
const signUpDataInitialState: SignUpDataInitialStateTypes = {
  isLoadingSignUpData: false,
  errorSignUpData: '',
  signUpData: [],
};
const deepCandidateSignUpDataSlice = createSlice({
  name: 'api',
  initialState: signUpDataInitialState,
  reducers: {
    deepCandidateSignUpDataLoad(state) {
      state.isLoadingSignUpData = true;
    },
    deepCandidateSignUpSuccess(state, action) {
      state.isLoadingSignUpData = false;
      state.signUpData = action.payload;
    },
    deepCandidateSignUpFailure(state, action) {
      state.isLoadingSignUpData = false;
      state.errorSignUpData = action.payload;
    },
  },
});

export const {
  deepCandidateSignUpDataLoad,
  deepCandidateSignUpSuccess,
  deepCandidateSignUpFailure,
} = deepCandidateSignUpDataSlice.actions;
export { deepCandidateSignUpDataSlice };
