/** @format */

import StripeSubscriptionPaymentSuccessComponent from 'components/StripeSubscriptionPaymentSuccess/StripeSubscriptionPaymentSuccess';
import React from 'react';

function StripeSubscriptionPaymentSuccess() {
  return <StripeSubscriptionPaymentSuccessComponent />;
}

export default StripeSubscriptionPaymentSuccess;
