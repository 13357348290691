/** @format */

import Typography from 'widgets/Typography/Typography';
import React from 'react';

function Timer({ minutes, seconds }: { minutes: number; seconds: number }) {
  return (
    <Typography label=''>
      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}/
      10:00
    </Typography>
  );
}

export default Timer;
