import i18n from 'i18next';
import { AppThunk } from 'redux/store';
import { ProtectedUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import {
  KnowledgeTipsLoad,
  KnowledgeTipsFailure,
  KnowledgeTipsSuccess,
} from 'redux/slices/KnoledgeTips-slice';

export const GetKnowledgeTipService =
  (tip_no: number): AppThunk =>
  async (dispatch) => {
    dispatch(KnowledgeTipsLoad());

    await commonAPI
      .get(ProtectedUrls.getKnowledgeTips.url(tip_no))
      .then((response) => {
        dispatch(KnowledgeTipsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          KnowledgeTipsFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
        );
      });
  };
