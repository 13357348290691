/** @format */

import Box from '@mui/material/Box/Box';
import Modal from '@mui/material/Modal/Modal';
import Rating, { IconContainerProps } from '@mui/material/Rating/Rating';
import TextField from '@mui/material/TextField/TextField';
import React, { useState } from 'react';

import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'redux/store';
import { VisitorFeedbackSubmitApi } from '../../services/PreviewServices/UserValidation-service';
import { FeedbackData } from 'types/PreviewTypes/UserValidation-types';
import { ProtectedRoutes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#FFF',
  border: '1px solid #D1D5DB',
  boxShadow: 24,
  px: 4,
  p: 5,
  borderRadius: '29.552px',
};
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
  '& .MuiRating-icon': {
    margin: '0 5px',
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color='error' />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color='error' />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color='warning' />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color='success' />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color='success' />,
    label: 'Very Satisfied',
  },
};

interface VisitorFeedbackProps {
  Onopen: boolean;
  onClose: () => void;
}

function VisitorFeedback({ Onopen, onClose }: VisitorFeedbackProps) {
  const [open, setOpen] = useState(true);
  const [visitorFeedback, setVisitorFeedback] = useState('');
  const [value, setValue] = React.useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmitFeedback = () => {
    const visitorIDStr = sessionStorage.getItem('VisitorsID');
    if (!visitorIDStr) {
      setError('Visitor ID not found.');
      return;
    }

    const visitorID = parseInt(visitorIDStr, 10);
    if (isNaN(visitorID)) {
      setError('Invalid Visitor ID.');
      return;
    }

    const FeedbackDataValues: FeedbackData = {
      rating: value,
      feedback: visitorFeedback,
    };

    dispatch(VisitorFeedbackSubmitApi(visitorID, FeedbackDataValues));
    history.push({
      pathname: ProtectedRoutes.SandboxGallery,
    });
    setOpen(false);
  };
  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            ...style,
            width: 500,
            height: 'auto',
            display: 'block',
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
            gap: 2,
            position: 'relative',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            label='Preview.Analysisfeedback'
            sx={{
              color: '#1F2937',
              fontSize: '32px',
              fontWeight: 600,
            }}
          />
          <Typography
            label='Preview.Howlikelydoyouagreewithmirrosanalysisofthismedia'
            sx={{
              color: '#4B5563',
              fontSize: '16px',
              fontWeight: 400,
            }}
          />

          <StyledRating
            name='highlight-selected-only'
            size='large'
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            sx={{ mt: 3, mb: 3 }}
          />

          <Typography
            label='Preview.Additionalfeedback'
            sx={{
              color: '#6B7280',
              fontSize: '15px',
              fontWeight: 400,
              textAlign: 'left',
              mb: 1,
            }}
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id='outlined-multiline-jobAssessmentCreationFormValues-jobDescription'
            label=''
            multiline
            rows={3}
            placeholder='My feedback'
            sx={{ width: '100%' }}
            value={visitorFeedback}
            onChange={(event) => setVisitorFeedback(event.target.value)}
          />

          <Button
            sx={{
              width: '100%',
              p: 1,
              color: '#FFF',
              backgroundColor: '#1206F9',
              mt: 3,
            }}
            onClick={handleSubmitFeedback}
            label='Submit feedback'
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default VisitorFeedback;
