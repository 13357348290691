import { useState } from 'react';
import { removeFilesFromArray } from 'utils/removeFilesFromArray';

export function useFiles({
  initialState = [],
  maxFiles,
}: {
  initialState?: any;
  maxFiles: number;
}) {
  const [state, setstate] = useState(initialState);
  function withBlobs(files: any) {
    const destructured = [...files];

    if (destructured.length > maxFiles) {
      const difference = destructured.length - maxFiles;
      removeFilesFromArray(destructured, difference);
    }
    const blobs = destructured
      .map((file) => {
        file.preview = URL.createObjectURL(file);
        return file;
      })
      .filter((elem) => elem !== null);

    setstate(blobs);
  }
  return [state, withBlobs];
}
