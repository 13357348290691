/** @format */

import Typography from 'widgets/Typography/Typography';
import React, { useState, useEffect } from 'react';

const Clock = ({ isMyAssessment }: { isMyAssessment?: boolean }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerID);
  }, []);

  const formatDate = (date: Date): string => {
    const monthNames: string[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? '0' : ''
    }${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${period}`;

    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} ${formattedTime}`;
  };

  const formattedDate = formatDate(currentTime);

  return (
    <Typography
      sx={{
        color: '#33344B ',
        fontFamily: 'Poppins ',
        fontSize: '14px',
        textAlign: isMyAssessment ? '' : 'center',
      }}
      label=''
    >
      {formattedDate}
    </Typography>
  );
};

export default Clock;
