/** @format */

import React from 'react';

function ColumnsListTableIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
    >
      <rect
        x='0.375'
        y='0.375'
        width='12.25'
        height='11.25'
        rx='0.625'
        stroke='#1206F9'
        stroke-width='0.75'
      />
      <line
        x1='4.375'
        y1='1.96705e-08'
        x2='4.375'
        y2='12'
        stroke='#1206F9'
        stroke-width='0.75'
      />
      <line
        x1='8.375'
        y1='1.96705e-08'
        x2='8.375'
        y2='12'
        stroke='#1206F9'
        stroke-width='0.75'
      />
    </svg>
  );
}

export default ColumnsListTableIcon;
