/** @format */

import React from 'react';

function CloseDashboardIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='14'
      viewBox='0 0 28 14'
      fill='none'
    >
      <path
        d='M28 9.295L25.5277 7L28 4.705L27.2389 4L24 7L27.2389 10L28 9.295Z'
        fill='#2C2C2C'
      />
      <rect width='19' height='2' rx='1' fill='#2C2C2C' />
      <rect y='6' width='19' height='2' rx='1' fill='#2C2C2C' />
      <rect y='12' width='19' height='2' rx='1' fill='#2C2C2C' />
    </svg>
  );
}

export default CloseDashboardIcon;
