/** @format */

import React from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './learnings.css';
function LearningsComponent() {
  return (
    <AuthWrapper>
      <div>LearningsComponent</div>
    </AuthWrapper>
  );
}

export default LearningsComponent;
