/** @format */

import React from 'react';

function DeleteIcon() {
  return (
    <svg
      width='12'
      height='15'
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.35295 2.12103H3.56543C3.67509 1.33033 4.16161 0.642215 4.87041 0.275287C5.57934 -0.0917623 6.42214 -0.0917623 7.13095 0.275287C7.83987 0.642215 8.32639 1.33033 8.43605 2.12103H11.647C11.8419 2.12103 11.9999 2.27903 11.9999 2.47389C11.9999 2.66888 11.8419 2.82687 11.647 2.82687H11.2729L10.7081 11.8317C10.6684 12.451 10.3948 13.0319 9.94273 13.457C9.49073 13.8819 8.89407 14.1193 8.27354 14.1209H3.72634C3.10547 14.1198 2.5082 13.8827 2.05572 13.4577C1.60324 13.0325 1.32943 12.4513 1.28962 11.8317L0.726986 2.82687H0.352859C0.157993 2.82687 -1.90735e-06 2.66888 -1.90735e-06 2.47389C-1.90735e-06 2.27903 0.157996 2.12103 0.352859 2.12103H0.35295ZM6.00012 0.709224C5.59326 0.709106 5.19892 0.849612 4.88375 1.10689C4.56871 1.36415 4.3521 1.72246 4.27066 2.12103H7.7297C7.64827 1.72243 7.43166 1.36412 7.11661 1.10689C6.80144 0.849627 6.4071 0.709133 6.00024 0.709224H6.00012ZM1.99413 11.7915C2.02355 12.2312 2.21866 12.6434 2.54021 12.9449C2.86176 13.2462 3.28578 13.4143 3.72643 13.4151H8.27363C8.71428 13.4142 9.1383 13.2462 9.45985 12.9449C9.7814 12.6434 9.97649 12.2312 10.0059 11.7915L10.5657 2.82687H1.4344L1.99413 11.7915Z'
        fill='white'
      />
      <path
        d='M6.00029 4.94434C6.19516 4.94434 6.35327 5.10233 6.35327 5.29732V10.9445V10.9444C6.35327 11.1394 6.19516 11.2974 6.00029 11.2974C5.80542 11.2974 5.64731 11.1394 5.64731 10.9444V5.2972V5.29732C5.64731 5.20372 5.68453 5.11391 5.75071 5.04774C5.81689 4.98156 5.9067 4.94434 6.00029 4.94434Z'
        fill='white'
      />
      <path
        d='M7.93181 5.05273C8.12667 5.05273 8.28479 5.21073 8.28479 5.40572V11.0529V11.0528C8.28479 11.2478 8.12667 11.4058 7.93181 11.4058C7.73694 11.4058 7.57883 11.2478 7.57883 11.0528V5.4056V5.40572C7.57883 5.31212 7.61605 5.22231 7.68223 5.15614C7.7484 5.08996 7.83821 5.05273 7.93181 5.05273Z'
        fill='white'
      />
      <path
        d='M4.14226 5.05273C4.33712 5.05273 4.49524 5.21073 4.49524 5.40572V11.0529V11.0528C4.49524 11.2478 4.33712 11.4058 4.14226 11.4058C3.94739 11.4058 3.78928 11.2478 3.78928 11.0528V5.4056V5.40572C3.78928 5.31212 3.8265 5.22231 3.89268 5.15614C3.95885 5.08996 4.04866 5.05273 4.14226 5.05273Z'
        fill='white'
      />
    </svg>
  );
}

export default DeleteIcon;
