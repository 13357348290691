export const dashboardTableHeaderIds = {
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  recordings: 'recordings',
  eventType: 'eventType',
  attendees: 'attendees',
  emotion: 'emotion',
  engagement: 'engagement',
  positivity: 'positivity',
  green: 'green',
  yellow: 'yellow',
  red: 'red',
  intervieweeTalkTimePercentage: 'intervieweeTalkTimePercentage',
  confidentTone: 'confidentTone',
  stressedTone: 'stressedTone',
  fatiguedTone: 'fatiguedTone',
};
