import { Box, Typography } from '@mui/material';
import React from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import useToneStatSeriesData from './useToneStatData';
import ReactECharts from 'echarts-for-react';

function ToneStatGraph({
  ProcessDataListForParticipantsVersion2,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
}) {
  const seriesData = useToneStatSeriesData(
    ProcessDataListForParticipantsVersion2
  );

  const option = {
    padding:2,
    title: {
      text: 'Tonal Performance ',
      textStyle :{
        fontSize:'10px',
        color:'black',
        marginTop:'10px'
      }

    },
    legend: {
      show: false,
     
     
    },
    tooltip: {
      formatter: function (params: any) {
        return params.seriesName + ': ' + params.value[1].toFixed(2);
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      data: (ProcessDataListForParticipantsVersion2[0]?.prompt_sections || [])
        .map((_, index: number) => `P${index + 1}`),
    },
    yAxis: {
      min: 0,
      max:100,
      type: 'value',
      axisTick: {
        show: false,
      }
    },
    series: seriesData.map((series) => ({
      name: series.label,
      data: series.data.map((value, index) => [index, value]),
      type: 'bar',
      stack: 'total',
      colorBy:series.color,
      itemStyle: {
        color: series.color,
      }
     
    }))
  };
  return (
    <Box sx={{ width: 'auto',height: 'fit-content', }}>
    
      <ReactECharts option={option} />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'center' }}>
        {seriesData.map((series, index) => (
           <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: series.color,
                  marginRight: 1,
                }}
              />
              <Typography sx={{ fontFamily: 'Inter', fontSize: '10px', fontWeight: 600, lineHeight: '8.47px', letterSpacing: '-0.23539972305297852px', textAlign: 'center' }}>
              {series.label}%
             </Typography>

            </Box>
            <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, lineHeight: '13.18px', letterSpacing: '-0.47079944610595703px', textAlign: 'center',mt:1 }}>  {`${
            ProcessDataListForParticipantsVersion2[0]?.[series.id as keyof CoachingSessionReport] || 0
          }%`}</Typography>
          </Box>
        ))}
      </Box> 
    </Box>
  );
}

export default ToneStatGraph;
