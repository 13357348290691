/** @format */

import { getFeatureFlagsSelector } from 'redux/selectors/selectors';
import { useAppSelector } from 'redux/store';
import { FeatureFlagsData } from 'types/common/Feature-flags-types';
import { cleanString } from 'utils/cleanString';

function useFeatureFlag() {
  const { featureFlags } = useAppSelector(getFeatureFlagsSelector);

  function isFeatureEnable(featureName: string) {
    const foundFeature = featureFlags.find(
      (record: FeatureFlagsData) =>
        cleanString(record.feature_name) === cleanString(featureName)
    );
    return foundFeature ? foundFeature._is_enabled : false;
  }

  return { isFeatureEnable };
}

export default useFeatureFlag;
