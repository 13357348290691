/** @format */

import PageNotFoundComponent from 'components/PageNotFound/PageNotFound';
import React from 'react';

function PageNotFound() {
  return <PageNotFoundComponent />;
}

export default PageNotFound;
