/** @format */

import { Route, Redirect, RouteProps } from 'react-router-dom';
import React from 'react';
import Loader from 'widgets/Loader/Loader';
import { OpenRoutes } from 'routes/routes';

const AdminRoute = ({ component: Component, ...rest }: RouteProps) => {
  const isAdmin = sessionStorage.getItem('userRole') === 'Admin';
  const isAuthenticated = sessionStorage.getItem('apiKey');

  if (!Component) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin && isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={OpenRoutes.signInRoute} />
        )
      }
    />
  );
};

export default AdminRoute;
