import React, { useRef, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';

interface VideoPreviewPlayerProps {
  mediaBlobUrl: string | null | undefined;
  isPlaying: boolean;
}

const VideoPreviewPlayer: React.FC<VideoPreviewPlayerProps> = ({
  mediaBlobUrl,
  isPlaying,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (videoRef.current) {
      videoRef.current.currentTime = newValue as number;
      setCurrentTime(newValue as number);
    }
  };

  const handleVideoTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  return (
    <video
      ref={videoRef}
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
      onTimeUpdate={handleVideoTimeUpdate}
      controls
      controlsList='nodownload noplaybackrate '
      disablePictureInPicture
      disableRemotePlayback
    >
      {mediaBlobUrl && <source src={mediaBlobUrl} type='video/mp4' />} Your
      browser does not support the video tag.
    </video>
  );
};

export default VideoPreviewPlayer;
