import { useEffect, useState } from 'react';
import { CoachingSessionReport } from 'types/VideoPreview-types';

type CustomSeriesType = {
  data: number[];
  label: string;
  id: string;
 
  color: string;
  Type:string;
};

function usePerformanceStatSeriesData(
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>
): CustomSeriesType[] {
  const [seriesData, setSeriesData] = useState<CustomSeriesType[]>([]);

  useEffect(() => {
    if (ProcessDataListForParticipantsVersion2.length > 0) {
      const engagementScore: number[] = [];
      const positivityScore: number[] = [];
      const energyScore: number[] = [];
      const variationScore: number[] = [];

      const promptSections =
        ProcessDataListForParticipantsVersion2[0]?.prompt_sections;

      if (Array.isArray(promptSections)) {
        promptSections.forEach((section: any) => {
          const prompt = section;

          engagementScore.push(prompt?.engagement_score || 0);
          positivityScore.push(prompt?.positivity_score || 0);
          energyScore.push(prompt?.overall_energy || 0);
          variationScore.push(prompt?.variation_score || 0);
        });
      }

      setSeriesData([
        {
          data: engagementScore,
          label: 'Engagement',
          id: 'pvId',
         
          color: '#07dcaa',
          Type:'line',
        },
        {
          data: positivityScore,
          label: 'Positivity',
          id: 'uvId',
         
          color: '#f6d41f',
          Type:'line',
        },
        {
          data: energyScore,
          label: 'Energy',
          id: 'zvId',
        
          color: '#ff0000',
          Type:'line',
        },
        {
          data: variationScore,
          label: 'Variation',
          id: 'vvId',
         
          color: '#62c960',
          Type:'line',
        },
      ]);
    }
  }, [ProcessDataListForParticipantsVersion2]);

  return seriesData;
}

export default usePerformanceStatSeriesData;
