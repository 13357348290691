/** @format */

import { i18n } from 'i18n/i18n';
import {
  getFeatureFlagsLoad,
  getFeatureFlagsSuccess,
  getFeatureFlagsFailure,
  updateFeatureFlagsFailure,
  updateFeatureFlagsLoad,
  updateFeatureFlagsSuccess,
} from 'redux/slices/FeatureFlags-slice';
import { AppThunk } from 'redux/store';
import { AdminUrls } from 'routes/urls';

import commonAPI from 'shared/Api/commonAPI';
import { UpdateFeatureFlagPayload } from 'types/Features-flags-types';
import { Toast } from 'widgets/Toast/Toast';

export const getFeatureFlagsServiceApi = (): AppThunk => async (dispatch) => {
  dispatch(getFeatureFlagsLoad());

  await commonAPI
    .put(AdminUrls.getFeatureFlagApiUrl.url())
    .then((response) => {
      dispatch(getFeatureFlagsSuccess(response.data));
    })
    .catch((error) =>
      dispatch(
        getFeatureFlagsFailure(error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'))
      )
    );
};

export const updateFeatureFlagsServiceApi =
  (payload: UpdateFeatureFlagPayload): AppThunk =>
  async (dispatch) => {
    dispatch(updateFeatureFlagsLoad());

    await commonAPI
      .put(AdminUrls.updateFeatureFlagApiUrl.url(payload))
      .then((response) => {
        dispatch(updateFeatureFlagsSuccess(response.data));
        dispatch(getFeatureFlagsServiceApi());
        Toast(i18n.t('toastMessageNotifications.FeatureUpdatedSuccessfully'), 'success');
      })
      .catch((error) =>
        dispatch(
          updateFeatureFlagsFailure(
            error || i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
          )
        )
      );
  };
