import { IconButton, Paper } from '@mui/material';
import React from 'react';

function SsoIconButton({
  onCLickSsoButton,
  SsoIcon,
}: {
  onCLickSsoButton: () => void;
  SsoIcon: React.ElementType;
}) {
  return (
    <IconButton
      onClick={onCLickSsoButton}
      sx={{
        border: '2px solid #E0E0E9',
        borderRadius: 5,
        p: 2,
        background: '#FFF',
      }}
    >
      <SsoIcon />
    </IconButton>
  );
}

export default SsoIconButton;
