/** @format */

import React from 'react';

function SortTableDataIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='12'
      viewBox='0 0 6 12'
      fill='none'
    >
      <path
        d='M0.705 7.30339L3 10.0569L5.295 7.30339L6 8.15109L3 11.7584L3.86231e-08 8.15109L0.705 7.30339Z'
        fill='#2C2C2C'
      />
      <path
        d='M5.295 5.07589L3 2.32234L0.705 5.07589L-3.86231e-08 4.22818L3 0.620911L6 4.22818L5.295 5.07589Z'
        fill='#2C2C2C'
      />
    </svg>
  );
}

export default SortTableDataIcon;
