/** @format */

import React from 'react';

function OpenDashboardIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='14'
      viewBox='0 0 28 14'
      fill='none'
    >
      <path
        d='M24 9.295L26.4723 7L24 4.705L24.7611 4L28 7L24.7611 10L24 9.295Z'
        fill='#2C2C2C'
      />
      <rect width='19' height='2' rx='1' fill='#2C2C2C' />
      <rect y='6' width='19' height='2' rx='1' fill='#2C2C2C' />
      <rect y='12' width='19' height='2' rx='1' fill='#2C2C2C' />
    </svg>
  );
}

export default OpenDashboardIcon;
