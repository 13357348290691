import React from 'react';

export default function TipsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='13'
      viewBox='0 0 11 13'
      fill='none'
    >
      <path
        d='M3.20511 8.81609C3.20511 7.50536 2.10082 6.40547 1.50541 5.59262C1.17461 5.14126 1.00099 4.63689 1 4.1244C1 2.39963 3.14972 1 5.50022 1'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
      <path
        d='M7.79487 8.81609C7.79487 7.50536 8.89915 6.40547 9.49457 5.59262C9.82537 5.14126 9.99898 4.63689 9.99998 4.1244C9.99998 2.39963 7.85026 1 5.49976 1'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
      <path
        d='M8.12748 8.745H3.04223C2.63692 8.745 2.30835 8.9861 2.30835 9.28351V9.28605C2.30835 9.58346 2.63692 9.82457 3.04223 9.82457H8.12748C8.53279 9.82457 8.86136 9.58346 8.86136 9.28605V9.28351C8.86136 8.9861 8.53279 8.745 8.12748 8.745Z'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
      <path
        d='M7.72585 9.82202H3.44372C3.03841 9.82202 2.70984 10.0631 2.70984 10.3605V10.3631C2.70984 10.6605 3.03841 10.9016 3.44372 10.9016H7.72585C8.13116 10.9016 8.45973 10.6605 8.45973 10.3631V10.3605C8.45973 10.0631 8.13116 9.82202 7.72585 9.82202Z'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
      <path
        d='M6.70131 10.9016V11.115C6.71344 11.2278 6.69362 11.3413 6.6431 11.4482C6.59259 11.5552 6.51246 11.6533 6.40777 11.7364C6.30308 11.8195 6.17608 11.8858 6.03477 11.9312C5.89346 11.9766 5.74088 12 5.58664 12C5.43239 12 5.27982 11.9766 5.1385 11.9312C4.99719 11.8858 4.8702 11.8195 4.7655 11.7364C4.66081 11.6533 4.58069 11.5552 4.53017 11.4482C4.47966 11.3413 4.45984 11.2278 4.47197 11.115V10.9016'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
      <path
        d='M2.63354 3.93387C2.65083 3.6838 2.73512 3.43868 2.88161 3.21254C3.02809 2.98639 3.23389 2.78365 3.48724 2.61591C3.74059 2.44817 4.03652 2.31872 4.3581 2.23497C4.67967 2.15122 5.0206 2.11481 5.36137 2.12782'
        stroke='#4A4A4A'
        stroke-width='0.7'
      />
    </svg>
  );
}
