import { Box } from '@mui/material';
import React from 'react';

import { CoachingSessionReport } from 'types/VideoPreview-types';
import usePerformanceStatSeriesData from './usePerformanceStatData';
import ReactECharts from 'echarts-for-react';

function PerformanceStatGraph({
  ProcessDataListForParticipantsVersion2,
}: {
  ProcessDataListForParticipantsVersion2: Array<CoachingSessionReport>;
}) {
  
  const seriesData = usePerformanceStatSeriesData(
    ProcessDataListForParticipantsVersion2
  );

 
  const option = {
   height:'fit-content',
    title: {
      text: 'Tonal Performance ',
      textStyle :{
        fontSize:'10px',
        color:'black',
        marginTop:-2,
      }

    },
    legend: {
      show: true,
      bottom:-5,
      padding: [2, 3, 5, 3], 
      itemWidth: 10,
      itemHeight: 0,
      textStyle: {
        color: '#333',
      },
    },
    
    tooltip: {
      formatter: function (params: any) {
        return params.seriesName + ': ' + params.value[1].toFixed(2);
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      data: (ProcessDataListForParticipantsVersion2[0]?.prompt_sections || [])
        .map((_, index: number) => `P${index + 1}`),
    },
    yAxis: {
      min: 0,
      max:100,
      type: 'value',
      axisTick: {
        show: false,
      }
    },
    series: seriesData.map((series, index) => ({
      name: series.label,
      data: series.data.map((value, index) => [index, value]),
      symbolSize: 0,
      type: 'line', // Specify the type as 'line'
      lineStyle: {
        symbolSize: 0,
        color: series.color,
        width: 2,
        type: index === 0 ? [0.5, 1,1,0.5] : index === 1 ? [1, 2, 1, 2] : index === 2 ? [0.5, 1, 1.5, 3] : [1.74,3.48, 5.2],
       
        dashoffset: 0, 
      }
    }))
  };
  

  return (
    <Box sx={{ width: 'auto',height: 'auto',minHeight:330, }}>
    
      <ReactECharts option={option} />
    </Box>
  );
}

export default PerformanceStatGraph;
