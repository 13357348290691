/** @format */

import languages from 'constants/languageCodes';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AdminUrls } from 'routes/urls';
import commonAPI from 'shared/Api/commonAPI';
import { generateAdminKey } from 'utils/generateAdminKey';

const supportedLanguages = languages.map(lang => lang.code);
i18n.use(initReactI18next).init({
  lng: detectBrowserLanguage(),
  fallbackLng: 'en',
  resources: {},
  interpolation: {
    escapeValue: false,
  },
});
function detectBrowserLanguage() {  
    const userLanguages = window.navigator.languages || [
    window.navigator.language,
  ];
  for (const lang of userLanguages) {
    const languageCode = lang.split('-')[0];
    if (supportedLanguages.includes(languageCode)) {
      return languageCode;
    }
  }
  return 'en';
}

// static call for locale folder
// const loadTranslations = async (language: string) => {
//   const translationModule = await import(`locales/${language}.json`);
//   i18n.addResourceBundle(
//     language,
//     'translation',
//     translationModule.default,
//     true
//   );
//   return translationModule.default;
// };

const loadTranslations = async (language: string) => {
  const headers = {
    'x-api-key': await generateAdminKey(),
  };

  // temporary only english translation is available at backend
  // application_type_id is the application type eg. react-web, react-mobile etc.
  // in future user should be able to change the language

  const payload = {
    language,
    application_type_id: 3,
  };

  const response = await commonAPI.get(AdminUrls.GetLocalizedStrings.url(payload), {
    headers,
  });

  if (response.data.length > 0 && response.data[0].location !== null) {
    const translationModule = response.data[0].location;

    i18n.addResourceBundle(
      language,
      'translation',
      { ...translationModule },
      true
    );
    return translationModule
  } 
  else {
    const englishPayload = {
      language: 'english',
      application_type_id: 3,
    };

    const englishResponse = await commonAPI.get(AdminUrls.GetLocalizedStrings.url(englishPayload), {
      headers,
    });

    const englishTranslationModule = englishResponse.data[0].location;

    i18n.addResourceBundle(
      language,
      'translation',
      { ...englishTranslationModule },
      true
    );
    return englishTranslationModule
  }
};
export { i18n, loadTranslations };
