import { createSlice } from '@reduxjs/toolkit';
import { UserRolesInitialStateTypes } from 'types/Dashboard-types';

const UserRolesInitialState: UserRolesInitialStateTypes = {
  isLoadingUserRoles: false,
  errorUserRoles: '',
  UserRoles: [],
};

const UserRolesSlice = createSlice({
  name: 'api',
  initialState: UserRolesInitialState,
  reducers: {
    UserRolesLoad(state) {
      state.isLoadingUserRoles = true;
    },
    UserRolesSuccess(state, action) {
      state.isLoadingUserRoles = false;
      state.UserRoles = action.payload;
    },
    UserRolesFailure(state, action) {
      state.isLoadingUserRoles = false;
      state.errorUserRoles = action.payload;
    },
  },
});

export const { UserRolesLoad, UserRolesFailure, UserRolesSuccess } =
  UserRolesSlice.actions;
export { UserRolesSlice };
