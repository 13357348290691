import React from 'react';
import { Box, Paper, useMediaQuery } from '@mui/material';
import Typography from 'widgets/Typography/Typography';
import SandboxPreviewWrapper from 'shared/SandboxPreviewWrapper/SandboxPreviewWrapper';
import VisitorValidationForm from './VistorValidationForm';

function VisitorValidation() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  return (
    <SandboxPreviewWrapper>
      <Box
        sx={{
          width: '95vw',
          height: '100%',
          maxHeight: '90vh',
          overflow: 'hidden',
          background: '#FBFBFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            textAlign: 'center',
            maxWidth: isMobile ? '90%' : isTablet ? '80%' : '60%',
            minWidth: isMobile ? '300px' : isTablet ? '400px' : '450px',
            padding: isMobile ? 2 : 3,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'fit-content',
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              mb: 2,

              maxHeight: '20px',
            }}
          >
            <Typography sx={{ fontSize: '12px' }} label='loginpage.PoweredBy' />

            <Box
              component='img'
              alt='mirro-logo-with-name'
              src='./comonImg/mirro-logo-with-namee.png'
              sx={{
                width: isMobile ? '100px' : 'auto',
                height: '100%',
                cursor: 'pointer',
                maxWidth: '100%',
                maxHeight: '20px',
              }}
            />
          </Box>

          <VisitorValidationForm />
        </Paper>
      </Box>
    </SandboxPreviewWrapper>
  );
}

export default VisitorValidation;
