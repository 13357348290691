import React from 'react';

export default function SaveIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
    >
      <g filter='url(#filter0_d_972_1183)'>
        <g filter='url(#filter1_i_972_1183)'>
          <circle cx='18' cy='16' r='15' fill='#1206F9' />
        </g>
        <path
          d='M11.1821 16.0001L15.9549 20.7728L25.5003 10.5455'
          stroke='white'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_972_1183'
          x='0.142857'
          y='0.285714'
          width='35.7143'
          height='35.7143'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.14286' />
          <feGaussianBlur stdDeviation='1.42857' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_972_1183'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_972_1183'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_i_972_1183'
          x='3'
          y='1'
          width='30'
          height='30'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='0.714286'
            operator='erode'
            in='SourceAlpha'
            result='effect1_innerShadow_972_1183'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='0.714286' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0705882 0 0 0 0 0.0235294 0 0 0 0 0.976471 0 0 0 0.23 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_972_1183'
          />
        </filter>
      </defs>
    </svg>
  );
}
