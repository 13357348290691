import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Box,
  IconButton,
} from '@mui/material';
import { t } from 'i18next';
import CircularProgressWithLabel from 'widgets/CircularProgressWithLabel/CircularProgressWithLabel';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';

export default function RecordedVideoUploadStatusModal({
  openRecordedVideoUploadStatusModal,
  handleClosedRecordedVideoUploadStatusModal,
  UploadSingleFileProgressStatus,
  handleCancelUpload,
  handleRetryUpload,
  uploadFileStatus,
}: {
  openRecordedVideoUploadStatusModal: boolean;
  handleCancelUpload: () => void;
  handleRetryUpload: () => void;
  handleClosedRecordedVideoUploadStatusModal: () => void;
  UploadSingleFileProgressStatus: any;
  uploadFileStatus: string;
}) {
  return (
    <React.Fragment>
      <Dialog
        maxWidth='md'
        fullWidth
        open={openRecordedVideoUploadStatusModal}
        onClose={handleClosedRecordedVideoUploadStatusModal}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
          File Upload Status
        </DialogTitle>
        {uploadFileStatus === 'canceled' && (
          <IconButton
            aria-label='close'
            onClick={handleClosedRecordedVideoUploadStatusModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}{' '}
        <DialogContent dividers={true}>
          <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography label='upload.FileName' />
                    </TableCell>
                    <TableCell align='center'>
                      <Typography label='upload.Status' />
                    </TableCell>
                    <TableCell align='center'>
                      <Typography label='upload.Actions' />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={UploadSingleFileProgressStatus.fileName}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      {UploadSingleFileProgressStatus.fileName}
                    </TableCell>
                    <TableCell align='center'>
                      {uploadFileStatus === 'canceled' ? (
                        <Typography
                          sx={{ color: 'red' }}
                          label='upload.Canceled'
                        />
                      ) : (
                        <CircularProgressWithLabel
                          value={UploadSingleFileProgressStatus.uploadProg}
                        />
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {uploadFileStatus === 'canceled' ? (
                        <Button
                          sx={{ color: 'black' }}
                          onClick={handleRetryUpload}
                          label='upload.Retry'
                        />
                      ) : (
                        <Button
                          sx={{ color: 'black' }}
                          onClick={handleCancelUpload}
                          label='upload.Cancel'
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
