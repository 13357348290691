import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Box,
} from '@mui/material';
import { t } from 'i18next';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Clock from 'widgets/Clock';
import { recordingTypeList } from 'constants/Record';
import Typography from 'widgets/Typography/Typography';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import getMediaDevices from 'utils/getMediaDevices';
import { THSType } from 'types/MyAssessments-types';
import generateCandidateVideoDesc from 'utils/generateCandidateVideoDesc';
import { CandidateDetailsType } from 'types/SendAssessments-types';

export default function RecorderSettingsMenu({
  setMainRecordingSettings,
  mainRecordingSettings,
  anchorEl,
  handleCloseRecorderSettingsDialog,
  mediaRecorderStatus,
  isFromCandidateAssessment,
  candidateDetails,
}: {
  setMainRecordingSettings: Function;
  mainRecordingSettings: THSType;
  anchorEl: null | HTMLElement;
  handleCloseRecorderSettingsDialog: () => void;
  mediaRecorderStatus: string | null;
  isFromCandidateAssessment: boolean;
  candidateDetails: CandidateDetailsType;
}) {
  const open = Boolean(anchorEl);
  const [cameras, setCameras] = React.useState<Array<CamerasType>>([]);
  const [microphones, setMicrophones] = React.useState<Array<MicrophonesType>>(
    []
  );
  React.useEffect(() => {
    getMediaDevices(setCameras, setMicrophones);
  }, []);
  React.useEffect(() => {
    if (cameras.length > 0) {
      setMainRecordingSettings((prev: THSType) => ({
        ...prev,
        cameraDetails: cameras[0],
      }));
    }
  }, [cameras]);
  React.useEffect(() => {
    if (microphones.length > 0) {
      setMainRecordingSettings((prev: THSType) => ({
        ...prev,
        microphoneDetails: microphones[0],
      }));
    }
  }, [microphones]);
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseRecorderSettingsDialog}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        style: {
          width: '30%',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
        <MenuItem>
          <TextField
            name='videoDescription'
            size='small'
            variant='outlined'
            fullWidth
            id='videoDescription'
            className='overlap-2'
            InputProps={{
              style: {
                borderRadius: '35px',
                fontFamily: 'poppins',
              },
              endAdornment: (
                <InputAdornment position='end' sx={{ opacity: '0.7' }}>
                  <IconButton
                    aria-label='video-description'
                    onClick={() => {
                      setMainRecordingSettings((prev: THSType) => ({
                        ...prev,
                        videoDescription: isFromCandidateAssessment
                          ? generateCandidateVideoDesc(candidateDetails)
                          : getDefaultVideoDescription(),
                      }));
                    }}
                  >
                    <RefreshOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            defaultValue={
              isFromCandidateAssessment
                ? generateCandidateVideoDesc(candidateDetails)
                : getDefaultVideoDescription()
            }
            label={t('record.VideoDescription')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMainRecordingSettings((prev: THSType) => ({
                ...prev,
                videoDescription: event.target.value,
              }));
            }}
            value={mainRecordingSettings.videoDescription}
            helperText={
              mainRecordingSettings.videoDescription === ''
                ? t('record.VideoDescriptionIsRequired')
                : ''
            }
            error={mainRecordingSettings.videoDescription === ''}
          />
        </MenuItem>
        <MenuItem disabled={isFromCandidateAssessment}>
          <Autocomplete
            disablePortal
            disabled={
              mediaRecorderStatus === 'recording' || isFromCandidateAssessment
                ? true
                : false
            }
            id='combo-box-recording-types'
            size='small'
            options={recordingTypeList}
            getOptionLabel={(option) => t(option.name)}
            sx={{ fontFamily: 'Poppins' }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('record.RecordingType')}
                helperText={
                  mainRecordingSettings.recordingType &&
                  mainRecordingSettings.recordingType.id === -1
                    ? t('record.RecordingTypeIsRequired')
                    : ''
                }
                error={
                  mainRecordingSettings.recordingType &&
                  mainRecordingSettings.recordingType.id === -1
                }
              />
            )}
            onChange={(_event, newValue) => {
              if (newValue) {
                setMainRecordingSettings((prev: THSType) => ({
                  ...prev,
                  recordingType: newValue,
                }));
              }
            }}
            value={mainRecordingSettings.recordingType}
          />
        </MenuItem>
        <MenuItem sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography
            sx={{
              color: '#33344B ',
              fontFamily: 'Poppins ',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '23px',
            }}
            label='record.RecordingSettings'
          />{' '}
          <Autocomplete
            disabled={
              mainRecordingSettings.recordingType &&
              mainRecordingSettings.recordingType.id === 2
            }
            fullWidth
            onChange={(_event, newVal) => {
              if (newVal)
                setMainRecordingSettings((prev: THSType) => ({
                  ...prev,
                  cameraDetails: newVal,
                }));
            }}
            size='small'
            value={mainRecordingSettings.cameraDetails}
            options={cameras}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('record.SelectCamera')}
                variant='outlined'
                helperText={
                  mainRecordingSettings.cameraDetails.deviceId === ''
                    ? t('record.PleaseSelectCamera')
                    : ''
                }
                error={mainRecordingSettings.cameraDetails.deviceId === ''}
              />
            )}
          />{' '}
          <Autocomplete
            fullWidth
            onChange={(_event, newVal) => {
              if (newVal) {
                setMainRecordingSettings((prev: THSType) => ({
                  ...prev,
                  microphoneDetails: newVal,
                }));
              }
            }}
            size='small'
            value={mainRecordingSettings.microphoneDetails}
            options={microphones}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('record.SelectMicrophone')}
                variant='outlined'
                helperText={
                  mainRecordingSettings.microphoneDetails.deviceId === ''
                    ? t('record.PleaseSelectMicrophone')
                    : ''
                }
                error={mainRecordingSettings.microphoneDetails.deviceId === ''}
              />
            )}
          />
        </MenuItem>{' '}
      </Box>
    </Menu>
  );
}
