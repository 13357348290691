/** @format */

import React from 'react';

export default function EditTitleIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      viewBox='0 0 15 15'
      fill='none'
    >
      <g opacity='0.6'>
        <path
          d='M6.81472 2.37054H2.29216C1.94946 2.37054 1.62079 2.50668 1.37846 2.74901C1.13614 2.99134 1 3.32 1 3.6627V12.7078C1 13.0505 1.13614 13.3792 1.37846 13.6215C1.62079 13.8638 1.94946 14 2.29216 14H11.3373C11.68 14 12.0086 13.8638 12.251 13.6215C12.4933 13.3792 12.6294 13.0505 12.6294 12.7078V8.18526'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M11.6603 1.40142C11.9173 1.1444 12.2659 1 12.6294 1C12.9929 1 13.3415 1.1444 13.5985 1.40142C13.8556 1.65845 14 2.00705 14 2.37054C14 2.73403 13.8556 3.08264 13.5985 3.33966L7.46078 9.47742L4.87646 10.1235L5.52254 7.53918L11.6603 1.40142Z'
          stroke='black'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  );
}
