/** @format */

import { Typography } from '@mui/material';
import React from 'react';

function BulbOn() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='42'
      height='47'
      viewBox='0 0 42 47'
      fill='none'
    >
      <path
        d='M34.7139 20.8576L40.9999 19.9304'
        stroke='#1206F9'
        stroke-width='2'
      />
      <path
        d='M32.7485 15.4215L36.6793 13.2456'
        stroke='#1206F9'
        stroke-width='2'
      />
      <path d='M28.7378 11.3987L31.3795 6' stroke='#1206F9' stroke-width='2' />
      <path
        d='M21.2267 10.6211L21.1948 6.33655'
        stroke='#1206F9'
        stroke-width='2'
      />
      <path d='M13.938 11.6679L11.8135 6' stroke='#1206F9' stroke-width='2' />
      <path
        d='M8.92572 15.3618L4.92334 13.313'
        stroke='#1206F9'
        stroke-width='2'
      />
      <path d='M7.16669 20.5512L1 19.5717' stroke='#1206F9' stroke-width='2' />
      <path
        d='M15.4739 36.6277C15.4739 32.7693 12.9356 29.5315 11.567 27.1388C10.8066 25.8101 10.4076 24.3254 10.4053 22.8168C10.4053 17.7396 15.3466 13.6195 20.7494 13.6195'
        fill='#1206F9'
      />
      <path
        d='M15.4739 36.6277C15.4739 32.7693 12.9356 29.5315 11.567 27.1388C10.8066 25.8101 10.4076 24.3254 10.4053 22.8168C10.4053 17.7396 15.3466 13.6195 20.7494 13.6195'
        stroke='#1206F9'
      />
      <path
        d='M26.024 36.6277C26.024 32.7693 28.5623 29.5315 29.9309 27.1388C30.6913 25.8101 31.0904 24.3254 31.0927 22.8168C31.0927 17.7396 26.1514 13.6195 20.7485 13.6195'
        fill='#1206F9'
      />
      <path
        d='M26.024 36.6277C26.024 32.7693 28.5623 29.5315 29.9309 27.1388C30.6913 25.8101 31.0904 24.3254 31.0927 22.8168C31.0927 17.7396 26.1513 13.6195 20.7485 13.6195'
        stroke='#1206F9'
      />
      <path
        d='M26.7888 36.4185H15.1C14.1683 36.4185 13.4131 37.1282 13.4131 38.0037V38.0112C13.4131 38.8867 14.1683 39.5964 15.1 39.5964H26.7888C27.7205 39.5964 28.4757 38.8867 28.4757 38.0112V38.0037C28.4757 37.1282 27.7205 36.4185 26.7888 36.4185Z'
        fill='#1206F9'
        stroke='white'
        stroke-width='0.5'
      />
      <path
        d='M25.8657 39.5887H16.0228C15.0912 39.5887 14.3359 40.2985 14.3359 41.174V41.1814C14.3359 42.0569 15.0912 42.7667 16.0228 42.7667H25.8657C26.7973 42.7667 27.5525 42.0569 27.5525 41.1814V41.174C27.5525 40.2985 26.7973 39.5887 25.8657 39.5887Z'
        fill='#1206F9'
        stroke='white'
        stroke-width='0.5'
      />
      <path
        d='M23.5107 42.7667V43.3948C23.5386 43.727 23.493 44.0609 23.3769 44.3757C23.2608 44.6905 23.0766 44.9794 22.836 45.224C22.5953 45.4687 22.3034 45.664 21.9786 45.7975C21.6538 45.931 21.3031 46 20.9485 46C20.594 46 20.2433 45.931 19.9185 45.7975C19.5936 45.664 19.3017 45.4687 19.0611 45.224C18.8205 44.9794 18.6363 44.6905 18.5202 44.3757C18.4041 44.0609 18.3585 43.727 18.3864 43.3948V42.7667'
        fill='#1206F9'
      />
      <path
        d='M23.5107 42.7667V43.3948C23.5386 43.727 23.493 44.0609 23.3769 44.3757C23.2608 44.6905 23.0766 44.9794 22.836 45.224C22.5953 45.4687 22.3034 45.664 21.9786 45.7975C21.6538 45.931 21.3031 46 20.9485 46C20.594 46 20.2433 45.931 19.9185 45.7975C19.5936 45.664 19.3017 45.4687 19.0611 45.224C18.8205 44.9794 18.6363 44.6905 18.5202 44.3757C18.4041 44.0609 18.3585 43.727 18.3864 43.3948V42.7667'
        stroke='white'
        stroke-width='0.5'
      />
      <path
        d='M15.4263 18.4082C15.4263 15.9229 17.441 13.9082 19.9263 13.9082H21.7461C24.2314 13.9082 26.2461 15.9229 26.2461 18.4082V35.3734H20.8362H15.4263V18.4082Z'
        fill='#1206F9'
      />
      <path
        d='M12.959 21.1262C12.9987 20.39 13.1925 19.6685 13.5292 19.0028C13.8659 18.3371 14.3389 17.7403 14.9213 17.2465C15.5036 16.7527 16.1838 16.3717 16.923 16.1251C17.6622 15.8786 18.4458 15.7714 19.2291 15.8097'
        fill='#1206F9'
      />
      <path
        d='M12.959 21.1262C12.9987 20.39 13.1925 19.6685 13.5292 19.0028C13.8659 18.3371 14.3389 17.7403 14.9213 17.2465C15.5036 16.7527 16.1838 16.3717 16.923 16.1251C17.6622 15.8786 18.4458 15.7714 19.2291 15.8097'
        stroke='white'
      />
      <path
        d='M17.8306 28.0061V20.203C17.8306 19.4236 18.6826 18.9438 19.349 19.3478L25.9919 23.3753C26.6446 23.771 26.6312 24.7226 25.9676 25.0998L19.3247 28.8755C18.6581 29.2544 17.8306 28.7729 17.8306 28.0061Z'
        fill='white'
      />
    </svg>
  );
}

export default BulbOn;
